import React from 'react';
import { TVideoSuggestion } from '../../services/BffService/Video';
import styled from 'styled-components';
import { VideoThumb } from '../../components/VideoThumb/VideoThumb';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../../components/Text/Text';

export interface TSuggestionProps {
  suggestion: TVideoSuggestion;
}

export const Suggestion: React.FC<TSuggestionProps> = (props) => {
  const { suggestion } = props;
  const { id, scaledPoster, titleSuggestion, subtitleSuggestion, duration } = suggestion;
  const thumbnailUrl = scaledPoster?.small ?? null;
  return (
    <StyledWrap>
      {thumbnailUrl && <StyledVideoThumb thumbnailUrl={thumbnailUrl} id={id} duration={duration} />}
      <StyledTitle>{titleSuggestion}</StyledTitle>
      {subtitleSuggestion && <StyledSubtitle>{subtitleSuggestion}</StyledSubtitle>}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.large}px;
    }
    ${mediaQuery.tabletLandscape(`
      ${mediaQuery.netflixWidthQuery(theme.components.swimlane.images.suggestions.width)}
      &:not(:last-child) {
        margin-right: ${theme.components.swimlane.horizontalSpacing}px;
      }
    `)}
  `}
`;

const StyledVideoThumb = styled(VideoThumb)`
  ${({ theme }) => `
    ${mediaQuery.netflixQuery(
      theme.components.swimlane.images.suggestions.width,
      theme.components.swimlane.images.suggestions.height
    )}
  `};
`;

const StyledTitle = styled(Text).attrs({ tag: 'h3' })`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledSubtitle = styled(Text).attrs({ tag: 'h5' })``;
