import React from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner } from '../../components/Spinner/Spinner';
import Services from '../../services/Services';

export interface TRedirectPageProps {
  to: string;
}

export const RedirectPage: React.FC<TRedirectPageProps> = (props) => {
  const { to } = props;
  const linkType = Services.linkService.getLinkType(to);

  React.useEffect(() => {
    if (linkType !== 'internal') {
      window.location.href = to;
    }
  }, [to, linkType]);

  return linkType === 'internal' ? <Redirect to={to} /> : <Spinner />;
};
