import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { Peetie } from '../Peetie/Peetie';
import { TPeetie } from '../Peetie/types';

type TProps = {
  data: TPeetie;
};

export const SwimlanePeetieItem = ({ data }: TProps) => {
  return (
    <StyledWrap>
      <Peetie data={data} />
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    ${mediaQuery.netflixQuery(
      theme.components.swimlane.images.heroes.width,
      theme.components.swimlane.images.heroes.height
    )}
    cursor: pointer;
    margin-right: ${theme.components.swimlane.horizontalSpacing}px;
  `}
`;
