import React, { RefObject } from 'react';
import styled from 'styled-components';
import { TStorySummary } from './types';
import Flickity from 'flickity';
import { StoryContext, TStoryContextValue } from './StoryContext';
import { isNumber } from 'lodash-es';
import { TSwimlaneStoriesItem } from '../Swimlane/types';
import { Story } from './Story';
import { nextIndex } from '../../lib/indices';
import Services from '../../services/Services';
import { WatchedStoryMarker } from './WatchedStoryMarker';

export interface TStoriesSwimlaneProps {
  initialStory: TSwimlaneStoriesItem;
  size: TStoriesSize;
  onClose: () => any;
}

interface TStoriesSwimlaneState {
  activeStoryIndex: number;
  hasRequestedPause: boolean;
}

export interface TStoriesSize {
  width: number;
  height: number;
  mode: 'single' | 'multi';
}

export class StoriesSwimlane extends React.Component<TStoriesSwimlaneProps, TStoriesSwimlaneState> {
  private flktyContainerRef: RefObject<HTMLDivElement>;

  private flkty: Flickity | null;

  private stories: TStorySummary[];

  constructor(props: TStoriesSwimlaneProps) {
    super(props);
    this.flktyContainerRef = React.createRef<HTMLDivElement>();
    this.flkty = null;
    this.stories = props.initialStory.items;
    this.state = {
      activeStoryIndex: props.initialStory.items.indexOf(props.initialStory) ?? 0,
      hasRequestedPause: false,
    };
  }

  private requestPause = () => {
    this.setState({ hasRequestedPause: true });
  };

  private gotoNextStory = () => {
    // do nothing when pause was requested
    if (this.state.hasRequestedPause) return;
    if (this.flkty) {
      const next = nextIndex(this.state.activeStoryIndex, this.stories.length);
      this.flkty.select(next);
    }
  };

  componentWillUnmount() {
    if (this.flkty) {
      this.flkty.destroy();
    }
  }

  componentDidMount() {
    if (!this.flktyContainerRef.current) {
      console.error('flktyContainerRef is not yet set');
      return;
    }
    this.flkty = new Flickity(this.flktyContainerRef.current, {
      initialIndex: this.state.activeStoryIndex,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      on: {
        change: (idx) => {
          if (typeof idx === 'number') {
            this.setState({ activeStoryIndex: idx, hasRequestedPause: false });
          } else {
            Services.loggerService.warn('StoryViewer: flkty on change without valid index');
          }
        },
        staticClick: (evt, _pointer, _cellElement, cellIndex) => {
          if (isNumber(cellIndex) && this.flkty && cellIndex !== this.flkty.selectedIndex) {
            evt?.preventDefault();
            this.flkty.select(cellIndex, true, false);
          }
        },
      },
    });
    // focus container so keyboard input works immediately
    // https://github.com/metafizzy/flickity/issues/84
    this.flktyContainerRef.current.focus();
  }

  render() {
    const { size } = this.props;
    const { activeStoryIndex } = this.state;
    const { stories, gotoNextStory, requestPause } = this;
    const total = stories.length;
    const storyContext: TStoryContextValue = {
      activeStoryIndex,
      requestPause,
      gotoNextStory,
      size,
      total,
    };
    return (
      <StoryContext.Provider value={storyContext}>
        <WatchedStoryMarker stories={stories} />
        <StyledWrap>
          <StyledInner style={{ height: size.height }}>
            <StyledFlickityContainer ref={this.flktyContainerRef}>
              {stories.map((story, idx) => (
                <Story key={story.id} data={story} index={idx} />
              ))}
            </StyledFlickityContainer>
          </StyledInner>
        </StyledWrap>
      </StoryContext.Provider>
    );
  }
}

const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInner = styled.div`
  width: 100%;
`;

const StyledFlickityContainer = styled.div`
  width: 100%;
  & .carousel-cell {
    width: 33%;
    padding: 8px;
  }
`;
