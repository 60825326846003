import BaseService from '../BaseService';
import { IFetchService, IFetchClient } from './IFetchService';
import FetchClient from './FetchClient';

class FetchService extends BaseService implements IFetchService {
  private clients: { [key: string]: IFetchClient } = {};

  public createClient(baseUrl: string) {
    if (!this.clients.hasOwnProperty(baseUrl)) {
      this.clients[baseUrl] = new FetchClient(baseUrl);
    }
    return this.clients[baseUrl];
  }

  public get bffClient(): IFetchClient {
    const baseUrl = this.services.configService.config.bffBaseUrl;
    return this.createClient(baseUrl);
  }
}

export default FetchService;
