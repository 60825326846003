import React from 'react';
import styled from 'styled-components';
import Services from '../../services/Services';
import { TStoryReactionName } from './types';
import { EmojiReaction } from '../EmojiReaction/EmojiReaction';

export interface TStoryReactionProps {
  name: TStoryReactionName;
  isSelected: boolean;
  count: number;
  onSelect: (name: TStoryReactionName) => any;
}

export const StoryReaction: React.FC<TStoryReactionProps> = (props) => {
  const { name, count, isSelected, onSelect } = props;
  const emoji = `/assets/emojis/${name}.png`;
  const onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onSelect(name);
  };

  if (!emoji) {
    Services.loggerService.warn(`Unmapped story emoji: ${name}`);
    return null;
  }

  return (
    <StyledWrap onClick={onClick}>
      <EmojiReaction emojiKind={name} count={count} active={isSelected} />
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  margin: 0;
  height: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
