import { TTheme } from '../../pages/ThemePage/types';
import { pathToTitle } from './metaHelpers';
import { TPageMeta } from './PageMeta';

export function getThemeMeta(path: string, theme: TTheme, canonical: string | null): TPageMeta {
  return {
    title: theme.header.title ?? pathToTitle(path),
    description: theme.header.description ?? 'Een thema van ketnet',
    canonical,
  };
}
