import React from 'react';
import styled from 'styled-components';
import { OverlayContext } from '../../components/Overlay/OverlayContext';
import { Spinner } from '../../components/Spinner/Spinner';
import { Text } from '../../components/Text/Text';
import { TRequestConsentOptions } from '../../services/GamesService/types';
import { WebGamesSDK } from '../../services/GamesService/WebGamesSDK';
import Services from '../../services/Services';
import { RequestConsentModal } from './RequestConsentModal';
import { TGame } from './types';

export interface TGameFrameProps {
  game: TGame;
}

export const GameFrame: React.FC<TGameFrameProps> = (props) => {
  const { imageUrl, gameUrl } = props.game;
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [sdk, setSDK] = React.useState<WebGamesSDK | null>(null);
  const { showLogin } = React.useContext(OverlayContext);
  const [requestConsentOptions, setRequestConsentOptions] = React.useState<TRequestConsentOptions | null>(null);
  const overlay = React.useContext(OverlayContext);
  const [activeGameUrl, setActiveGameUrl] = React.useState<string | null>(gameUrl);

  React.useEffect(() => {
    return () => sdk?.destroy();
  }, [sdk]);

  if (!gameUrl || isError) {
    return <StyledError>Het spel kon niet geladen worden.</StyledError>;
  }

  const handleOnLoad = () => {
    setIsPlaying(true);
    if (!iframeRef.current) {
      Services.loggerService.error('iframe ref was not populated onLoad');
      return;
    }
    const onExit = () => Services.appService.goBack();
    const onTriggerLogin = () => showLogin();
    const onTriggerRequestConsent = (options: TRequestConsentOptions) => {
      // disable fullscreen so the consent modal can be shown
      overlay.disableFullscreen();
      setRequestConsentOptions(options);
    };
    const onTriggerDifferentGame = (gameUrl: string) => {
      setActiveGameUrl(gameUrl);

      if (iframeRef.current) {
        let newGame = props.game;
        newGame.gameUrl = gameUrl;
        Services.gamesService
          .initializeWebSDK(newGame, iframeRef.current, {
            onExit,
            onTriggerLogin,
            onTriggerRequestConsent,
            onTriggerDifferentGame,
          })
          .then((initializedSDK) => setSDK(initializedSDK));
      }
    };

    Services.gamesService
      .initializeWebSDK(props.game, iframeRef.current, {
        onExit,
        onTriggerLogin,
        onTriggerRequestConsent,
        onTriggerDifferentGame,
      })
      .then((initializedSDK) => setSDK(initializedSDK));
  };

  const handleOnClose = (isUpdated: boolean) => {
    if (sdk && isUpdated) {
      sdk
        .refreshData()
        .catch((e) => Services.loggerService.errorObject('sdk.refreshData failed', e))
        // wait to remove the screen until data was refreshed
        .finally(() => {
          overlay.enableFullscreen();
          setRequestConsentOptions(null);
        });
    } else {
      setRequestConsentOptions(null);
    }
  };

  return (
    <>
      {activeGameUrl && (
        <StyledIframe onLoad={handleOnLoad} onError={() => setIsError(true)} src={activeGameUrl} ref={iframeRef}>
          Wordt niet ondersteund door je browser
        </StyledIframe>
      )}

      {!isPlaying && (
        <StyledLauncher>
          {imageUrl && <StyledGameThumb src={imageUrl} />}
          <Spinner />
        </StyledLauncher>
      )}
      {requestConsentOptions && <RequestConsentModal onClose={handleOnClose} options={requestConsentOptions} />}
    </>
  );
};

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const StyledError = styled(Text)`
  width: 100%;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const StyledLauncher = styled.div`
  ${({ theme }) => `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`}
`;

const StyledGameThumb = styled.img`
  ${({ theme }) => `
  width: 200px;
  height: 150px;
  background-color: ${theme.colors.darkPurple};
  margin-bottom: ${theme.spacings.small}px;
`}
`;
