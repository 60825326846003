import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AspectRatio } from '../AspectRatio/AspectRatio';
import { EColor } from '../../theme/styleguide';
import { mediaQuery } from '../../theme/mediaQuery';
import { Duration } from '../Duration/Duration';
import { Text } from '../Text/Text';

export interface TSwimlaneThumbnailsItemProps {
  link: string;
  title: string | null;
  subtitle: string | null;
  subtitleColor?: EColor;
  imageUrl: string | null;
  duration?: number | null;
  icon?: JSX.Element;
}

export const SwimlaneThumbnailsItem: React.FC<TSwimlaneThumbnailsItemProps> = (props) => {
  const { link, title, subtitle, subtitleColor = EColor.yellow, imageUrl, duration, icon } = props;
  return (
    <Link to={link}>
      <StyledWrap>
        <StyledPosterWrap>
          <AspectRatio width={16} height={9}>
            {imageUrl && <StyledPoster style={{ backgroundImage: `url(${imageUrl})` }} />}
            {duration && <Duration duration={duration} />}
            {icon && <StyledIconWrap>{icon}</StyledIconWrap>}
          </AspectRatio>
        </StyledPosterWrap>
        {title && <StyledTitle>{title}</StyledTitle>}
        {subtitle && <StyledSubtitle color={subtitleColor}>{subtitle}</StyledSubtitle>}
      </StyledWrap>
    </Link>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    ${mediaQuery.netflixWidthQuery(theme.components.swimlane.images.thumbnails.width)}
    position: relative;
    margin-right: ${theme.components.swimlane.horizontalSpacing}px;
  `}
`;

const StyledPosterWrap = styled.div`
  position: relative;
  ${({ theme }) => `
    ${mediaQuery.netflixWidthQuery(theme.components.swimlane.images.thumbnails.width)}
    margin-bottom: 12px;
  `}
`;

const StyledPoster = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const StyledTitle = styled(Text).attrs({ tag: 'h4', size: 'medium' })``;

const StyledSubtitle = styled(Text).attrs({ tag: 'h5' })``;

const StyledIconWrap = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 48px;
  height: 48px;
`;
