import { TPostComment, postCommentFragment } from './PostComment';
import { TVrtPlayerConfig, vrtPlayerFragment } from './VrtPlayer';
import { TScaledImage } from './ScaledImage';
import { TEmoji } from '../../lib/emoji';

const name = 'postFragment';

const query = `
fragment ${name} on Post {
  id
  postedOn
  author {
    name
    avatarUrl
  }
  message
  media {
    ... on PostMediaVideo {
      type
      vrtPlayer { ...${vrtPlayerFragment.name} }
      scaledPoster { ...scaledImage }
    }
    ... on PostMediaPicture {
      type
      scaledImage { ...scaledImage }
    }
  }
  paginatedComments(first: $feedPostCommentPageSize){
    edges {
        node {
          ...${postCommentFragment.name}
        }
        cursor
    }
    totalCount
    next
  }
  reactions {
    id
    count
  }
  myReaction
  sticky
  type
  disableComments
}`;

export interface TPost {
  id: string;
  postedOn: string;
  author: TPostAuthor | null;
  message: string | null;
  media: TPostMedia | null;
  paginatedComments: TPostCommentConnection | null;
  reactions: TPostReaction[] | null;
  myReaction: TEmoji | null;
  sticky: boolean;
  type: 'mediaPost';
  disableComments: boolean;
}

export interface TPostAuthor {
  name: string | null;
  avatarUrl: string;
}

export interface TPostReaction {
  id: TEmoji;
  count: number;
}

export interface TPostMediaVideo {
  type: 'video';
  scaledPoster: TScaledImage | null;
  vrtPlayer: TVrtPlayerConfig;
}

export interface TPostMediaPicture {
  type: 'picture';
  scaledImage: TScaledImage;
}
export interface TPostCommentConnection {
  edges: TPostCommentEdge[];
  totalCount: number;
  next: string | null;
}
export interface TPostCommentEdge {
  node: TPostComment;
  cursor: string;
}
export type TPostMedia = TPostMediaPicture | TPostMediaVideo;

export const postFragment = { name, query };
