import React from 'react';
import styled from 'styled-components';
import { StoryContext } from './StoryContext';
import { getStoryGradientBackground } from './getStoryGradientBackground';
import { StoryProgressBar } from './StoryProgressBar';
import { TStoryImageBackground } from './types';

export interface TStoryImageBackgroundProps {
  background: TStoryImageBackground;
  index: number;
}

export const StoryImageBackground: React.FC<TStoryImageBackgroundProps> = (props) => {
  const { background, index } = props;
  const { duration, overlayType } = background;
  const { activeStoryIndex, gotoNextStory } = React.useContext(StoryContext);
  const isActive = activeStoryIndex === index;
  // only show gradient for reactions overlay
  const showBottomGradient = overlayType === 'reactions';
  return (
    <StyledWrap showBottomGradient={showBottomGradient}>
      {isActive && <StoryProgressBar durationInMs={duration} onEnd={gotoNextStory} />}
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ showBottomGradient: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
  padding-top: 32px;
  display: flex;
  justify-content: center;
  ${({ showBottomGradient }) => getStoryGradientBackground(showBottomGradient)}
`;
