import { TUser } from '../SessionService/ISessionService';
import { DateTime } from 'luxon';
import { TPostComment } from '../../pages/ProgramPage/types';

export function buildFakeComment(postId: string, message: string, session: TUser): TPostComment {
  return {
    postId,
    author: {
      // TODO: handle session.picture is null use-case
      avatarUrl: session.picture || '',
      name: session.username,
    },
    message,
    commentId: 'fake-comment-id-' + new Date().toString(),
    postedOn: DateTime.fromJSDate(new Date()).toISO(),
    mine: true,
    fake: true,
  };
}
