import React from 'react';
import { TTab, TProgram } from './types';
import { Region } from '../../components/Region/Region';
import { EColor } from '../../theme/styleguide';
import styled, { useTheme } from 'styled-components';
import { Header } from '../../components/Header/Header';
import { Button } from '../../components/Button/Button';
import { ProgramTab } from './ProgramTab';
import { findActiveProgramTab } from './findActiveProgramTab';
import { ProgramContext, TProgramContextValue } from './ProgramContext';
import { mediaQuery } from '../../theme/mediaQuery';
import { Background } from '../../components/Background/Background';
import { useLiveConfig } from '../../lib/useLiveConfig';
import { useHistory } from 'react-router-dom';
import Services from '../../services/Services';

export interface TProgramProps {
  data: TProgram;
}

export const Program: React.FC<TProgramProps> = (props) => {
  const program = props.data;
  const { header, tabs } = program;
  const [activeTab, setActiveTab] = React.useState<TTab | null>(findActiveProgramTab(program));

  const history = useHistory();
  const liveConfig = useLiveConfig();
  const theme = useTheme();

  const programContext: TProgramContextValue = {
    programTitle: header.title,
    programTabType: activeTab?.type,
  };

  return (
    <ProgramContext.Provider value={programContext}>
      <StyledWrap>
        <Header data={header} />
        <StyledArrows type="arrows-right" className="foreground" />
        {tabs?.length > 1 && (
          <Region color={theme.colors.transparent} verticalSpacing={0}>
            <StyledContent>
              <StyledTabButtons role="tablist">
                {tabs.map((tab, idx) => (
                  <StyledButton
                    key={idx}
                    text={tab.title ?? '<naamloze tab>'}
                    onClick={() => {
                      tab.type === 'link' && tab.link
                        ? history.push(tab.link)
                        : activeTab && tab.name !== activeTab.name && setActiveTab(tab);
                      Services.analyticsService.trackEvent('ketnetEvent', 'feedFilter', tab.title ?? '<naamloze tab>');
                    }}
                    textColor={EColor.white}
                    variant={activeTab?.name === tab.name ? 'filledAndBorders' : 'stroke'}
                    primaryColor={activeTab?.name === tab.name ? liveConfig.foregroundColor : EColor.transparent}
                    borderColor={tab.type === 'link' && tab.link ? EColor.red : liveConfig.foregroundColor}
                    aria-controls={tab.name}
                    aria-selected={activeTab?.name === tab.name}
                    role="tab"
                    tabIndex={0}
                    showVoteIcon={
                      !!(
                        tab.type === 'link' &&
                        tab.linkItem.gameType.toLowerCase() === 'voting' &&
                        !tab.linkItem.userHasVoted
                      )
                    }
                  />
                ))}
              </StyledTabButtons>
            </StyledContent>
          </Region>
        )}
        {activeTab && (
          <StyledTabPanel role="tabpanel" id={activeTab?.name}>
            <ProgramTab tab={activeTab} />
          </StyledTabPanel>
        )}
      </StyledWrap>
    </ProgramContext.Provider>
  );
};

const StyledWrap = styled.div`
  position: relative;
`;

const StyledArrows = styled(Background)`
  position: absolute;
  left: 0;
  top: 475px;
  z-index: 0;
  & svg {
    width: 175px;
    height: auto;
  }
  ${mediaQuery.tabletLandscape(`
    display: none;
  `)}
`;

const StyledContent = styled.div`
  padding-top: 72px;
`;

const StyledTabButtons = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: row;
  & div:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacings.large}px;
  }
`;

const StyledButton = styled(Button)<{ showVoteIcon: boolean }>`
  ${({ showVoteIcon }) => `
    position: relative;
    ${
      showVoteIcon &&
      `
      &::after {
        content: '';
        position: absolute;
        right: -22px;
        top: -22px;
        background-image: url('/assets/icons/alert.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 43px;
        height: 43px;
      }
    `
    }
  `}
`;

const StyledTabPanel = styled.div``;
