import React, { CSSProperties, useCallback, useEffect, useRef } from 'react';
import anime from 'animejs';
import { calculateAnimeProps } from './calculateAnimeProps';
import { useDeepValue } from '../../hooks/useDeepValue';

export type TMotionAnimeProps = {
  delay?: number; // in milliseconds
  duration?: number;
  fadeIn?: boolean;
  fadeInFrom?: number;
  fadeInTo?: number;

  scaleIn?: boolean;
  scaleInFrom?: number;
  scaleInTo?: number;

  rotate?: boolean;
  rotateFrom?: number;
  rotateTo?: number;

  jumpUp?: boolean;
  jumpUpFrom?: number;
  jumpUpTo?: number;

  jumpDown?: boolean;
  jumpDownFrom?: number;
  jumpDownTo?: number;

  jumpLeft?: boolean;
  jumpLeftFrom?: number;
  jumpLeftTo?: number;

  jumpRight?: boolean;
  jumpRightFrom?: number;
  jumpRightTo?: number;
};

export type TMotionProps = TMotionAnimeProps & {
  tagName?: keyof HTMLElementTagNameMap;
  style?: CSSProperties;
  inline?: boolean;
  retriggerKey?: string | number;
};

export const Motion: React.FunctionComponent<TMotionProps> = (props) => {
  const { children, style, tagName = 'div', ...motionProps } = props;
  const memoizedMotionProps = useDeepValue(motionProps);

  const timeoutRef = useRef<number>();
  const animationRef = useRef<anime.AnimeInstance>();
  const containerRef = useRef<HTMLMapElement>(null);

  const clearAnimation = useCallback(() => {
    window.clearTimeout(timeoutRef.current);
    anime.remove(containerRef?.current);
  }, []);

  useEffect(() => {
    window.requestAnimationFrame(function () {
      clearAnimation();
      timeoutRef.current = window.setTimeout(() => {
        animationRef.current = anime({
          targets: [containerRef.current],
          ...calculateAnimeProps(memoizedMotionProps),
        });
      }, 0);
    });

    return () => {
      clearAnimation();
    };
  }, [memoizedMotionProps, clearAnimation]);

  return React.createElement(
    tagName,
    {
      ref: containerRef,
      style: {
        position: 'relative',
        ...style,
      },
    },
    children
  );
};
