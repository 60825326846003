import React from 'react';
import { Region } from '../Region/Region';
import styled, { useTheme } from 'styled-components';
import { TMultiHighlight } from './types';
import Bullets from './Bullets';
import CarouselItem from './CarouselItem';
import { mediaQuery } from '../../theme/mediaQuery';
import DialogBox from './DialogBox';
import { ReactComponent as PreviousIcon } from './previousIcon.svg';
import { ReactComponent as NextIcon } from './nextIcon.svg';
import { ReactComponent as PlayIcon } from './playIcon.svg';
import { ReactComponent as PauseIcon } from './pauseIcon.svg';
import { Link } from 'react-router-dom';

interface TMultiHighlightProps {
  data: TMultiHighlight;
}

const MultiHighlight: React.FC<TMultiHighlightProps> = (props) => {
  const { data } = props;
  const theme = useTheme();
  const { highlights } = data;
  const [currentIdx, setCurrentIdx] = React.useState(0);
  const [isNavigationPaused, setIsNavigationPaused] = React.useState<boolean>(false);
  const length = highlights.length;

  const nextSlide = () => {
    setCurrentIdx(currentIdx === length - 1 ? 0 : currentIdx + 1);
  };

  const prevSlide = () => {
    setCurrentIdx(currentIdx === 0 ? length - 1 : currentIdx - 1);
  };

  React.useEffect(() => {
    const next = (isNavigationPaused ? currentIdx : currentIdx + 1) % length;

    const id = setTimeout(() => setCurrentIdx(next), 5000);

    return () => clearTimeout(id);
  }, [currentIdx, length, isNavigationPaused]);

  return (
    <StyledRegion color={theme.colors.red} fullMobileWidth={true}>
      <StyledMultiHighlightWrap>
        <StyledNavigationWrap>
          <StyledNavigationState onClick={() => setIsNavigationPaused(!isNavigationPaused)}>
            {isNavigationPaused ? <PlayIcon /> : <PauseIcon />}
          </StyledNavigationState>
          {highlights.map((slide, idx) => (
            <Bullets
              key={idx}
              idx={idx}
              length={length}
              currentIdx={currentIdx}
              onClickBullets={() => {
                setCurrentIdx(idx);
              }}
            />
          ))}
        </StyledNavigationWrap>

        <StyledCarouselContainer>
          <StyledButton type="previous" onClick={prevSlide}>
            <PreviousIcon />
          </StyledButton>
          {highlights.map((slide, idx) => {
            const carouselItem = () => {
              return (
                <StyledCarousel isActive={idx === currentIdx}>
                  {slide?.imageUrl && <CarouselItem image={slide?.imageUrl} isActive={idx === currentIdx} />}
                </StyledCarousel>
              );
            };
            return slide.link ? (
              <StyledLink key={idx} to={slide.link}>
                {carouselItem()}
              </StyledLink>
            ) : (
              carouselItem()
            );
          })}
          <StyledButton type="next" onClick={nextSlide}>
            <NextIcon />
          </StyledButton>
        </StyledCarouselContainer>

        {highlights.map((item, idx) => (
          <DialogBox
            isActive={idx === currentIdx}
            item={item}
            idx={length}
            key={idx}
            currentIdx={currentIdx}
            isNavigationPaused={isNavigationPaused}
          />
        ))}
      </StyledMultiHighlightWrap>
    </StyledRegion>
  );
};

const StyledRegion = styled(Region)``;

const StyledMultiHighlightWrap = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 375px;
    overflow-x: hidden;
    margin: ${theme.spacings.small}px;
    ${mediaQuery.tabletLandscape(`
      flex-direction: column;
      justify-content: center;
    `)}
  `}
`;

const StyledNavigationWrap = styled.div`
  ${({ theme }) => `
    order: 1;
    width: 40px;
    height: 100%;
    margin-right: ${theme.spacings.large}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${mediaQuery.tabletLandscape(`
      order: 2;
      height: 50px;
      margin: ${theme.spacings.large}px auto;
      width: 100%;
      flex-direction: row;
    `)}
  `}
`;

const StyledNavigationState = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: ${theme.colors.darkRed};
    margin: 0 0 4px 0;
    ${mediaQuery.tabletLandscape(`
      margin: 0 4px 0 0;
    `)}
  `}
`;

const StyledCarouselContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.1);
    ${mediaQuery.tabletPortrait(`
      transform: none;
    `)}
  }
  order: 2;
  ${mediaQuery.tabletLandscape(`
    order: 1;
  `)}
`;

const StyledCarousel = styled.div<{ isActive: boolean }>`
  ${({ isActive }) => `
    position: relative;
    transition: transform 0.5s;
    height: auto;
    ${
      isActive &&
      `
      animation: display 1s linear forwards;
      @-webkit-keyframes display {
        0% { opacity: 0.2; }
        50% { opacity: 0.6; }
        100% { opacity: 1; }
      }
      @keyframes display {
        0% { opacity: 0.2; }
        50% { opacity: 0.6; }
        100% { opacity: 1; }
      }
    `
    }
  `}
`;

const StyledButton = styled.div<{ type: 'previous' | 'next' }>`
  ${({ theme, type }) => `
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 10%;
    ${type === 'previous' ? `left: ${theme.spacings.medium}px;` : ``}
    ${type === 'next' ? `right: ${theme.spacings.medium}px;` : ``}
    & svg {
      width: 100%;
    }
  `}
`;

const StyledLink = styled(Link)``;

export default MultiHighlight;
