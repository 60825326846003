import React from 'react';
import ResponsiveModal from 'react-responsive-modal';
import styled, { useTheme } from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { Text } from '../Text/Text';

type TProps = {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  color: EColor;
  closeIcon?: JSX.Element;
  closeIconLeftPosition?: boolean;
  container?: Element;
};

export const Modal: React.FC<TProps> = (props) => {
  const { title, isOpen, onClose, color, children, closeIcon, closeIconLeftPosition, container } = props;
  const theme = useTheme();
  const styles = {
    modal: {
      padding: 0,
      backgroundColor: isOpen ? theme.colors[color] : theme.colors.transparent,
      zIndex: 10001,
      boxShadow: 'none',
    },
    closeButton: closeIconLeftPosition === true ? { left: 16, top: 16 } : {},
  };
  return (
    <ResponsiveModal
      open={isOpen}
      onClose={onClose}
      center
      styles={styles}
      closeIcon={closeIcon}
      container={container ?? undefined}
    >
      {isOpen && title && (
        <StyledTitleWrap>
          <StyledTitle>{title}</StyledTitle>
        </StyledTitleWrap>
      )}
      <StyledContent>{children}</StyledContent>
    </ResponsiveModal>
  );
};

const StyledTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacings.medium}px auto;
`;

const StyledTitle = styled(Text).attrs({ size: 'large', color: EColor.blue, bold: true })``;

const StyledContent = styled.div``;
