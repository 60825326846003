import React from 'react';
import styled from 'styled-components';
import { TPostMediaPicture } from '../../pages/ProgramPage/types';
import Services from '../../services/Services';

export type TPostMediaPictureProps = {
  data: TPostMediaPicture;
};

export const PostMediaPicture = (props: TPostMediaPictureProps) => {
  const { data } = props;
  let src = data.url;
  // keep PNG where possible for transparancy
  if (src.endsWith('.png') && src.includes('w720hx_j80')) {
    src = Services.linkService.forceImageStoreProfile(src, 'w720hx');
  }

  return (
    <StyledWrap>
      <StyledImage src={src} alt="Afbeelding van het bericht" />
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
