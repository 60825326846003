import React from 'react';
import { TPostMedia } from '../../pages/ProgramPage/types';
import { PostMediaPicture } from './PostMediaPicture';
import { Video } from '../../components/Video/Video';

export type TPostMediaProps = {
  data: TPostMedia;
};

export const PostMedia = (props: TPostMediaProps) => {
  const { data } = props;

  switch (data.type) {
    case 'PICTURE':
      return <PostMediaPicture data={data} />;
    case 'VIDEO':
      const { type, ...video } = data;
      return <Video data={video} />;
    default:
      // Todo: Error reporting.
      return null;
  }
};
