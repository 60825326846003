import React, { useContext } from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner/Spinner';
import { Text } from '../Text/Text';
import { StoryContext } from './StoryContext';
import { StoryDetails } from './StoryDetails';
import { TStorySummary } from './types';
import { useStoryDetails } from './useStoryDetails';

export interface TStoryProps {
  data: TStorySummary;
  index: number;
}

export const Story: React.FC<TStoryProps> = (props) => {
  const {
    data: { id, imageUrl },
    index,
  } = props;
  const {
    activeStoryIndex,
    size: { width, height },
  } = useContext(StoryContext);
  const req = useStoryDetails(id);
  const isActive = activeStoryIndex === index;

  return (
    <StyledWrap style={{ height, width }}>
      <StyledPoster style={{ backgroundImage: `url(${imageUrl})` }} isActive={isActive}>
        <StyledContent isActive={isActive}>
          {req.state === 'error' ? (
            <StyledError>Er is een probleem met deze story. Probeer het later nog eens opnieuw.</StyledError>
          ) : req.state === 'loading' ? (
            <Spinner />
          ) : (
            <StoryDetails data={req.data} index={index} />
          )}
        </StyledContent>
      </StyledPoster>
    </StyledWrap>
  );
};

const StyledWrap = styled.div``;

const StyledPoster = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in;
  ${({ isActive }) =>
    isActive
      ? ''
      : `
    transform: scale(0.7);
  `}
`;

const StyledContent = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const StyledError = styled(Text)`
  background-color: ${({ theme }) => theme.colors.red};
  padding: 10px;
  margin: 10px;
`;
