import React from 'react';
import { Region } from '../../components/Region/Region';
import { usePageTracking } from '../../hooks/usePageTracking';
import { Text } from '../../components/Text/Text';
import { useTheme } from 'styled-components';

const DEFAULT_TITLE = 'Oeps! Er is iets fout gelopen...';
const DEFAULT_DESCRIPTION = 'Probeer het later nog eens opnieuw.';

export interface TErrorPageProps {
  title?: string;
}

export const ErrorPage: React.FC<TErrorPageProps> = (props) => {
  const { title = DEFAULT_TITLE, children } = props;
  const theme = useTheme();

  usePageTracking({
    id: 'error',
    title,
  });

  return (
    <Region color={theme.colors.red}>
      <Text tag="h1">{title}</Text>
      {children || <Text tag="p">{DEFAULT_DESCRIPTION}</Text>}
    </Region>
  );
};
