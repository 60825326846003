import { gql } from '@apollo/client';

export const setPostReactionMutation = gql`
  mutation SetPostReaction($input: SetPostReactionInput!) {
    contentfeedSetPostReaction(input: $input)
  }
`;

export interface SetPostReactionArgs {
  input: {
    postId: string;
    reactionId: string | null;
    gdpr: string;
  };
}

export interface SetPostReactionResult {
  contentfeedSetPostReaction: string;
}

export interface SetPostReactionData {
  postId: string;
  reactionId: string | null;
  gdpr: string;
}
