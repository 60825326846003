import { DateTime, Duration } from 'luxon';

// DateTime.weekday is 1-based, Monday = 1, Sunday = 7
const DAYS_OF_WEEK = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag', 'zondag'];

const MONTHS_OF_YEAR = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
];

export function formatIsoDateTime(isoDateTime: string): string {
  const dt = DateTime.fromISO(isoDateTime);
  if (!dt.isValid) {
    throw new Error(`formatIsoDateTime input is invalid: ${isoDateTime}`);
  }
  const now = DateTime.utc();
  if (
    // 7+ days in the past
    dt.valueOf() < now.minus({ days: 7 }).valueOf() ||
    // 7+ days in the future
    dt.valueOf() > now.plus({ days: 7 }).valueOf()
  ) {
    // The same year.
    if (dt.toFormat('yyyy') === now.toFormat('yyyy')) {
      return dt.toFormat(`d '${MONTHS_OF_YEAR[dt.month - 1]} om' H'u'mm`);
    }
    return dt.toFormat(`d '${MONTHS_OF_YEAR[dt.month - 1]}' yyyy 'om' H'u'mm`);
  }
  if (dt.toISODate() === now.toISODate()) {
    return `vandaag om ${dt.toFormat(`H'u'mm`)}`;
  }
  if (dt.toISODate() === now.minus({ days: 1 }).toISODate()) {
    return `gisteren om ${dt.toFormat(`H'u'mm`)}`;
  }
  return `${DAYS_OF_WEEK[dt.weekday - 1]} om ${dt.toFormat(`H'u'mm`)}`;
}

export function formatVideoDuration(duration: number): string {
  const d = Duration.fromObject({ milliseconds: duration });
  if (!d.isValid) {
    throw new Error(`formatDuration input is invalid: ${duration}`);
  }
  return d.toFormat('m:ss');
}

export function formatBroadcastDate(broadcastDate: number): string {
  const bcd = DateTime.fromMillis(broadcastDate);
  const weekday = DAYS_OF_WEEK[bcd.weekday - 1];
  const day = bcd.toFormat(`d`);
  const month = MONTHS_OF_YEAR[bcd.month];

  if (!bcd.isValid) {
    throw new Error(`formatBroadcastDate input is invalid: ${broadcastDate}`);
  }
  return `${weekday.slice(0, 3)} ${day} ${month}`;
}

export function isInTheFuture(isoDateTime: string): boolean {
  const dt = DateTime.fromISO(isoDateTime);
  if (!dt.isValid) {
    throw new Error(`isoDtString is invalid: ${isoDateTime}`);
  }
  return dt.diffNow().as('millisecond') > 0;
}

export function getDayOfTheYear(): number {
  return DateTime.utc().ordinal;
}
