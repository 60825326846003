import React from 'react';
import { TSwimlaneBoxsetItem } from './types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaQuery } from '../../theme/mediaQuery';

export interface TSwimlaneBoxsetItemProps {
  data: TSwimlaneBoxsetItem;
}

export const SwimlaneBoxsetItem: React.FC<TSwimlaneBoxsetItemProps> = (props) => {
  const { data } = props;
  const { link, imageUrl, alt } = data;
  return (
    <Link to={link}>
      <StyledWrap title={alt}>
        <StyledImageWrap>
          {imageUrl && <StyledImage style={{ backgroundImage: `url(${imageUrl})` }} aria-label={alt} />}
        </StyledImageWrap>
      </StyledWrap>
    </Link>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    ${mediaQuery.netflixQuery(
      theme.components.swimlane.images.boxset.width,
      theme.components.swimlane.images.boxset.height
    )}
    position: relative;
    margin-right: ${theme.components.swimlane.horizontalSpacing}px;
    pointer-events: none;
  `}
`;

const StyledImageWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
`;

const StyledImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
`;
