import { TMotionProps } from './Motion';

export const calculateAnimeProps = (props: TMotionProps): anime.AnimeAnimParams => {
  const {
    duration = 750,
    delay = 0,

    fadeIn,
    fadeInFrom,
    fadeInTo,

    scaleIn,
    scaleInFrom,
    scaleInTo,

    jumpUp,
    jumpUpFrom,
    jumpUpTo,

    jumpLeft,
    jumpLeftFrom,
    jumpLeftTo,

    jumpDown,
    jumpDownFrom,
    jumpDownTo,

    jumpRight,
    jumpRightFrom,
    jumpRightTo,

    rotate,
    rotateFrom,
    rotateTo,
  } = props;

  const params: anime.AnimeAnimParams = {
    duration,
    delay,
  };

  if (fadeIn) params.opacity = [fadeInFrom || 0, fadeInTo || 1];
  if (scaleIn) params.scale = [scaleInFrom || 0.7, scaleInTo || 1];
  if (jumpUp) params.translateY = [jumpUpFrom || 50, jumpUpTo || 0];
  if (jumpDown) params.translateY = [jumpDownFrom || -50, jumpDownTo || 0];
  if (jumpLeft) params.translateX = [jumpLeftFrom || 50, jumpLeftTo || 0];
  if (jumpRight) params.translateX = [jumpRightFrom || -50, jumpRightTo || 0];
  if (rotate) params.rotate = [rotateFrom || 45, rotateTo || 0];

  return params;
};
