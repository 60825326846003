import React, { useCallback, useState } from 'react';
import 'react-responsive-modal/styles.css';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { EColor } from '../../theme/styleguide';
import { useForm, TValidationErrors } from '../../hooks/useForm';
import { useProfile } from '../ProfileModal/ProfileContext';
import Services from '../../services/Services';
import { Text } from '../Text/Text';
import { injectFontSize } from '../../theme/typography';

export type TLoginFormProps = {
  onLoggedIn: () => any;
};

interface TForm {
  login: string;
  password: string;
}

function getLoginFormValues(form: TForm) {
  return {
    login: form.login.trim(),
    password: form.password.trim(),
  };
}

export const LoginForm = (props: TLoginFormProps) => {
  const { onLoggedIn } = props;
  const { showProfileAction } = useProfile();
  const [formError, setFormError] = useState<string>();

  const {
    formValues,
    setFormValue,
    getFormValue,
    validateForm,
    getValidationError,
    clearValidationError,
    isValidField,
  } = useForm({
    defaultValues: {},
    calculateValidation: (formValues) => {
      const { login, password } = getLoginFormValues({
        login: formValues.login ?? '',
        password: formValues.password ?? '',
      });
      const errors: TValidationErrors = {};
      if (login === '') errors['login'] = 'Graag je login opgeven';
      if (password === '') errors['password'] = 'Graag je wachtwoord opgeven';
      return errors;
    },
  });

  const handleLogin = useCallback(async () => {
    setFormError('');
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    const { login, password } = getLoginFormValues({
      login: formValues.login ?? '',
      password: formValues.password ?? '',
    });
    // attempt login
    const { monitoringService, sessionService, loggerService } = Services;
    try {
      await sessionService.doPasswordLogin(login, password);
    } catch (e) {
      // notify of any error
      const serviceError = sessionService.getServiceError(e);
      if (serviceError && serviceError.errorType === 'InvalidCredentials') {
        setFormError('Ongeldige combinatie login-wachtwoord');
      } else {
        loggerService.errorObject('LoginForm.handleLogin', e);
        monitoringService.captureException(e);
        setFormError('Er is iets fout gelopen. Probeer het later opnieuw');
      }
      return;
    }
    onLoggedIn();
  }, [validateForm, formValues, onLoggedIn]);

  return (
    <StyledWrap>
      <StyledImageWrap>
        <StyledImage src="/assets/login-wrappers.jpg" alt="aanmelden" />
      </StyledImageWrap>
      <StyledForm>
        <StyledLabel htmlFor="login-login">login</StyledLabel>
        <StyledInput
          data-cyid="login-login"
          type="text"
          value={getFormValue('login') || ''}
          onChange={(e) => setFormValue('login', e.currentTarget.value)}
          onFocus={() => clearValidationError('login')}
          placeholder="dit is je ketnet gebruikersnaam"
        />
        <StyledFormElementDescription hasError={isValidField('login')}>
          {getValidationError('login') ?? ''}
        </StyledFormElementDescription>

        <StyledLabel htmlFor="login-password">wachtwoord</StyledLabel>
        <StyledInput
          data-cyid="login-password"
          type="password"
          value={getFormValue('password') || ''}
          onChange={(e) => setFormValue('password', e.currentTarget.value)}
          onFocus={() => clearValidationError('password')}
          placeholder="de sleutel tot jouw profiel"
        />
        <StyledFormElementDescription hasError={isValidField('password')}>
          {getValidationError('password') ?? ''}
        </StyledFormElementDescription>

        <StyledFormLinks>
          <StyledLink
            onClick={() => showProfileAction('/user/password')}
            onKeyPress={() => showProfileAction('/user/password')}
            tabIndex={0}
            role="link"
          >
            <StyledFormLink color={EColor.blue}> wachtwoord vergeten?</StyledFormLink>
          </StyledLink>
          <StyledLink
            tabIndex={0}
            role="link"
            onKeyPress={() => showProfileAction('/user/username')}
            onClick={() => showProfileAction('/user/username')}
          >
            <StyledFormLink color={EColor.blue}> gebruikersnaam vergeten?</StyledFormLink>
          </StyledLink>
        </StyledFormLinks>

        <StyledFormActions>
          <Button
            tabIndex={0}
            role="button"
            text="aanmelden"
            primaryColor={EColor.blue}
            textColor={EColor.white}
            onClick={handleLogin}
            onKeyPress={handleLogin}
          />
          <Button
            tabIndex={0}
            role="button"
            text="profiel aanmaken"
            primaryColor={EColor.white}
            textColor={EColor.blue}
            borderColor={EColor.blue}
            onClick={() => showProfileAction('/user/register')}
            onKeyPress={() => showProfileAction('/user/register')}
          />
        </StyledFormActions>
        {formError && (
          <Text color={EColor.red} size="small">
            {formError}
          </Text>
        )}
      </StyledForm>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  max-width: 375px;
`;

const StyledImageWrap = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.blue};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacings.small}px;
  `}
`;

const StyledImage = styled.img`
  max-height: 250px;
`;

const StyledForm = styled.form`
  padding: ${({ theme }) => theme.spacings.large}px;
`;

const StyledLabel = styled.label`
  ${({ theme }) => `
    display: block;
    &:not(:first-child) {
      margin-top: ${theme.spacings.large}px;
    }
    ${injectFontSize('medium')};
    font-weight: 600;
    margin-bottom: ${theme.spacings.small}px;
  `}
`;

const StyledInput = styled.input`
  ${({ theme }) => `
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.blue};
    box-shadow: 5px 5px 0px 0px ${theme.colors.blue};
    padding: ${theme.spacings.small}px;
    width: 100%;
    ::placeholder {
      font-weight: bold;
      color: ${theme.colors.blue};
    }
    ${injectFontSize('regular')};
  `}
`;

const StyledFormElementDescription = styled.p<{ hasError?: boolean }>`
  margin: 5px 0;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.red : theme.colors.black)};
`;

const StyledFormLinks = styled.div`
  margin: ${({ theme }) => theme.spacings.large}px 0;
`;

const StyledLink = styled.div``;

const StyledFormLink = styled(Text).attrs({ size: 'medium' })`
  span {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledFormActions = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacings.large}px 0 0 0;
    & div:not(:last-child) {
      margin-right: ${theme.spacings.small}px;
    }
  `}
`;
