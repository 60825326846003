import React from 'react';
import Services from '../../services/Services';
import { StoryContext } from './StoryContext';
import { TStorySummary } from './types';

export interface TWatchedStoryMarkerProps {
  stories: TStorySummary[];
}

export const WatchedStoryMarker: React.FC<TWatchedStoryMarkerProps> = (props) => {
  // track activeStory and mark them immediately as watched
  const { activeStoryIndex } = React.useContext(StoryContext);
  const { stories } = props;
  const storyId = stories[activeStoryIndex]?.id;

  // everytime the activeStory changes, mark it as watched
  React.useEffect(() => {
    if (storyId) {
      Services.storyService.markAsWatched(storyId);
    }
  }, [storyId]);

  return null;
};
