import React from 'react';
import styled from 'styled-components';
import { Text } from '../../components/Text/Text';
import { ReactComponent as PixelatedBorderSVG } from './pixelated-border.svg';
import { useLiveConfig } from '../../lib/useLiveConfig';
import { EColor } from '../../theme/styleguide';

const NO_SEARCH_RESULTS_IMAGE = 'https://static.ketnet.be/assets/ketnetbe-assets/no-search-results.gif';
const BORDER_SIZE = 180;
const IMAGE_SIZE = 212;
const WRAPPER_SIZE = 410;

export type TSearchNoResultsProps = {
  term: string;
};

export const SearchNoResults = (props: TSearchNoResultsProps) => {
  const { term } = props;
  const liveConfig = useLiveConfig();

  return (
    <StyledWrap>
      <StyledNoResultsImage>
        <StyledPixelatedBorder fill={liveConfig.backgroundColor} />
      </StyledNoResultsImage>
      <StyledTitle>Oeps, we konden niets vinden dat lijkt op "{term}"</StyledTitle>
      <StyledInfo>Zoek je Tik Tak, Sarah, Bumba of een ander Ketnet Junior-programma?</StyledInfo>
      <StyledLink href="https://www.ketnet-junior.be" target="_blank" rel="noopener noreferrer">
        Ga naar Ketnet Junior
      </StyledLink>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${WRAPPER_SIZE}px;
  margin: auto;
  text-align: center;
  & p {
    opacity: 0.7;
  }
`;

const StyledNoResultsImage = styled.div`
  ${({ theme }) => `
    background-image: url('${NO_SEARCH_RESULTS_IMAGE}');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    width: ${BORDER_SIZE}px;
    height: ${BORDER_SIZE}px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: ${theme.spacings.extralarge}px;
  `}
`;

const StyledPixelatedBorder = styled(PixelatedBorderSVG)<{ fill: EColor }>`
  ${({ theme, fill }) => `
    width: ${IMAGE_SIZE}px;
    height: ${IMAGE_SIZE}px;
    position: absolute;
    top: -calc((${IMAGE_SIZE}px - ${BORDER_SIZE}px)/2);
    left: -calc((${IMAGE_SIZE}px - ${BORDER_SIZE}px)/2);
    fill: ${theme.colors[fill]};
  `}
`;

const StyledTitle = styled(Text).attrs({ tag: 'h2' })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.small}px;
  `};
`;

const StyledInfo = styled(Text)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.small}px;
  `};
`;

const StyledLink = styled.a`
  ${({ theme }) => `
    display: inline-block;
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.white};
    color: ${theme.colors.white};
  `};
`;
