import React from 'react';
import styled from 'styled-components';
import { TSearchEpisode } from './types';
import { EColor } from '../../theme/styleguide';
import { SearchResultLabel } from './SearchResultLabel';
import { Text } from '../../components/Text/Text';
import { formatVideoDuration } from '../../lib/datetime';
import { Link } from 'react-router-dom';

export type TSearchResultEpisodeProps = {
  data: TSearchEpisode;
};

export const SearchResultEpisode = (props: TSearchResultEpisodeProps) => {
  const { data } = props;

  return (
    <StyledSearchResult>
      <Link to={data.id}>
        <StyledImageWrap>
          <StyledImage imageUrl={data.imageUrl} />
          <SearchResultLabel icon="watch" />
          <StyledDuration>{formatVideoDuration(data.duration)}</StyledDuration>
        </StyledImageWrap>
        <StyledTitle>{data.title}</StyledTitle>
        <StyledSubtitle>{data.subtitle}</StyledSubtitle>
      </Link>
    </StyledSearchResult>
  );
};

const StyledSearchResult = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 160px;
    
    margin-bottom: ${theme.spacings.small}px;
  `}
`;
const StyledDuration = styled.div`
  ${({ theme }) => `
display: flex;
align-items: center;
justify-content: center;
position: absolute;
height: 22px;
min-width: 45px;
padding: 0 ${theme.spacings.small}px;
right: 0px;
bottom: 0px;
color: #fff;
background: ${theme.colors.red};
`}
`;
const StyledImageWrap = styled.div`
  ${({ theme }) => `
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    width: 160px;
    height: 90px;
    margin-bottom: ${theme.spacings.small}px;
  `}
`;

const StyledImage = styled.div<{ imageUrl: string }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

const StyledTitle = styled(Text).attrs({ tag: 'h4' })`
  text-transform: capitalize;
`;

const StyledSubtitle = styled(Text).attrs({ tag: 'p', color: EColor.yellow })`
  word-wrap: break-word;
`;
