import React from 'react';
import { useLocation } from 'react-router-dom';
import { PageWithData } from '../../components/PageWithData/PageWithData';
import Services from '../../services/Services';
import { AemPage } from './AemPage';
import { getRedirect } from './getRedirect';
import { TPage } from './types';

export const AemPageLoader: React.FC = () => {
  const { pathname } = useLocation();

  // cut off preceding slash
  const id = Services.linkService.getIdForSlug(pathname.substr(1));

  const fetcher = async (): Promise<TPage> => {
    // always try to fetch the corresponding aem page first
    const res = await Services.bffService.getPage({
      id,
      feedPostPageSize: Services.configService.config.postsPerPage,
      feedPostCommentPageSize: 3,
    });
    if (!res.page) {
      // then fallback to checking if there is a possible redirect for the page
      const to = getRedirect(pathname);
      if (to) {
        return { pageType: 'redirect', to, canonical: null };
      }
      return Promise.reject(`Invalid page`);
    }
    return Services.contentService.mapPage(id, res.page, res.canonical);
  };

  return <PageWithData key={id} swrKey={id} fetcher={fetcher} component={AemPage} />;
};
