import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { EColor } from '../../theme/styleguide';
import { Motion, TMotionProps } from '../Motion/Motion';
import { Text } from '../Text/Text';

type TProps = {
  path: string;
  text: string;
  textColor: EColor;
  backgroundColor: EColor;
  icon: JSX.Element;
  isMobile?: boolean;
};

export const isNavLinkActive = (navLinkPath: string, currentPathName: string): boolean => {
  if (navLinkPath === '/') {
    if (navLinkPath === currentPathName) {
      return true;
    }
  } else {
    return currentPathName.indexOf(navLinkPath) === 0;
  }
  return false;
};

export const MainMenuItem = ({ path, backgroundColor, text, textColor, icon, isMobile }: TProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const isActive = isNavLinkActive(path, pathname);

  const motionProps = useMemo(() => {
    const _motionProps: TMotionProps = { delay: 200 };
    if (isActive) {
      _motionProps[isMobile ? 'jumpUp' : 'jumpRight'] = true;
      if (!isMobile) {
        _motionProps['jumpRightFrom'] = -20;
      } else {
        _motionProps['jumpUpFrom'] = 20;
      }
    }
    return _motionProps;
  }, [isActive, isMobile]);

  return (
    <StyledMenuItem isMobile={isMobile} isActive={isActive}>
      <Motion style={{ flex: 1 }} {...motionProps}>
        <StyledInnerWrap backgroundColor={backgroundColor} isMobile={isMobile} isActive={isActive}>
          <StyledMenuLink aria-label={text} onClick={() => history.push(path)} tabIndex={0} role="menuitem">
            {icon}
            <StyledLabel color={textColor}>{text}</StyledLabel>
          </StyledMenuLink>
        </StyledInnerWrap>
      </Motion>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled.li<{ isMobile?: boolean; isActive?: boolean }>`
  position: relative;
  display: flex;
  width: 116px;
  height: 116px;

  ${({ isActive }) =>
    isActive &&
    `
    width: 126px;
  `}

  ${({ isMobile }) =>
    isMobile &&
    `
      flex: 1;
      width: auto;
      height: 76px;
      & svg {
        transform: scale(0.7, 0.7);
      }
      bottom: 0px;
      align-self: flex-end;
    `}

  ${({ isActive, isMobile }) =>
    isActive &&
    isMobile &&
    `
    height: 86px;
  `}
`;

const StyledInnerWrap = styled.span<{ backgroundColor: EColor; isMobile?: boolean; isActive?: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  background: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};

  ${({ theme, isActive, isMobile, backgroundColor }) =>
    isActive &&
    !isMobile &&
    `
      &::after,
      & a::after {
        position: absolute;
        z-index: 3;
        top: 0px;
        left: 10px;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid ${theme.colors[backgroundColor]};
        border-left: 0px;
      }
      & a::after {
        left: 20px;
      }
  `}
`;

const StyledMenuLink = styled.a`
  position: absolute;
  z-index: 4;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLabel = styled(Text).attrs({ size: 'small', bold: true })`
  ${({ theme }) => `
    margin-top: ${theme.spacings.small}px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    ${mediaQuery.tabletPortrait(`
        margin-top: ${theme.spacings.small}px;
    `)}
  `}
`;
