import BaseService from '../BaseService';
import { TGamesService } from './TGamesService';
import { WebGamesSDK } from './WebGamesSDK';

export class GamesService extends BaseService implements TGamesService {
  public initializeWebSDK: TGamesService['initializeWebSDK'] = async (game, iframe, callbacks) => {
    if (!iframe.contentWindow) {
      throw this.createError('createWebSDK for iframe without contentWindow');
    }
    if (!game.gameUrl) {
      throw this.createError('createWebSDK for game with no URL');
    }
    const { origin } = new URL(game.gameUrl);
    const sdk = new WebGamesSDK(
      {
        gameId: game.id,
        gamePermissions: game.permissions,
        gameName: game.title ?? '<unnamed game>',
        iframe: iframe.contentWindow,
        origin,
        ...callbacks,
      },
      this.services
    );
    try {
      this.logger.info(`Initializing Ketnet SDK for ${game.id}`);
      await sdk.initialize();
    } catch (e) {
      this.logger.errorObject('Failed to initialize WebGamesSDK', e);
      return Promise.reject('failed to initialize');
    }
    return sdk;
  };
}
