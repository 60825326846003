import styled from 'styled-components';
import { TSearchGame } from './types';
import { EColor } from '../../theme/styleguide';
import { SearchResultLabel } from './SearchResultLabel';
import { Text } from '../../components/Text/Text';
import { Link } from 'react-router-dom';

export type TSearchResultGameProps = {
  data: TSearchGame;
};

export const SearchResultGame = (props: TSearchResultGameProps) => {
  const { data } = props;

  return (
    <StyledSearchResult>
      <Link to={data.id}>
        <StyledImageWrap>
          <StyledImage imageUrl={data.imageUrl} />
          <SearchResultLabel icon="game" type={data.gameType} />
        </StyledImageWrap>
        <StyledTitle>{data.title}</StyledTitle>
        <StyledSubtitle>{data.gameTypeDisplay}</StyledSubtitle>
      </Link>
    </StyledSearchResult>
  );
};

const StyledSearchResult = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 160px;
    margin-bottom: ${theme.spacings.small}px;
  `}
`;

const StyledImageWrap = styled.div`
  ${({ theme }) => `
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    width: 160px;
    height: 90px;
    margin-bottom: ${theme.spacings.small}px;
  `}
`;

const StyledImage = styled.div<{ imageUrl: string }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

const StyledTitle = styled(Text).attrs({ tag: 'h4' })``;

const StyledSubtitle = styled(Text).attrs({ tag: 'p', color: EColor.green })`
  word-wrap: break-word;
  text-transform: capitalize;
`;
