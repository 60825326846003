import { TProgram } from '../../pages/ProgramPage/types';
import { pathToTitle } from './metaHelpers';
import { TPageMeta } from './PageMeta';

export function getProgramMeta(path: string, program: TProgram, canonical: string | null): TPageMeta {
  return {
    title: program.header.title ?? pathToTitle(path),
    description: program.header.description ?? 'Een programma van ketnet',
    canonical,
  };
}
