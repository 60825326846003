import chalk from 'chalk';

import { ILoggerService } from './ILoggerService';
import BaseService from '../BaseService';
import { IServices } from '../IServices';
import { LogLevel } from './LogLevel';

class LoggerService extends BaseService implements ILoggerService {
  private logLevel: LogLevel;

  constructor(services: IServices) {
    super(services);
    this.logLevel = LogLevel.DEBUG;
  }

  public error = (message: string) => this._log(LogLevel.ERROR, 'ERROR', message);
  public warn = (message: string) => this._log(LogLevel.WARN, 'WARN', message);
  public info = (message: string) => this._log(LogLevel.INFO, 'INFO', message);
  public http = (message: string) => this._log(LogLevel.HTTP, 'HTTP', message);
  public verbose = (message: string) => this._log(LogLevel.VERBOSE, 'VERBOSE', message);
  public debug = (message: string) => this._log(LogLevel.DEBUG, 'DEBUG', message);
  public silly = (message: string) => this._log(LogLevel.SILLY, 'SILLY', message);

  public errorObject = (message: string, error: any) => {
    let details: string;
    if (error instanceof Error) {
      details = error.stack || error.message;
    } else {
      try {
        details = JSON.stringify(error);
      } catch {
        this.error('logged error is not an instance of Error and cannot be stringified');
        if (error instanceof Object) {
          details = error.toString();
        } else {
          details = '<object could not be logged>';
        }
      }
    }
    this.error(`${message}\n${details}`);
  };

  private _log(level: LogLevel, label: string, message: string) {
    if (this.logLevel >= level) {
      const { error, log, info, warn } = console;

      const dateFormat = this.formattedDate(new Date());
      const logEntry = `[ ${label} ] ${dateFormat} | ${message}`;

      if (level === LogLevel.ERROR) return error(chalk.keyword('crimson')(logEntry));
      if (level === LogLevel.WARN) return warn(chalk.keyword('orange')(logEntry));
      if (level === LogLevel.INFO) return info(chalk.keyword('springgreen')(logEntry));
      if (level === LogLevel.HTTP) return log(chalk.keyword('steelblue')(logEntry));
      return log(logEntry);
    }
  }

  private formattedDate(date: Date) {
    return date.toLocaleString('nl-BE');
  }
}

export default LoggerService;
