import { IServices } from './IServices';
import * as env from './ConfigService/env';
import { ISessionService } from './SessionService/ISessionService';
import SessionService from './SessionService/SessionService';
import { IFetchService } from './FetchService/IFetchService';
import FetchService from './FetchService/FetchService';
import { ILoggerService } from './LoggerService/ILoggerService';
import LoggerService from './LoggerService/LoggerService';
import { IConfigService } from './ConfigService/IConfigService';
import ConfigService from './ConfigService/ConfigService';
import { IVRTMediaPlayerService } from './VRTMediaPlayerService/IVRTMediaPlayerService';
import VRTMediaPlayerService from './VRTMediaPlayerService/VRTMediaPlayerService';
import MockVRTMediaPlayerService from './VRTMediaPlayerService/MockVRTMediaPlayerService';
import { IBffService } from './BffService/IBffService';
import BffService from './BffService/BffService';
import MockBffService from './BffService/MockBffService';
import { TAnalyticsService } from './AnalyticsService/TAnalyticsService';
import AnalyticsService from './AnalyticsService/AnalyticsService';
import MockAnalyticsService from './AnalyticsService/MockAnalyticsService';
import { IMonitoringService } from './MonitoringService/IMonitoringService';
import MonitoringService from './MonitoringService/MonitoringService';
import MockMonitoringService from './MonitoringService/MockMonitoringService';
import { IAppService } from './AppService/IAppService';
import AppService from './AppService/AppService';
import { TLinkService } from './LinkService/TLinkService';
import { LinkService } from './LinkService/LinkService';
import { TContentService } from './ContentService/TContentService';
import { ContentService } from './ContentService/ContentService';
import { TMediaService } from './MediaService/TMediaService';
import { MediaService } from './MediaService/MediaService';
import { TGamesService } from './GamesService/TGamesService';
import { GamesService } from './GamesService/GamesService';
import { StoryService } from './StoryService/StoryService';
import { TStoryService } from './StoryService/TStoryService';

class ServicesImpl implements IServices {
  public readonly configService: IConfigService = new ConfigService(this);
  public readonly loggerService: ILoggerService = new LoggerService(this);
  public readonly sessionService: ISessionService = new SessionService(this);
  public readonly fetchService: IFetchService = new FetchService(this);
  public readonly appService: IAppService = new AppService(this);
  public readonly linkService: TLinkService = new LinkService(this);
  public readonly contentService: TContentService = new ContentService(this);
  public readonly mediaService: TMediaService = new MediaService(this);
  public readonly gamesService: TGamesService = new GamesService(this);
  public readonly storyService: TStoryService = new StoryService(this);

  public readonly analyticsService: TAnalyticsService = env.MOCK_ANALYTICS
    ? new MockAnalyticsService()
    : new AnalyticsService(this);
  public readonly monitoringService: IMonitoringService = env.MOCK_MONITORING
    ? new MockMonitoringService(this)
    : new MonitoringService(this);
  public readonly vrtMediaPlayerService: IVRTMediaPlayerService = env.MOCK_VIDEO
    ? new MockVRTMediaPlayerService(this)
    : new VRTMediaPlayerService(this);
  public readonly bffService: IBffService = env.MOCK_DATA ? new MockBffService(this) : new BffService(this);
}

const Services = new ServicesImpl();

export default Services;
