import React from 'react';
import styled from 'styled-components';
import { TEmoji } from '../../lib/emoji';
import { EmojiReaction } from '../EmojiReaction/EmojiReaction';

export interface TEmojiReaction {
  id: TEmoji;
  count: number;
}

type TProps = {
  reactions?: TEmojiReaction[] | null;
  activeEmojiKind?: TEmoji | null;
  onPressReaction?: (emojiKind: TEmoji) => any;
};

export const EmojiReactionOverview = ({ reactions, activeEmojiKind, onPressReaction }: TProps) => {
  return (
    <StyledWrap>
      {reactions?.map(({ id, count }) => {
        const active = id === activeEmojiKind;
        return (
          <EmojiReaction key={id} emojiKind={id} count={count} active={active} onPress={() => onPressReaction?.(id)} />
        );
      })}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 48px;
`;
