import React from 'react';
import useSession from '../../lib/useSession';
import { ArrowCloseIcon } from '../../assets/icons';
import { EColor } from '../../theme/styleguide';
import { Modal } from '../Modal/Modal';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { LoginForm } from '../LoginForm/LoginForm';

export interface TKetprofielModalProps {
  onClose: () => any;
  isOpen?: boolean;
  container?: Element;
}

export const KetprofielModal: React.FC<TKetprofielModalProps> = (props) => {
  const { onClose, isOpen, container } = props;
  const session = useSession();

  return (
    <Modal
      title="jouw ketprofiel"
      isOpen={isOpen ?? true}
      onClose={onClose}
      color={EColor.white}
      closeIcon={<ArrowCloseIcon role="img" aria-label="Ga terug" />}
      closeIconLeftPosition={true}
      container={container}
    >
      {session ? <ProfileMenu onLoggedOut={onClose} /> : <LoginForm onLoggedIn={onClose} />}
    </Modal>
  );
};
