import React from 'react';
import styled from 'styled-components';
import { usePageTracking } from '../../hooks/usePageTracking';
import { Overlay } from '../../components/Overlay/Overlay';
import Services from '../../services/Services';
import useSession from '../../lib/useSession';
import { OverlayContext } from '../../components/Overlay/OverlayContext';
import { Text } from '../../components/Text/Text';

export interface TEventProps {
  data: TEventData;
}

export interface TEventData {
  link: string;
  title: string;
}

export const Event: React.FC<TEventProps> = (props) => {
  const { link, title } = props.data;
  const refContainer = React.useRef<HTMLDivElement>(null);
  const session = useSession();

  usePageTracking({
    id: 'livecenter',
    title,
  });

  // changing the iframe's key reloads zender on session change
  const iframeKey = session?.uid ?? 'anonymous';

  if (!link) {
    return <Text tag="h2">Er is momenteel geen livestream</Text>;
  }

  const customPosition = `
    right: 10px;
    top: 10px;
  `;

  return (
    <Overlay onClose={Services.appService.goBack} customPosition={customPosition}>
      <StyledWrap ref={refContainer}>
        <StyledIframe key={iframeKey} title="event" src={link}>
          De livestream wordt niet ondersteund door je browser
        </StyledIframe>
      </StyledWrap>
      <ZenderLoginListener />
    </Overlay>
  );
};

const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const ZenderLoginListener: React.FC = () => {
  const { showLogin } = React.useContext(OverlayContext);
  // listen for ketnetbe_showLogin messages
  React.useEffect(() => {
    const listener = (evt: MessageEvent) => {
      if (evt.data?.name === 'ketnetbe_showLogin') {
        showLogin();
      }
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  });
  return null;
};
