import React from 'react';
import { THeader } from './types';
import { Region } from '../Region/Region';
import styled, { useTheme } from 'styled-components';
import { Title } from '../Title/Title';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../Text/Text';
import { useMatchMedia } from '../../hooks/useMatchMedia';

export type THeaderProps = {
  data: THeader;
};

export const Header: React.FC<THeaderProps> = (props) => {
  const { data } = props;
  const { title, imageUrl, description, logoUrl, height } = data;
  const isLargeHeader = !!(height === 'LARGE');
  const isTablet = useMatchMedia(mediaQuery.mobileTabletLandscapeQuery);
  const theme = useTheme();

  return (
    <Region color={theme.colors.black} verticalSpacing={0} fullMobileWidth={true}>
      <StyledWrap isLargeHeader={isLargeHeader}>
        <StyledInfo>
          {logoUrl && !isTablet && <StyledLogo src={logoUrl} description={description} isLargeHeader={isLargeHeader} />}
          {!logoUrl && title && (
            <StyledTitle text={title} backgroundColor={theme.colors.black} textColor={theme.colors.white} />
          )}
          {description && isLargeHeader && <StyledDescription size="regular">{description}</StyledDescription>}
        </StyledInfo>
        {imageUrl && (
          <StyledImageWrap>
            {logoUrl && isTablet && <StyledGradientLogo src={logoUrl} />}
            <StyledImage src={imageUrl} isLargeHeader={isLargeHeader} />
          </StyledImageWrap>
        )}
      </StyledWrap>
    </Region>
  );
};

const StyledWrap = styled.div<{ isLargeHeader: boolean }>`
  ${({ theme, isLargeHeader }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${isLargeHeader ? `${theme.components.header.large}px` : `${theme.components.header.small}px`};
    position: relative;
    flex-direction: row;
    ${mediaQuery.tabletLandscape(`
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: auto;
    `)}
  `}
`;

const StyledInfo = styled.div`
  width: 100%;
  max-width: 400px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  ${mediaQuery.tabletLandscape(`
    order: 2;
  `)}
`;

const StyledTitle = styled(Title)``;

const StyledDescription = styled(Text)`
  ${({ theme }) => `
    ${mediaQuery.tabletLandscape(`
      margin: ${theme.spacings.medium}px 0;
    `)}
    ${mediaQuery.tabletPortrait(`
      margin: ${theme.spacings.medium}px;
    `)}
  `}
`;

const StyledImageWrap = styled.div`
  position: relative;
  ${mediaQuery.desktop(`
    flex: 1;
    display: flex;
  `)}
`;

const StyledGradientLogo = styled.img`
  ${({ theme }) => `
    position: absolute;
    bottom: 0px;
    max-width: 35%;
    padding: ${theme.spacings.medium}px;
    background: linear-gradient(to right, transparent 0%, black 100%);
  `}
`;

const StyledLogo = styled.img<{ description: string | null; isLargeHeader: boolean }>`
  ${({ theme, description, isLargeHeader }) => `
    ${description ? `width: 185px;` : `width: 95%;`}
    ${isLargeHeader && `margin-bottom: ${theme.spacings.extralarge}px`};
  `}
`;

const StyledImage = styled.img<{ isLargeHeader: boolean }>`
  ${({ theme, isLargeHeader }) => `
    max-height: ${isLargeHeader ? `${theme.components.header.large}px` : `${theme.components.header.small}px`};
    width: auto;
  `}
`;
