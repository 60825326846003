import Listener, { TDetachListener, TListenFn } from '../../lib/Listener';
import BaseService from '../BaseService';

const LS_WATCHED_STORIES = 'watchedStories';

type TWatchedStories = string[];

export class StoryService extends BaseService {
  private watchedStories: TWatchedStories = this.loadWatchedStories() ?? [];

  private watchedListener = new Listener<void>();

  markAsWatched(storyId: string): void {
    if (this.watchedStories.includes(storyId)) {
      return;
    }
    this.watchedStories.push(storyId);
    this.saveWatchedStories();
    this.watchedListener.notify();
  }

  isWatched(storyId: string): boolean {
    return this.watchedStories.includes(storyId);
  }

  /**
   * Register a listener on session changed events
   * @param callback a function to handle session changes
   * @returns a callback to remove the listener
   */
  onWatchlistChanged(listener: TListenFn<void>): TDetachListener {
    return this.watchedListener.listen(listener);
  }

  clearWatched(): void {
    this.watchedStories = [];
    sessionStorage.removeItem(LS_WATCHED_STORIES);
    this.watchedListener.notify();
  }

  private saveWatchedStories(): void {
    try {
      sessionStorage.setItem(LS_WATCHED_STORIES, JSON.stringify(this.watchedStories));
    } catch (e) {
      this.logger.errorObject('Failed to save watchedStories to sessionStorage', e);
    }
  }

  private loadWatchedStories(): TWatchedStories | null {
    try {
      const serialized = sessionStorage.getItem(LS_WATCHED_STORIES);
      return serialized ? JSON.parse(serialized) : null;
    } catch (e) {
      this.logger.errorObject('Failed to load watchedStories from sessionStorage', e);
      return null;
    }
  }
}
