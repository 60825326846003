import { TFragment } from './fragment';

const name = 'header';

const query = `
fragment ${name} on Header {
  type
  title
  description  
  imageUrl
  logoUrl
  height
}
`;

export const headerFragment: TFragment = { name, query };

export interface THeader {
  type: 'header';
  title: string | null;
  description: string | null;
  imageUrl: string | null;
  logoUrl: string | null;
  height: THeaderHeight;
}

type THeaderHeight = 'SMALL' | 'LARGE';
