import React from 'react';
import { Overlay } from '../../components/Overlay/Overlay';
import Services from '../../services/Services';
import { GameFrame } from './GameFrame';
import { TGame } from './types';

export interface TGameDetailProps {
  data: TGame;
}

export const GameDetail: React.FC<TGameDetailProps> = (props) => {
  const { orientation, hideCloseButton } = props.data;

  const handleClose = () => Services.appService.goBack();

  return (
    <Overlay
      onClose={handleClose}
      hideCloseButton={hideCloseButton}
      forceMobileOrientation={orientation ?? undefined}
      checkFullscreenFail={true}
    >
      <GameFrame game={props.data} />
    </Overlay>
  );
};
