import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageWithData } from '../../components/PageWithData/PageWithData';
import { useQuery } from '../../hooks/useQuery';
import Services from '../../services/Services';
import { Event, TEventData } from './Event';

export const fetcher = async () => {
  const res = await Services.bffService.getUpdates(null);
  if (!res.updates.event) {
    return Promise.reject('no event');
  }
  return res.updates.event;
};

function getLivecenterSwrProps(link: string | null) {
  if (!link) {
    return {
      fetcher,
      key: 'event',
    };
  }
  return {
    fetcher: async (): Promise<TEventData> => {
      return {
        link: decodeURIComponent(link),
        title: 'zender',
      };
    },
    key: `event-${link}`,
  };
}

export const EventPage = () => {
  // this route is used for testing purposes only -- to be able to test different zender setups without aem config
  const query = useQuery();
  const link = query.get('link');
  const isInvalidLivecenterUrl = link && !Services.linkService.isValidLivecenterUrl(link);

  // prevent XSS by passing something else than a livecenter URL, log attempts
  React.useEffect(() => {
    if (isInvalidLivecenterUrl) {
      const msg = `XSS attempt via livecenter for url "${link}"`;
      Services.loggerService.warn(msg);
      Services.monitoringService.captureMessage(msg);
    }
  }, [isInvalidLivecenterUrl, link]);
  if (isInvalidLivecenterUrl) {
    return <Redirect to="/" />;
  }

  const { key, fetcher } = getLivecenterSwrProps(link);
  return <PageWithData swrKey={key} fetcher={fetcher} component={Event} />;
};
