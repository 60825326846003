import React from 'react';
import { TSwimlaneStoriesItem } from './types';
import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { useIsWatched } from './useIsWatched';
import { Emoji } from '../Emoji/Emoji';

export interface TSwimlaneStoriesItemProps {
  data: TSwimlaneStoriesItem;
}

export const SwimlaneStoriesItem: React.FC<TSwimlaneStoriesItemProps> = (props) => {
  const { data } = props;
  const { id, title, imageUrl, topReaction } = data;
  const isWatched = useIsWatched(id);

  return (
    <StyledWrap
      style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : undefined}
      hasBorder={!isWatched}
      aria-label={title}
    >
      {imageUrl ? (
        <StyledImage style={{ backgroundImage: `url(${imageUrl})` }} />
      ) : (
        <StyledAlt>
          <div>{title}</div>
        </StyledAlt>
      )}
      <StyledOverlay>
        {topReaction && (
          <StyledStoryReaction>
            <StyledStoryCount>{topReaction.count}</StyledStoryCount>
            <Emoji kind={topReaction.name} />
          </StyledStoryReaction>
        )}
      </StyledOverlay>
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ hasBorder: boolean }>`
  ${({ hasBorder, theme }) => `
  ${mediaQuery.netflixQuery(
    theme.components.swimlane.images.stories.width,
    theme.components.swimlane.images.stories.height
  )}
  position: relative;
  margin-right: ${theme.spacings.small}px;
  overflow: hidden;
  cursor: pointer;
  ${hasBorder ? `border: 4px solid ${theme.colors.yellow};` : ''}
`}
`;

const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const StyledAlt = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOverlay = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledStoryReaction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
`;

const StyledStoryCount = styled.span`
  ${({ theme }) => `
  font-size: 16px;
  color: ${theme.colors.white};
  font-weight: 700;
  margin-right: 8px;
`}
`;
