import { gql } from '@apollo/client';
import { includeDependencies } from './fragment';
import { TPostCommentConnection } from './Post';
import { postCommentFragment } from './PostComment';

const dependencies = includeDependencies([postCommentFragment]);

const query = `
query getContentfeedPostComments($postId: String!, $feedPostCommentPageSize: Int!, $after: String) {
  contentfeedPostComments(postId: $postId, first: $feedPostCommentPageSize, after: $after) {
   edges {
       node {...${postCommentFragment.name}}
       cursor
   }
   totalCount
   next
 }
}
${dependencies}`;

export const getContentfeedPostCommentQuery = gql`
  ${query}
`;

export interface TGetContentfeedPostCommentsArgs {
  postId: string;
  feedPostCommentPageSize: number;
  after: string;
}

export interface TGetContentfeedPostCommentsResult {
  contentfeedPostComments: TPostCommentConnection;
}
