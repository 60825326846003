import { gql } from '@apollo/client';

const query = `
query getKetprofiel($sessionId: String!) {
  ketprofiel(sessionId: $sessionId) {
    uid
    username
    publicUsage
    picture
  }
}
`;
// console.log(query);

export const getKetprofiel = gql`
  ${query}
`;

export interface TGetKetprofielArgs {
  sessionId: string;
}

export interface TGetKetprofielResult {
  ketprofiel: TKetprofiel | null;
}

export interface TKetprofiel {
  uid: string;
  username: string;
  publicUsage: boolean;
  picture: string | null;
}
