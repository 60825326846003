import React from 'react';
import { Overlay } from '../Overlay/Overlay';
import { TSwimlaneStoriesItem } from '../Swimlane/types';
import { StoriesSwimlane } from './StoriesSwimlane';
import useDimensions from '../../hooks/useDimensions';
import styled from 'styled-components';
import { getStoriesSize } from './getStoriesSize';
import { usePageTracking } from '../../hooks/usePageTracking';
import { EColor } from '../../theme/styleguide';

export interface TStoryViewerProps {
  initialStory: TSwimlaneStoriesItem;
  onClose: () => any;
}

export const StoryViewer: React.FC<TStoryViewerProps> = (props) => {
  const { initialStory, onClose } = props;
  const [containerRef, size] = useDimensions<HTMLDivElement>();
  const storiesSize = size ? getStoriesSize(size) : null;
  const storiesMeta = !storiesSize
    ? null
    : {
        id: 'stories-overlay',
        title: 'Stories',
        pagePathname: '/stories',
        site: {
          section1: 'home',
          section2: 'stories',
        },
      };

  usePageTracking(storiesMeta);

  return (
    <Overlay onClose={onClose} forceMobileOrientation="PORTRAIT" backgroundColor={EColor.darkPurple}>
      <StyledWrap ref={containerRef}>
        {storiesSize && <StoriesSwimlane initialStory={initialStory} onClose={onClose} size={storiesSize} />}
      </StyledWrap>
    </Overlay>
  );
};

const StyledWrap = styled.div`
  width: 100vw;
  height: 100%;
`;
