import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { EColor } from '../../theme/styleguide';
import { Text } from '../Text/Text';
import { THighlight } from '../Highlight/types';
import { Button } from '../Button/Button';
interface TDialogBoxProps {
  isActive: boolean;
  currentIdx: number;
  idx: number;
  item: THighlight;
  isNavigationPaused: boolean;
}

const DialogBox: React.FC<TDialogBoxProps> = (props) => {
  const { isActive, item, currentIdx, idx, isNavigationPaused } = props;

  if (!isActive && !item.title && !item.description && !item.link && !item.buttonText) return null;

  // Todo: Simplify this.
  const bulletWidth = 22;
  const arrowCenter = 13;
  const distance = idx === 3 ? bulletWidth : 10;
  const moveLeft = currentIdx * bulletWidth - arrowCenter - distance + 12;

  const dialogBoxContent = () => {
    return (
      <StyledWrapper>
        <ProgressArrow isActive={currentIdx === idx} left={moveLeft} />
        {item.title && <StyledTitle>{item.title}</StyledTitle>}
        {item.description && <StyledDescription>{item.description}</StyledDescription>}
        {item.link && item.buttonText && (
          <Button
            text={item.buttonText}
            primaryColor={EColor.yellow}
            textColor={EColor.black}
            className="btnHighlight"
          />
        )}
      </StyledWrapper>
    );
  };

  return (
    <StyledTextBox isActive={isActive} isNavigationPaused={isNavigationPaused} className="highlightContainer">
      {item.link ? <StyledLink to={item.link}>{dialogBoxContent()}</StyledLink> : dialogBoxContent()}
    </StyledTextBox>
  );
};

const ProgressArrow = styled.div<{ isActive: boolean; left: number | null }>`
  ${({ left }) => `
  content: '';
  position: absolute;
  top: calc(50% - 18px);
  left: -24px;
  background: ${EColor.darkRed};
  width: 36px;
  height: 36px;
  clip-path: polygon(100% 0, 0 50%, 100% 100%);

  ${mediaQuery.tabletLandscape(`
    width: 26px;
    height: 26px;
    top: -24px;
    left: calc(50% + ${left}px);
    clip-path: polygon(100% 100%, 50% 0, 0 100%);
  `)}
  `}
`;

const StyledTextBox = styled.div<{ isActive: boolean; isNavigationPaused: boolean }>`
  ${({ theme, isActive, isNavigationPaused }) => `
    order: 3;
    position: relative;
    color: ${EColor.white};
    background: ${EColor.darkRed};
    max-width: 454px;
    width: 100%;
    height: auto;
    margin-left: ${theme.spacings.extralarge}px;
    ${mediaQuery.tabletLandscape(`
      margin-left: 0px;
    `)}

    ${isActive ? `display: flex;` : `display: none;`}

    ${
      isActive &&
      !isNavigationPaused &&
      `
      animation: swipe 4s 15s alternate forwards, pulse 0.5s linear 1;
      @keyframes pulse{
        25%  {transform: scale(0.9);}
        75%  {transform: scale(1.0.5);}
      }
      @keyframes swipe {
        20% {
          transform: translateX(0%);
          opacity: 1;
        }
        60% {
          transform: translateX(50%);
          opacity: 0;
        }
        100% {
          transform: translateX(1000%);
          opacity: 0;
        }
      }
    `
    }

    ${mediaQuery.tabletLandscape(`
      animation: none !important;
    `)}

    & > * {
      padding: ${theme.spacings.large}px;
    }
  `}
`;

const StyledTitle = styled(Text).attrs({ size: 'xlarge', bold: true })`
  text-transform: uppercase;
  overflow-wrap: break-word;
`;

const StyledDescription = styled(Text).attrs({ size: 'regular' })`
  ${({ theme }) => `
    margin: ${theme.spacings.small}px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical; 
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

const StyledWrapper = styled.div``;

export default DialogBox;
