import { TFragment } from './fragment';
import { scaledImageFragment, TScaledImage } from './ScaledImage';
import { pagecontentItemFragment, TPagecontentItem } from './PagecontentItem';
import { headerFragment, THeader } from './Header';
import { TPost } from './Post';
import { postFragment } from './Post';
import { ctaPostFragment } from './ctaPost';
import { pinboardItemFragment, TPinboardItem } from './PinboardItem';

const name = 'program';

const query = `
fragment ${name} on Program {
  id
  title
  header { ... ${headerFragment.name} }
  activeTab
  tabsV2 {
    ... on FeedTab {
        ...feedTab
    }
    ... on PinboardTab {
        ...pinboardTab
    }
    ... on PlaylistsTab {
        ...playlistsTab
    }
    ... on PageContentTab {
        ...pageContentTab
    }
    ... on LinkTab {
        ...linkTab
    }
  }
}

fragment feedTab on FeedTab {
  id
  name
  title
  type
  disableComments
  paginatedPosts(first: $feedPostPageSize) {
    edges {
      node {
        ...${postFragment.name}
        ...${ctaPostFragment.name}
      }
      cursor
    }
    totalCount
    next
  }
  characterFiltersTitle
  filterOnCharacters
  characters {
    id
    imageUrl
    title
    characterDisabled
  }
  theming {
    backgroundColor
    foregroundColor
    buttonColor
    loginButtonColor
  }  
}

fragment pinboardTab on PinboardTab {
  id
  name
  title
  type
  paginatedPinboardRows(first: 4) {
    edges {
      node {
        ...pinboardRowFragment
      }
      cursor
    }
    totalCount
    next
  }
  backgroundUrl
  borderColor
}

fragment pinboardRowFragment on PinboardRow {
  type
  items {
      ...${pinboardItemFragment.name}
  }
}

fragment playlistsTab on PlaylistsTab {
  name
  title
  type
  playlists { ...seasonOverviewTabItem }
}

fragment pageContentTab on PageContentTab {
  name
  title
  type
  pagecontent { ...${pagecontentItemFragment.name} }
}

fragment linkTab on LinkTab {
  id
  name
  linkItem {
    ... on Game {
      id
      userHasVoted
      gameType
    }
    ... on Program {
      id
    }
    ... on Story {
      id
    }
    ... on Theme {
      id
    }
    ... on Video {
      id
    }
  }
  title
  type
}

fragment seasonOverviewTabItem on Playlist {
  name
  title
  type
  imageUrl
  items {
    id
    titlePlaylist
    subtitlePlaylist
    scaledPoster { ...${scaledImageFragment.name} }
    description
    duration
  }
}
`;

// console.log(query);

export const programFragment: TFragment = { name, query };

export interface TProgram {
  id: string;
  title: string | null;
  header: THeader | null;
  activeTab: string | null;
  tabsV2: TTab[];
}

export type TTab = TPlaylistsTab | TPagecontentTab | TFeedTab | TPinboardTab | TLinkTab;

export interface TTabBase {
  name: string;
  title: string | null;
  imageUrl: string | null;
}

export interface TLinkTab extends TTabBase {
  id: string;
  type: 'link';
  name: string;
  linkItem: TLinkTabCta;
}

export interface TLinkTabCta {
  id: string;
  userHasVoted: boolean | null;
  gameType: string;
}

export interface TFeedTab extends TTabBase {
  id: string;
  type: 'feed';
  paginatedPosts: TPostConnection;
  disableComments: boolean;
  characterFiltersTitle: string;
  filterOnCharacters: boolean;
  characters: TCharacter[];
  theming: TFeedTheming;
}

export interface TPostConnection {
  edges: TPostEdge[];
  totalCount: number;
  next: string | null;
}

export interface TCharacter {
  id: string;
  imageUrl: string;
  title: string;
  characterDisabled: boolean;
}

export interface TFeedTheming {
  backgroundColor: string;
  buttonColor: string;
  loginButtonColor: string;
}

export interface TPostEdge {
  node: TPost;
  cursor: string;
}

export interface TPinboardTab extends TTabBase {
  id: string;
  name: string;
  title: string;
  type: 'pinboard';
  paginatedPinboardRows: TPinboardItemConnection;
  backgroundUrl: string;
  borderColor: string;
}

export interface TPinboardItemConnection {
  edges: TPinboardItemEdge[];
  totalCount: number;
  next: string | null;
}

export interface TPinboardItemEdge {
  node: TPinboardRow;
  cursor: string;
}

export interface TPinboardRow {
  items: TPinboardItem[];
}

export interface TPlaylistsTab extends TTabBase {
  type: 'playlists';
  playlists: TPlaylist[];
}

export interface TPlaylist {
  name: string;
  type: string;
  title: string | null;
  imageUrl: string | null;
  items: TPlaylistItem[];
}

export type TPlaylistItem = TVideoPlaylist;

export interface TVideoPlaylist {
  id: string;
  titlePlaylist: string;
  subtitlePlaylist: string | null;
  description: string | null;
  scaledPoster: TScaledImage | null;
  duration: number | null;
}

export interface TPagecontentTab extends TTabBase {
  type: 'pagecontent';
  pagecontent: TPagecontentItem[];
}
