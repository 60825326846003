import { TAnalyticsService, TDigitalData, TPageMetadata } from './TAnalyticsService';

class MockAnalyticsService implements TAnalyticsService {
  readonly cimPlayerId: string = '';

  trackPage(): void {
    return;
  }

  trackEvent(): void {
    return;
  }

  getDigitalData(): TDigitalData | null {
    return null;
  }

  getMetadata(): TPageMetadata {
    return {} as any;
  }

  getFeedVideoDD: TAnalyticsService['getFeedVideoDD'] = () => {
    return null;
  };
}

export default MockAnalyticsService;
