import React from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { Button } from '../Button/Button';
import { Region } from '../Region/Region';
import { Title } from '../Title/Title';
import { THighlight } from './types';
import { Link } from 'react-router-dom';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../Text/Text';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { useTheme } from 'styled-components';

export type THighlightProps = {
  data: THighlight;
};

export const Highlight = (props: THighlightProps) => {
  const { data } = props;
  const theme = useTheme();
  const {
    title,
    description,
    imageUrl,
    buttonText,
    link,
    fullWidthEnabled,
    fullWidthImageUrl,
    fullWidthBackgroundUrl,
    textColor,
  } = data;
  const isLink = !!(buttonText && link);
  const fullWidthNeeded = !!(!useMatchMedia(mediaQuery.mobileTabletLandscapeQuery) && fullWidthEnabled);

  let highlightBackgroundColor = theme.colors.red;
  let highlightTextColor = textColor ? textColor : theme.colors.white;

  const highlightContent = () => {
    return (
      <StyledWrap className="highlightContainer">
        <StyledInfo fullWidthNeeded={fullWidthNeeded} backgroundColor={highlightBackgroundColor}>
          <StyledText textColor={highlightTextColor}>
            {title && (
              <Title
                text={title}
                backgroundColor={highlightBackgroundColor}
                fullWidthEnabled={fullWidthEnabled}
                textColor={highlightTextColor}
              />
            )}
            {description && (
              <StyledDescription fullWidthNeeded={fullWidthNeeded} textColor={highlightTextColor}>
                {description}
              </StyledDescription>
            )}
          </StyledText>
          {isLink && (
            <StyledButton
              text={buttonText}
              className="btnHighlight"
              primaryColor={EColor.yellow}
              textColor={EColor.blue}
            />
          )}
        </StyledInfo>
        {imageUrl && (
          <StyledImageWrap fullWidthNeeded={fullWidthNeeded}>
            <StyledImage src={fullWidthNeeded && fullWidthImageUrl ? fullWidthImageUrl : imageUrl} />
          </StyledImageWrap>
        )}
      </StyledWrap>
    );
  };

  return (
    <Region
      color={highlightBackgroundColor}
      verticalSpacing={0}
      fullMobileWidth={true}
      backgroundImage={fullWidthBackgroundUrl}
    >
      {link && <StyledLink to={link}>{highlightContent()}</StyledLink>}
      {!link && highlightContent()}
    </Region>
  );
};

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  position: relative;
  flex-direction: row;
  ${mediaQuery.tabletLandscape(`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `)}
`;

const StyledInfo = styled.div<{ fullWidthNeeded: boolean; backgroundColor: string }>`
  width: 100%;
  display: inline-flex;
  ${({ theme }) =>
    mediaQuery.tabletLandscape(`
      order: 2;
      padding: ${theme.spacings.medium}px 0;
    `)}
  ${({ theme }) =>
    mediaQuery.tabletPortrait(`
      padding: ${theme.spacings.medium}px;
    `)}
  ${({ theme, fullWidthNeeded, backgroundColor }) => `
    ${
      fullWidthNeeded &&
      `
      background-color: ${backgroundColor};
      z-index: 2;
      justify-content: unset;
      bottom: 0px;
      left: 0px;
      padding: ${theme.spacings.large}px;
      position: absolute;
      flex-direction: row;
      max-width: 600px;
      align-items: center;
    `
    }
    ${
      !fullWidthNeeded &&
      `
      position: unset;
      flex-direction: column;
      max-width: 400px;
      align-items: flex-start;
    `
    }
  `}
`;

const StyledText = styled.div<{ textColor: string }>`
  ${({ textColor }) => `
    display: flex;
    flex-direction: column;
    color: ${textColor};
  `}
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: fit-content;
`;

const StyledDescription = styled(Text)<{ fullWidthNeeded: boolean; textColor: string }>`
  ${({ theme, fullWidthNeeded, textColor }) => `
    ${
      !fullWidthNeeded &&
      `
      margin-bottom: ${theme.spacings.extralarge}px;
      color: ${textColor};
    `
    }
  `}
`;

const StyledLink = styled(Link)``;

const StyledImageWrap = styled.div<{ fullWidthNeeded: boolean }>`
  ${({ fullWidthNeeded }) => `
    ${mediaQuery.desktop(`
      flex: 1;
      display: flex;
      ${
        fullWidthNeeded &&
        `
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
      `
      }
    `)}
    ${mediaQuery.tabletLandscape(`
      order: 1;
      position: unset;
    `)}
  `}
`;

const StyledImage = styled.img`
  max-height: 400px;
  width: auto;
  object-fit: contain;
`;
