import { TFragment } from './fragment';

const name = 'storyReaction';

const query = `
fragment ${name} on StoryReaction {
  name
  count
}
`;
// console.log(query);

export const storyReactionFragment: TFragment = { name, query };

export interface TStoryReaction {
  name: string;
  count: number;
}
