import { BREAKPOINTS } from './breakpoints';

const mobileTabletPortraitQuery = `(max-width: ${BREAKPOINTS.tabletPortrait}px)`;

const mobileTabletLandscapeQuery = `(max-width: ${BREAKPOINTS.tablet}px)`;

const mobileQuery = `(max-width: ${BREAKPOINTS.mobile}px)`;

const desktopQuery = `(min-width: ${BREAKPOINTS.tabletPortrait}px)`;

const mobile = (css: string) => {
  return `@media only screen and ${mobileQuery}{ ${css} }`;
};

const tablet = (css: string) => {
  return `@media only screen and (min-width: ${BREAKPOINTS.mobile}px) and (max-width: ${BREAKPOINTS.tablet}px){ ${css} }`;
};

const tabletPortrait = (css: string) => {
  return `@media only screen and (max-width: ${BREAKPOINTS.tabletPortrait}px){ ${css} }`;
};

const tabletLandscape = (css: string) => {
  return `@media only screen and (max-width: ${BREAKPOINTS.tablet}px){ ${css} }`;
};

const desktop = (css: string) => {
  return `@media only screen and ${desktopQuery}{ ${css} }`;
};

const desktopDown = (css: string) => {
  return `@media only screen and (max-width: ${BREAKPOINTS.desktop}px){ ${css} }`;
};

const kiefer = (css: string) => {
  return `@media only screen and (min-width: ${BREAKPOINTS.desktop}px){ ${css} }`;
};

const netflixQuery = (width: number, height: number) => {
  return `@media only screen and (min-width: 1400px){
    width: ${width}px;
    height: ${height}px;
  }
  @media only screen and (max-width: 1400px){
    width: calc(${width}px - 20px);
    height: calc(${height}px - 20px);
  }
  @media only screen and (max-width: 1100px){
    width: calc(${width}px - 30px);
    height: calc(${height}px - 30px);
  }
  @media only screen and (max-width: 800px){
    width: calc(${width}px - 40px);
    height: calc(${height}px - 40px);
  }
  @media only screen and (max-width: 500px){
    width: calc(${width}px - 50px);
    height: calc(${height}px - 50px);
  }
  `;
};

const netflixWidthQuery = (width: number) => {
  return `@media only screen and (min-width: 1400px){
    width: ${width}px;
  }
  @media only screen and (max-width: 1400px){
    width: calc(${width}px - 20px);
  }
  @media only screen and (max-width: 1100px){
    width: calc(${width}px - 30px);
  }
  @media only screen and (max-width: 800px){
    width: calc(${width}px - 40px);
  }
  @media only screen and (max-width: 500px){
    width: calc(${width}px - 50px);
  }
  `;
};

export const mediaQuery = {
  mobileTabletPortraitQuery,
  mobileTabletLandscapeQuery,
  mobileQuery,
  desktopQuery,
  mobile,
  tablet,
  tabletPortrait,
  tabletLandscape,
  desktop,
  desktopDown,
  kiefer,
  netflixQuery,
  netflixWidthQuery,
};
