import { TFragment } from './fragment';

const name = 'vrtPlayerFragment';

const query = `
fragment ${name} on VrtPlayerConfig {
  mediaReference
  aggregatorUrl
  clientCode
  aspectRatio
}
`;

export interface TVrtPlayerConfig {
  aggregatorUrl: string;
  mediaReference: string;
  clientCode: string;
  aspectRatio: string;
}

export const vrtPlayerFragment: TFragment = { name, query };
