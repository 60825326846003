import React, { useState } from 'react';
import styled from 'styled-components';
import { TPostComment } from '../../pages/ProgramPage/types';
import { formatIsoDateTime } from '../../lib/datetime';
import Services from '../../services/Services';
import { EColor } from '../../theme/styleguide';
import { Avatar } from '../Avatar/Avatar';
import { Button } from '../Button/Button';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../Text/Text';

export type TPostCommentProps = {
  data: TPostComment;
  onDeleted: (comment: TPostComment) => void;
};

export const PostComment = (props: TPostCommentProps) => {
  const { author, postedOn, message } = props.data;
  const { onDeleted } = props;
  const [error, setError] = useState<boolean>(false);

  const canDelete = props.data.mine;

  const deletePostComment = async () => {
    const { commentId, postId } = props.data;
    setError(false);
    try {
      if (props.data.mine) {
        await Services.bffService.deletePostComment({ commentId, postId });
      }
      onDeleted(props.data);
    } catch (error) {
      Services.loggerService.errorObject(`Failed to send message`, { props, error });
      Services.monitoringService.captureException(error);
      setError(true);
    }
  };

  return (
    <StyledWrap>
      <StyledInnerWrap>
        <StyledAvatarWrap>
          <Avatar size="small" imageUrl={author?.avatarUrl} initials={author?.name} />
        </StyledAvatarWrap>
        <StyledContentWrap>
          {author?.name && <Text>{author.name} </Text>}
          <Text>{message}</Text>
          {canDelete && (
            <StyledDeleteButton>
              <Button text="wissen" primaryColor={EColor.red} textColor={EColor.white} onClick={deletePostComment} />
            </StyledDeleteButton>
          )}
          {error && (
            <Text color={EColor.red}>
              De post kon niet verwijderd worden. Probeer het later opnieuw of contacteer de webmaster.
            </Text>
          )}
        </StyledContentWrap>
      </StyledInnerWrap>
      <StyledPostDate>
        <Text>{formatIsoDateTime(postedOn)}</Text>
      </StyledPostDate>
    </StyledWrap>
  );
};

const StyledWrap = styled.div``;

const StyledInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledContentWrap = styled.div`
  ${({ theme }) => `
    background-color: rgba(255, 255, 255, 0.2);
    flex-grow:1;
    margin-left: ${theme.spacings.small}px;
    padding: ${theme.spacings.small}px;
    overflow: hidden;
    & > span {
      overflow-wrap: break-word;
    }
    & > span:first-child {
      margin-right: ${theme.spacings.medium}px;
    }
  `}
`;

const StyledAvatarWrap = styled.div`
  ${({ theme }) => `
    width: ${theme.components.avatar.small}px;
    ${mediaQuery.tabletLandscape(`
      width: calc(${theme.components.avatar.small}px - ${theme.components.avatar.mobileReduction}px);
    `)}
  `}
`;

const StyledDeleteButton = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacings.small}px 0;
    & > div {
      height: 32px;
    }
  `}
`;

const StyledPostDate = styled.div`
  ${({ theme }) => `
    width: 100%;
    opacity: 0.5;
    text-align: right;
    padding: ${theme.spacings.small}px 0;
    & p {
      font-weight: 400;
    }
  `}
`;
