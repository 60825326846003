import React from 'react';
import styled from 'styled-components';
import { TEmoji } from '../../lib/emoji';
import { Emoji } from '../Emoji/Emoji';
import { Motion } from '../Motion/Motion';
import { ReactComponent as ActiveBorderSVG } from './active-border.svg';
import { TStoryReactionName } from '../StoryViewer/types';

type TProps = {
  emojiKind: TEmoji | TStoryReactionName;
  count?: number;
  active?: boolean;
  onPress?: () => any | null;
};

export const EmojiReaction = ({ emojiKind, count = 0, active, onPress }: TProps) => {
  return (
    <StyledWrap onClick={onPress}>
      <StyledEmojiBorderWrap>
        {active && (
          <Motion scaleIn fadeIn>
            <StyledActiveBorder>
              <ActiveBorderSVG />
            </StyledActiveBorder>
          </Motion>
        )}
        <StyledEmojiWrap>
          <StyledEmoji kind={emojiKind} size={active ? 'large' : 'small'} />
        </StyledEmojiWrap>
      </StyledEmojiBorderWrap>
      <Motion jumpUp={active} jumpUpFrom={5}>
        <StyledCount active={active}>{count}</StyledCount>
      </Motion>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0px 8px;
`;

const StyledEmojiBorderWrap = styled.div``;

const StyledEmojiWrap = styled.div`
  position: relative;
  z-index: 5;
`;

const StyledEmoji = styled(Emoji)``;

const StyledActiveBorder = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 2;
`;

const StyledCount = styled.p<{ active?: boolean }>`
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  margin: 0;
  margin-left: 4px;
  position: relative;
  top: 2px;

  ${({ active, theme }) =>
    active &&
    `
    font-size: 16px;
    color: ${theme.colors.green};
    margin-left: 8px;
  `}
`;
