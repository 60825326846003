import React from 'react';
import { TPinboardTab, TPinboardItem } from './types';
import styled from 'styled-components';
import { Region } from '../../components/Region/Region';
import { EColor } from '../../theme/styleguide';
import { Button } from '../../components/Button/Button';
import { LoadMoreIcon } from '../../assets/icons';
import { loadSpecificPinboardRows } from '../../services/FeedService/FeedService';
import { PinboardOverlay } from '../../components/Pinboard/PinboardOverlay';
import { PinboardItem } from '../../components/Pinboard/PinboardItem';
import { useTheme } from 'styled-components';
import { PinboardContext, TPinboardContextValue, TMyPinboardReaction } from '../../components/Pinboard/PinboardContext';

export interface TPinboardTabProps {
  tab: TPinboardTab;
}

export const PinboardTab: React.FC<TPinboardTabProps> = (props) => {
  const [rows, setRows] = React.useState(props.tab.paginatedPinboardRows || []);
  const { backgroundUrl, borderColor } = props.tab;
  const [next, setNext] = React.useState(props.tab.next);
  const [total, setTotal] = React.useState(props.tab.totalCount);
  const [isAtEnd, setIsAtEnd] = React.useState(props.tab.paginatedPinboardRows.length >= total);
  const [selectedPinboardItem, setSelectedPinboardItem] = React.useState<TPinboardItem | null>(null);
  const [myReactions, setMyReactions] = React.useState<TMyPinboardReaction[] | []>([]);
  const theme = useTheme();

  const loadPinboardRows = async () => {
    const specificPinboardRows = await loadSpecificPinboardRows(props.tab.id, next);
    setTotal(specificPinboardRows.totalCount);
    const updatedRows = rows.concat(specificPinboardRows.rows);
    setRows(updatedRows);
    const nextCursor = updatedRows.length < specificPinboardRows.totalCount ? specificPinboardRows.next : null;
    setNext(nextCursor);
    setIsAtEnd(!nextCursor);
  };

  const pinboardContext: TPinboardContextValue = {
    myReactions: myReactions,
    setMyReactions: () => setMyReactions,
    activePinboardIndex: 1,
    requestPause: () => {},
    total: 1,
    size: {
      height: 0,
      width: 0,
      mode: 'single',
    },
  };

  return (
    <PinboardContext.Provider value={pinboardContext}>
      <Region color={theme.colors.transparent} fullMobileWidth={true}>
        <StyledWrap backgroundUrl={backgroundUrl} borderColor={borderColor}>
          {rows.map((pinboardRow, rowIndex) => {
            return (
              <StyledPinboardRow key={`pinboard-row-${rowIndex}`}>
                {pinboardRow.items.map((pinboardItem, cellIndex) => {
                  return (
                    <PinboardItem
                      item={pinboardItem}
                      onClick={() => setSelectedPinboardItem(pinboardItem)}
                      key={`pinboard-cell-${rowIndex}-${cellIndex}`}
                    />
                  );
                })}
              </StyledPinboardRow>
            );
          })}

          {!isAtEnd && (
            <StyledButtonWrapper>
              <StyledButton
                text="bekijk meer"
                primaryColor={EColor.red}
                textColor={EColor.white}
                onClick={() => loadPinboardRows()}
                icon={<LoadMoreIcon />}
                variant="filledAndBorders"
              />
            </StyledButtonWrapper>
          )}

          {selectedPinboardItem && (
            <PinboardOverlay onClose={() => setSelectedPinboardItem(null)} item={selectedPinboardItem} />
          )}
        </StyledWrap>
      </Region>
    </PinboardContext.Provider>
  );
};

const StyledWrap = styled.div<{ backgroundUrl: string; borderColor: string }>`
  ${({ backgroundUrl, borderColor }) => `
    width: 100%;
    background-image: url(${backgroundUrl});
    background-repeat: repeat;
    border: 5px solid ${borderColor};
    padding: 16px;
    overflow: hidden;
    padding-bottom: 50px;
  `}
`;

const StyledPinboardRow = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

const StyledButton = styled(Button)`
  width: auto;
  margin: auto;
`;
