import Services from '../../services/Services';
import { TPagecontentData } from '../Pagecontent/Pagecontent';
import { pathToTitle } from './metaHelpers';
import { TPageMeta } from './PageMeta';

export function getPagecontentMeta(path: string, page: TPagecontentData, canonical: string | null): TPageMeta {
  return {
    title: pathToTitle(path),
    description: getDescription(page),
    canonical,
  };
}

function getDescription(pagecontent: TPagecontentData): string {
  return pagecontent.items
    .map((item) => {
      switch (item.type) {
        case 'header':
          return item.title ?? item.description ?? null;
        case 'highlight':
          return item.title ?? item.description ?? null;
        case 'swimlane':
          switch (item.style) {
            // first story title
            case 'stories':
              // return item.items[0]?.title ?? null;
              // title is not usable for now, as it's only a technical title
              return null;
            // thumb swimlane title
            case 'thumbnails':
              return item.title ?? null;
            // first 3 hero program/theme names
            case 'heroes':
              const description = item.items
                // filter peeties
                .map((hero) => (hero.type === 'hero' ? hero.alt : null))
                .filter((hero) => hero !== null)
                .slice(0, 3)
                .join(', ');
              return description.trim() !== '' ? description : null;
            // should not occur here
            // boxset swimlane title
            case 'boxset':
              return item.title ?? null;
            case 'seasons':
            default:
              Services.loggerService.warn(`Unexpected swimlane style ${item.style} in getPagecontentMeta`);
              return null;
          }
        default:
          return null;
      }
    })
    .filter((part) => part !== null)
    .join(' - ');
}
