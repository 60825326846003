import { TPagecontentPage } from '../../pages/AemPage/types';
import { TPageMetadata } from '../../services/AnalyticsService/TAnalyticsService';

export function getPagecontentPageMetadata(page: TPagecontentPage): TPageMetadata {
  const { pathname } = window.location;
  switch (pathname) {
    case '/':
      return HOMEPAGE_METADATA;
    case '/kijken':
      return VIDEOZONE_METADATA;
    case '/spelen':
      return GAMEZONE_METADATA;
    default:
  }
  console.warn(`Unmapped pathname for getPagecontentPageMetadata(): ${pathname}`);
  return {
    id: window.location.pathname.substr(0),
    title: 'unnamed page',
  };
}

const HOMEPAGE_METADATA: TPageMetadata = {
  id: 'content/ketnet/nl',
  title: 'home',
  site: {
    section1: 'home',
  },
};

const VIDEOZONE_METADATA: TPageMetadata = {
  id: 'content/ketnet/nl/kijken',
  title: 'Kijken',
  site: {
    section1: 'kijken',
  },
};

const GAMEZONE_METADATA: TPageMetadata = {
  id: 'content/ketnet/nl/spelen',
  title: 'gameZone',
  site: {
    section1: 'spelen',
  },
};
