import { TFragment } from './fragment';
import { storyReactionFragment, TStoryReaction } from './StoryReaction';
import { TVrtPlayerConfig, vrtPlayerFragment } from './VrtPlayer';

const name = 'storyDetails';

const query = `
fragment ${name} on Story {
  id
  title
  background {
    type
    ...on StoryVideoBackground {
      imageUrl
      duration
      vrtPlayer { ...${vrtPlayerFragment.name} }
    }
    ...on StoryImageBackground {
      imageUrl
      duration
    }
  }
  overlay {
    type
    ...on StoryCallToActionOverlay {
      link
      linkItem {
        ... on Game { id }
        ... on Program { id }
        ... on Theme { id }
        ... on Video { id }
        # type
      }
      buttonText
    }
    ...on StoryReactionsOverlay {
      myReaction
      reactions { ...${storyReactionFragment.name} }
    }
    ...on StoryPollOverlay {
      myAnswerId
      answers {
        id
        label
        count
      }
    }
  }
}
`;

export const storyDetailsFragment: TFragment = { name, query };

export interface TStoryDetails {
  id: string;
  title: string | null;
  background: TStoryBackground | null;
  overlay: TStoryOverlay | null;
}

export type { TStoryReaction } from './StoryReaction';

export type TStoryBackground = TStoryImageBackground | TStoryVideoBackground;

export interface TStoryImageBackground {
  type: 'image';
  duration: number;
  imageUrl: string;
}

export interface TStoryVideoBackground {
  type: 'video';
  duration: number;
  imageUrl: string;
  vrtPlayer: TVrtPlayerConfig;
}

export type TStoryOverlay = TStoryReactionsOverlay | TStoryCallToActionOverlay | TStoryPollOverlay;

export interface TStoryReactionsOverlay {
  type: 'reactions';
  myReaction: string | null;
  reactions: TStoryReaction[];
}
export interface TStoryCallToActionOverlay {
  type: 'calltoaction';
  link: string | null;
  linkItem: TStoryCallToActionLinkItem | null;
  buttonText: string | null;
}

export interface TStoryPollOverlay {
  type: 'poll';
  myAnswerId: string | null;
  answers: TStoryPollAnswer[];
}

export interface TStoryPollAnswer {
  id: string;
  label: string;
  count: number;
}

export interface TStoryCallToActionLinkItem {
  id: string;
  // type: string; // only needed in the app
}
