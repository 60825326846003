import { TVideoDetail, TPlaylist } from './types';

export function findActivePlaylist(video: TVideoDetail): TPlaylist | null {
  const { activePlaylist } = video;
  const { playlists } = video;
  if (!playlists || playlists.length === 0) {
    return null;
  }
  const playlist = playlists.find((p) => p.name === activePlaylist);
  return playlist ?? null;
}
