const KARREWIET_PATH = '/themas/karrewiet';
const LIKEME_PATH = '/kijken/-/likeme';

const KIJKEN_RX = /^\/kijken\/([a-z0-9]{2,})(\/.*)?$/i;

export function getRedirect(pathname: string): string | null {
  if (
    pathname.startsWith('/kijken/karrewiet') ||
    pathname.startsWith('/karrewiet') ||
    pathname.startsWith('/zoeken/karrewiet')
  ) {
    return KARREWIET_PATH;
  }

  if (pathname.startsWith('/kijken/likeme')) {
    return LIKEME_PATH;
  }

  if (pathname.startsWith('/deregelvan3S-interactief')) {
    return '/spelen/r/regel-het-voor-dries-de-kunstroof';
  }

  if (pathname.startsWith('/ketnet-voor-ouders')) {
    return 'https://ouders.ketnet.be/';
  }

  if (pathname.startsWith('/junior')) {
    return 'https://www.ketnet-junior.be/';
  }

  const kijkenMatch = KIJKEN_RX.exec(pathname);
  if (kijkenMatch) {
    const program = kijkenMatch[1];
    const letter = program[0];
    return `/kijken/${letter}/${program}`;
  }

  return null;
}
