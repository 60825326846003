import { BREAKPOINTS } from './breakpoints';
import { styleguide } from './styleguide';
import { TTheme } from './types';
import { RESPONSIVE_FONT_SIZES } from './typography';

export const theme2021: TTheme = {
  // Styleguide values
  colors: styleguide.colors,
  contrast: styleguide.contrast,
  spacings: styleguide.spacings,

  // Breakpoints
  breakpoints: BREAKPOINTS,

  // Typography
  fontSizes: RESPONSIVE_FONT_SIZES,

  // Components
  components: {
    avatar: {
      large: 40,
      small: 32,
      mobileReduction: 8,
    },
    header: {
      large: 400,
      small: 200,
    },
    mainHeader: {
      logo: {
        width: 144,
        height: 160,
      },
      logoTabletLandscape: {
        width: 100,
        height: 111,
      },
      logoTabletPortrait: {
        width: 44,
        height: 48,
      },
    },
    title: {
      singleLineHeight: 54,
    },
    button: {
      primaryColor: styleguide.colors.yellow,
      height: 48,
      paddingHorizontal: 12,
      textTransform: 'lowercase',
    },
    iconButton: {
      width: 64,
      height: 64,
      widthMobile: 48,
      heightMobile: 48,
    },
    swimlane: {
      horizontalSpacing: 12,
      images: {
        boxset: {
          width: 200,
          height: 285,
        },
        heroes: {
          width: 200,
          height: 200,
        },
        stories: {
          width: 220,
          height: 390,
        },
        seasons: {
          width: 210,
          height: 300,
        },
        thumbnails: {
          width: 291,
          height: 164,
        },
        suggestions: {
          width: 291,
          height: 164,
        },
        filters: {
          width: 152,
          height: 146,
        },
      },
    },
    region: {
      padding: 24,
      paddingLeft: 192,
      maxWidth: 1920,
    },
    modal: {
      backgroundColor: styleguide.colors.darkPurple,
    },
  },

  // Pages
  pages: {
    videoZone: {
      primaryColor: styleguide.colors.yellow,
    },
    gameZone: {
      primaryColor: styleguide.colors.green,
    },
    program: {
      feed: {
        maxWidth: 590,
      },
    },
    privacy: {
      logoWidth: 120,
      maxWidth: 1250,
      containerWidth: 1130,
      gutterWidth: 20,
    },
  },
};
