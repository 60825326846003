import { TFragment } from './fragment';
import { pagecontentItemFragment, TPagecontentItem } from './PagecontentItem';
import { headerFragment, THeader } from './Header';

const name = 'theme';

const query = `
fragment ${name} on Theme {
  id
  title
  header { ...${headerFragment.name} }
  pagecontent { ...${pagecontentItemFragment.name} }
}
`;
// console.log(query);

export const themeFragment: TFragment = { name, query };

export interface TTheme {
  id: string;
  title: string | null;
  header: THeader | null;
  pagecontent: TPagecontentItem[];
}
