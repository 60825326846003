import React, { useEffect } from 'react';
import useSWR from 'swr';
import { PageLoading } from '../PageLoading/PageLoading';
import { ErrorPage } from '../../pages/ErrorPage/ErrorPage';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Services from '../../services/Services';
import { NotFound } from '../../components/NotFound/NotFound';
import { injectFontSize } from '../../theme/typography';

const DEFAULT_SWR_RETRY_COUNT = 5;
const DEFAULT_SWR_RETRY_INTERVAL_IN_S = 60;

export interface TPageWithDataProps<T> {
  swrKey: string;
  fetcher: () => Promise<T>;
  component: React.FC<{ data: T }>;
}

export const PageWithData = <T extends object>(props: TPageWithDataProps<T>) => {
  const { data, error, isValidating, mutate } = useSWR(props.swrKey, props.fetcher, {
    errorRetryCount: DEFAULT_SWR_RETRY_COUNT,
    errorRetryInterval: DEFAULT_SWR_RETRY_INTERVAL_IN_S * 1000,
  });

  // revalidate the page when session changed
  useEffect(() => Services.sessionService.onSessionChanged(() => mutate()), [mutate]);

  if (data) {
    const Component = props.component;
    return <Component data={data} />;
  }

  if (isValidating || (!isValidating && !error)) {
    // SWR briefly has a state where there is no data, no error and it's reporting not to be validating
    return <PageLoading />;
  }

  return (
    <>
      <ErrorPage title="Oeps! De pagina kon niet geladen worden.">
        <StyledLink to="/">Ga naar de homepagina</StyledLink>
      </ErrorPage>
      <NotFound />
    </>
  );
};

const StyledLink = styled(Link)`
  ${({ theme }) => `
    display: inline-block;
    margin: ${theme.spacings.extralarge}px 0px;
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.white};

  `};
  ${injectFontSize('medium')};
`;
