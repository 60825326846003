import React from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { isIos, isMobile, isTablet } from '../../lib/features';
import { ReactComponent as AndroidIcon } from './icon-android.svg';
import { ReactComponent as AppleIcon } from './icon-apple.svg';
import { Button } from '../../components/Button/Button';
import { Text } from '../../components/Text/Text';

const SVG_SIZE = 21;

export type TJuniorAppPromoProps = {};

export const JuniorAppPromo = (props: TJuniorAppPromoProps) => {
  if (!isMobile && !isTablet) {
    return null;
  }

  const storeUrl = isIos
    ? 'https://apps.apple.com/be/app/ketnet-junior/id1001982587?l=nl'
    : 'https://play.google.com/store/apps/details?id=be.vrt.ketnet.ketnetjr&hl=nl&gl=NL';

  return (
    <StyledWrapper>
      <StyledTitle>Wist je dat er ook een Junior app is?!</StyledTitle>
      <StyledLink href={storeUrl}>
        <Button text="download Junior app" icon={isIos ? <AppleIcon /> : <AndroidIcon />} primaryColor={EColor.blue} />
      </StyledLink>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Text)`
  ${({ theme }) => `
    margin-top: ${theme.spacings.extralarge}px;
  `}
`;

const StyledLink = styled.a`
  ${({ theme }) => `
    display: block;
    margin-top: ${theme.spacings.small}px;
    & svg {
      max-width: ${SVG_SIZE}px;
      max-height: ${SVG_SIZE}px;
    }
  `}
`;
