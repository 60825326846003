import { TPage as TAemPage } from '../../pages/AemPage/types';
import BaseService from '../BaseService';
import { TAnalyticsService, TDigitalData, TPageEnvironment, TPageMetadata } from './TAnalyticsService';
import { getGamePageMetadata } from './getGamePageMetadata';
import { getPagecontentPageMetadata } from './getPagecontentPageMetadata';
import { getProgramPageMetadata } from './getProgramPageMetadata';
import { getThemePageMetadata } from './getThemePageMetadata';
import { getVideoPageMetadata } from './getVideoPageMetadata';
import Services from '../../services/Services';

declare global {
  interface Window {
    digitalData?: TDigitalData;
    pp_gemius_hit(identifier: string): void;
    _satellite: {
      track: (event: string, meta: object) => void;
    };
  }
}

class AnalyticsService extends BaseService implements TAnalyticsService {
  // we only have CIM ids for production, so they can be hardcoded here
  public readonly cimPlayerId = 'zUbldi7KiU1K.FE63Z.JprQXnKQ_VEvkP9GNFOr3TOD.q7';
  private readonly cimPagetrackingId = 'pyY70.swsHeA6rX2Vua9p3Z2.fOZZztwfiYc9kkcWyn.Y7';

  private createDigitalDataObject(): TDigitalData {
    const { sessionService } = Services;
    const userProfile = sessionService.isLoggedIn() ? sessionService.getUserProfile() : null;
    return {
      page: {
        // page_id: null, // via trackPage
        // page_url: null, // via trackPage
        // page_uri: null, // via trackPage
        page_domain: window.location.hostname,
        // page_title: null, // via trackPage
        environment: this.getPageEnvironment(),
        page_technology: 'single page',
        page_language: 'nl',
        channel: 'web',
        brand_technology: 'ketnet',
        brand_media: 'ketnet',
      },
      user: {
        ketnetId: userProfile ? userProfile.uid : '',
      },
      session: {},
      event: {
        siteSearch_term: '',
      },
      events: [],
    };
  }

  public trackPage(meta: TPageMetadata): void {
    const dd = this.getDigitalData(meta);
    if (dd) {
      dd.page.trackPageView = 'true';
      // adobe analytics SDK (launch) picks up window.digitalData automatically and detects changes to it automagically
      window.digitalData = dd;
    }

    // set extra ketnet_pageview event for snowplow.
    const customPageEvent = new Event('ketnet_pageview');
    document.dispatchEvent(customPageEvent);
  }

  public trackEvent(event: string, interaction: string, interactionDetail: string): void {
    if (typeof window !== 'undefined') {
      window._satellite.track(event, { interaction, interactionDetail });
    }
  }

  private gemiusFirstHitSkipped = false;
  private trackGemius() {
    if (this.gemiusFirstHitSkipped) {
      window.pp_gemius_hit(this.cimPagetrackingId);
    } else {
      // the first hit is handled by "pending gemius hit" call, via the snippet in index.html
      // somehow this snippet has access to the CIM pagetracking id *magic*
      this.gemiusFirstHitSkipped = true;
    }
  }

  public getMetadata(page: TAemPage): TPageMetadata | null {
    switch (page.pageType) {
      case 'video':
        return getVideoPageMetadata(page);
      case 'program':
        return getProgramPageMetadata(page);
      case 'theme':
        return getThemePageMetadata(page);
      case 'game':
        return getGamePageMetadata(page);
      case 'pagecontent':
        return getPagecontentPageMetadata(page);
      case 'redirect':
        return null;
      // unmapped content
      default:
        throw this.createError(`Unmapped pageType: ${(page as TAemPage).pageType}`);
    }
  }

  public getDigitalData(meta: TPageMetadata): TDigitalData | null {
    const dd = this.createDigitalDataObject();
    dd.page.page_id = meta.id;
    dd.page.page_title = meta.title;
    dd.page.page_url = this.getPageUrl(meta.pagePathname);
    dd.page.page_uri = this.getPageUri(meta.pagePathname);

    if (meta.labels) {
      dd.page.label = meta.labels.join(',');
    }

    if (meta.search) {
      dd.event.siteSearch_results = `${meta.search.results}`;
      dd.event.siteSearch_term = meta.search.term;
    }

    if (meta.program !== undefined) {
      dd.page.program_name = meta.program.name;
      if (meta.program !== undefined) {
        dd.page.program_whatsonId = meta.program.whatsonId;
      }
    }

    if (meta.episode) {
      dd.page.episode_name = meta.episode.name;
      if (meta.episode.nr !== undefined) {
        dd.page.episode_id = `${meta.episode.nr}`;
      }
      if (meta.episode.whatsonId) {
        dd.page.episode_whatsonId = meta.episode.whatsonId;
      }
      if (meta.episode.broadcastDate) {
        dd.page.episode_broadcast_date = meta.episode.broadcastDate;
      }
      if (meta.episode.season) {
        dd.page.episode_season = meta.episode.season;
      }
    }

    if (meta.site) {
      dd.page.siteSection_1 = meta.site.section1;
      if (meta.site.section2) {
        dd.page.siteSection_2 = meta.site.section2;
        if (meta.site.section3) {
          dd.page.siteSection_3 = meta.site.section3;
        }
      }
    }

    if (meta.media) {
      dd.media = { ...meta.media };
    }

    if (window.digitalData?.events) {
      dd.events = window.digitalData.events;
    }

    return dd;
  }

  getFeedVideoDD: TAnalyticsService['getFeedVideoDD'] = (post) => {
    // TODO: KETNET-3577 get info from server, add correct pagePathname
    const meta: TPageMetadata = {
      id: post.id,
      title: post.id,
      pagePathname: '/kijken/m/meisjes',
      site: {
        section1: 'kijken',
        section2: 'meisjes',
        section3: 'feed',
      },
      episode: {
        name: post.id,
        broadcastDate: post.postedOn,
      },
      program: {
        name: 'meisjes',
      },
      media: {
        media_subtype: 'post',
      },
    };
    return this.getDigitalData(meta);
  };

  private getPageEnvironment(): TPageEnvironment {
    switch (this.services.configService.environment) {
      case 'production':
        return 'prod';
      case 'staging':
        return 'stag';
      default:
        return 'dev';
    }
  }

  private getPageUri(pathnameOverride?: string): string {
    // exclude query params and anchors
    return pathnameOverride ?? window.location.pathname;
  }

  private getPageUrl(pathnameOverride?: string): string {
    const { host, protocol, pathname } = window.location;
    return `${protocol}/${host}${pathnameOverride ?? pathname}`;
  }
}

export default AnalyticsService;
