import React from 'react';
import styled from 'styled-components';
import { VideoThumb } from '../../components/VideoThumb/VideoThumb';
import { TPlaylistItem } from './types';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../../components/Text/Text';

export interface TPlaylistItemProps {
  item: TPlaylistItem;
}

export const PlaylistItem: React.FC<TPlaylistItemProps> = (props) => {
  const { item } = props;
  const { description, scaledPoster, id, titlePlaylist, subtitlePlaylist, duration } = item;
  return (
    <StyledWrap>
      <StyledThumb>
        {scaledPoster && <VideoThumb thumbnailUrl={scaledPoster.small} id={id} duration={duration} />}
      </StyledThumb>
      <StyledInfo>
        <StyledTitle>{titlePlaylist}</StyledTitle>
        {subtitlePlaylist && <StyledSubtitle>{subtitlePlaylist}</StyledSubtitle>}
        {description && <StyledDescription>{description}</StyledDescription>}
      </StyledInfo>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  display: flex;
  flex-direction: row;
  ${mediaQuery.tabletPortrait(`
    flex-direction: column;
  `)}
`;

const StyledThumb = styled.div`
  // figma says 452, but this is huge
  width: 291px;
  margin-right: 32px;
  flex-shrink: 0;
`;

const StyledInfo = styled.div`
  ${({ theme }) =>
    mediaQuery.tabletPortrait(`
      margin-top: ${theme.spacings.small}px;
    `)}
`;

const StyledTitle = styled(Text).attrs({ tag: 'h2' })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.small}px;
  `};
`;

const StyledSubtitle = styled(Text).attrs({ tag: 'h5' })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.small}px;
  `};
`;

const StyledDescription = styled(Text).attrs({ tag: 'p' })``;
