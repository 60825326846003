import { gql } from '@apollo/client';
import { programFragment, TProgram } from './Program';
import { themeFragment, TTheme } from './Theme';
import { pagecontentFragment, TPagecontent } from './Pagecontent';
import { videoFragment, TVideo } from './Video';
import { includeDependencies } from './fragment';
import { scaledImageFragment } from './ScaledImage';
import { vrtPlayerFragment } from './VrtPlayer';
import { headerFragment } from './Header';
import { ctaPostFragment } from './ctaPost';
import { postFragment } from './Post';
import { postCommentFragment } from './PostComment';
import { pinboardItemFragment } from './PinboardItem';

import { gameFragment, TGame } from './Game';
import { storyReactionFragment } from './StoryReaction';

const dependencies = includeDependencies([
  gameFragment,
  programFragment,
  themeFragment,
  videoFragment,
  pagecontentFragment,
  scaledImageFragment,
  vrtPlayerFragment,
  headerFragment,
  postFragment,
  ctaPostFragment,
  postCommentFragment,
  storyReactionFragment,
  pinboardItemFragment,
]);

const query = `
query GetPage($id: String!, $feedPostPageSize: Int!, $feedPostCommentPageSize: Int! ) {
  canonical(id: $id)
  page(id: $id) {
    ...on Program {
      pageType
      ...${programFragment.name}
    }
    ...on Theme {
      pageType
      ...${themeFragment.name}
    }
    ...on Video {
      pageType
      ...${videoFragment.name}
    }
    ... on Game {
      pageType
      ...${gameFragment.name}
    }
    ...on Pagecontent {
      pageType
      ...${pagecontentFragment.name}
    }
  }
}

${dependencies}`;
// console.log(query);

export const getPageQuery = gql`
  ${query}
`;

export interface TGetPageArgs {
  id: string;
  feedPostPageSize: number;
  feedPostCommentPageSize: number;
}

export interface TGetPageResult {
  canonical: string | null;
  page: TPage | null;
}

export type TPage =
  | ({ pageType: 'program' } & TProgram)
  | ({ pageType: 'video' } & TVideo)
  | ({ pageType: 'theme' } & TTheme)
  | ({ pageType: 'game' } & TGame)
  | ({ pageType: 'pagecontent' } & TPagecontent);

export type TPageType = 'pagecontent' | 'program' | 'theme' | 'video';
