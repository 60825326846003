import React from 'react';
import styled from 'styled-components';
import useSession from '../../lib/useSession';
import Services from '../../services/Services';
import { OverlayContext } from '../Overlay/OverlayContext';
import { StoryContext } from './StoryContext';
import { StoryPollAnswer } from './StoryPollAnswer';
import { TStoryPollAnswer, TStoryPollOverlay } from './types';

export interface TStoryPollOverlayProps {
  storyId: string;
  overlay: TStoryPollOverlay;
}

export const StoryPollOverlay: React.FC<TStoryPollOverlayProps> = (props) => {
  const { overlay, storyId } = props;
  const [selectedId, setSelectedId] = React.useState<string | null>(overlay.myAnswerId);
  const [answers, setAnswers] = React.useState(overlay.answers);
  const { showLogin } = React.useContext(OverlayContext);
  const { requestPause } = React.useContext(StoryContext);
  const session = useSession();
  const total = answers.map((a) => a.count).reduce((a, b) => a + b, 0);

  const handleSelected = React.useCallback(
    (answer: TStoryPollAnswer) => {
      if (overlay.myAnswerId || selectedId) return; // no changing votes
      if (!session) return showLogin(); // only logged in users can vote
      const answerId = answer.id;
      requestPause();
      // optimistic UI update
      setSelectedId(answerId);
      setAnswers(
        overlay.answers.map((a) =>
          a.id !== answerId
            ? a
            : {
                ...a,
                count: a.count + 1,
              }
        )
      );
      const gdpr = `Gestemd via ketnet.be op antwoord ${answerId} voor story ${storyId}`;
      // cast vote in background
      Services.bffService
        .castPollVote({
          input: {
            answerId,
            storyId,
            gdpr,
          },
        })
        .catch((e) => Services.loggerService.errorObject('Failed to cast poll vote', e));
    },
    [session, overlay, selectedId, storyId, showLogin, requestPause]
  );

  return (
    <StyledWrap>
      {answers.map((answer) => (
        <StoryPollAnswer
          answer={answer}
          key={answer.id}
          showPercentage={!!selectedId}
          isSelected={selectedId === answer.id}
          onSelected={handleSelected}
          total={total}
        />
      ))}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacings.medium}px;
  & > *:not(:last-child) {
    margin-bottom: ${theme.spacings.small}px;
  }
`}
`;
