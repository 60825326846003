import { useEffect } from 'react';
import useSWR from 'swr';
import Services from '../../services/Services';
import { TStoryDetails } from './types';

export type TUseStoryDetailsResponse =
  | { state: 'loading' }
  | { state: 'error' }
  | { state: 'loaded'; data: TStoryDetails };

export const useStoryDetails = (storyId: string): TUseStoryDetailsResponse => {
  const { data, error, mutate } = useSWR<TStoryDetails | false, true>(storyId, async () => {
    try {
      const { story } = await Services.bffService.getStoryDetails({ storyId });
      const details = story ? Services.contentService.mapStoryDetails(story) : null;
      if (!details) {
        Services.loggerService.error(`Fetch story ${storyId} yielded empty response`);
        return false;
      }
      return details;
    } catch (e) {
      Services.loggerService.errorObject('Failed to fetch story details', e);
      return Promise.reject(true);
    }
  });
  // revalidate when session changed
  useEffect(() => Services.sessionService.onSessionChanged(() => mutate()), [mutate]);
  if (error || data === false) {
    return { state: 'error' };
  }
  if (data === undefined) {
    return { state: 'loading' };
  }
  return { state: 'loaded', data };
};
