function loadScript(src: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const el = document.createElement('script');
    el.async = true;
    el.src = src;
    el.addEventListener('error', (ev) => reject(ev));
    el.addEventListener('load', () => resolve());
    document.body.appendChild(el);
  });
}

export default loadScript;
