import React from 'react';
import styled from 'styled-components';
import { TSearchJrProgram } from './types';
import { EColor } from '../../theme/styleguide';
import { SearchResultLabel } from './SearchResultLabel';
import { Text } from '../../components/Text/Text';

export type TSearchResultJrProgramProps = {
  data: TSearchJrProgram;
};

export const SearchResultJrProgram = (props: TSearchResultJrProgramProps) => {
  const { data } = props;

  return (
    <StyledSearchResult>
      <a href={data.vrtNuUrl}>
        <StyledImageWrap>
          <StyledImage imageUrl={data.imageUrl} />
          <SearchResultLabel icon="watchJr" />
        </StyledImageWrap>
        <StyledTitle>{data.title}</StyledTitle>
        <StyledSubtitle>Kijken</StyledSubtitle>
      </a>
    </StyledSearchResult>
  );
};

const StyledSearchResult = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 160px;
    height: 90px;
    margin-bottom: ${theme.spacings.small}px;
  `}
`;

const StyledImageWrap = styled.div`
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.div<{ imageUrl: string }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url(${({ imageUrl }) => imageUrl});
`;

const StyledTitle = styled(Text).attrs({ tag: 'p' })`
  margin-top: 8px;
`;

const StyledSubtitle = styled(Text).attrs({ tag: 'p', color: EColor.yellow })``;
