import { useEffect, useState } from 'react';
import equal from 'fast-deep-equal';

export const useDeepValue = <T extends {}>(value: T) => {
  const [deepValue, setDeepValue] = useState<T>(value);

  useEffect(() => {
    if (!equal(deepValue, value)) {
      setDeepValue(value);
    }
  }, [deepValue, value]);

  return deepValue;
};
