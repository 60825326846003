import { gql } from '@apollo/client';

const query = `
mutation storySaveUserReaction($input: StorySaveUserReactionsInput!)  {
  storySaveUserReactions(input: $input) {
    id
  }
}
`;

export const saveStoryUserReactionsMutation = gql`
  ${query}
`;

export interface TSaveStoryUserReactionsArgs {
  input: TStorySaveUserReactionsInput;
}

export interface TStorySaveUserReactionsInput {
  id: string;
  reactions: string[];
  gdpr: string;
}

export interface TSaveStoryUserReactionsResult {
  storySaveUserReactions: TStoryReactions | null;
}

export interface TStoryReactions {
  id: string;
}
