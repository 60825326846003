import { TStoriesSize } from './StoriesSwimlane';

export interface TContainerSize {
  width: number;
  height: number;
}

// figma design values used to determine ideal story width/height
const WIDTH_NON_ACTIVE_STORY = 340;
const WIDTH_ACTIVE_STORY = 530;
const SPACING = 32;
const PADDING_TOP_BOTTOM = 70;

// At minimum we want to be able to show this for the multi story layout:
// 1/2 non-active story + spacing + active story + spacing + 1/2 non-active story
const MULTI_MINIMUM_WIDTH =
  WIDTH_NON_ACTIVE_STORY / 2 + SPACING + WIDTH_ACTIVE_STORY + SPACING + WIDTH_NON_ACTIVE_STORY / 2;

export const getStoriesSize = (size: TContainerSize): TStoriesSize => {
  const { width, height } = size;
  const AR_WH = 9 / 16;
  const AR_HW = 16 / 9;

  // check if we use the available height, if there is enough width for more than a single story
  let storyHeight = height - 2 * PADDING_TOP_BOTTOM;
  let storyWidth = storyHeight * AR_WH;
  if (width >= MULTI_MINIMUM_WIDTH && width > storyWidth) {
    return {
      mode: 'multi',
      width: storyWidth,
      height: storyHeight,
    };
  }

  // single story / screen
  // check if story fits when using all available width
  storyWidth = width;
  storyHeight = storyWidth * AR_HW;
  if (storyHeight <= height) {
    return {
      mode: 'single',
      width: storyWidth,
      height: storyHeight,
    };
  }

  // else use all available height
  storyHeight = height;
  storyWidth = storyHeight * AR_WH;
  return {
    mode: 'single',
    width: storyWidth,
    height: storyHeight,
  };
};
