import React, { useRef } from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { usePeeties } from './PeetieProvider';
import { TPeetieMoviePosition } from './types';
import { supportsWebM } from '../../lib/features';

export const PeetieMovie = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { activePeetieMovie } = usePeeties();
  const position = activePeetieMovie?.position;
  const videoUrl = activePeetieMovie?.webmUrl || '';
  const gifUrl = activePeetieMovie?.gifUrl || '';

  if (!activePeetieMovie) {
    return null;
  }

  return (
    <StyledWrap>
      <StyledContainer position={position}>
        {supportsWebM && (
          <StyledVideo key={videoUrl} ref={(ref) => (videoRef.current = ref)} autoPlay>
            <source src={videoUrl} type="video/webm" />
          </StyledVideo>
        )}
        {
          // Video tag doesnt work in safari https://developer.apple.com/forums/thread/690523
          !supportsWebM && (
            <StyledPicture>
              <img src={gifUrl} alt={activePeetieMovie.alt} />
            </StyledPicture>
          )
        }
      </StyledContainer>
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ isPlayingMovie?: boolean }>`
  position: fixed;
  z-index: 99;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const StyledContainer = styled.div<{ position?: TPeetieMoviePosition }>`
  position: absolute;
  max-width: none;
  width: auto;
  height: auto;
  max-height: 100vh;
  ${mediaQuery.tabletPortrait(`
    width: 100vw;
  `)}

  ${({ position }) =>
    position === 'center' &&
    `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  ${({ position }) =>
    position === 'top' &&
    `
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
  `}


  ${({ position }) =>
    position === 'top-right' &&
    `
    top: 0px;
    right: 0px;
  `}
  
  ${({ position }) =>
    position === 'right' &&
    `
    top: 50%;
    right: 0px;
    transform: translate(-100%, -50%);
  `}

  ${({ position }) =>
    position === 'bottom-right' &&
    `
    bottom: 0px;
    right: 0px;
  `}
  
  ${({ position }) =>
    position === 'bottom' &&
    `
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
  `}

  ${({ position }) =>
    position === 'bottom-left' &&
    `
    bottom: 0px;
    left: 0px;
  `}

  ${({ position }) =>
    position === 'left' &&
    `
    top: 50%;
    left: 0px;
    transform: translate(0px, -50%);
  `}

  ${({ position }) =>
    position === 'top-left' &&
    `
    top: 0px;
    left: 0px;
  `}
`;

const StyledVideo = styled.video`
  max-height: 100vh;
  width: auto;
`;

const StyledPicture = styled.picture`
  & img {
    max-height: 100vh;
    width: auto;
  }
`;
