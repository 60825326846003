import { useEffect } from 'react';
import Services from '../services/Services';
import { TPageMetadata } from '../services/AnalyticsService/TAnalyticsService';

export type TPageTrackingProps = TPageMetadata;

export function usePageTracking(meta: TPageMetadata | null) {
  useEffect(() => {
    if (meta) {
      Services.analyticsService.trackPage(meta);
    }
  }, [meta]);
}
