import React from 'react';

export interface TOverlayContextValue {
  showLogin: () => void;
  enableFullscreen: () => void;
  disableFullscreen: () => void;
}

export const OverlayContext = React.createContext<TOverlayContextValue>({
  showLogin: () => {},
  enableFullscreen: () => {},
  disableFullscreen: () => {},
});
