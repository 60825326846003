import React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { injectFontSize, TTextSize } from '../../theme/typography';

export type TTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

export type TTextProps = {
  tag?: TTag;
  size?: TTextSize;
  color?: EColor;
  bold?: boolean;
  accessibilityLabel?: string;
  className?: string;
  onClick?: () => void;
  ariaVisible?: boolean;
};

export const Text: React.FC<TTextProps> = ({
  tag = 'p',
  size = 'regular',
  color = EColor.white,
  bold = false,
  accessibilityLabel,
  className,
  onClick,
  ariaVisible = false,
  children,
}) => {
  const accessibilityAttrs = useMemo(() => {
    if (accessibilityLabel) return { ariaLabel: accessibilityLabel };
    return {};
  }, [accessibilityLabel]);

  return (
    <StyledText
      as={tag}
      className={className}
      size={size}
      color={color}
      bold={bold}
      {...accessibilityAttrs}
      onClick={onClick}
      ariaVisible={ariaVisible}
    >
      {children}
    </StyledText>
  );
};

const StyledText = styled.div<{ size: TTextSize; color: EColor; bold?: boolean; ariaVisible?: boolean }>`
  margin: 0;
  ${({ theme, color, bold, ariaVisible }) => `
    color: ${theme.colors[color]};
    font-family: ${bold ? 'favorit_bold' : 'favorit_regular'};
    ${
      ariaVisible &&
      `
      position: fixed;
      top: 0;
      left: 0;
      transform: translate(-100%,-100%);
      opacity: 0;
      pointer-events: none;
    `
    }
  `}
  ${({ size }) => injectFontSize(size)};
`;
