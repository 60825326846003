import BaseService from '../BaseService';
import {
  IVRTMediaPlayerService,
  VRTMediaPlayer,
  IVRTMediaPlayerBootstrapper,
  IGetPlayerArgs,
  IVRTPlayerElement,
} from './IVRTMediaPlayerService';
import loadScript from '../../lib/loadScript';

declare global {
  interface Window {
    VRTMediaPlayer?: IVRTMediaPlayerBootstrapper;
  }
}

class VRTMediaPlayerService extends BaseService implements IVRTMediaPlayerService {
  private bootstrapperP: Promise<IVRTMediaPlayerBootstrapper> | null = null;

  initialize(): Promise<IVRTMediaPlayerBootstrapper> {
    if (!this.bootstrapperP) {
      this.bootstrapperP = this.loadBootstrapper();
    }
    return this.bootstrapperP;
  }

  async getPlayer(args: IGetPlayerArgs): Promise<VRTMediaPlayer> {
    const { container, playerConfig, uiConfig, digitalData } = args;
    await this.initialize();
    try {
      const player = document.createElement('vrt-mediaplayer') as IVRTPlayerElement;
      // player styling
      player.style.position = 'relative!important';
      player.style.width = '100%';
      player.style.height = '100%';
      player.style.display = 'block!important';
      // assign player init properties
      player.playerConfig = playerConfig;
      player.digitalDatas = [digitalData];
      player.uiConfig = uiConfig;
      await new Promise<void>((resolve, reject) => {
        const onFault = (fault: unknown) => {
          if (args.errorHandler) {
            args.errorHandler(fault as CustomEvent);
          }
          detachListeners();
          reject({ playerFault: fault });
        };
        const onReady = () => {
          detachListeners();
          resolve();
        };
        const detachListeners = () => {
          player.removeEventListener('ready', onReady);
          player.removeEventListener('fault', onFault);
        };
        player.addEventListener('fault', onFault);
        player.addEventListener('ready', onReady);
        // add to the DOM *after* adding the player events to ensure ready is always captured
        container.appendChild(player);
      });
      this.logger.debug(`VRTMediaPlayer instance created`);
      return player.api;
    } catch (e) {
      const msg = 'failed to get VRTMediaPlayer';
      this.logger.errorObject(msg, e);
      throw this.createError(msg);
    }
  }

  private async loadBootstrapper(): Promise<IVRTMediaPlayerBootstrapper> {
    const loadedP = new Promise<void>((resolve, reject) => {
      window.addEventListener('vrtPlayerBootstrapped', () => {
        resolve();
      });
      window.addEventListener('vrtPlayerBootstrapFailed', (e) => {
        const msg = 'VRTMediaPlayerBootstrapper failed to initialize';
        this.logger.errorObject(msg, e);
        reject(this.createError('VRTMediaPlayerBootstrapper failed to initialize'));
      });
    });
    await loadScript(this.services.configService.config.vrtPlayerUrl);
    // after some timeout, conclude the video player was not bootstrapped
    const timeout = 1000 * 10; // 10 seconds
    await Promise.race<void>([
      loadedP,
      new Promise<void>((_resolve, reject) => setTimeout(() => reject('timed out'), timeout)),
    ]);
    const bootstrapper = window.VRTMediaPlayer;
    if (!bootstrapper) {
      throw this.createError('VRTMediaPlayer was not registered on window');
    }
    this.logger.debug(`Using VRTMediaPlayer version ${bootstrapper.version}`);
    return bootstrapper;
  }
}

export default VRTMediaPlayerService;
