import React from 'react';
import styled from 'styled-components';
import { StoryContext } from './StoryContext';
import { StoryVideoPlayer, TStoryVideoData } from './StoryVideoPlayer';
import { TStoryVideoBackground } from './types';

export interface TStoryVideoBackgroundProps {
  id: string;
  index: number;
  data: TStoryVideoBackground;
}

export const StoryVideoBackground: React.FC<TStoryVideoBackgroundProps> = (props) => {
  const context = React.useContext(StoryContext);
  const storyVideoData = React.useMemo<TStoryVideoData>(
    () => ({
      id: props.id,
      index: props.index,
      duration: props.data.duration,
      vrtPlayerConfig: props.data.vrtPlayer,
      overlayType: props.data.overlayType,
      digitalData: props.data.digitalData,
    }),
    [props]
  );
  return (
    <StyledWrap>
      <StoryVideoPlayer data={storyVideoData} context={context} />
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
`;
