import { isInTheFuture } from '../../lib/datetime';
import BaseService from '../BaseService';
import { TMediaService, TStream, TStreamType } from './TMediaService';
import * as ms from './mediaServicesTypes';
import { PartialDeep } from 'type-fest';

export class MediaService extends BaseService implements TMediaService {
  private get mediaServicesUrl(): string {
    return this.services.configService.config.mediaServicesUrl;
  }

  private get mediaServicesClientId(): string {
    return this.services.configService.config.mediaServicesClientId;
  }

  async getStream(mediaReference: string, type: TStreamType): Promise<TStream> {
    const token = await this.getToken();
    const path = `vualto-video-aggregator-web/rest/external/v1/videos/${mediaReference}`;
    const url = new URL(`${this.mediaServicesUrl}/${path}`);
    url.searchParams.append('clientId', this.mediaServicesClientId);
    url.searchParams.append('vrtPlayerToken', token);
    const res = await fetch(url.toString());
    if (!res.ok) {
      throw new Error(`mediaService.getStreamUrl: ${res.status} ${res.statusText}`);
    }
    const data = await res.json();
    return this.parseRawStreamResponse(data, type);
  }

  private parseRawStreamResponse(raw: PartialDeep<ms.TStream>, type: TStreamType): TStream {
    const { title, duration, posterImageUrl, targetUrls } = raw;
    const url = targetUrls?.find((t) => t?.type === type)?.url;
    return {
      url: url ?? null,
      title: title ?? null,
      duration: duration ?? null,
      posterImageUrl: posterImageUrl ?? null,
    };
  }

  private tokenP: Promise<ms.TToken> | null = null;

  private async getToken(): Promise<string> {
    if (!this.tokenP) {
      this.tokenP = this.getFreshToken();
    }
    const token = await this.tokenP;
    if (isInTheFuture(token.expirationDate)) {
      return token.vrtPlayerToken;
    }
    // else invalidate the token and renew
    this.tokenP = null;
    return this.getToken();
  }

  private async getFreshToken(): Promise<ms.TToken> {
    const url = `${this.mediaServicesUrl}/vualto-video-aggregator-web/rest/external/v1/tokens`;
    const res = await fetch(url, { method: 'POST' });
    if (!res.ok) {
      throw new Error(`mediaService.getFreshToken: ${res.status} ${res.statusText}`);
    }
    const data = await res.json();
    const expirationDate = data?.expirationDate;
    if (typeof expirationDate !== 'string') {
      throw new Error(`Invalid response from mediaServices token API: expirationDate`);
    }
    const vrtPlayerToken = data?.vrtPlayerToken;
    if (typeof vrtPlayerToken !== 'string') {
      throw new Error(`Invalid response from mediaServices token API: vrtPlayerToken`);
    }
    return {
      expirationDate,
      vrtPlayerToken,
    };
  }
}
