import React, { useMemo } from 'react';
import { TPage } from './types';
import { Pagecontent } from '../../components/Pagecontent/Pagecontent';
import { Program } from '../ProgramPage/Program';
import { Theme } from '../ThemePage/Theme';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { VideoDetail } from '../VideoDetailPage/VideoDetail';
import { GameDetail } from '../GameDetailPage/GameDetail';
import { usePageTracking } from '../../hooks/usePageTracking';
import Services from '../../services/Services';
import { PageMeta } from '../../components/PageMeta/PageMeta';
import { RedirectPage } from '../RedirectPage/RedirectPage';
import { Text } from '../../components/Text/Text';

export interface TAemPageProps {
  data: TPage;
}

export const AemPage: React.FC<TAemPageProps> = ({ data }) => {
  const meta = useMemo(() => Services.analyticsService.getMetadata(data), [data]);
  usePageTracking(meta);

  const dispatch = () => {
    switch (data.pageType) {
      case 'pagecontent':
        return <Pagecontent data={data} />;
      case 'game':
        return <GameDetail data={data} />;
      case 'program':
        return <Program data={data} />;
      case 'theme':
        return <Theme data={data} />;
      case 'video':
        return <VideoDetail data={data} meta={meta} />;
      case 'redirect':
        return <RedirectPage to={data.to} />;
      default:
        return <ErrorPage />;
    }
  };

  return (
    <>
      <PageMeta page={data} />
      {data.pageType !== 'redirect' && data.pageTitle && (
        <Text tag="h1" ariaVisible={true}>
          {data.pageTitle}
        </Text>
      )}
      {dispatch()}
    </>
  );
};
