import { useCallback, useState } from 'react';

interface TFormValues {
  [fieldName: string]: string | undefined;
}

export interface TValidationErrors {
  [fieldName: string]: string | undefined;
}

type TProps = {
  defaultValues?: TFormValues;
  calculateValidation: (formValues: TFormValues) => TValidationErrors;
};

export const useForm = (props: TProps) => {
  const { defaultValues, calculateValidation } = props;

  const [formValues, setFormValues] = useState<TFormValues>(defaultValues || {});
  const [validationErrors, setValidationErrors] = useState<TValidationErrors>({});

  const setFormValue = (fieldName: string, fieldValue: string) => {
    setFormValues((prevFormValues) => {
      const nextFormValues = { ...prevFormValues };
      nextFormValues[fieldName] = fieldValue;
      return nextFormValues;
    });
  };

  const getFormValue = (fieldName: string) => {
    return formValues[fieldName];
  };

  const clearForm = () => {
    setValidationErrors({});
    setFormValues({});
  };

  const validateForm = useCallback(() => {
    const validationErrors = calculateValidation(formValues);
    setValidationErrors(validationErrors);
    const isValid = Object.keys(formValues).length > 0 && Object.keys(validationErrors).length === 0;
    return isValid;
  }, [calculateValidation, formValues]);

  const clearValidationError = (fieldName: string) => {
    setValidationErrors((currentValidationErrors) => {
      const nextValidationErrors = { ...currentValidationErrors };
      nextValidationErrors[fieldName] = undefined;
      return nextValidationErrors;
    });
  };

  const getValidationError = useCallback(
    (fieldName: string) => {
      return validationErrors[fieldName];
    },
    [validationErrors]
  );

  const isValidField = useCallback(
    (fieldName: string) => {
      return !!validationErrors[fieldName];
    },
    [validationErrors]
  );

  return {
    formValues,
    setFormValue,
    getFormValue,
    clearForm,
    validateForm,
    getValidationError,
    clearValidationError,
    isValidField,
  };
};
