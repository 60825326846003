import BaseService from '../BaseService';
import { IAppService, THistory } from './IAppService';
import { Location } from 'history';

class AppService extends BaseService implements IAppService {
  private initializeP: Promise<boolean> | null = null;

  private history: THistory | null = null;

  initialize(history: THistory): Promise<boolean> {
    if (this.initializeP === null) {
      return (this.initializeP = this.doInitialize(history));
    }
    return this.initializeP;
  }

  goBack = (): void => {
    if (!this.history) {
      this.logger.error('goBack() invoked before initialization');
      return;
    }
    // pop the current location if there is any
    this.internalLocations.pop();
    if (this.internalLocations.length > 0) {
      const lastLocation = this.internalLocations[this.internalLocations.length - 1];
      this.history.replace(lastLocation.pathname + (lastLocation.search ? lastLocation.search : ''));
    } else {
      // otherwise go home, e.g. when going from google straight to an overlay page
      this.history.replace('/');
    }
  };

  private async doInitialize(history: THistory): Promise<boolean> {
    this.history = history;
    this.trackInternalLocations(history);
    try {
      await this.services.sessionService.restoreSession();
      // for safety initialize other services *AFTER* session was restored
      return true;
    } catch (e) {
      this.logger.errorObject('Failed to restore session', e);
      this.services.monitoringService.captureException(e);
      return false;
    }
  }

  private internalLocations: Location[] = [];

  /**
   * Track any history-based navigation since page load, to prevent navigating outside
   * of the webpage when closing an overlay or similar use cases
   */
  private trackInternalLocations(history: THistory): void {
    history.listen((location, action) => {
      // this only triggers for internal navigations
      // navigations from nested iframes will not trigger this callback.
      if (action === 'PUSH') {
        const lastLocation = this.internalLocations[this.internalLocations.length - 1];
        if (
          !lastLocation ||
          lastLocation.pathname !== location.pathname ||
          (location.search && lastLocation.search !== location.search)
        ) {
          this.internalLocations.push(location);
        }
      } else if (action === 'POP') {
        this.internalLocations.pop();
      } else {
        this.internalLocations[this.internalLocations.length - 1] = location;
      }
    });
  }
}

export default AppService;
