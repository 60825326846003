import { gql } from '@apollo/client';
import { includeDependencies } from './fragment';
import { vrtPlayerFragment } from './VrtPlayer';
import { storyReactionFragment } from './StoryReaction';
import { storyDetailsFragment, TStoryDetails } from './StoryDetails';

const dependencies = includeDependencies([vrtPlayerFragment, storyReactionFragment, storyDetailsFragment]);

const query = `
query GetStoryDetails($storyId: ID!) {
  story(id: $storyId) { ...${storyDetailsFragment.name} }
}

${dependencies}
`;
// console.log(query);

export const getStoryDetailsQuery = gql`
  ${query}
`;

export interface TGetStoryDetailsArgs {
  storyId: string;
}

export interface TGetStoryDetailsResult {
  story: TStoryDetails | null;
}
