import { IBffService } from './IBffService';
import BaseService from '../BaseService';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, DocumentNode } from '@apollo/client';
import { TSearchArgs, TSearchResult, searchQuery } from './search.query';
import { TGetUpdatesResult, updatesQuery } from './getUpdates.query';
import { getPageQuery, TGetPageArgs, TGetPageResult } from './getPage.query';
import {
  getContentfeedPostsQuery,
  TGetContentfeedPostsArgs,
  TGetContentfeedPostsResult,
} from './getContentfeedPosts.query';
import {
  getPaginatedPinboardRowsQuery,
  TGetPaginatedPinboardRowsArgs,
  TGetPaginatedPinboardRowsResult,
} from './getPaginatedPinboardRows.query';
import { getStoryDetailsQuery, TGetStoryDetailsArgs, TGetStoryDetailsResult } from './getStoryDetails.query';
import {
  saveStoryUserReactionsMutation,
  TSaveStoryUserReactionsArgs,
  TSaveStoryUserReactionsResult,
} from './saveStoryUserReactions.mutation';
import { rerouteLegacyGames } from './rerouteLegacyGames';
import { likeVideoMutation, TLikeVideoArgs, TLikeVideoResult } from './likeVideo.mutation';
import { unlikeVideoMutation, TUnlikeVideoArgs, TUnlikeVideoResult } from './unlikeVideo.mutation';
import {
  addPostCommentMutation,
  TAddPostCommentArgs,
  TAddPostCommentResult,
  TAddPostCommentData,
} from './addPostComment.mutation';
import { TPostComment } from '../../pages/ProgramPage/types';
import {
  deletePostCommentMutation,
  TDeletePostCommentArgs,
  TDeletePostCommentResult,
  TDeletePostCommentData,
} from './deletePostComment.mutation';
import {
  setPostReactionMutation,
  SetPostReactionResult,
  SetPostReactionArgs,
  SetPostReactionData,
} from './setPostReaction.mutation';
import {
  setPinboardItemReactionMutation,
  SetPinboardItemReactionResult,
  SetPinboardItemReactionArgs,
  SetPinboardItemReactionData,
} from './setPinboardItemReaction.mutation';
import { getKetprofiel, TGetKetprofielArgs, TGetKetprofielResult } from './getKetprofiel.query';
import { castPollVoteMutation, TCastPollVoteArgs, TCastPollVoteResult } from './castPollVote.mutation';
import {
  getContentfeedPostCommentQuery,
  TGetContentfeedPostCommentsArgs,
  TGetContentfeedPostCommentsResult,
} from './getContentfeedPostComments.query';

class BffService extends BaseService implements IBffService {
  private _apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

  private get graphqlUrl(): string {
    return `${this.services.configService.config.bffBaseUrl}/graphql`;
  }

  private get apolloClient(): ApolloClient<NormalizedCacheObject> {
    if (!this._apolloClient) {
      this._apolloClient = new ApolloClient<NormalizedCacheObject>({
        uri: this.graphqlUrl,
        cache: new InMemoryCache({
          typePolicies: {
            PostReaction: {
              // 'id' has a special meaning in Apollo
              // By default this is considered a unique key, so it will ignore different
              // values for count. Instead we include the field in the type's key
              keyFields: ['id', 'count'],
            },
          },
        }),
        // useSWR already does caching for us, don't rely on iffy ApolloClient's caching
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
      });
    }
    return this._apolloClient;
  }

  public async getPage(args: TGetPageArgs): Promise<TGetPageResult> {
    const legacyArgs = rerouteLegacyGames(args);
    return this.basicQuery<TGetPageArgs, TGetPageResult>(getPageQuery, legacyArgs);
  }

  public async getContentfeedPosts(args: TGetContentfeedPostsArgs): Promise<TGetContentfeedPostsResult> {
    return this.basicQuery<TGetContentfeedPostsArgs, TGetContentfeedPostsResult>(getContentfeedPostsQuery, args);
  }

  public async getContentfeedPostComments(
    args: TGetContentfeedPostCommentsArgs
  ): Promise<TGetContentfeedPostCommentsResult> {
    return this.basicQuery<TGetContentfeedPostCommentsArgs, TGetContentfeedPostCommentsResult>(
      getContentfeedPostCommentQuery,
      args
    );
  }

  public async getPaginatedPinboardRows(args: TGetPaginatedPinboardRowsArgs): Promise<TGetPaginatedPinboardRowsResult> {
    return this.basicQuery<TGetPaginatedPinboardRowsArgs, TGetPaginatedPinboardRowsResult>(
      getPaginatedPinboardRowsQuery,
      args
    );
  }

  public async search(args: TSearchArgs): Promise<TSearchResult> {
    return this.basicQuery<TSearchArgs, TSearchResult>(searchQuery, args);
  }

  public async getUpdates(args: null): Promise<TGetUpdatesResult> {
    return this.basicQuery<null, TGetUpdatesResult>(updatesQuery, args);
  }

  public async getStoryDetails(args: TGetStoryDetailsArgs): Promise<TGetStoryDetailsResult> {
    return this.basicQuery<TGetStoryDetailsArgs, TGetStoryDetailsResult>(getStoryDetailsQuery, args);
  }

  public async saveStoryUserReactions(args: TSaveStoryUserReactionsArgs): Promise<TSaveStoryUserReactionsResult> {
    return this.basicQuery<TSaveStoryUserReactionsArgs, TSaveStoryUserReactionsResult>(
      saveStoryUserReactionsMutation,
      args
    );
  }

  public async likeVideo(args: TLikeVideoArgs): Promise<TLikeVideoResult> {
    return this.basicQuery<TLikeVideoArgs, TLikeVideoResult>(likeVideoMutation, args);
  }

  public async unlikeVideo(args: TUnlikeVideoArgs): Promise<TUnlikeVideoResult> {
    return this.basicQuery<TUnlikeVideoArgs, TUnlikeVideoResult>(unlikeVideoMutation, args);
  }

  public async addPostComment(data: TAddPostCommentData): Promise<TPostComment> {
    const headers = await this.getHeaders();
    const res = await this.apolloClient.mutate<TAddPostCommentResult, TAddPostCommentArgs>({
      mutation: addPostCommentMutation,
      variables: { input: data },
      context: { headers },
    });
    if (res.errors) {
      const msg = `Failed to post comment`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    if (!res.data) {
      const msg = `Unexpected empty result`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    return res.data.contentfeedAddPostComment;
  }

  public async deletePostComment(data: TDeletePostCommentData): Promise<void> {
    const headers = await this.getHeaders();
    const res = await this.apolloClient.mutate<TDeletePostCommentResult, TDeletePostCommentArgs>({
      mutation: deletePostCommentMutation,
      variables: { input: data },
      context: { headers },
    });
    if (res.errors) {
      const msg = `Failed to delete post comment`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    if (!res.data) {
      const msg = `Unexpected empty result`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    return;
  }

  public async setPostReaction(data: SetPostReactionData): Promise<void> {
    const headers = await this.getHeaders();
    const res = await this.apolloClient.mutate<SetPostReactionResult, SetPostReactionArgs>({
      mutation: setPostReactionMutation,
      variables: { input: data },
      context: { headers },
    });
    if (res.errors) {
      const msg = `Failed to set post reaction`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    if (!res.data) {
      const msg = `Unexpected empty result`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    return;
  }

  public async setPinboardItemReaction(data: SetPinboardItemReactionData): Promise<void> {
    const headers = await this.getHeaders();
    const res = await this.apolloClient.mutate<SetPinboardItemReactionResult, SetPinboardItemReactionArgs>({
      mutation: setPinboardItemReactionMutation,
      variables: { input: data },
      context: { headers },
    });
    if (res.errors) {
      const msg = `Failed to set pinboardItem reaction`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    if (!res.data) {
      const msg = `Unexpected empty result`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    return;
  }

  public getKetprofiel: IBffService['getKetprofiel'] = async (args) => {
    return this.basicQuery<TGetKetprofielArgs, TGetKetprofielResult>(getKetprofiel, args);
  };

  public castPollVote: IBffService['castPollVote'] = (args) => {
    return this.basicMutation<TCastPollVoteArgs, TCastPollVoteResult>(castPollVoteMutation, args);
  };

  private async basicQuery<TArgs, TResult>(query: DocumentNode, variables: TArgs): Promise<TResult> {
    const headers = await this.getHeaders();
    const res = await this.apolloClient.query<TResult, TArgs>({
      query,
      variables,
      context: { headers },
    });
    if (res.error) {
      const msg = `query failed: ${res.error.message}`;
      this.logger.error(msg);
      this.logger.debug(JSON.stringify(res.error, null, 2));
      throw this.createError(msg);
    }
    if (!res.data) {
      const msg = `query returned empty result`;
      throw this.createError(msg);
    }
    return res.data;
  }

  private async basicMutation<TArgs, TResult>(mutation: DocumentNode, variables: TArgs): Promise<TResult> {
    const headers = await this.getHeaders();
    const res = await this.apolloClient.mutate<TResult, TArgs>({
      mutation,
      variables,
      context: { headers },
    });
    if (res.errors) {
      const msg = `mutation failed: ${res.errors.map((e) => e.message).join('\n')}`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    if (!res.data) {
      const msg = `mutation returned empty result`;
      this.logger.errorObject(msg, res);
      throw this.createError(msg);
    }
    return res.data;
  }

  private async getHeaders(): Promise<{ [key: string]: string }> {
    const { sessionService } = this.services;
    const headers: { [key: string]: string } = {};
    if (sessionService.isLoggedIn()) {
      const accessToken = await sessionService.getAccessToken();
      headers['authorization'] = accessToken;
    }
    return headers;
  }
}

export default BffService;
