// https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
export interface TNetworkConnection {
  // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType
  effectiveType?: 'slow-2g' | '2g' | '3g' | '4g';
  // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/saveData
  saveData?: boolean;
}

const navigator = window.navigator as any;
const connection: TNetworkConnection | null =
  navigator?.connection ?? navigator?.mozConnection ?? navigator?.webkitConnection ?? null;

export function getNetworkSpeed(): TNetworkSpeed {
  switch (connection?.effectiveType ?? null) {
    case '4g':
      return 'fast';
    case '2g':
    case 'slow-2g':
    case '3g':
      return 'slow';
    default:
      return 'unknown';
  }
}

export function getSaveDataSetting(): TSaveDataSetting {
  switch (connection?.saveData) {
    case true:
      return 'yes';
    case false:
      return 'no';
    default:
      return 'unknown';
  }
}

export type TNetworkSpeed = 'fast' | 'slow' | 'unknown';

export type TSaveDataSetting = 'yes' | 'no' | 'unknown';
