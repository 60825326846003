import { TGetPageArgs } from './getPage.query';

// TODO: Remove this file when the amount of traffic from Google to legacy game url's becomes trivial

const legacyRouteMap: { [key: string]: string } = {
  // Old google-sheets slug                                                       : AEM content-id
  'content/ketnet/nl/spelen/2/wrapper-apocalyps.model.json': 'content/ketnet/nl/spelen/w/wrapper-apocalyps.model.json',
  'content/ketnet/nl/spelen/3/hoodie-run.model.json': 'content/ketnet/nl/spelen/h/hoodie-run.model.json',
  'content/ketnet/nl/spelen/4/oma-en-oma-s-taartenreis.model.json':
    'content/ketnet/nl/spelen/t/oma-en-oma-s-taartenreis.model.json',
  'content/ketnet/nl/spelen/5/kingsize-live-ufo-jumper.model.json':
    'content/ketnet/nl/spelen/k/kingsize-live-ufo-jumper.model.json',
  'content/ketnet/nl/spelen/6/tandenjacht.model.json': 'content/ketnet/nl/spelen/t/tandenjacht.model.json',
  'content/ketnet/nl/spelen/7/de-verkleedhoek.model.json': 'content/ketnet/nl/spelen/v/verkleedhoek.model.json',
  'content/ketnet/nl/spelen/8/golfbrekers.model.json': 'content/ketnet/nl/spelen/g/golfbrekers.model.json',
  'content/ketnet/nl/spelen/9/gloria-s-potlodenstrijd.model.json':
    'content/ketnet/nl/spelen/g/gloria-s-potlodenstrijd.model.json',
  'content/ketnet/nl/spelen/10/mep-de-wrapper.model.json': 'content/ketnet/nl/spelen/m/mep-de-wrapper.model.json',
  'content/ketnet/nl/spelen/11/oude-taart.model.json': 'content/ketnet/nl/spelen/o/oude-taart.model.json',
  'content/ketnet/nl/spelen/12/de-actuaquiz-van-karrewiet.model.json':
    'content/ketnet/nl/spelen/d/de-actuaquiz-van-karrewiet.model.json',
  'content/ketnet/nl/spelen/13/de-regels-van-floor-quiz.model.json':
    'content/ketnet/nl/spelen/d/de-regels-van-floor-quiz.model.json',
  'content/ketnet/nl/spelen/14/12-campus-12-vragen.model.json':
    'content/ketnet/nl/spelen/1-tem-100/12-campus-12-vragen.model.json',
  'content/ketnet/nl/spelen/15/de-grote-likeme-quiz.model.json':
    'content/ketnet/nl/spelen/d/de-grote-likeme-quiz.model.json',
  'content/ketnet/nl/spelen/16/welke-wrapper-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welke-wrapper-ben-jij.model.json',
  'content/ketnet/nl/spelen/17/ben-jij-een-echte-love-dokter.model.json':
    'content/ketnet/nl/spelen/b/ben-jij-een-echte-love-dokter.model.json',
  'content/ketnet/nl/spelen/18/weet-jij-alles-over-de-regels-van-3s.model.json':
    'content/ketnet/nl/spelen/w/weet-jij-alles-over-de-regels-van-3s.model.json',
  'content/ketnet/nl/spelen/19/welk-instrument-past-bij-jou.model.json':
    'content/ketnet/nl/spelen/w/welk-instrument-past-bij-jou.model.json',
  'content/ketnet/nl/spelen/20/ben-jij-een-campus-12-expert.model.json':
    'content/ketnet/nl/spelen/b/ben-jij-campus-12-expert.model.json',
  'content/ketnet/nl/spelen/21/welk-ketnet-dier-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welk-ketnet-dier-ben-jij.model.json',
  'content/ketnet/nl/spelen/22/welke-sport-past-bij-jou.model.json':
    'content/ketnet/nl/spelen/w/welke-sport-past-bij-jou.model.json',
  'content/ketnet/nl/spelen/23/gloria-s-potlodenstrijd.model.json':
    'content/ketnet/nl/spelen/g/gloria-s-potlodenstrijd.model.json',
  'content/ketnet/nl/spelen/24/wrapper-apocalyps.model.json': 'content/ketnet/nl/spelen/w/wrapper-apocalyps.model.json',
  'content/ketnet/nl/spelen/25/mep-de-wrapper.model.json': 'content/ketnet/nl/spelen/m/mep-de-wrapper.model.json',
  'content/ketnet/nl/spelen/26/welke-wrapper-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welke-wrapper-ben-jij.model.json',
  'content/ketnet/nl/spelen/27/kingsize-live-ufo-jumper.model.json':
    'content/ketnet/nl/spelen/k/kingsize-live-ufo-jumper.model.json',
  'content/ketnet/nl/spelen/28/de-verkleedhoek.model.json': 'content/ketnet/nl/spelen/v/verkleedhoek.model.json',
  'content/ketnet/nl/spelen/29/bij-welk-gezin-van-ik-u-ook-hoor-jij.model.json':
    'content/ketnet/nl/spelen/b/bij-welk-gezin-van-ik-u-ook-hoor-jij.model.json',
  'content/ketnet/nl/spelen/30/welk-meisje-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welk-meisje-ben-jij.model.json',
  'content/ketnet/nl/spelen/31/welk-monster-uit-nachtwacht-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welk-monster-uit-nachtwacht-ben-jij.model.json',
  'content/ketnet/nl/spelen/32/wie-ben-jij-van-likeme.model.json':
    'content/ketnet/nl/spelen/w/wie-ben-jij-van-likeme.model.json',
  'content/ketnet/nl/spelen/33/wie-ben-jij-van-nachtwacht.model.json':
    'content/ketnet/nl/spelen/w/wie-ben-jij-van-nachtwacht.model.json',
  'content/ketnet/nl/spelen/34/wie-ben-jij-van-hoodie.model.json':
    'content/ketnet/nl/spelen/w/wie-ben-jij-van-hoodie.model.json',
  'content/ketnet/nl/spelen/35/welke-wrapper-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welke-wrapper-ben-jij.model.json',
  'content/ketnet/nl/spelen/36/welk-vakantietype-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welk-vakantietype-ben-jij.model.json',
  'content/ketnet/nl/spelen/37/welke-held-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welke-held-ben-jij.model.json',
  'content/ketnet/nl/spelen/38/wie-ben-jij-van-4ever.model.json':
    'content/ketnet/nl/spelen/w/wie-ben-jij-van-4ever.model.json',
  'content/ketnet/nl/spelen/39/welk-kotnet-gezicht-ben-jij.model.json':
    'content/ketnet/nl/spelen/w/welk-kotnet-gezicht-ben-jij.model.json',
  'content/ketnet/nl/spelen/40/boeva-the-game.model.json': 'content/ketnet/nl/spelen/b/boeva-the-game.model.json',
  'content/ketnet/nl/spelen/41/2beat-boeva.model.json': 'content/ketnet/nl/spelen/1-tem-100/2beat-boeva.model.json',
  'content/ketnet/nl/spelen/42/mijn-ridder-en-ik-racing-ridders.model.json':
    'content/ketnet/nl/spelen/m/mijn-ridder-en-ik-racing-ridders.model.json',
  'content/ketnet/nl/spelen/43/mijn-ridder-en-ik-vliegende-prinsessen.model.json':
    'content/ketnet/nl/spelen/m/mijn-ridder-en-ik-vliegende-prinsessen.model.json',
  'content/ketnet/nl/spelen/44/speel-de-junior-basketball-derby.model.json':
    'content/ketnet/nl/spelen/s/speel-de-junior-basketball-derby.model.json',
  'content/ketnet/nl/spelen/45/pingwin.model.json': 'content/ketnet/nl/spelen/p/pingwin.model.json',
  'content/ketnet/nl/spelen/46/verzorg-zippo.model.json': 'content/ketnet/nl/spelen/v/verzorg-zippo.model.json',
  'content/ketnet/nl/spelen/47/tafeltwist.model.json': 'content/ketnet/nl/spelen/t/tafeltwist.model.json',
  'content/ketnet/nl/spelen/48/complimentenjackpot.model.json':
    'content/ketnet/nl/spelen/c/complimentenjackpot.model.json',
  'content/ketnet/nl/spelen/49/ketnet-liefdesmeter.model.json':
    'content/ketnet/nl/spelen/k/ketnet-liefdesmeter.model.json',
  'content/ketnet/nl/spelen/50/ontdek-het-huis-van-elias-uit-buck.model.json':
    'content/ketnet/nl/spelen/o/ontdek-het-huis-van-elias-uit-buck.model.json',
  'content/ketnet/nl/spelen/51/regel-het-voor-dries-de-kunstroof.model.json':
    'content/ketnet/nl/spelen/r/regel-het-voor-dries-de-kunstroof.model.json',
  'content/ketnet/nl/spelen/52/de-verkleedhoek.model.json': 'content/ketnet/nl/spelen/v/verkleedhoek.model.json',
  'content/ketnet/nl/spelen/53/het-complimentkanon.model.json':
    'content/ketnet/nl/spelen/c/complimentenkanon.model.json',
  'content/ketnet/nl/spelen/54/olly-wannabe-soundboard.model.json':
    'content/ketnet/nl/spelen/o/olly-wannabe-soundboard.model.json',
  'content/ketnet/nl/spelen/55/het-spring-soundboard.model.json':
    'content/ketnet/nl/spelen/h/het-spring-soundboard.model.json',
  'content/ketnet/nl/spelen/56/de-voetbal-geluidengenerator.model.json':
    'content/ketnet/nl/spelen/v/voetbal-soundboard.model.json',
  'content/ketnet/nl/spelen/57/samson-gert-soundboard.model.json':
    'content/ketnet/nl/spelen/s/samson-gert-soundboard.model.json',
};

/* Re-route legacy game-slugs (Google Sheets) to new AEM content-ids */
export const rerouteLegacyGames = (args: TGetPageArgs): TGetPageArgs => {
  if (legacyRouteMap[args.id]) {
    return { ...args, id: legacyRouteMap[args.id] };
  }
  return args;
};
