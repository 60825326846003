import React from 'react';
import { EColor } from '../../theme/styleguide';
import { TSwimlaneThumbnailsItem } from './types';
import { SwimlaneThumbnailsItem } from './SwimlaneThumbnailsItem';
import LazyGameTypeIcon from '../GameTypeIcon/LazyGameTypeIcon';

export interface TSwimlaneThumbnailsItemDispatchProps {
  data: TSwimlaneThumbnailsItem;
}

export const SwimlaneThumbnailsItemDispatch: React.FC<TSwimlaneThumbnailsItemDispatchProps> = (props) => {
  const { data } = props;
  switch (data.type) {
    case 'game':
      return (
        <SwimlaneThumbnailsItem
          {...data}
          subtitle={data.genre ?? 'Spelen'}
          subtitleColor={EColor.green}
          icon={<LazyGameTypeIcon gameType={data.gameType} />}
        />
      );
    case 'video':
      return <SwimlaneThumbnailsItem {...data} />;
    // TODO: video - peetie
    default:
      return null;
  }
};
