import React from 'react';
import { TSwimlaneSeasonsItem } from './types';
import styled from 'styled-components';
import { Text } from '../Text/Text';

const BACKUP_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN083CrBwADBQFVb/nJmQAAAABJRU5ErkJggg==';

export interface TSwimlaneSeasonsItemProps {
  data: TSwimlaneSeasonsItem;
}

export const SwimlaneSeasonsItem: React.FC<TSwimlaneSeasonsItemProps> = (props) => {
  const { data } = props;
  const { imageUrl, title, isActive, onClick } = data;
  return (
    <StyledWrap onClick={() => !isActive && onClick()} imageUrl={imageUrl ?? BACKUP_IMAGE}>
      {isActive && <StyledActiveBorder />}
      <StyledTitle isActive={isActive}>
        <Text tag="h3">{title}</Text>
      </StyledTitle>
    </StyledWrap>
  );
};

const StyledTitle = styled.div<{ isActive: boolean }>`
  ${({ theme, isActive }) => `
    overflow: hidden;
    text-align: center;
    padding: ${theme.spacings.small}px;
    background-color: ${isActive ? theme.colors.red : theme.colors.blue};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  `}
`;

const StyledWrap = styled.div<{ imageUrl: string }>`
  ${({ theme, imageUrl }) => `
    width: ${theme.components.swimlane.images.seasons.width}px;
    height: ${theme.components.swimlane.images.seasons.height}px;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: ${theme.components.swimlane.horizontalSpacing}px;
    }
    background-image: url(${imageUrl});
    background-size: cover;
    background-position: center;
    position: relative;
  `}
`;

const StyledActiveBorder = styled.div`
  ${({ theme }) => `
  border: 4px solid ${theme.colors.red};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`}
`;
