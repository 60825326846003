import React from 'react';
import styled from 'styled-components';
import { TctaPostRoot } from '../../pages/ProgramPage/types';
import { Text } from '../../components/Text/Text';
import { Button } from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import { mediaQuery } from '../../theme/mediaQuery';

export type TCtaPostProps = {
  data: TctaPostRoot;
};

export const CtaPost = (props: TCtaPostProps) => {
  const { data } = props;

  const ctaContent = () => {
    return (
      <StyledWrap className="borderBtnContainer">
        <StyledInfo>
          {data.title && <Text size="large">{data.title}</Text>}
          {data.description && <Text>{data.description}</Text>}
        </StyledInfo>
        <StyledButtonWrapper>
          {data.ctaText && <StyledButton className="borderBtn" text={data.ctaText} />}
        </StyledButtonWrapper>
      </StyledWrap>
    );
  };

  return data.link ? <StyledLink to={data.link}>{ctaContent()}</StyledLink> : ctaContent();
};

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
`;

const StyledWrap = styled.div`
  ${({ theme }) => `
    position: relative;
    max-width: ${theme.pages.program.feed.maxWidth}px;
    width: 100%;
    background-color: ${theme.colors.red};
    margin-bottom: 48px;
    padding: ${theme.spacings.medium}px;
    color: ${theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mediaQuery.mobile(`
      flex-direction: column;
      align-items: start;
      & > div:not(:last-child) {
        margin-bottom: ${theme.spacings.medium}px;
      }
    `)}
  `}
`;

const StyledInfo = styled.div`
  width: 65%;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  background-image: url('/assets/backgrounds/cta-post-button.svg');
  background-repeat: no-repeat;
  background-position: center left;
  min-height: 106px;
  min-width: 94px;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => `
    width: auto;
    margin-left: ${theme.spacings.extralarge}px;
  `}
`;
