import { TFragment } from './fragment';

const name = 'scaledImage';

const query = `
fragment ${name} on ScaledImage {
  small
  medium
  large
}
`;

export interface TScaledImage {
  small: string;
  medium: string;
  large: string;
}

export const scaledImageFragment: TFragment = { name, query };
