import { gql } from '@apollo/client';

const query = `
mutation unlikeVideo($input: UnlikeVideoInput!)  {
  unlikeVideo(input: $input) {
    id
  }
}
`;

export const unlikeVideoMutation = gql(query);

export interface TUnlikeVideoArgs {
  input: TUnlikeVideoInput;
}

export interface TUnlikeVideoInput {
  id: string;
}

export interface TUnlikeVideoResult {
  unlikeVideo: TUnlikeVideo | null;
}

export interface TUnlikeVideo {
  id: string;
}
