import { gql } from '@apollo/client';
import { TPinboardItemConnection } from './Program';
import { includeDependencies } from './fragment';
import { scaledImageFragment } from './ScaledImage';
import { vrtPlayerFragment } from './VrtPlayer';
import { pinboardItemFragment } from './PinboardItem';

const dependencies = includeDependencies([pinboardItemFragment, vrtPlayerFragment, scaledImageFragment]);

const query = `
query getPaginatedPinboardRows($pinboardId: String!, $pageSize: Int!, $after: String) {
  paginatedPinboardRows(pinboardId: $pinboardId, first: $pageSize, after: $after) {
    edges {
        node {
            ...pinboardRowFragment
        }
        cursor
    }
    totalCount
    next
  }
}

fragment pinboardRowFragment on PinboardRow {
  type
  items {
      ...${pinboardItemFragment.name}
  }
}

${dependencies}`;

export const getPaginatedPinboardRowsQuery = gql`
  ${query}
`;

export interface TGetPaginatedPinboardRowsArgs {
  pinboardId: string;
  pageSize: number;
  after: string;
}

export interface TGetPaginatedPinboardRowsResult {
  paginatedPinboardRows: TPinboardItemConnection;
}
