import React from 'react';

export interface TProgramContextValue {
  programTitle: string | null;
  programTabType: string | undefined;
}

export const ProgramContext = React.createContext<TProgramContextValue>({
  programTitle: '',
  programTabType: '',
});
