import React from 'react';
import { TTheme } from './types';
import styled from 'styled-components';
import { Header } from '../../components/Header/Header';
import { PagecontentItem } from '../../components/PagecontentItem/PagecontentItem';

export interface TThemeProps {
  data: TTheme;
}

export const Theme: React.FC<TThemeProps> = (props) => {
  const theme = props.data;
  const { header, pagecontent: items } = theme;

  return (
    <StyledWrap>
      <Header data={header} />
      {items.map((item, idx) => (
        <PagecontentItem key={idx} item={item} />
      ))}
    </StyledWrap>
  );
};

const StyledWrap = styled.div``;
