import React from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { ReactComponent as CornerSVG } from './corner.svg';
import { mediaQuery } from '../../theme/mediaQuery';

type TCornerSize = 'large' | 'small';

type TProps = {
  color?: EColor;
  size?: TCornerSize;
};

export const PixelCorners = ({ color = EColor.blue, size = 'large' }: TProps) => {
  const corner = <StyledCorner color={color} size={size} />;

  return (
    <>
      <StyledTopLeftCorner>{corner}</StyledTopLeftCorner>
      <StyledTopRightCorner>{corner}</StyledTopRightCorner>
      <StyledBottomLeftCorner>{corner}</StyledBottomLeftCorner>
      <StyledBottomRightCorner>{corner}</StyledBottomRightCorner>
    </>
  );
};

const StyledCorner = styled(CornerSVG)<{ color: EColor; size: TCornerSize }>`
  ${({ theme, size, color }) => `
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${size === 'large' ? 8 : 6}px;
    height: ${size === 'large' ? 8 : 6}px;
    color: ${theme.colors[color]};

    ${mediaQuery.tabletLandscape(`
      width: ${size === 'large' ? 6 : 4}px;
      height: ${size === 'large' ? 6 : 4}px;
    `)}
  `}
`;

const StyledTopLeftCorner = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;

const StyledTopRightCorner = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  transform: rotate(90deg);
`;

const StyledBottomLeftCorner = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: rotate(270deg);
`;

const StyledBottomRightCorner = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: rotate(180deg);
`;
