import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '../../components/Modal/Modal';
import { EColor } from '../../theme/styleguide';
import { useProfile } from './ProfileContext';
import { useQuery } from '../../hooks/useQuery';
import { CloseIcon } from '../../assets/icons';

export interface TProfileModalProps {}

export const ProfileModal: React.FC<TProfileModalProps> = (props) => {
  const baseUrl = window.location.protocol + '//' + window.location.host;
  const query = useQuery();
  const initialProfileActionUrl = query.get('q');

  const { hideProfileAction, showProfileAction, profileActionUrl } = useProfile();

  useEffect(() => {
    if (initialProfileActionUrl) {
      showProfileAction(initialProfileActionUrl);
    }
  }, [showProfileAction, initialProfileActionUrl]);

  return (
    <Modal
      title=""
      isOpen={!!profileActionUrl}
      onClose={hideProfileAction}
      color={EColor.darkPurple}
      closeIcon={<CloseIcon />}
    >
      <StyledWrap>
        <StyledMainContent>
          <iframe title="user" height="100%" width="100%" src={`${baseUrl}/${profileActionUrl}`} />
        </StyledMainContent>
      </StyledWrap>
    </Modal>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 90vh;
  width: 385px;
`;

const StyledMainContent = styled.div`
  flex: 1;
`;
