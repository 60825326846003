import React from 'react';
import Services from '../../services/Services';

export function useIsWatched(storyId: string) {
  const [isWatched, setIsWatched] = React.useState(Services.storyService.isWatched(storyId));
  const handleWatchlistChanged = () => setIsWatched(Services.storyService.isWatched(storyId));
  // register the watchlist changed listener and return the detachfn for cleanup
  React.useEffect(() => Services.storyService.onWatchlistChanged(handleWatchlistChanged));
  return isWatched;
}
