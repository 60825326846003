const name = 'postCommentFragment';

const query = `
fragment ${name} on PostComment {
  postId
  commentId
  author {
    name
    avatarUrl
  }
  message
  postedOn
  mine
}`;

export interface TPostComment {
  commentId: string;
  postId: string;
  message: string;
  author: TPostCommentAuthor;
  postedOn: string;
  mine: boolean;
}

export interface TPostCommentAuthor {
  name: string;
  avatarUrl: string;
}

export const postCommentFragment = { name, query };
