import {
  IVRTMediaPlayerService,
  IVRTMediaPlayerBootstrapper,
  VRTMediaPlayer,
  IGetPlayerArgs,
} from './IVRTMediaPlayerService';
import BaseService from '../BaseService';

const logAction = (action: string) => {
  if (localStorage['XDEBUG']) {
    console.log(`MockVRTMediaPlayerService::${action}`);
  }
  return;
};

const MOCK_PLAYER: VRTMediaPlayer = {
  config: {
    playerRootElement: undefined,
  },
  currentTime: 0,
  currentDateTime: Promise.resolve(new Date()),
  currentSrc: Promise.resolve(''),
  duration: 0,
  loop: Promise.resolve(false),
  muted: false,
  paused: Promise.resolve(false),
  playbackRate: Promise.resolve(1),
  src: Promise.resolve(''),
  volume: 1,
  activeSubtitles: Promise.resolve({}),
  controls: Promise.resolve(true),
  currentMediaReference: Promise.resolve(''),
  currentPlaylistItem: Promise.resolve({}),
  hasPlayed: Promise.resolve(false),
  playlistSize: Promise.resolve(1),
  seekable: Promise.resolve(true),
  play: logAction.bind(null, 'play'),
  pause: logAction.bind(null, 'pause'),
  togglePlay: logAction.bind(null, 'togglePlay'),
  skipAd: logAction.bind(null, 'skipAd'),
  toggleCast: logAction.bind(null, 'toggleCast'),
  rewindSeconds: logAction.bind(null, 'rewindSeconds'),
  toggleMute: logAction.bind(null, 'toggleMute'),
  fastSeek: logAction.bind(null, 'fastSeek'),
  liveStartOver: logAction.bind(null, 'liveStartOver'),
  toggleSubtitles: logAction.bind(null, 'toggleSubtitles'),
  toggleFullscreen: logAction.bind(null, 'toggleFullscreen'),
  showTitle: logAction.bind(null, 'showTitle'),
  disableInterface: logAction.bind(null, 'disableInterface'),
  enableInterface: logAction.bind(null, 'enableInterface'),
  next: logAction.bind(null, 'next'),
  previous: logAction.bind(null, 'previous'),
  goto: logAction.bind(null, 'goto'),
  addToPlaylist: logAction.bind(null, 'addToPlaylist'),
  addEventListener: logAction.bind(null, 'addEventListener'),
  removeEventListener: logAction.bind(null, 'removeEventListener'),
  throwError: logAction.bind(null, 'throwError'),
  destroy: logAction.bind(null, 'destroy'),
};

class MockVRTMediaPlayerService extends BaseService implements IVRTMediaPlayerService {
  async initialize(): Promise<IVRTMediaPlayerBootstrapper> {
    const bootstrapper: IVRTMediaPlayerBootstrapper = {
      getNextId() {
        return Date.now().toString();
      },
      version: 'MOCK',
    };
    return bootstrapper;
  }

  getPlayer(args: IGetPlayerArgs): Promise<VRTMediaPlayer> {
    const { container } = args;
    const text = document.createElement('h2');
    text.style.paddingTop = '40px';
    text.style.color = 'white';
    text.style.textAlign = 'center';
    text.innerText = 'MOCKED VIDEO';
    container.appendChild(text);
    return Promise.resolve(MOCK_PLAYER);
  }
}

export default MockVRTMediaPlayerService;
