import React from 'react';
import { useLocation } from 'react-router-dom';
import { PagecontentItem } from '../../components/PagecontentItem/PagecontentItem';
import { TPagecontentItem } from '../PagecontentItem/types';

export interface TPagecontentProps {
  data: TPagecontentData;
}

export interface TPagecontentData {
  items: TPagecontentItem[];
}

function keyForPagecontentItem(item: TPagecontentItem) {
  if (item.type === 'swimlane') {
    return item.id;
  } else if (item.type === 'jumbotron') {
    return item.title;
  } else if (item.type === 'multiHighlight') {
    return item.highlights;
  }
  return `${item.type}::${item.title ?? item.description}`;
}

export const Pagecontent: React.FC<TPagecontentProps> = (props) => {
  const { items } = props.data;
  const location = useLocation();
  return (
    <>
      {items.map((item) => {
        const key = keyForPagecontentItem(item);
        // use location as part of the key to force unmount/mount across pagecontent items
        return <PagecontentItem item={item} key={`${location.pathname}-${key}`} />;
      })}
    </>
  );
};
