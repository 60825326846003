import { TRedirectPage } from '../../pages/AemPage/types';
import { pathToTitle } from './metaHelpers';
import { TPageMeta } from './PageMeta';

export function getRedirectMeta(path: string, redirect: TRedirectPage, canonical: string | null): TPageMeta {
  return {
    title: pathToTitle(path),
    description: 'Deze pagina staat elders!',
    canonical,
  };
}
