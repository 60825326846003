import React from 'react';
import styled from 'styled-components';

export interface TProgressBarProps {
  durationInMs: number;
  onEnd?: () => any;
  className?: string;
}

export const ProgressBar: React.FC<TProgressBarProps> = (props) => {
  const { durationInMs, onEnd, className } = props;
  const [progress, setProgress] = React.useState<number>(0);

  React.useEffect(() => {
    let timeout: ReturnType<Window['setTimeout']>;
    const start = Date.now();
    const loop = () => {
      timeout = window.setTimeout(() => {
        const diff = Date.now() - start;
        // stop condition: we're past the end timestamp
        if (diff > durationInMs) {
          setProgress(100);
          if (onEnd) {
            onEnd();
          }
        } else {
          // set progress percentage
          setProgress(100 * (diff / durationInMs));
          loop();
        }
      }, 10);
    };
    loop();
    return () => window.clearTimeout(timeout);
  }, [durationInMs, onEnd, setProgress]);

  return (
    <StyledWrap className={className}>
      <StyledProgress style={{ width: `${progress}%` }} />
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.5);
`;

const StyledProgress = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.green};
`;

// #00DD69
