import React, { Suspense } from 'react';
import styled from 'styled-components';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { mediaQuery } from '../../theme/mediaQuery';
const SpinnerAnimation = React.lazy(() => import('./SpinnerAnimation'));

export type TSpinnerSize = 'small' | 'large';

export interface TSpinnerProps {
  size?: TSpinnerSize;
}

export const Spinner: React.FC<TSpinnerProps> = (props) => {
  const isMobile = useMatchMedia(mediaQuery.mobileTabletPortraitQuery);
  const size: TSpinnerSize = props.size ?? (isMobile ? 'small' : 'large');
  return (
    <StyledWrap size={size}>
      <Suspense fallback={<div />}>
        <SpinnerAnimation size={size} />
      </Suspense>
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ size: TSpinnerSize }>`
  display: flex;
  flex: 0 0 auto;
  margin: 0;
  width: 200px;
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ size }) =>
    size === 'small' &&
    `
    width: 100px;
    height: 100px;
  `}
`;
