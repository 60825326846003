declare type RequestFullscreenFn = (options?: FullscreenOptions) => Promise<void>;

declare interface HTMLElement {
  requestFullscreen?: RequestFullscreenFn;
  webkitRequestFullscreen?: RequestFullscreenFn;
  msRequestFullscreen?: RequestFullscreenFn;
}

declare global {
  interface Document {
    exitFullscreen: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitCurrentFullScreenElement?: HTMLElement | null;
  }
}

function closeFullscreen() {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    return document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    return document.msExitFullscreen();
  }
  return Promise.resolve();
}

function openFullscreen(element: HTMLElement, options?: FullscreenOptions) {
  if (element.requestFullscreen) {
    return element.requestFullscreen(options);
  } else if (element.webkitRequestFullscreen) {
    /* Safari */
    return element.webkitRequestFullscreen(options);
  } else if (element.msRequestFullscreen) {
    /* IE11 */
    return element.msRequestFullscreen(options);
  }
  return Promise.resolve();
}

function lockOrientation(orientation: TOrientation) {
  const lockType: OrientationLockType = orientation === 'PORTRAIT' ? 'portrait' : 'landscape';
  return window.screen.orientation.lock(lockType);
}

function unlockOrientation() {
  return window.screen.orientation.unlock();
}

export interface TEnableFullscreenOptions {
  requestFullscreenOptions?: FullscreenOptions;
  orientation?: TOrientation;
}

export type TOrientation = 'LANDSCAPE' | 'PORTRAIT';

export async function enableFullscreen(element: HTMLElement, options?: TEnableFullscreenOptions): Promise<boolean> {
  // iOS iPhone -- fullscreen API is not available
  // some workarounds are suggested but they don't seem to work today
  // https://stackoverflow.com/questions/12822739/full-screen-api-html5-and-safari-ios-6
  try {
    await openFullscreen(element, options?.requestFullscreenOptions);
    if (options?.orientation) {
      await lockOrientation(options.orientation).catch();
    }
    return true;
  } catch (e) {
    return false;
  }
}

export async function disableFullscreen(): Promise<boolean> {
  try {
    await closeFullscreen();
    unlockOrientation();
    return true;
  } catch {
    return false;
  }
}

export function hasFullscreenElement() {
  if (document.fullscreenElement instanceof Element) {
    return true;
  }
  if (document.webkitCurrentFullScreenElement instanceof Element) {
    return true;
  }
}
