import { TDetachListener } from '../../lib/Listener';
import { IServices } from '../IServices';
import { TSession } from '../SessionService/ISessionService';
import {
  TSDKMessageIn,
  TSDKEnvironment,
  TGamePermission,
  TRequestConsentOptions,
  TKetnetUserData,
  TKetnetGameData,
  TSDKMessageOut,
  TKetnetGamePayload,
} from './types';

const DEFAULT_USER_DATA: TKetnetUserData = {
  loggedIn: null,
  consents: [],
  accesstoken: null,
  profile: null,
};

export interface TGameData {
  iframe: Window;
  origin: string;
  gameId: string;
  gameName: string;
  gamePermissions: TGamePermission[];
  onExit: () => any;
  onTriggerLogin: () => any;
  onTriggerRequestConsent: (options: TRequestConsentOptions) => any;
  onTriggerDifferentGame: (gameUrl: string) => any;
}

export class WebGamesSDK {
  constructor(private props: TGameData, private services: IServices) {}

  private detachSessionChanged: TDetachListener | null = null;

  async initialize(): Promise<void> {
    const payload = await this.getGamePayload();
    this.sendMessage({
      msgType: 'ketnetsdk_init',
      ...payload,
    });
    window.addEventListener('message', this.handleIncommingMessage);
    this.detachSessionChanged = this.services.sessionService.onSessionChanged(this.handleSessionChanged);
  }

  async refreshData(): Promise<void> {
    const payload = await this.getGamePayload();
    this.sendMessage({
      msgType: 'ketnetsdk_refresh',
      ...payload,
    });
  }

  private async getGamePayload(): Promise<TKetnetGamePayload> {
    const gameData = this.getGameData();
    const userData = await this.getUserData();
    const payload: TKetnetGamePayload = {
      ...gameData,
      ...userData,
    };
    return payload;
  }

  destroy() {
    window.removeEventListener('message', this.handleIncommingMessage);
    this.detachSessionChanged?.();
  }

  private async getUserData(): Promise<TKetnetUserData> {
    const loggedIn = this.services.sessionService.isLoggedIn();
    if (!loggedIn) {
      return DEFAULT_USER_DATA;
    }
    // only expose userprofile for games that have the permission to see it
    if (!this.props.gamePermissions.includes('USERPROFILE')) {
      return DEFAULT_USER_DATA;
    }
    // ensure a valid accessToken
    const accessToken = await this.services.sessionService.getAccessToken();
    // always work with the most recent version of ketprofiel
    const user = await this.services.sessionService.refreshUserProfile();
    return {
      loggedIn: true,
      accesstoken: accessToken,
      // only one consent for now
      consents: user.publicUsage ? ['publicUsage'] : [],
      profile: {
        age: user.age,
        firstName: user.firstName,
        username: user.username,
        picture: user.picture,
        uid: user.uid,
      },
    };
  }

  private getSDKEnvironment(): TSDKEnvironment {
    switch (this.services.configService.environment) {
      case 'development':
        return 'local';
      case 'staging':
        return 'stag';
      case 'production':
        return 'prod';
    }
  }

  private getGameData(): TKetnetGameData {
    const environment = this.getSDKEnvironment();
    const { gamePermissions, gameId, gameName } = this.props;
    return {
      gameId,
      gameName,
      gamePermissions,
      environment,
      inApp: false,
    };
  }

  private handleSessionChanged = async (session: TSession) => {
    this.logger.debug(`User logged ${session ? 'in' : 'out'}`);
    if (session) {
      // fresh session, so we can use accessToken from it
      const userData = await this.getUserData();
      this.sendMessage({
        msgType: 'ketnetsdk_login',
        ...userData,
      });
    } else {
      this.sendMessage({
        msgType: 'ketnetsdk_logout',
      });
    }
  };

  private sendMessage(msg: TSDKMessageOut) {
    const { iframe, origin } = this.props;
    iframe.postMessage(msg, origin);
    const debug = { 'ketnet->game': msg.msgType };
    this.logger.debug(JSON.stringify(debug, null, 2));
  }

  private handleIncommingMessage = (evt: MessageEvent) => {
    if (evt.origin === this.props.origin) {
      const msg: TSDKMessageIn = evt.data ?? {};
      const debug = { 'game->ketnet': msg.msgType };
      this.logger.debug(JSON.stringify(debug, null, 2));
      switch (msg.msgType) {
        case 'ketnetsdk_triggerLogin':
          this.props.onTriggerLogin();
          return;
        case 'ketnetsdk_exit':
          this.props.onExit();
          return;
        case 'ketnetsdk_triggerRequestConsent':
          this.props.onTriggerRequestConsent(msg.options);
          return;
        case 'ketnetsdk_triggerDifferentGame':
          this.props.onTriggerDifferentGame(msg.gameUrl);
          return;
        default:
          this.logger.debug(`incoming SDK message NYI: ${(msg as any).msgType}`);
      }
    }
    // skip messages from other origins
  };

  private get logger() {
    return this.services.loggerService;
  }
}
