import { IServices } from './IServices';
import { ILoggerService } from './LoggerService/ILoggerService';
import ServiceTypedError from './ServiceTypedError';

abstract class BaseService<TError = undefined> {
  constructor(protected services: IServices) {}

  private get serviceName() {
    return this.constructor.name;
  }

  /**
   * Create an error with the service name prefixed to the error message
   * @param message a descriptive error message
   */
  protected createError(message: string): Error {
    return new Error(`${this.serviceName}: ${message}`);
  }

  /**
   * Create a service specific error
   */
  protected createTypedError(errorType: TError): ServiceTypedError<TError> {
    return new ServiceTypedError(this.serviceName, errorType);
  }

  /**
   * Wrapped logger methods that prefix the logger service name
   */
  protected get logger(): ILoggerService {
    const { loggerService } = this.services;
    return {
      error: (m: string) => {
        loggerService.error(`[${this.serviceName}] ${m}`);
      },
      warn: (m: string) => {
        loggerService.warn(`[${this.serviceName}] ${m}`);
      },
      info: (m: string) => {
        loggerService.info(`[${this.serviceName}] ${m}`);
      },
      http: (m: string) => {
        loggerService.http(`[${this.serviceName}] ${m}`);
      },
      verbose: (m: string) => {
        loggerService.verbose(`[${this.serviceName}] ${m}`);
      },
      debug: (m: string) => {
        loggerService.debug(`[${this.serviceName}] ${m}`);
      },
      silly: (m: string) => {
        loggerService.silly(`[${this.serviceName}] ${m}`);
      },
      errorObject: (m: string, e: any) => {
        loggerService.errorObject(`[${this.serviceName}] ${m}`, e);
      },
    };
  }

  public getServiceError(error: any): ServiceTypedError<TError> | null {
    if (error instanceof ServiceTypedError) {
      return error as ServiceTypedError<TError>;
    }
    return null;
  }
}

export default BaseService;
