import Services from '../../services/Services';
import { TVideoDetail } from './types';

export const toggleLike = (video: TVideoDetail, isLikedByMe: boolean) => {
  const { id, titleVideodetail, subtitleVideodetail } = video;
  if (isLikedByMe) {
    const gdpr = `Video "${titleVideodetail} ${subtitleVideodetail ?? ''}" geliked`;
    Services.bffService
      .likeVideo({ input: { id, gdpr } })
      .catch((e) => Services.loggerService.errorObject(e, `Failed to like video ${id}`));
  } else {
    Services.bffService
      .unlikeVideo({ input: { id } })
      .catch((e) => Services.loggerService.errorObject(e, `Failed to unlike video ${id}`));
  }
};
