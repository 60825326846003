import styled from 'styled-components';
import { ProgressBar } from '../ProgressBar/ProgressBar';

export const StoryProgressBar = styled(ProgressBar)`
  position: absolute;
  top: 32px;
  left: 24px;
  right: 24px;
  width: auto !important;
`;
