import React from 'react';
import { EColor } from '../../theme/styleguide';
import { SwimlaneButton } from './SwimlaneButton';
import { ArrowNextIcon, ArrowPrevIcon } from '../../assets/icons';
import styled, { useTheme } from 'styled-components';

export type TSwimlaneArrowProps = {
  color: EColor;
  type?: 'next' | 'prev';
  onClick?: () => void;
  swimlaneStyle: 'boxset' | 'heroes' | 'stories' | 'seasons' | 'thumbnails' | 'suggestions' | 'filters';
};

export const SwimlaneArrow = (props: TSwimlaneArrowProps) => {
  const { type, color, onClick, swimlaneStyle } = props;
  const theme = useTheme();
  const arrowContainerHeight = theme.components.swimlane.images[swimlaneStyle].height;

  switch (type) {
    case 'prev':
      return (
        <PrevArrowContainer backgroundColor={color}>
          <StyledInner arrowContainerHeight={arrowContainerHeight}>
            <SwimlaneButton icon={<ArrowPrevIcon />} onClick={onClick} />
          </StyledInner>
        </PrevArrowContainer>
      );
    case 'next':
    default:
      return (
        <NextArrowContainer backgroundColor={color}>
          <StyledInner arrowContainerHeight={arrowContainerHeight}>
            <SwimlaneButton icon={<ArrowNextIcon />} onClick={onClick} />
          </StyledInner>
        </NextArrowContainer>
      );
  }
};

const PrevArrowContainer = styled.div<{ backgroundColor: EColor }>`
  ${({ theme, backgroundColor }) => `
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0.85;
    padding: 0 5px;
    background: linear-gradient(to left, transparent 0%, ${theme.colors[backgroundColor]} 70% 100%);
  `}
`;

const StyledInner = styled.div<{ arrowContainerHeight: number }>`
  ${({ arrowContainerHeight }) => `
    display: flex;
    height: ${arrowContainerHeight}px;
    justify-content: center;
    align-items: center;
  `}
`;

const NextArrowContainer = styled(PrevArrowContainer)`
  ${({ theme, backgroundColor }) => `
    right: 0px;
    left: auto;
    background: linear-gradient(to right, transparent 0%, ${theme.colors[backgroundColor]} 70% 100%);
  `}
`;
