import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  TPinboardItem,
  TPinboardItemBackgroundPosition,
  TPinboardItemBackground,
  TPinboardItemTitlePosition,
} from '../../pages/ProgramPage/types';
import { mediaQuery } from '../../theme/mediaQuery';
import { PostMedia } from '../../components/Post/PostMedia';
import { PinboardItemDetail } from '../../components/Pinboard/PinboardItemDetail';
import { PinboardVideoPlayer } from './PinboardVideoPlayer';
import { PinboardContext } from './PinboardContext';
import { Emoji } from '../Emoji/Emoji';

export interface TPinboardItemProps {
  item: TPinboardItem;
  onClick?: (item: TPinboardItem) => void;
  inOverlay?: boolean;
}

export interface TImageSize {
  width: number;
  height: number;
}

export const preloadImage = (url: string): Promise<TImageSize> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve({ width: img.width, height: img.height }));
    img.addEventListener('error', (error) => reject(error));
    img.src = url;
    return img;
  });
};

async function fetchImage(
  setWrapDimensions: (dimensions: TPinboardItemBackgroundPosition) => void,
  setIsLoaded: (status: boolean) => void,
  item: TPinboardItem
) {
  let imageUrl: string;
  if (item.media && item.media.type === 'PICTURE') {
    imageUrl = item.media.url;
  } else if (item.media && item.media.type === 'VIDEO' && item.media.poster) {
    imageUrl = item.media.poster.medium;
  } else {
    return null;
  }

  await preloadImage(imageUrl).then((image) => {
    const maxWrap = 100;
    const aspectRatio = image.width > image.height ? image.width / image.height : image.height / image.width;
    const imageHeight = image.width > image.height ? maxWrap / aspectRatio : maxWrap;
    const imageWidth = image.width > image.height ? maxWrap : maxWrap / aspectRatio;
    const wrapWidth = imageWidth / maxWrap;
    const wrapHeight = imageHeight / maxWrap;

    setWrapDimensions({
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      marginTop: (1 - wrapHeight) / 2,
      marginRight: (1 - wrapWidth) / 2,
      marginBottom: (1 - wrapHeight) / 2,
      marginLeft: (1 - wrapWidth) / 2,
    });

    setIsLoaded(true);
  });
}

export const PinboardItem: React.FC<TPinboardItemProps> = (props) => {
  const { item, onClick, inOverlay = false } = props;
  const reactionsEnabled = !!item.reactionsEnabled && !inOverlay;
  const itemProps = {
    ...(onClick &&
      reactionsEnabled && {
        onClick: () => {
          onClick(item);
        },
      }),
  };
  const [wrapDimensions, setWrapDimensions] = useState<TPinboardItemBackgroundPosition | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const context = React.useContext(PinboardContext);
  const hasCtxReaction = context.myReactions.findIndex((contextItem) => contextItem['id'] === item.id);
  const topReactionsCount = () => {
    if (hasCtxReaction !== -1) {
      const myNewReaction = context.myReactions[hasCtxReaction]['myReaction'];
      if (!item.topReaction && !myNewReaction) {
        return null;
      }
      if (!item.topReaction && myNewReaction) {
        return { count: 1, id: myNewReaction };
      }
      if (item.reactions && myNewReaction !== item.myReaction && myNewReaction !== item.topReaction.id) {
        const countOfMyNewReaction = item?.reactions.findIndex((contextItem) => contextItem['id'] === myNewReaction);
        if (countOfMyNewReaction !== -1) {
          if (item.reactions[countOfMyNewReaction].count + 1 >= item.topReaction.count) {
            return { count: item.reactions[countOfMyNewReaction].count + 1, id: myNewReaction };
          }
        }
      }
      if (item.myReaction !== myNewReaction && myNewReaction === item.topReaction.id) {
        return { count: item.topReaction?.count + 1, id: item.topReaction.id };
      }
      if (
        item.myReaction === item.topReaction.id &&
        item.myReaction !== myNewReaction &&
        myNewReaction !== item.topReaction.id
      ) {
        const count = item.topReaction?.count - 1;
        if (count === 0) {
          return myNewReaction ? { count: 1, id: myNewReaction } : null;
        }
        return { count: item.topReaction?.count - 1, id: item.topReaction.id };
      }
    }
    return item.topReaction;
  };
  const topReactions = !inOverlay && isLoaded ? topReactionsCount() : null;

  useEffect(() => {
    if (item.background) {
      const position = item.background.position;
      setWrapDimensions({
        width: position.width,
        height: position.height,
        left: position.left,
        top: position.top,
        marginBottom: position.marginBottom,
        marginLeft: position.marginLeft,
        marginRight: position.marginRight,
        marginTop: position.marginTop,
      });
      setIsLoaded(true);
    } else {
      fetchImage(setWrapDimensions, setIsLoaded, item);
    }
  }, [item, setWrapDimensions]);

  return (
    <StyledPinboardItemWrap rotation={item.background && item.background.rotation} inOverlay={inOverlay}>
      {isLoaded && wrapDimensions && (
        <>
          <StyledPinboardItem
            background={item.background && item.background}
            wrapDimensions={wrapDimensions}
            reactionsEnabled={reactionsEnabled}
            type="content"
            {...itemProps}
          >
            <StyledPinboardMain>
              {item.text && <StyledPinboardText>{item.text}</StyledPinboardText>}
              {item.media && item.media.type === 'PICTURE' && <PostMedia data={item.media} />}
              {item.media && item.media.type === 'VIDEO' && !inOverlay && (
                <StyledPosterWrapper>
                  <StyledPosterImage src={item.media.poster?.medium} />
                </StyledPosterWrapper>
              )}
              {item.media && item.media.type === 'VIDEO' && inOverlay && (
                <PinboardVideoPlayer data={item.media} context={context} />
              )}
            </StyledPinboardMain>

            {item.title && (
              <StyledPinboardTitle backgroundPosition={item.background} titlePosition={item.background.titlePosition}>
                {item.title}
              </StyledPinboardTitle>
            )}
          </StyledPinboardItem>

          {reactionsEnabled && (
            <StyledPinboardItem
              background={null}
              wrapDimensions={wrapDimensions}
              reactionsEnabled={reactionsEnabled}
              type="interaction"
              {...itemProps}
            >
              {topReactions && topReactions?.count > 0 && topReactions.id && (
                <StyledLike background={!!item.background} wrapDimensions={wrapDimensions}>
                  <StyledLikeCount>{topReactions.count}</StyledLikeCount>
                  <StyledLikeEmoji>
                    <Emoji kind={topReactions.id} size="large" />
                  </StyledLikeEmoji>
                </StyledLike>
              )}
            </StyledPinboardItem>
          )}
        </>
      )}

      {!inOverlay &&
        item.backgroundDetails.map((detail, detailIndex) => {
          return <PinboardItemDetail detail={detail} parent={item} key={`pinboardDetail-${detailIndex}`} />;
        })}
    </StyledPinboardItemWrap>
  );
};

const StyledPinboardItemWrap = styled.div<{ rotation: number; inOverlay: boolean }>`
  ${({ rotation, inOverlay }) => `
    font-family: 'noteworthy_light';
    font-size: 16px;
    line-height: 18px;
    display: flex;
    position: relative;
    width: 100%;
    padding-top: 100%;
    ${
      !inOverlay &&
      `
      width: 25%;
      padding-top: 25%;
      ${mediaQuery.tabletPortrait(`
        width: 50%;
        padding-top: 50%;
      `)}
      ${mediaQuery.mobile(`
        width: 100%;
        padding-top: 100%;
      `)}
    `
    }
    transform: ${inOverlay ? `unset` : `rotate(${rotation}deg)`};
  `}
`;

const StyledPinboardItem = styled.div<{
  background: TPinboardItemBackground | null;
  wrapDimensions: TPinboardItemBackgroundPosition;
  reactionsEnabled: boolean;
  type: 'content' | 'interaction';
}>`
  ${({ background, wrapDimensions, reactionsEnabled, type }) => `
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: ${wrapDimensions.left * 100}%;
    top: ${wrapDimensions.top * 100}%;
    padding-left: ${wrapDimensions.marginLeft * 100}%;
    padding-right: ${wrapDimensions.marginRight * 100}%;
    padding-top: ${wrapDimensions.marginTop * 100}%;
    padding-bottom: ${wrapDimensions.marginBottom * 100}%;
    max-width: ${wrapDimensions.width * 100}%;
    max-height: ${wrapDimensions.height * 100}%; 
    width: ${wrapDimensions.width * 100}%;
    height: ${wrapDimensions.height * 100}%;
    cursor: ${reactionsEnabled && type === 'interaction' ? `pointer` : `default`};
    z-index: ${reactionsEnabled && type === 'interaction' ? `50` : `5`};
    
    ${
      background &&
      `  
      background-image: url(${background.url});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    `
    }
  `}
`;

const StyledPinboardMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledPinboardTitle = styled.div<{
  backgroundPosition: TPinboardItemBackground;
  titlePosition: TPinboardItemTitlePosition;
}>`
  ${({ backgroundPosition, titlePosition }) => `
    position: absolute;
    top: ${(backgroundPosition.position.top + backgroundPosition.position.marginTop + titlePosition.titleTop) * 100}%;
    left: ${
      (backgroundPosition.position.left + backgroundPosition.position.marginLeft + titlePosition.titleLeft) * 100
    }%;
    width: ${titlePosition.titleWidth * 100}%;
    height: ${titlePosition.titleHeight * 100}%;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const StyledPinboardText = styled.div`
  ${({ theme }) => `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    word-break: break-word;
    text-align: center;
    padding: ${theme.spacings.small}px;
  `}
`;

const StyledPosterWrapper = styled.div`
  position: relative;
  width: 100%;
  &::after {
    display: block;
    content: ' ';
    background-image: url('/assets/icons/play.svg');
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: center center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`;

const StyledPosterImage = styled.img`
  min-width: 100%;
`;

const StyledLike = styled.div<{ background: boolean; wrapDimensions: TPinboardItemBackgroundPosition }>`
  ${({ theme, background, wrapDimensions }) => `
    position: absolute;
    right: ${background ? `-10px` : `calc(${wrapDimensions.marginRight * 100}% - 10px)`};
    bottom: ${background ? `-10px` : `calc(${wrapDimensions.marginBottom * 100}% - 10px)`};
    display: flex;
    flex-direction: row;
    padding: ${theme.spacings.small}px;
  `}
`;

const StyledLikeCount = styled.div`
  ${({ theme }) => `
    font-family: 'favorit_bold';
    padding: 4px ${theme.spacings.medium}px;
    background: ${theme.colors.white};
    border-top-left-radius: ${theme.spacings.medium}px;
    border-bottom-left-radius: ${theme.spacings.medium}px;
    position: absolute;
    right: 45px;
    margin: 0;
    bottom: 16px;
    z-index: 51;
    -moz-box-shadow: 2px 1px 0px 1px ${theme.colors.black};
    -webkit-box-shadow: 2px 1px 0px 1px ${theme.colors.black};
    box-shadow: 2px 1px 0px 1px ${theme.colors.black};
  `}
`;

const StyledLikeEmoji = styled.div`
  ${({ theme }) => `
    border-radius: 50%;
    background: ${theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    z-index: 52;
    -moz-box-shadow: 2px 1px 0px 1px ${theme.colors.black};
    -webkit-box-shadow: 2x 1px 0px 1px ${theme.colors.black};
    box-shadow: 2px 1px 0px 1px ${theme.colors.black};
  `}
`;
