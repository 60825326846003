import { gql } from '@apollo/client';
import { includeDependencies } from './fragment';
import { storyDetailsFragment, TStoryDetails } from './StoryDetails';
import { storyReactionFragment } from './StoryReaction';
import { vrtPlayerFragment } from './VrtPlayer';

const dependencies = includeDependencies([vrtPlayerFragment, storyReactionFragment, storyDetailsFragment]);

export const castPollVoteMutation = gql`
mutation CastPollVote($input: StorySaveUserVoteInput!) {
  storySaveUserVote(input: $input) {
    ...${storyDetailsFragment.name}
  }
}

${dependencies}
`;

export interface TCastPollVoteArgs {
  input: TStorySaveUserVoteInput;
}

export interface TStorySaveUserVoteInput {
  storyId: string;
  answerId: string;
  gdpr: string;
}

export interface TCastPollVoteResult {
  storySaveUserVote: TStoryDetails;
}
