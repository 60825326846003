import React, { useState } from 'react';
import styled from 'styled-components';
import { TPostRoot, TPostComment, TFeedTheming } from '../../pages/ProgramPage/types';
import { PostMedia } from './PostMedia';
import { AccountIcon } from '../../assets/icons';
import { formatIsoDateTime } from '../../lib/datetime';
import { Button } from '../Button/Button';
import useSession from '../../lib/useSession';
import { PostCommentInput } from './PostCommentInput';
import { KetprofielModal } from '../KetprofielModal/KetprofielModal';
import { PostReactions } from './PostReactions';
import { EColor } from '../../theme/styleguide';
import { PostCommentOverview } from './PostCommentOverview';
import { Avatar } from '../Avatar/Avatar';
import { Text } from '../Text/Text';
import { Background } from '../Background/Background';
import { StickyPostIcon } from '../../assets/icons';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { mediaQuery } from '../../theme/mediaQuery';

export type TPostProps = {
  data: TPostRoot;
  feedTheming: TFeedTheming;
  disableComments: boolean;
  totalCount: number;
  next: string | null;
};

export const Post = (props: TPostProps) => {
  const { media, author, postedOn, message, id, sticky, disableComments } = props.data;
  const [comments, setComments] = useState<TPostComment[]>(props.data.comments);
  const [addedComments, setAddedComments] = useState<TPostComment[]>([]);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const session = useSession();
  const feedTheming = props.feedTheming;
  const disableFeedComments = props.disableComments;
  const [activeTotalComments, setActiveTotalComments] = useState<number>(props.totalCount);
  const isMobile = useMatchMedia(mediaQuery.mobileTabletPortraitQuery);

  const handleSent = (addedComment: TPostComment) => {
    // prepend the latest comment
    setAddedComments([addedComment].concat(addedComments));
    setActiveTotalComments(activeTotalComments + 1);
  };

  const handleDeleted = (comment: TPostComment) => {
    setComments(comments.filter(({ commentId }) => commentId !== comment.commentId));
    setAddedComments(addedComments.filter(({ commentId }) => commentId !== comment.commentId));
    setActiveTotalComments(activeTotalComments - 1);
  };

  return (
    <StyledWrap backgroundColor={feedTheming.backgroundColor}>
      {sticky && <StyledStickyPost size={isMobile ? 32 : 48} />}
      <StyledTop type="top" />
      <StyledInnerWrap isSticky={sticky}>
        <StyledAvatarWrap>
          <Avatar initials={author?.name} imageUrl={author?.avatarUrl} />
        </StyledAvatarWrap>
        <StyledPostInfo>
          {author?.name && <Text tag="h5">{author.name}</Text>}
          {postedOn && <StyledPostDate>{formatIsoDateTime(postedOn)}</StyledPostDate>}
        </StyledPostInfo>
      </StyledInnerWrap>

      {message && (
        <StyledMessage>
          <Text>{message}</Text>
        </StyledMessage>
      )}
      {media && <PostMedia data={media} />}

      <PostReactions post={props.data} />

      {!disableComments && !disableFeedComments && (
        <StyledComments>
          {session ? (
            <PostCommentInput post={props.data} onSent={handleSent} />
          ) : (
            <StyledLoginButton
              text="aanmelden om te reageren"
              onClick={() => setShowLogin(true)}
              primaryColor={EColor[feedTheming.loginButtonColor]}
              textColor={EColor.white}
              icon={<AccountIcon />}
            />
          )}
          <PostCommentOverview
            postId={id}
            next={props.next}
            totalComments={activeTotalComments}
            comments={[...addedComments, ...comments]}
            onDeleteComment={handleDeleted}
            feedTheming={feedTheming}
            updateComments={setComments}
          />
          <StyledBottom type="bottom" />
        </StyledComments>
      )}

      {/* // TODO: KETNET-3567 trigger sessionchanged, mutate current page + trigger cache invalidation on post reactions + comments */}
      {showLogin && <KetprofielModal onClose={() => setShowLogin(false)} isOpen />}
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ backgroundColor: EColor }>`
  ${({ theme, backgroundColor }) => `
    position: relative;
    max-width: ${theme.pages.program.feed.maxWidth}px;
    width: 100%;
    margin-bottom: 48px;
    background-color: ${theme.colors[backgroundColor]};
  `};
`;

const StyledStickyPost = styled(StickyPostIcon)<{ size: number }>`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
    position: absolute;
    left: calc(50% - ${size / 2}px);
    top: -${size / 2}px;
  `}
`;

const StyledTop = styled(Background)`
  position: absolute;
  right: 0;
  max-width: 82px;
  & svg {
    stroke-width: 0px;
  }
`;

const StyledInnerWrap = styled.div<{ isSticky: boolean }>`
  ${({ theme, isSticky }) => `
    display: flex;
    flex-direction: row;
    padding: ${theme.spacings.medium}px;
    ${isSticky && `padding-top: ${theme.spacings.extralarge}px`};
  `};
`;

const StyledAvatarWrap = styled.div`
  ${({ theme }) => `
    margin-right: ${theme.spacings.small}px;
  `}
`;

const StyledPostInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledPostDate = styled(Text)`
  opacity: 0.5;
`;

const StyledMessage = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacings.medium}px;
    padding-top: 0px;
  `}
`;
const StyledBottom = styled(Background)`
  position: absolute;
  left: -1px;
  max-width: 83px;
  & svg {
    stroke-width: 0px;
  }
`;

const StyledComments = styled.div`
  ${({ theme }) => `
    padding: 0 ${theme.spacings.small}px;
    padding-bottom: 55px;
  `}
`;

const StyledLoginButton = styled(Button)`
  width: 100%;
`;
