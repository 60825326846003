import React from 'react';
import { TEmoji } from '../../lib/emoji';

export interface TPinboardSize {
  width: number;
  height: number;
  mode: 'single' | 'multi';
}

export interface TMyPinboardReaction {
  id: string;
  myReaction: TEmoji | null;
}

export interface TPinboardContextValue {
  myReactions: TMyPinboardReaction[] | [];
  setMyReactions: (myReactions: [] | TMyPinboardReaction[]) => void;
  activePinboardIndex: number;
  requestPause: () => void;
  total: number;
  size: TPinboardSize;
}

export const PinboardContext = React.createContext<TPinboardContextValue>({
  myReactions: [],
  setMyReactions: () => {},
  activePinboardIndex: 0,
  requestPause: () => {},
  total: 0,
  size: {
    height: 0,
    width: 0,
    mode: 'single',
  },
});
