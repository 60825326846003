import React, { FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { TPagecontentItemBackground } from '../PagecontentItem/types';
import { Background } from '../Background/Background';

export type TRegionProps = {
  color?: string;
  verticalSpacing?: number;
  fullMobileWidth?: boolean;
  background?: TPagecontentItemBackground | null;
  backgroundImage?: string | null;
  type?: string;
};

export const Region: FunctionComponent<TRegionProps> = (props) => {
  const { color, verticalSpacing, fullMobileWidth = false, children, background, backgroundImage, type } = props;
  const theme = useTheme();
  let backgroundColor = color ? color : undefined;
  const paddingTop = verticalSpacing !== undefined ? verticalSpacing : theme.components.region.padding;
  const paddingBottom = paddingTop;
  const backgroundItem = background ?? null;

  return (
    <StyledWrap style={{ backgroundColor }} backgroundImage={backgroundImage ?? null} type={type ?? null}>
      <StyledInnerWrap style={{ paddingTop, paddingBottom }} fullMobileWidth={fullMobileWidth}>
        {children}
      </StyledInnerWrap>
      {backgroundItem !== null && (
        <StyledBackground
          type={backgroundItem.imageType}
          positionX={backgroundItem.positionX}
          positionY={backgroundItem.positionY}
        />
      )}
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ backgroundImage: string | null; type: string | null }>`
  width: 100%;
  position: relative;
  ${({ backgroundImage }) => `
    ${
      backgroundImage &&
      `
      background-image: url('${backgroundImage}');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      ${mediaQuery.tabletLandscape(`
        background: unset;
      `)}
    `
    }
  `};
`;

const StyledInnerWrap = styled.div<{ fullMobileWidth: boolean }>`
  ${({
    theme: {
      components: { region },
      breakpoints,
    },
    fullMobileWidth,
  }) => `
  padding-right: ${region.padding}px;
  ${mediaQuery.desktopDown(`
    padding-left: ${region.paddingLeft}px;
  `)}
  ${mediaQuery.kiefer(`
    max-width: ${breakpoints.desktop - /*width menu left + right*/ 2 * 116 - /* some padding left&right */ 50}px;
  `)}
  margin: 0 auto;
  position: relative;
  z-index: 1;

  ${mediaQuery.tabletPortrait(`
    padding-left: ${region.padding}px;
    ${
      fullMobileWidth
        ? `
      padding-left: 0px;
      padding-right: 0px;
    `
        : ''
    }
  `)}
`}
`;

const StyledBackground = styled(Background)<{ positionY: string; positionX: string }>`
  ${({ positionY, positionX }) => `
    content: '';
    position: absolute;
    overflow: visible;
    z-index: 0;
    background-position: ${positionX} ${positionY};
    right: ${positionY === 'top' ? '0' : 'unset'};
    top: ${positionY === 'top' ? '0' : 'unset'};
    bottom: ${positionY === 'bottom' ? '-10px' : 'unset'};
  `}
  ${mediaQuery.tabletPortrait(`
    display: none;
  `)}
`;
