import React from 'react';
import styled from 'styled-components';
import { StoryCallToActionOverlay } from './StoryCallToActionOverlay';
import { StoryImageBackground } from './StoryImageBackground';
import { StoryPollOverlay } from './StoryPollOverlay';
import { StoryReactionsOverlay } from './StoryReactionsOverlay';
import { StoryVideoBackground } from './StoryVideoBackground';
import { TStoryDetails, TStoryBackground, TStoryOverlay } from './types';

export interface TStoryDetailsProps {
  data: TStoryDetails;
  index: number;
}

export const StoryDetails: React.FC<TStoryDetailsProps> = (props) => {
  const { background, overlay, id } = props.data;
  return (
    <StyledWrap>
      <StoryBackground background={background} index={props.index} id={id} />
      <StoryOverlay overlay={overlay} index={props.index} id={id} />
    </StyledWrap>
  );
};

const StoryBackground: React.FC<{ background: TStoryBackground; index: number; id: string }> = ({
  background,
  index,
  id,
}) => {
  switch (background.type) {
    case 'image':
      return <StoryImageBackground background={background} index={index} />;
    case 'video':
      return <StoryVideoBackground data={background} index={index} id={id} />;
    default:
      // Unmapped background type
      return null;
  }
};

const StoryOverlay: React.FC<{ overlay: TStoryOverlay; index: number; id: string }> = ({ overlay, index, id }) => {
  switch (overlay.type) {
    case 'calltoaction':
      return <StoryCallToActionOverlay data={overlay} />;
    case 'reactions':
      return <StoryReactionsOverlay overlay={overlay} storyId={id} />;
    case 'poll':
      return <StoryPollOverlay overlay={overlay} storyId={id} />;
  }
};

const StyledWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
