import BaseService from '../BaseService';
import { IServices } from '../IServices';
import { ISessionService, TSessionServiceErrors, TUser, TSession } from './ISessionService';
import Listener, { TListenFn, TDetachListener } from '../../lib/Listener';
import ketprofiel from '../../assets/icons/ketprofiel-yellow.svg';
import { cache } from 'swr';

const LS_SESSION = 'session';
const DEFAULT_USER: Partial<TUser> = {
  picture: ketprofiel,
  firstName: 'Anonieme Ket',
};

class SessionService extends BaseService<TSessionServiceErrors> implements ISessionService {
  private session: TSession;

  private sessionListener = new Listener<TSession>();

  private showLoginFormListener = new Listener<void>();

  constructor(services: IServices) {
    super(services);
    this.session = null;
    this.initializeListener();
  }

  private initializeListener() {
    // listen to iframe POST messages to us, the parent (ketnet.be)
    window.addEventListener('message', (message: MessageEvent) => {
      const { data } = message;
      if (data?.name === 'ketnetbe_showLogin') {
        this.showLoginForm();
      }
    });
  }

  isLoggedIn() {
    return this.session !== null;
  }

  async doPasswordLogin(login: string, password: string): Promise<TUser> {
    const { bffClient } = this.services.fetchService;
    const res = await bffClient.fetch({
      path: '/login',
      method: 'POST',
      data: {
        username: login,
        password,
      },
    });
    if (res.status === 401) {
      throw this.createTypedError('InvalidCredentials');
    }
    if (res.status === 200) {
      const data: ILoginResponse = await res.json();
      this.setSession(data.user);
      return this.getUserProfile();
    }
    // TODO: set accessToken -- great success case
    throw this.createError(`Unexpected response from /login: ${res.status} ${res.statusText}`);
  }

  async restoreSession(): Promise<boolean> {
    const stored = localStorage.getItem(LS_SESSION);
    if (stored) {
      try {
        const session = JSON.parse(stored);
        // TODO: renew the session if needed
        this.setSession(session);
        return true;
      } catch (e) {
        this.logger.errorObject(`Invalid session stored in LS`, e);
      }
    }
    return false;
  }

  async getAccessToken(): Promise<string> {
    if (this.session === null) {
      throw this.createTypedError('NoActiveSession');
    }
    // TODO: renew token if expired
    return this.session.accessToken;
  }

  getUserProfile(): TUser {
    if (this.session === null) {
      throw this.createTypedError('NoActiveSession');
    }
    return { ...this.session };
  }

  refreshUserProfile: ISessionService['refreshUserProfile'] = async () => {
    if (this.session === null) {
      throw this.createTypedError('NoActiveSession');
    }
    const user = this.getUserProfile();
    const { sessionId } = user;
    try {
      const { ketprofiel } = await this.services.bffService.getKetprofiel({ sessionId });
      if (!ketprofiel) {
        throw new Error('No ketprofiel returned from BFF');
      }
      const { picture, publicUsage, uid, username } = ketprofiel;
      const updated = {
        ...user,
        picture,
        publicUsage,
        uid,
        username,
      };
      return updated;
    } catch (e) {
      this.logger.errorObject('Failed to refresh ketprofiel', e);
      return user;
    }
  };

  onSessionChanged(callback: TListenFn<TSession>): TDetachListener {
    return this.sessionListener.listen(callback);
  }

  doLogout(): void {
    // clear the watched stories when a user logs out, likely scenario is that another user will log in
    this.services.storyService.clearWatched();
    this.setSession(null);
  }

  private setSession(session: TSession) {
    if (session === null) {
      this.session = null;
      localStorage.removeItem(LS_SESSION);
    } else {
      const user = { ...session };
      // TODO: validate mandatory fields are present
      // provide defaults for missing properties
      for (const [key, value] of Object.entries(DEFAULT_USER)) {
        if (!user.hasOwnProperty(key)) {
          this.logger.warn(`User (uid:${user.uid}) is missing property ${key}`);
          (user as any)[key] = value;
        }
      }
      this.session = user;
      localStorage.setItem(LS_SESSION, JSON.stringify(session));
    }
    // clear SWR cache to force next fetches to account for the session change
    cache.clear();
    this.sessionListener.notify(this.session);
    this.setSessionCookie(this.session);
  }

  private setSessionCookie(session: TSession) {
    const cookieKey = this.services.configService.config.ketnetProfileCookieKey;
    const cookieValue = session?.sessionId ? session.sessionId : '';
    const cookieExpires = session?.sessionId ? '' : 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    document.cookie = `${cookieKey}=${cookieValue}; ${cookieExpires} samesite=lax;domain=.ketnet.be;path=/;`;
  }

  isModerator(): boolean {
    return this.session ? this.session.groups.includes('ddtModerator') : false;
  }

  showLoginForm(): void {
    this.showLoginFormListener.notify();
  }

  onShowLoginForm(callback: TListenFn<void>): TDetachListener {
    return this.showLoginFormListener.listen(callback);
  }
}

interface ILoginResponse {
  user: TUser;
}

export default SessionService;
