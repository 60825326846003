import React from 'react';
import { CompetitionIcon, GameIcon, PollIcon, QuizIcon } from './icons';
import { TGameType } from './types';

export interface TProps {
  gameType: TGameType;
}

const GameTypeIcon: React.FC<TProps> = (props) => {
  switch (props.gameType) {
    case 'COMPETITION':
      return <CompetitionIcon />;
    case 'POLL':
      return <PollIcon />;
    case 'QUIZ':
      return <QuizIcon />;
    case 'GAME':
    default:
      return <GameIcon />;
  }
};

export default GameTypeIcon;
