import React from 'react';
import 'react-responsive-modal/styles.css';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { EColor } from '../../theme/styleguide';
import { useProfile } from '../ProfileModal/ProfileContext';
import Services from '../../services/Services';

export type TProfileMenuProps = {
  onLoggedOut: () => any;
};

export const ProfileMenu = (props: TProfileMenuProps) => {
  const { onLoggedOut } = props;
  const { showProfileAction } = useProfile();

  const { sessionService } = Services;
  const handleLogout = () => {
    sessionService.doLogout();
    onLoggedOut();
  };

  return (
    <StyledWrap>
      <StyledImageWrap>
        <StyledImage src="/assets/login-wrappers.jpg" alt="mijn gegevens" />
      </StyledImageWrap>
      <StyledMenu>
        <Button
          text="mijn ketprofiel"
          primaryColor={EColor.blue}
          textColor={EColor.white}
          borderColor={EColor.blue}
          onClick={() => showProfileAction('gebruiker/wijzig')}
        />
        <Button
          text="uitloggen"
          primaryColor={EColor.transparent}
          textColor={EColor.blue}
          borderColor={EColor.blue}
          onClick={handleLogout}
        />
      </StyledMenu>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  max-width: 375px;
`;

const StyledImageWrap = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.blue};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.spacings.small}px;
  `}
`;

const StyledImage = styled.img`
  max-height: 250px;
`;

const StyledMenu = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacings.large}px;
    margin: ${theme.spacings.small}px 0;
    & div:not(:last-child) {
      margin-right: ${theme.spacings.small}px;
    }
  `}
`;
