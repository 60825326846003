import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CallToActionIcon, CallToActionInvertedIcon } from '../../assets/icons';
import Services from '../../services/Services';
import { TStoryCallToActionOverlay } from './types';

export interface TStoryCallToActionOverlayProps {
  data: TStoryCallToActionOverlay;
}

export const StoryCallToActionOverlay: React.FC<TStoryCallToActionOverlayProps> = (props) => {
  const {
    data: { buttonText, link },
  } = props;
  const history = useHistory();
  const navigate = () => {
    const linkType = Services.linkService.getLinkType(link);
    switch (linkType) {
      case 'embed':
        history.push(Services.linkService.getEmbedLink(buttonText, link));
        break;
      case 'external':
        window.open(link, '_blank', 'noreferrer,noopener');
        break;
      case 'internal':
        history.push(link);
        break;
    }
  };

  return (
    <StyledWrap>
      <StyledButton onClick={navigate}>
        <StyledCallToActionIcon />
        <StyledCallToActionInvertedIcon />
        <StyledText>{buttonText}</StyledText>
      </StyledButton>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  ${({ theme }) => `
  background-color: ${theme.colors.red};
  border: 0;
  box-sizing: border-box;
  box-shadow: 10px 10px ${theme.colors.darkPurple};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
  padding: 10px;
  cursor: pointer;
  max-width: 90%;
  
  &:active {
    background-color: ${theme.colors.white};
  }
  &:active ${StyledText} {
    color: ${theme.colors.red};
  }
  &:active ${StyledCallToActionIcon} {
    display: none;
  }
  &:active ${StyledCallToActionInvertedIcon} {
    display: block;
  }
`}
`;

const StyledText = styled.span`
  ${({ theme }) => `
  color: ${theme.colors.white};
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  word-break: break-word;
  /* limit to two lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`}
`;

const StyledCallToActionIcon = styled(CallToActionIcon)`
  min-width: 25px;
  width: 35px;
  height: auto;
`;

const StyledCallToActionInvertedIcon = styled(CallToActionInvertedIcon)`
  display: none;
`;
