import React from 'react';
import Flickity from 'flickity';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { SwimlaneArrow } from './SwimlaneArrow';
import { TSwimlane, TSwimlaneItem, TSwimlaneStoriesItem } from './types';
import { Text } from '../Text/Text';
import { StoryViewer } from '../StoryViewer/StoryViewer';
import { SwimlaneBoxsetItem } from './SwimlaneBoxsetItem';
import { SwimlaneHeroesItem } from './SwimlaneHeroesItem';
import { SwimlaneStoriesItem } from './SwimlaneStoriesItem';
import { SwimlaneSeasonsItem } from './SwimlaneSeasonsItem';
import { SwimlaneFilterItem } from './SwimlaneFilterItem';
import { SwimlaneThumbnailsItemDispatch } from './SwimlaneThumbnailsItemDispatch';
import { Suggestion } from '../../pages/VideoDetailPage/Suggestion';
import { isIos } from '../../lib/features';
import Services from '../../services/Services';
import { SwimlanePeetieItem } from './SwimlanePeetieItem';

export type TSwimlaneProps = {
  data: TSwimlane;
  color?: EColor;
  onClickItem?: (item: TSwimlaneItem) => void;
};

interface TSwimlaneState {
  slideIndex: number;
  totalSlides: number;
  selectedStory: TSwimlaneStoriesItem | null;
  reachedStart: boolean;
  reachedEnd: boolean;
}

export class Swimlane extends React.Component<TSwimlaneProps, TSwimlaneState> {
  private flkty: Flickity | null = null;

  private flktyContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: TSwimlaneProps) {
    super(props);
    this.state = {
      slideIndex: 0,
      totalSlides: 1,
      selectedStory: null,
      reachedStart: false,
      reachedEnd: false,
    };
  }

  private get swimlane() {
    return this.props.data;
  }

  private get title(): string | null {
    switch (this.swimlane.style) {
      case 'thumbnails':
      case 'boxset':
        return this.swimlane.title;
      default:
        return null;
    }
  }

  private get totalSlides(): number {
    return this.flkty?.slides?.length ?? 1;
  }

  private get color() {
    if (this.props.color) {
      return this.props.color;
    }
    return EColor.transparent;
  }

  private handlePreviousPageClick = () => {
    this.flkty?.previous();
    this.handleSwimlaneArrows();
  };

  private handleNextPageClick = () => {
    this.flkty?.next();
    this.handleSwimlaneArrows();
  };

  private get hasReachedStart() {
    return this.flkty?.selectedIndex === 0;
  }

  private get hasReachedEnd() {
    return this.flkty?.selectedIndex === this.totalSlides - 1;
  }

  private initializeFlickity(container: HTMLDivElement) {
    this.flkty = new Flickity(container, {
      cellAlign: 'left',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      groupCells: true,
      on: {
        // set initial totalSlides, also gets fired on resize
        ready: () => this.setState({ totalSlides: this.totalSlides }),
        change: (idx) => {
          const slideIndex = idx ?? 0;
          this.setState({ slideIndex });
          this.handleSwimlaneArrows();
        },
        staticClick: (_evt, _pointer, _cellElement, cellIndex) => {
          if (typeof cellIndex === 'number') {
            this.handleStaticClick(cellIndex);
          }
        },
      },
    });
  }

  handleStaticClick = (idx: number) => {
    // handle clicks at flickity level because onClick on the items will conflict with flkty drag/drop
    if (this.swimlane.style === 'stories') {
      const selectedStory = this.swimlane.items[idx] ?? null;
      this.setState({ selectedStory });
    }

    const clickedItem = this.swimlane.items[idx] ?? null;
    if (clickedItem) this.props.onClickItem?.(clickedItem);
  };

  componentDidMount() {
    const flktyContainer = this.flktyContainerRef.current;
    if (flktyContainer) {
      this.initializeFlickity(flktyContainer);
      this.handleSwimlaneArrows();
      window.addEventListener('resize', this.handleSwimlaneArrows);
      this.iosDragFix(flktyContainer);
    } else {
      // TODO: log to sentry
      console.error(`Unable to initialize swimlane: Flickity container ref was not initialized`);
    }
  }

  iosDragFix(flickityContainer: HTMLElement) {
    if (!isIos) {
      return;
    }
    // https://github.com/metafizzy/flickity/issues/740#issuecomment-485562201
    const tapArea = flickityContainer.querySelector('.flickity-viewport');
    let startX = 0;
    if (!tapArea) {
      console.error(`did not find taparea`);
      return;
    }
    tapArea.addEventListener('touchstart', (e) => {
      try {
        startX = (e as any).touches[0].clientX;
      } catch {}
    });
    tapArea.addEventListener('touchmove', (e) => {
      try {
        if (Math.abs((e as any).touches[0].clientX - startX) > 5 && e.cancelable) {
          e.preventDefault();
        }
      } catch {}
    });
  }

  componentWillUnmount() {
    // cleanup flickity
    this.flkty?.destroy();
    window.removeEventListener('resize', this.handleSwimlaneArrows);
  }

  handleSwimlaneArrows = () => {
    this.setState({ reachedStart: this.hasReachedStart, reachedEnd: this.hasReachedEnd });
  };

  renderSwimlaneItems() {
    const { swimlane } = this;
    switch (swimlane.style) {
      case 'boxset':
        return swimlane.items.map((item, idx) => <SwimlaneBoxsetItem key={idx} data={item} />);
      case 'heroes':
        return swimlane.items.map((item, idx) =>
          item.type === 'peetie' ? (
            <SwimlanePeetieItem key={idx} data={item} />
          ) : (
            <SwimlaneHeroesItem key={idx} data={item} />
          )
        );
      case 'stories':
        return swimlane.items.map((item, idx) => <SwimlaneStoriesItem key={idx} data={item} />);
      case 'seasons':
        return swimlane.items.map((item, idx) => <SwimlaneSeasonsItem key={idx} data={item} />);
      case 'thumbnails':
        return swimlane.items.map((item, idx) => <SwimlaneThumbnailsItemDispatch key={idx} data={item} />);
      case 'suggestions':
        return swimlane.items.map((item, idx) => <Suggestion key={idx} suggestion={item} />);
      case 'filters':
        return swimlane.items.map((item, idx) => <SwimlaneFilterItem key={idx} data={item} />);
      default:
        Services.loggerService.errorObject('unmapped swimlane style', this.swimlane);
        return [];
    }
  }

  getSwimlaneKey(): string {
    let ids: string[];
    switch (this.swimlane.style) {
      case 'seasons':
        ids = this.swimlane.items.map((i) => i.title);
        break;
      case 'boxset':
        ids = this.swimlane.items.map((i) => i.link);
        break;
      case 'heroes':
        ids = this.swimlane.items.map((i) => (i.type === 'peetie' ? i.id : i.link));
        break;
      case 'thumbnails':
        ids = this.swimlane.items.map((i) => i.link);
        break;
      case 'suggestions':
        ids = this.swimlane.items.map((i) => i.id);
        break;
      case 'stories':
        ids = this.swimlane.items.map((i) => i.id);
        break;
      case 'filters':
        ids = this.swimlane.items.map((i) => i.id);
        break;
      default:
        Services.loggerService.errorObject('unmapped swimlane style', this.swimlane);
        ids = [];
    }
    return ids.join('|');
  }

  render() {
    const { title, flktyContainerRef, swimlane, color } = this;
    const { selectedStory } = this.state;
    return (
      <StyledWrap data-analyticsid={swimlane.analyticsId}>
        {title && <StyledTitle>{title}</StyledTitle>}
        <StyledSwimlaneContainer>
          <StyledItemsContainer ref={flktyContainerRef} key={this.getSwimlaneKey()}>
            {this.renderSwimlaneItems()}
          </StyledItemsContainer>
          {!this.state.reachedStart && (
            <SwimlaneArrow
              color={color}
              type="prev"
              onClick={this.handlePreviousPageClick}
              swimlaneStyle={swimlane.style}
            />
          )}
          {!this.state.reachedEnd && (
            <SwimlaneArrow
              color={color}
              type="next"
              onClick={this.handleNextPageClick}
              swimlaneStyle={swimlane.style}
            />
          )}
        </StyledSwimlaneContainer>
        {selectedStory && (
          <StoryViewer initialStory={selectedStory} onClose={() => this.setState({ selectedStory: null })} />
        )}
      </StyledWrap>
    );
  }
}

const StyledWrap = styled.div`
  margin: auto;
  margin-bottom: 0;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledSwimlaneContainer = styled.div`
  position: relative;
`;

const StyledItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  &.flickity-enabled {
    flex-direction: column;
  }
  overflow: hidden;
`;

const StyledTitle = styled(Text).attrs({ tag: 'h2', size: 'large', bold: true })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.small}px;
  `}
`;
