import { vrtPlayerFragment } from './VrtPlayer';
import { TEmoji } from '../../lib/emoji';

const name = 'pinboardItemFragment';

const query = `
fragment ${name} on PinboardItem {
  id
  postedOn
  title
  text
  reactionsEnabled
  myReaction
  reactions {
    id
    count
  }
  topReaction {
    id
    count
  }
  media {
    ... on PostMediaVideo {
      type
      vrtPlayer { ...${vrtPlayerFragment.name} }
      scaledPoster { ...scaledImage }
    }
    ... on PostMediaPicture {
      type
      scaledImage { ...scaledImage }
    }
  }
  background {
    url
    position {
        width
        height
        top
        left
        marginLeft
        marginTop
        marginRight
        marginBottom
    }
    titlePosition {
        titleLeft
        titleTop
        titleWidth
        titleHeight
    }
    rotation 
  }
  backgroundDetails {
      url
      amount
      position
      rotation
  }
  type
}`;

export interface TPinboardItem {
  id: string;
  postedOn: string;
  title: string;
  text: string;
  reactionsEnabled: boolean;
  myReaction: TEmoji | null;
  reactions: TPinboardReaction[] | null;
  topReaction: TPinboardReaction;
  media: any; // Todo: fix this!!
  type: 'pinboardItemMedia';
  background: TPinboardItemBackground;
  backgroundDetails: TPinboardItemBackgroundDetail[];
}

export interface TPinboardReaction {
  id: TEmoji;
  count: number;
}

export interface TPinboardItemBackground {
  url: string;
  position: TPinboardItemBackgroundPosition;
  titlePosition: TPinboardItemTitlePosition;
  rotation: number;
}

export interface TPinboardItemBackgroundDetail {
  url: string;
  amount: string;
  position: string;
  rotation: number;
}

export interface TPinboardItemBackgroundPosition {
  width: number;
  height: number;
  top: number;
  left: number;
  marginLeft: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
}

export interface TPinboardItemTitlePosition {
  titleLeft: number;
  titleTop: number;
  titleWidth: number;
  titleHeight: number;
}

export const pinboardItemFragment = { name, query };
