import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { Text } from '../Text/Text';

export type TButtonProps = {
  variant?: 'stroke' | 'fill' | 'filledAndBorders';
  text: string;
  primaryColor?: EColor;
  borderColor?: EColor;
  textColor?: EColor;
  icon?: JSX.Element;
  iconColor?: EColor;
  onClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Button = (props: TButtonProps) => {
  const {
    text,
    variant = 'fill',
    primaryColor,
    borderColor,
    textColor,
    icon,
    iconColor,
    onClick,
    className,
    ...rest
  } = props;
  const theme = useTheme();

  const wrapBackgroundColor = useMemo(() => {
    let _wrapBackgroundColor: string | undefined = undefined;
    if (primaryColor) _wrapBackgroundColor = theme.colors[primaryColor];
    if (variant === 'stroke') _wrapBackgroundColor = 'transparent';
    return _wrapBackgroundColor;
  }, [variant, primaryColor, theme]);

  const wrapBorderColor = useMemo(() => {
    if (borderColor) {
      return theme.colors[borderColor];
    }
    return theme.colors.transparent;
  }, [borderColor, theme]);

  const labelColor = useMemo(() => {
    if (textColor) return textColor;
    if (primaryColor && [EColor.blue, EColor.black].indexOf(primaryColor) > -1) {
      return EColor.white;
    }
    return EColor.black;
  }, [primaryColor, textColor]);

  const iconColorStroke = useMemo(() => {
    if (iconColor) return theme.colors[iconColor];
    if (variant === 'stroke') return wrapBorderColor;
    return theme.colors.black;
  }, [wrapBorderColor, iconColor, theme, variant]);

  return (
    <StyledWrap
      variant={variant}
      style={{ backgroundColor: wrapBackgroundColor, borderColor: wrapBorderColor }}
      onClick={onClick}
      className={className}
      aria-label={text}
      {...rest}
    >
      <StyledLabel color={labelColor}>{text}</StyledLabel>
      {icon && <StyledIconWrap style={{ color: iconColorStroke }}>{icon}</StyledIconWrap>}
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ variant: string }>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  background: ${({ theme }) => theme.components.button.primaryColor};
  height: ${({ theme }) => theme.components.button.height}px;
  padding: 0 ${({ theme }) => theme.components.button.paddingHorizontal}px;
  border-radius: 0px;
  border: 1px solid;
  align-items: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  text-transform: ${({ theme }) => theme.components.button.textTransform};
  position: relative;

  ${({ variant, style }) =>
    variant === 'filledAndBorders' &&
    `
      &::after,
      &::before {
        position: absolute;
        z-index: 3;
        left: 0px;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid ${style?.backgroundColor};
        border-bottom: 0px;
        top: -5px;
      }
      &::before {
        top: -10px;
      }
    `}
`;

const StyledIconWrap = styled.div`
  display: flex;
  margin-left: 12px;
`;

const StyledLabel = styled(Text).attrs({ size: 'medium', bold: true })``;
