import { IMonitoringService } from './IMonitoringService';
import BaseService from '../BaseService';

class MockMonitoringService extends BaseService implements IMonitoringService {
  initialize(): void {
    return;
  }
  captureException(): void {
    return;
  }
  captureMessage(): void {
    return;
  }
}

export default MockMonitoringService;
