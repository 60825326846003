import React, { useContext } from 'react';
import { sleep } from '../../lib/sleep';
import { TPeetie, TPeetieMovie } from './types';
import { supportsWebM } from '../../lib/features';

// start counting down from this number
const INITIAL_COUNT = 3;

interface TPeetieProviderProps {
  tickDuration: number;
  preloadImage: (url: string) => Promise<void>;
  logError?: (message: string) => void;
}

interface TPeetieProviderState {
  activePeetie: TPeetie | null;
  activePeetieMovie: TPeetieMovie | null;
  isLoadingMovie: boolean;
  playedMovies: { [key: string]: number };
}

type TPeetieContext = TPeetieProviderState & {
  tickDuration: number;
  initialCount: number;
  launchPeetie: (peetie: TPeetie) => void;
};

const PeetieContext = React.createContext<TPeetieContext>({
  tickDuration: 0,
  initialCount: INITIAL_COUNT,
  activePeetie: null,
  activePeetieMovie: null,
  isLoadingMovie: false,
  playedMovies: {},
  launchPeetie: () => {},
});

export const usePeeties = () => {
  return useContext(PeetieContext);
};

export class PeetieProvider extends React.Component<TPeetieProviderProps, TPeetieProviderState> {
  private clickedPeetie: TPeetie | null = null;

  // We need an unique ID every time we click a peetie
  // otherwise when jumping from peetie1 > peetie2 > peetie1 within 3seconds,
  // it will fire straight away the third time (and not wait the for the countdown)
  private currentClickedId: number = 0;

  constructor(props: TPeetieProviderProps) {
    super(props);
    this.state = {
      activePeetie: null,
      activePeetieMovie: null,
      isLoadingMovie: false,
      playedMovies: {},
    };
  }

  startCountdown = () => {
    const { tickDuration } = this.props;
    // Wait at least 3 ticks > 3, 2, ... 1 before showing peetie animation
    // This gives users on slower connection devices the time to load
    // the animation (1MB > 7MB) (which should be loaded after 2sec max)
    return sleep(INITIAL_COUNT * tickDuration);
  };

  getPeetieMovie(peetie: TPeetie): TPeetieMovie | null {
    // We collect the active played per Peetie.
    const activePeetieId = peetie.id;
    let activeMovieKey =
      !this.state.playedMovies || !(activePeetieId in this.state.playedMovies)
        ? 0
        : this.state.playedMovies[activePeetieId] + 1;
    if (!peetie.movies[activeMovieKey]) {
      activeMovieKey = 0; // Reset when not exist.
    }
    let playedMovies = this.state.playedMovies;
    playedMovies[activePeetieId] = activeMovieKey;
    this.setState({ playedMovies });

    const movie = peetie.movies[activeMovieKey] ?? {};
    const { gifFrames, gifUrl, position } = movie;
    if (!(gifFrames && gifUrl && position)) {
      return null;
    }
    return movie;
  }

  launchPeetie = async (peetie: TPeetie) => {
    // ignore launching the same peetie as the one that's currently playing
    if (this.clickedPeetie === peetie) return;
    const movie = this.getPeetieMovie(peetie);
    // ensure the peetie is valid before doing anything
    if (!movie) {
      this.logError(`peetie ${peetie.id} has no valid movie`);
      return;
    }
    // track the clicked peetie
    this.clickedPeetie = peetie;
    const clickedId = (this.currentClickedId += 1);
    // and make it appear
    this.setState({
      activePeetie: peetie,
      activePeetieMovie: null,
      isLoadingMovie: true,
    });
    if (!supportsWebM) {
      try {
        await Promise.all([this.props.preloadImage(movie.gifUrl), this.startCountdown()]);
      } catch (e) {
        if (clickedId === this.currentClickedId) {
          this.setState({ isLoadingMovie: false });
        }
        this.logError(`failed to preload peetie: ${e instanceof Error ? e.message : '<unexpected error>'}`);
        return;
      }
    }
    // play it if it is still the currently clicked peetie
    this.playPeetieMovie(movie, clickedId);
  };

  async playPeetieMovie(movie: TPeetieMovie, clickedId: number) {
    if (this.currentClickedId !== clickedId) return;
    this.setState({
      isLoadingMovie: false,
      activePeetieMovie: movie,
    });

    const duration = (movie.gifFrames / 25) * 1000;

    // wait until the GIF is finished then clear the playing peetie
    await sleep(duration);
    if (this.currentClickedId !== clickedId) return;
    this.clickedPeetie = null;
    this.setState({
      activePeetie: null,

      // activePeetieMovie cannot be set to null,
      // otherwise peetiemovie would be hidden too soon iOS
      // the movie plays till the last frame, which is an empty frame
      // activePeetieMovie: null,
    });
  }

  render() {
    const { launchPeetie } = this;
    const { children, tickDuration } = this.props;
    return (
      <PeetieContext.Provider value={{ ...this.state, initialCount: INITIAL_COUNT, tickDuration, launchPeetie }}>
        {children}
      </PeetieContext.Provider>
    );
  }

  private logError(msg: string) {
    this.props.logError?.(msg);
  }
}
