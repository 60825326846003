import { gql } from '@apollo/client';

const query = `
mutation likeVideo($input: LikeVideoInput!)  {
  likeVideo(input: $input) {
    id
  }
}
`;

export const likeVideoMutation = gql(query);

export interface TLikeVideoArgs {
  input: TLikeVideoInput;
}

export interface TLikeVideoInput {
  id: string;
  gdpr: string;
}

export interface TLikeVideoResult {
  likeVideo: TLikeVideo | null;
}

export interface TLikeVideo {
  id: string;
}
