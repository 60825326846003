import React from 'react';
import styled from 'styled-components';
import { Overlay } from '../../components/Overlay/Overlay';
import { usePageTracking } from '../../hooks/usePageTracking';
import Services from '../../services/Services';

export interface TEmbedProps {
  title: string;
  url: string;
}

export const Embed: React.FC<TEmbedProps> = (props) => {
  const { title, url } = props;

  usePageTracking({
    id: 'embed',
    title: title,
  });

  const closeOverlay = Services.appService.goBack;

  return (
    <Overlay onClose={closeOverlay}>
      <StyledIframe title={title} src={url}>
        Wordt niet ondersteund door je browser
      </StyledIframe>
    </Overlay>
  );
};

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;
