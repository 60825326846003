import React, { useState, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { Text } from '../Text/Text';

const MARGIN_BOTTOM_SMALL = 16;
const MARGIN_BOTTOM_LARGE = 62;

export type TTitleProps = {
  text: string;
  backgroundColor: string;
  textColor: string;
  fullWidthEnabled?: boolean | null;
};

export const Title = (props: TTitleProps) => {
  const { text, backgroundColor, fullWidthEnabled = false, textColor } = props;
  const theme = useTheme();
  const [isMultiline, setMultiline] = useState<boolean>(true);
  const [marginBottom, setMarginBottom] = useState<number>(MARGIN_BOTTOM_LARGE);

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        let height = node.getBoundingClientRect().height;
        if (height > theme.components.title.singleLineHeight) {
          setMultiline(true);
          if (fullWidthEnabled) {
            setMarginBottom(MARGIN_BOTTOM_SMALL);
          }
        } else {
          setMultiline(false);
        }
      }
    },
    [theme, fullWidthEnabled]
  );

  return (
    <StyledWrap ref={measuredRef} marginBottom={marginBottom}>
      <StyledText backgroundColor={backgroundColor} content={text} isMultiline={isMultiline} textColor={textColor}>
        {text}
      </StyledText>
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ marginBottom: number }>`
  ${({ marginBottom }) => `
    width: 100%;
    max-width: 375px;
    margin-bottom: ${marginBottom}px;
  `}
`;

const StyledText = styled(Text)<{
  backgroundColor: string;
  content: string;
  isMultiline: boolean;
  textColor: string;
}>`
  ${({ backgroundColor, content, isMultiline, textColor }) => `
    text-transform: uppercase;
    background-color: ${backgroundColor};
    position: relative;
    display: inline-block;
    padding: 0;
    font-size: 44px;
    font-weight: 700;
    line-height: 44px;
    word-break: break-word;
    color: ${textColor};
    ${
      !isMultiline &&
      `
      min-height: 44px;
      &::before,
      &::after {
        content: "${content}";
        background-color: ${backgroundColor};
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 15px;
        z-index: 1;
        padding: 0;
        color: ${textColor};
      }
      &::after {
        top: 30px;
        z-index: 2;
      }
      `
    }
  `};
`;
