import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { PixelCorners } from '../PixelCorners/PixelCorners';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../Text/Text';
import { injectFontSize } from '../../theme/typography';
import { useLiveConfig } from '../../lib/useLiveConfig';

type TAvatarSize = 'small' | 'large';

type TProps = {
  backgroundColor?: EColor;
  initials?: string | null;
  imageUrl?: string | null;
  size?: TAvatarSize;
};

export const Avatar = ({ initials, imageUrl, size = 'large' }: TProps) => {
  const [loadedImage, setLoadedImage] = useState(false);
  const theme = useTheme();

  const liveConfig = useLiveConfig();

  const wrapSize = size === 'large' ? theme.components.avatar.large : theme.components.avatar.small;

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.addEventListener('load', () => {
        setLoadedImage(true);
      });
      img.src = imageUrl;
    }
  }, [imageUrl]);

  return (
    <StyledWrap imageUrl={imageUrl} wrapSize={wrapSize}>
      <PixelCorners size={size} color={liveConfig.foregroundColor} />
      {!loadedImage && <StyledLabel size={size}>{initials?.slice(0, 1) || ''}</StyledLabel>}
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ imageUrl?: string | null; wrapSize: number }>`
  ${({ theme, imageUrl, wrapSize }) => `
    width: ${wrapSize}px;
    height: ${wrapSize}px;
    background-color: ${theme.colors.green};
    background-image: url(${imageUrl});
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    ${mediaQuery.tabletLandscape(`
      width: calc(${wrapSize}px - ${theme.components.avatar.mobileReduction}px);
      height: calc(${wrapSize}px - ${theme.components.avatar.mobileReduction}px);
    `)}
  `}
`;

const StyledLabel = styled(Text).attrs({ bold: true, color: EColor.white })<{ size: TAvatarSize }>`
  ${({ size }) => `
    position: relative;
    top: 2px;
    text-transform: uppercase;

    font-size: ${size === 'large' ? 21 : 16}px;
    line-height: ${size === 'large' ? 21 : 16}px;
    ${mediaQuery.tabletLandscape(`
      font-size: 16px;
      line-height: 16px;
    `)}

    ${injectFontSize('medium')}
  `}
`;
