import React from 'react';
import { useLocation } from 'react-router-dom';
import Services from '../../services/Services';
import { PageWithData } from '../../components/PageWithData/PageWithData';
import { Search } from './Search';
import { TSearchResult } from './types';

export const SEARCH_KEYWORD_PARAM = 'zoekterm';

export const SearchPage = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const keyword = decodeURIComponent(query.get(SEARCH_KEYWORD_PARAM) ?? '');
  const id = `search-${keyword}`;
  const fetcher = async () => {
    const results = await Services.bffService.search({ keyword });
    return Services.contentService.mapSearchResult(results);
  };

  const SearchWrapper: React.FC<{ data: TSearchResult }> = ({ data }) => <Search result={data} term={keyword} />;

  return <PageWithData swrKey={id} fetcher={fetcher} component={SearchWrapper} />;
};
