import { IFetchClient, IFetchOptions } from './IFetchService';

class FetchClient implements IFetchClient {
  private baseUrl: string;

  constructor(baseUrl: string) {
    // remove trailing '/'
    this.baseUrl = baseUrl.replace(/\/+$/, '');
  }

  async fetch(options: IFetchOptions): Promise<Response> {
    const { path, method, headers = {}, data } = options;
    const url = this.constructUrl(path);
    const init: RequestInit = { method, headers };
    if (data) {
      init.body = JSON.stringify(data);
      headers['Content-Type'] = 'application/json';
    }
    const res = await fetch(url, init);
    return res;
  }

  async fetchJson<T = any>(options: IFetchOptions): Promise<T> {
    const res = await this.fetch(options);
    return (await res.json()) as T;
  }

  private constructUrl(path: string): string {
    // skip preceding '/'
    path = path.replace(/^\/+/, '');
    return `${this.baseUrl}/${path}`;
  }
}

export default FetchClient;
