import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { TPinboardItem } from '../../pages/ProgramPage/types';
import { EmojiReactionOverview, TEmojiReaction } from '../EmojiReactionOverview/EmojiReactionOverview';
import Services from '../../services/Services';
import useSession from '../../lib/useSession';
import { KetprofielModal } from '../KetprofielModal/KetprofielModal';
import { EMOJIS, TEmoji } from '../../lib/emoji';
import { ProgramContext } from '../../pages/ProgramPage/ProgramContext';
import { PinboardContext } from './PinboardContext';

export type TPinboardItemReactionsProps = {
  pinboardItem: TPinboardItem;
};

export const PinboardReactions = (props: TPinboardItemReactionsProps) => {
  const { pinboardItem } = props;

  const { myReactions, setMyReactions } = React.useContext(PinboardContext);

  const hasCtxReaction = myReactions.findIndex((contextItem) => contextItem['id'] === pinboardItem.id);
  const [myReaction, setMyReaction] = React.useState<TEmoji | null>(
    hasCtxReaction !== -1 ? myReactions[hasCtxReaction]['myReaction'] : pinboardItem.myReaction
  );

  const session = useSession();
  const [showLogin, setShowLogin] = useState(false);
  const { programTitle, programTabType } = useContext(ProgramContext);

  const handleEmojiClick = async (clickedReactionId: TEmoji) => {
    // Should be logged in
    if (!session) {
      setShowLogin(true);
      return;
    }
    const gdpr = `Reactie op Ketnet programma ${programTitle} - ${programTabType} op ${pinboardItem.postedOn}`;
    const reactionId = myReaction === clickedReactionId ? null : clickedReactionId;
    // Optimistic update
    setMyReaction(reactionId);
    const contextReaction = { id: pinboardItem.id, myReaction: reactionId };
    hasCtxReaction === -1
      ? (myReactions[myReactions.length] = contextReaction)
      : (myReactions[hasCtxReaction] = contextReaction);
    setMyReactions(myReactions);

    // Send to server
    try {
      await Services.bffService.setPinboardItemReaction({
        pinboardItemId: pinboardItem.id,
        reactionId: reactionId,
        gdpr,
      });
    } catch (e) {
      Services.loggerService.errorObject(`Failed to save reaction ${clickedReactionId} to post ${pinboardItem.id}`, e);
      Services.monitoringService.captureException(e);
    }
  };

  const reactions = useMemo(() => {
    return EMOJIS.map((emoji) => {
      const reaction = pinboardItem?.reactions?.find((reaction) => reaction.id === emoji);
      const count =
        (reaction?.count || 0) +
        (myReaction === emoji && pinboardItem?.myReaction !== emoji ? 1 : 0) +
        (hasCtxReaction !== -1 && myReaction !== emoji && pinboardItem?.myReaction === emoji ? -1 : 0);
      const emojiReaction: TEmojiReaction = {
        id: emoji,
        count,
      };
      return emojiReaction;
    });
  }, [pinboardItem, myReaction, hasCtxReaction]);

  return (
    <>
      <StyledWrap>
        <EmojiReactionOverview reactions={reactions} activeEmojiKind={myReaction} onPressReaction={handleEmojiClick} />
      </StyledWrap>
      {showLogin && <KetprofielModal onClose={() => setShowLogin(false)} />}
    </>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${theme.spacings.small}px;
  `}
`;
