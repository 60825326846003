import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { CloseIcon } from '../../assets/icons';
import { isIos, isMobile, isTablet } from '../../lib/features';
import { mediaQuery } from '../../theme/mediaQuery';
import { KeyPress } from '../KeyPress/KeyPress';
import { enableFullscreen, disableFullscreen, hasFullscreenElement } from '../../lib/fullscreen';
import { KetprofielModal } from '../KetprofielModal/KetprofielModal';
import { OverlayContext, TOverlayContextValue } from './OverlayContext';
import { EColor, styleguide } from '../../theme/styleguide';
import { Button } from '../Button/Button';
import Services from '../../services/Services';

export interface TOverlayProps {
  forceMobileOrientation?: TOrientation;
  onClose: () => any;
  hideCloseButton?: boolean;
  customPosition?: string;
  backgroundColor?: EColor;
  checkFullscreenFail?: boolean;
}

export type TOrientation = 'LANDSCAPE' | 'PORTRAIT';

export interface TOverlayState {
  showLogin: boolean;
  fullscreenFailMessage: 'start' | 'continue' | null;
}

export class Overlay extends React.Component<TOverlayProps, TOverlayState> {
  private originalBodyOverflow?: string;

  private originalBodyMarginTop?: string;

  private overlayRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  private overlayContext: TOverlayContextValue = {
    showLogin: () => this.setState({ showLogin: true }),
    enableFullscreen: () => this.enableFullscreen(true),
    disableFullscreen: () => disableFullscreen(),
  };

  constructor(props: TOverlayProps) {
    super(props);
    this.state = {
      showLogin: false,
      fullscreenFailMessage: null,
    };
  }

  private detachIosScrollBlock: (() => void) | null = null;

  private detachFullscreenListener: (() => void) | null = null;

  private isInitialEnableFullscreenCall = true;

  componentDidMount() {
    this.originalBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    // check for fullscreen failure on mount
    this.enableFullscreen(true);

    if (isIos) {
      const listener = (e: TouchEvent) => {
        // only block document scrolling when login modal is not visible
        if (!this.state.showLogin) {
          e.preventDefault();
        }
      };
      document.body.addEventListener('touchmove', listener, { passive: false });
      this.detachIosScrollBlock = () => {
        document.body.removeEventListener('touchmove', listener);
      };
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = this.originalBodyOverflow ?? '';
    document.body.style.marginTop = this.originalBodyMarginTop ?? '';
    disableFullscreen();
    this.detachIosScrollBlock?.();
    this.detachFullscreenListener?.();
  }

  render() {
    return ReactDOM.createPortal(this.renderOverlay(), document.body);
  }

  renderOverlay() {
    const { children, onClose, hideCloseButton, customPosition, backgroundColor } = this.props;
    return (
      <OverlayContext.Provider value={this.overlayContext}>
        <StyledWrap ref={this.overlayRef} backgroundColor={styleguide.colors[backgroundColor ?? EColor.darkBlue]}>
          <StyledContainer>
            {children}
            {!hideCloseButton && (
              <StyledCloseButton onClick={onClose} customPosition={customPosition ?? null}>
                <CloseIcon />
              </StyledCloseButton>
            )}
            <KeyPress keyName="Escape" onKeyPressed={onClose} />
          </StyledContainer>
          {this.overlayRef.current && (
            <KetprofielModal
              onClose={() => this.setState({ showLogin: false })}
              isOpen={this.state.showLogin}
              container={this.overlayRef.current}
            />
          )}
          {this.renderFullscreenFailed()}
        </StyledWrap>
      </OverlayContext.Provider>
    );
  }

  // In some usecases, enabling fullscreen requires a user gesture. When this is detected,
  // render an overlay with a button to initiate FS with a user click event.
  // e.g. Lock=>Unlock screen, refreshing a game page or arriving on a game page via google
  // https://atlas.vrt.be/jira/browse/KETNET-3712
  renderFullscreenFailed() {
    const { fullscreenFailMessage } = this.state;
    if (fullscreenFailMessage === null) return null;

    let btnText: string;
    switch (fullscreenFailMessage) {
      case 'continue':
        btnText = 'verder spelen';
        break;
      case 'start':
        btnText = 'start';
        break;
      default:
        Services.loggerService.error(`Unmapped fullscreenFailMessage: ${fullscreenFailMessage}`);
        return null;
    }

    return (
      <StyledFullscreenFailed>
        <Button onClick={this.handleContinueInFullscreenButtonClick} text={btnText} />
      </StyledFullscreenFailed>
    );
  }

  private handleContinueInFullscreenButtonClick = () => {
    // don't recheck  fullscreen failure to avoid being stuck
    this.enableFullscreen(false);
    // unset the fullscreenFailure explicitly
    this.setState({ fullscreenFailMessage: null });
  };

  private async enableFullscreen(checkSuccess: boolean) {
    const overlay = this.overlayRef.current;
    let success = false;
    if (overlay) {
      if (isMobile) {
        success = await enableFullscreen(overlay, { orientation: this.props.forceMobileOrientation });
      } else if (isTablet) {
        success = await enableFullscreen(overlay);
      } else {
        // we don't want non-mobile to go to fullscreen when using the overlay
        return;
      }
    }
    // check if fullscreen operation failed due to no user interaction, to signal showing
    // a button to properly enable fullscreen
    if (checkSuccess && this.props.checkFullscreenFail && !success) {
      // ios only allows fullscreen in PWA mode
      if (isIos) {
        return;
      }
      this.setState({ fullscreenFailMessage: this.isInitialEnableFullscreenCall ? 'start' : 'continue' });
    }

    // start listening for FS changes on the initial enableFullscreen call
    if (this.isInitialEnableFullscreenCall) {
      this.listenForFullscreenChange();
      this.isInitialEnableFullscreenCall = false;
    }
  }

  private listenForFullscreenChange() {
    // listen for FS changes to re-enable fullscreen
    const listener = () => {
      if (!hasFullscreenElement()) {
        this.enableFullscreen(true);
      }
    };
    window.addEventListener('fullscreenchange', listener);
    this.detachFullscreenListener = () => window.removeEventListener('fullscreenchange', listener);
  }
}

const StyledWrap = styled.div<{ backgroundColor: string }>`
  ${({ backgroundColor }) => `
  z-index: 990;/* must be below "react-responsive-modal-overlay" */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  ${
    // iPad and iPhone safari and chrome don't like 100vh.
    // The browser toolbar will go over your content
    // See https://www.xspdf.com/resolution/54465717.html
    isIos ? 'height: 100%;' : 'height: 100vh;'
  }
  overflow: hidden;
  background-color: ${backgroundColor};
  display: flex;
  justify-content: center;
`}
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overflow-y: ${isMobile ? 'scroll' : 'none'};
`;

const StyledCloseButton = styled.div<{ customPosition: string | null }>`
  z-index: 991;
  width: 64px;
  height: 64px;
  cursor: pointer;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  ${({ customPosition }) =>
    customPosition
      ? customPosition
      : `
    right: 25px;
    top: 25px;
    ${mediaQuery.desktop(`
      right: 50px;
      top: 50px;
    `)}
  `}
`;

const StyledFullscreenFailed = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > * {
    margin-bottom: 10px;
  }
`;
