import React, { useCallback, useContext, useMemo, useState } from 'react';

export type TProfileContextValue = {
  profileActionUrl: string | null;
  showProfileAction: (actionUrl: string) => void;
  hideProfileAction: () => void;
};

export const ProfileContext = React.createContext<TProfileContextValue>({
  profileActionUrl: null,
  showProfileAction: (actionUrl: string) => {},
  hideProfileAction: () => {},
});

export const ProfileProvider: React.FC = ({ children }) => {
  const [profileActionUrl, setProfileActionUrl] = useState<string | null>(null);

  const showProfileAction = useCallback((profileActionUrl: string) => {
    setProfileActionUrl(profileActionUrl);
  }, []);

  const hideProfileAction = useCallback(() => {
    setProfileActionUrl(null);
  }, []);

  const value = useMemo(() => {
    return {
      profileActionUrl,
      showProfileAction,
      hideProfileAction,
    };
  }, [profileActionUrl, showProfileAction, hideProfileAction]);

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => {
  return useContext(ProfileContext);
};
