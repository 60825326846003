import { TPostComment, TPaginatedPost, TPinboardRow } from '../../pages/ProgramPage/types';
import Services from '../../services/Services';

export async function loadSpecificPosts(
  feedId: string,
  after: string | null,
  characterIdFilter: string | null
): Promise<{ posts: TPaginatedPost[]; next: string | null; totalCount: number }> {
  try {
    const res = await Services.bffService.getContentfeedPosts({
      feedId,
      feedPostCommentPageSize: 3,
      feedPostPageSize: Services.configService.config.postsPerPage,
      after: after || '',
      characterIdFilter: characterIdFilter || '',
    });

    if (res.contentfeedPosts.edges) {
      return {
        posts: Services.contentService.mapContentfeedPosts(res.contentfeedPosts),
        next: res.contentfeedPosts.next,
        totalCount: res.contentfeedPosts.totalCount,
      };
    }
  } catch (e) {
    Services.loggerService.errorObject('loadPosts failed', e);
  }

  return { posts: [], next: null, totalCount: 0 };
}

export async function loadNextPostComments(
  postId: string,
  after: string
): Promise<{ postsComments: TPostComment[]; next: string | null }> {
  try {
    const res = await Services.bffService.getContentfeedPostComments({
      postId,
      feedPostCommentPageSize: 5,
      after,
    });

    if (res.contentfeedPostComments.edges) {
      return {
        postsComments: Services.contentService.mapContentfeedPostComments(res.contentfeedPostComments),
        next: res.contentfeedPostComments.next,
      };
    }
  } catch (e) {
    Services.loggerService.errorObject('loadPostComments failed', e);
  }
  return { postsComments: [], next: null };
}

export async function loadSpecificPinboardRows(
  pinboardId: string,
  after: string | null
): Promise<{ rows: TPinboardRow[]; next: string | null; totalCount: number }> {
  try {
    const res = await Services.bffService.getPaginatedPinboardRows({
      pinboardId,
      pageSize: 2, // Services.configService.config.postsPerPage,
      after: after || '',
    });

    if (res.paginatedPinboardRows.edges) {
      return {
        rows: Services.contentService.mapPaginatedPinboardRows(res.paginatedPinboardRows),
        next: res.paginatedPinboardRows.next,
        totalCount: res.paginatedPinboardRows.totalCount,
      };
    }
  } catch (e) {
    Services.loggerService.errorObject('loadSpecific pinboardrows failed', e);
  }

  return { rows: [], next: null, totalCount: 0 };
}
