export function pathToTitle(path: string): string {
  // all pages are "ketnet" at their root, incl homepage
  return (
    ('ketnet' + path)
      .split('/')
      // trim leading/trailing whitespace
      .map((part) => part.trim())
      // dash to space
      .map((part) => part.replace(/-+/g, ' '))
      // filter out empty parts
      .filter((part) => part.length > 0)
      // reverse the order, as the last part is the most specific, thus relevant in terms of content
      .reverse()
      // first char uppercase of each part
      .map((part) => part.substr(0, 1).toUpperCase() + part.substr(1))
      .join(' - ')
  );
}
