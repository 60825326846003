const name = 'ctaPostFragment';

const query = `
  fragment ${name} on CtaPost {
    id
    title
    description
    ctaLinkItem {
      ... on Game {
        id
        type
      }
      ... on Program {
        id
        type
      }
      ... on Story {
        id
        type
      }
      ... on Theme {
        id
        type
      }
      ... on Video {
        id
        type
      }
    }
    ctaText
    ctaAlt
    type
  }
`;

export const ctaPostFragment = { name, query };

export interface TctaPost {
  id: string;
  title: string;
  description: string;
  ctaLinkItem: TctaLinkItem;
  ctaText: string;
  ctaAlt: string;
  type: 'ctaPost';
}

export interface TctaLinkItem {
  id: string;
  type: string;
}
