import React from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { PixelBorder } from '../PixelBorder/PixelBorder';

interface TCarouselItemProps {
  image: string;
  isActive: boolean;
}

const CarouselItem: React.FC<TCarouselItemProps> = (props) => {
  const { image, isActive } = props;

  return (
    <>
      {isActive && (
        <StyledCarouselItemWrapper>
          <PixelBorder color={EColor.red} size="large" />
          <StyledCarouselItemImage src={image} isActive={isActive} />
        </StyledCarouselItemWrapper>
      )}
    </>
  );
};

const StyledCarouselItemWrapper = styled.div`
  ${({ theme }) => `
    border 1px solid ${theme.colors.red};
    overflow: hidden;
    &::before,
    &::after {
      position: absolute;
      z-index: 1;
      left: 0px;
      display: flex;
      content: ' ';
      height: calc(100% - 1px);
      width: 15%;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.9) -8.25%, rgba(0, 0, 0, 0) 100%);
    };
    &::after {
      left: unset;
      right: 0px;
      top: 0px;
      background: linear-gradient(to left, rgba(0, 0, 0, 0.9) -8.25%, rgba(0, 0, 0, 0) 100%);
    };
  `}
`;

const StyledCarouselItemImage = styled.img<{ isActive: boolean }>`
  max-height: 333px;
  max-width: 100%;
  width: auto;
  object-fit: contain;
`;

export default CarouselItem;
