import { gql } from '@apollo/client';
import { TPostConnection } from './Program';
import { includeDependencies } from './fragment';
import { postFragment } from './Post';
import { ctaPostFragment } from './ctaPost';
import { scaledImageFragment } from './ScaledImage';
import { vrtPlayerFragment } from './VrtPlayer';
import { postCommentFragment } from './PostComment';

const dependencies = includeDependencies([
  postFragment,
  ctaPostFragment,
  vrtPlayerFragment,
  scaledImageFragment,
  postCommentFragment,
]);

const query = `
query getContentfeedPosts($feedId: String!, $feedPostPageSize: Int!, $after: String!, $feedPostCommentPageSize: Int!, $characterIdFilter: String!) {
  contentfeedPosts(
    feedId: $feedId
    first: $feedPostPageSize
    after: $after
    characterIdFilter: $characterIdFilter
  ) {
    edges {
      node {
        ...${postFragment.name}
        ...${ctaPostFragment.name}
      }
      cursor
    }
    totalCount
    next
  }
}

${dependencies}`;

export const getContentfeedPostsQuery = gql`
  ${query}
`;

export interface TGetContentfeedPostsArgs {
  feedId: string;
  feedPostPageSize: number;
  after: string;
  feedPostCommentPageSize: number;
  characterIdFilter: string;
}
export interface TGetContentfeedPostsResult {
  contentfeedPosts: TPostConnection;
}
