import React, { useState } from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../assets/icons';
import { InfoIcon } from '../../assets/icons';
import { IconButton } from '../../components/IconButton/IconButton';
import { Text } from '../Text/Text';

export type TCookieConsentBoxProps = {};

const LS_COOKIE_CONSENT_BOX_CLOSED = 'cookie_consent_box_closed';
const COOKIES_LINK = 'https://www.ketnet.be/privacy/cookies';

function hasClosedCookieConsentBox(): boolean {
  const closed = localStorage.getItem(LS_COOKIE_CONSENT_BOX_CLOSED);
  return !!closed;
}

function markCookieConsentBoxAsClosed(): void {
  localStorage.setItem(LS_COOKIE_CONSENT_BOX_CLOSED, JSON.stringify(true));
}

export const CookieConsentBox = (props: TCookieConsentBoxProps) => {
  const [showCookieConsentbox, setShowCookieConsentBox] = useState<boolean>(!hasClosedCookieConsentBox());

  const handleClose = () => {
    setShowCookieConsentBox(false);
    markCookieConsentBoxAsClosed();
  };

  return (
    <>
      {showCookieConsentbox && (
        <StyledWrap>
          <InfoIcon />
          <StyledContent>
            De Ketnet-website gebruikt cookies om de site nog leuker te maken. Nee, dat zijn geen koekjes om op te eten!
            <StyledLink href={COOKIES_LINK} target="_blank" rel="noopener noreferrer">
              Klik en ontdek wat cookies precies zijn.
            </StyledLink>
          </StyledContent>
          <IconButton icon={<CloseIcon />} onClick={handleClose} />
        </StyledWrap>
      )}
    </>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    position: fixed;
    z-index: 6;
    width: 100%;
    max-width: 400px;
    bottom: 10px;
    left: 10px;
    display: flex;
    flex-direction: row;
    padding: ${theme.spacings.medium}px;
    background: ${theme.colors.blue};
  `}
`;

const StyledContent = styled(Text)`
  margin: 0 ${({ theme }) => theme.spacings.medium}px;
  width: 85%;
`;

const StyledLink = styled.a`
  ${({ theme }) => `
    color: ${theme.colors.white};
    text-decoration: underline;
    margin: ${theme.spacings.small}px;
  `}
`;
