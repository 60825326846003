import React from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { TPinboardItem } from '../../pages/ProgramPage/types';
import { Modal } from '../Modal/Modal';
import { CloseIcon } from '../../assets/icons';
import { mediaQuery } from '../../theme/mediaQuery';
import { PinboardItem } from './PinboardItem';
import { PinboardReactions } from './PinboardReactions';

export interface TPinboardOverlayProps {
  isOpen?: boolean;
  onClose: () => any;
  item: TPinboardItem;
}

export const PinboardOverlay: React.FC<TPinboardOverlayProps> = (props) => {
  const { isOpen, onClose, item } = props;

  const closeButton = (
    <StyledCloseButton>
      <CloseIcon role="img" aria-label="Ga terug naar het prikbord" />
    </StyledCloseButton>
  );

  if (item) {
    return (
      <Modal isOpen={isOpen ?? true} onClose={onClose} color={EColor.transparent} closeIcon={closeButton}>
        <StyledWrap>
          <StyledMainContent>
            <PinboardItem item={item} inOverlay={true} />
            <PinboardReactions pinboardItem={item} />
          </StyledMainContent>
        </StyledWrap>
      </Modal>
    );
  }

  return null;
};

const StyledWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledMainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 75vw;
  ${mediaQuery.mobile(`
    width: 90vw;
  `)}
`;

const StyledCloseButton = styled.div`
  z-index: 991;
  width: 64px;
  height: 64px;
  cursor: pointer;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: 25px;
  top: 25px;
  ${mediaQuery.desktop(`
    right: 50px;
    top: 50px;
  `)}
`;
