import BaseService from '../BaseService';
import { IConfigService, IConfig, Environment } from './IConfigService';

import development from './config-development.json';
import production from './config-production.json';
import staging from './config-staging.json';

const EMPTY_CONFIG: IConfig = {
  bffBaseUrl: '',
  enableGemius: false,
  ketnetProfileUrl: '',
  ketnetProfileCookieKey: '',
  vrtPlayerUrl: '',
  postsPerPage: 0,
  mediaServicesUrl: '',
  mediaServicesClientId: '',
  aggregatorUrl: '',
};

class ConfigService extends BaseService implements IConfigService {
  public get config(): IConfig {
    const config: IConfig = (() => {
      switch (this.environment) {
        case 'development':
          return development;
        case 'staging':
          return staging;
        case 'production':
          return production;
        default:
          throw this.createError(`Invalid environment: ${this.environment}`);
      }
    })();
    this.validateConfig(config);
    return config;
  }

  private validateConfig(config: IConfig) {
    const props: (keyof IConfig)[] = Object.keys(EMPTY_CONFIG) as any;

    const invalid = props.filter((prop) => {
      if (!config.hasOwnProperty(prop)) {
        this.logger.error(`Missing config property ${prop}`);
        return true;
      }
      const value = config[prop];
      if (typeof value !== typeof EMPTY_CONFIG[prop]) {
        this.logger.error(`Invalid type for config property ${prop}`);
        return true;
      }
      // if (value !== undefined && value !== null) return true;
      return false;
    });

    if (invalid.length > 0) {
      console.log(config);
      throw this.createError(`Invalid or missing config properties`);
    }
  }

  public get environment(): Environment {
    const { origin } = window.location;
    if (origin === 'wwwl.ketnet.be:5000') {
      return 'development';
    }
    if (/((.)+-)?stag\.ketnet\.be$/.test(origin)) {
      return 'staging';
    }
    if (/(.)+?ketnet\.be$/.test(origin)) {
      return 'production';
    }
    return 'development';
  }
}

export default ConfigService;
