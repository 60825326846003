import React from 'react';
import { TPagecontentTab } from './types';
import styled from 'styled-components';
import { PagecontentItem } from '../../components/PagecontentItem/PagecontentItem';

export interface TPagecontentTabProps {
  tab: TPagecontentTab;
}

export const PagecontentTab: React.FC<TPagecontentTabProps> = (props) => {
  const items = props.tab.pagecontent;

  return (
    <StyledWrap>
      {items.map((item, idx) => (
        <PagecontentItem key={idx} item={item} />
      ))}
    </StyledWrap>
  );
};

const StyledWrap = styled.div``;
