import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from './search-magnifier.svg';
import { ReactComponent as ClearIcon } from './search-clear.svg';
import { useForm, TValidationErrors } from '../../hooks/useForm';
import { mediaQuery } from '../../theme/mediaQuery';
import { SEARCH_KEYWORD_PARAM } from '../../pages/SearchPage/SearchPage';
import { useHistory } from 'react-router-dom';
import Services from '../../services/Services';
import { injectFontSize } from '../../theme/typography';

export type TSearchFormProps = {};

export const SearchForm = (props: TSearchFormProps) => {
  const history = useHistory();
  const inputRef = React.createRef<HTMLInputElement>();
  const [isClearButtonVisible, setIsClearButtonVisible] = useState<boolean>(false);

  const { formValues, setFormValue, getFormValue, validateForm, clearValidationError } = useForm({
    defaultValues: {},
    calculateValidation: (formValues) => {
      const trimmedSearchterm = (formValues[SEARCH_KEYWORD_PARAM] ?? '').trim();
      const errors: TValidationErrors = {};
      if (trimmedSearchterm === '') errors[SEARCH_KEYWORD_PARAM] = 'Graag je zoekterm opgeven';
      return errors;
    },
  });

  const handleSearch = useCallback(async () => {
    try {
      const isValid = validateForm();
      if (!isValid) {
        return;
      }
      let searchKeyword = formValues[SEARCH_KEYWORD_PARAM];
      inputRef.current?.blur();
      history.push(`/zoeken?${SEARCH_KEYWORD_PARAM}=${encodeURIComponent(searchKeyword ?? '')}`);
    } catch (e) {
      Services.loggerService.error(`failed to do searchrequest: ${e}`);
      Services.monitoringService.captureException(e);
    }
  }, [validateForm, formValues, history, inputRef]);

  const handleClearSearchTerm = useCallback(async () => {
    setFormValue(SEARCH_KEYWORD_PARAM, '');
    setIsClearButtonVisible(false);
  }, [setFormValue]);

  const handleFormElement = useCallback(
    (searchTermValue: string) => {
      setFormValue(SEARCH_KEYWORD_PARAM, searchTermValue);
      setIsClearButtonVisible(searchTermValue.length ? true : false);
    },
    [setFormValue]
  );

  return (
    <StyledForm
      data-cyid="SearchForm"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSearch();
      }}
    >
      <StyledFormElement>
        <StyledLabel htmlFor="search-searchterm">zoeken</StyledLabel>
        <StyledFormButton onClick={handleSearch}>
          <SearchIcon />
        </StyledFormButton>
        {isClearButtonVisible && (
          <StyledClearButton onClick={handleClearSearchTerm}>
            <ClearIcon />
          </StyledClearButton>
        )}
        <StyledInput
          ref={inputRef}
          id={SEARCH_KEYWORD_PARAM}
          type="text"
          placeholder="zoek op ketnet"
          value={getFormValue(SEARCH_KEYWORD_PARAM) || ''}
          onChange={(e) => handleFormElement(e.currentTarget.value)}
          onFocus={() => clearValidationError(SEARCH_KEYWORD_PARAM)}
        />
      </StyledFormElement>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  position: relative;
  top: -10px;
  width: 80%;
  &::after {
    position: absolute;
    top: -37px;
    right: -57px;
    content: '';
    display: block;
    background-image: url('/assets/backgrounds/search-input.svg');
    width: 142px;
    height: 142px;
  }
  ${mediaQuery.tabletLandscape(`
    width: 90%;
    top: 0px;
    margin: auto;
    &::after {
      display: none;
    }
  `)}
`;

const StyledFormElement = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 1;
  align-items: center;
  &::before {
    display: block;
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 10px;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.white};
  }

  ${mediaQuery.tabletPortrait(`
    height: 36px;
    &::before {
      top: 6px;
      left: 6px;
    }
  `)}
`;

const StyledLabel = styled.label`
  display: none;
`;

const StyledInput = styled.input`
  ${({ theme }) => `
    width: 100%;
    ::placeholder {
      color: ${theme.colors.black};
    }
    :focus {
      outline: none;
    }
    padding: 0px 5px;
    border: 0px;
    background: ${theme.colors.transparent};
  `}
  ${injectFontSize('regular')}
  font-weight: 600;
`;

const StyledFormButton = styled.button`
  cursor: pointer;
  border-radius: 100%;
  padding: 5px;
  width: 48px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border: 0px;
`;

const StyledClearButton = styled.button`
  ${({ theme }) => `
    cursor: pointer;
    background: ${theme.colors.white};
    border: 0px;
    position: absolute;
    right: ${theme.spacings.small}px;
  `}
`;
