import React, { useEffect } from 'react';
import { useKeyPress } from '../../hooks/useKeyPress';

export interface TKeyPressProps {
  keyName: string;
  onKeyPressed: () => any;
}

export const KeyPress: React.FC<TKeyPressProps> = (props) => {
  const { keyName, onKeyPressed } = props;
  const pressed = useKeyPress(keyName);
  useEffect(() => {
    if (pressed) {
      onKeyPressed();
    }
  }, [pressed, onKeyPressed]);
  return null;
};
