import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Services from '../../services/Services';
import useSession from '../../lib/useSession';
import { TPostRoot, TPostComment } from '../../pages/ProgramPage/types';
import { TAddPostCommentData } from '../../services/BffService/addPostComment.mutation';
import { Avatar } from '../Avatar/Avatar';
import { ProgramContext } from '../../pages/ProgramPage/ProgramContext';
import { buildFakeComment } from '../../services/CommentService/CommentService';
import { mediaQuery } from '../../theme/mediaQuery';

export type TPostCommentInputProps = {
  post: TPostRoot;
  /**
   * Triggered when the message has been sent to the server.
   * If the message failed to be delivered (within reasonable time)
   * onSent will still be triggered (graceful degradation), but an
   * error will be logged.
   * @param input
   */
  onSent: (comment: TPostComment) => void;
};

export const PostCommentInput = (props: TPostCommentInputProps) => {
  const { post, onSent } = props;
  const session = useSession();
  const inputRef = React.createRef<HTMLInputElement>();
  const { programTitle, programTabType } = useContext(ProgramContext);
  const [inputValue, setInputValue] = useState('');
  const [isPostingComment, setIsPostingComment] = useState(false);
  const [commentPostError, setCommentPostError] = useState<false | string>(false);

  const handlePostComment = async () => {
    if (!isPostingComment) {
      setIsPostingComment(true);
      setCommentPostError(false);
      if (!session) {
        Services.loggerService.error(`Invoked PostCommentInput.handleSend without session`);
        return;
      }
      if (!inputRef.current) {
        return;
      }

      const postId = post.id;
      const message = inputRef.current.value.trim();
      if (message === '') {
        return;
      }
      const gdpr = `Commentaar op Ketnet programma ${programTitle} - ${programTabType} op ${post.postedOn}`;
      const data: TAddPostCommentData = {
        postId,
        message,
        gdpr,
      };

      let comment: TPostComment | null = null;

      try {
        comment = await Services.bffService.addPostComment(data);
        onSent(comment || buildFakeComment(postId, message, session));
      } catch (error) {
        const parsedError = JSON.parse(JSON.stringify(error));
        const graphQLErrorCode = parsedError?.graphQLErrors[0].extensions.code;
        if (graphQLErrorCode) {
          switch (graphQLErrorCode) {
            case 'USER_BLOCKED':
              setCommentPostError('Oeps, met dit Ketprofiel kan je geen berichten meer plaatsen.');
              break;
            case 'INAPPROPRIATE_LANGUAGE':
              setCommentPostError('Oeps, sommige woorden die je gebruikt zijn niet toegelaten.');
              break;
          }
        }
        Services.loggerService.errorObject(`Failed to send message`, { data, error });
        Services.monitoringService.captureException(error);
      } finally {
        // clear the text after successfully handling send
        setInputValue('');
        setIsPostingComment(false);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handlePostComment();
    }
    if (inputValue === '') {
      setIsPostingComment(false);
    }
  };

  return (
    <StyledFormWrapper>
      <StyledForm>
        <StyledAvatarWrapper>
          <Avatar size="small" imageUrl={session?.picture} initials={session?.firstName} />
        </StyledAvatarWrapper>
        <StyledFormElement>
          <StyledLabel>jouw reactie</StyledLabel>
          <StyledInput
            type="text"
            placeholder="schrijf een reactie..."
            ref={inputRef}
            onKeyDown={handleKeyPress}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          {!isPostingComment && <StyledFormButton onClick={handlePostComment}>plaats</StyledFormButton>}
          {isPostingComment && <StyledFormButtonDisabled>plaats</StyledFormButtonDisabled>}
        </StyledFormElement>
      </StyledForm>
      {commentPostError && <StyledErrorMessage>{commentPostError}</StyledErrorMessage>}
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
`;

const StyledAvatarWrapper = styled.div`
  ${({ theme }) => `
    width: ${theme.components.avatar.small}px;
    ${mediaQuery.tabletLandscape(`
      width: calc(${theme.components.avatar.small}px - ${theme.components.avatar.mobileReduction}px);
    `)}
  `}
`;

const StyledFormElement = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    background-color: ${theme.colors.white};
    flex-grow: 1;
    margin-left: ${theme.spacings.small}px;
  `}
`;

const StyledLabel = styled.label`
  display: none;
`;

const StyledInput = styled.input`
  ${({ theme }) => `
    flex-grow: 1;
    border: 0px;
    font-size: 16px;
    padding: ${theme.spacings.small}px;
  `}
`;

const StyledFormButton = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.transparent};
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    padding: ${theme.spacings.small}px;
  `}
`;

const StyledFormButtonDisabled = styled(StyledFormButton)`
  color: #ccc;
`;

const StyledErrorMessage = styled.div`
  ${({ theme }) => `
    display: flex;
    place-content: center;
    color: ${theme.colors.white};
    padding: ${theme.spacings.small}px;
  `}
`;
