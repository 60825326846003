import React from 'react';
import styled from 'styled-components';
import { Region } from '../../components/Region/Region';
import { EColor } from '../../theme/styleguide';
import { usePageTracking } from '../../hooks/usePageTracking';
import { TSearchResult } from './types';
import { PagecontentItem } from '../../components/PagecontentItem/PagecontentItem';
import { SearchResults } from './SearchResults';
import { SearchNoResults } from './SearchNoResults';
import { JuniorAppPromo } from './JuniorAppPromo';
import { Text } from '../../components/Text/Text';
import { useLiveConfig } from '../../lib/useLiveConfig';
import { useTheme } from 'styled-components';
export interface TSearchProps {
  term: string;
  result: TSearchResult;
}

export const Search: React.FC<TSearchProps> = (props) => {
  const { term, result } = props;
  const totalResults = result.programs.length + result.jrPrograms.length + result.episodes.length + result.games.length;
  const liveConfig = useLiveConfig();
  const theme = useTheme();

  usePageTracking({
    id: 'search',
    title: 'Zoekresultaten voor ',
    site: {
      section1: 'zoeken',
    },
    search: {
      term: term,
      results: totalResults,
    },
  });

  return (
    <>
      <Region color={theme.colors.transparent}>
        <StyledWrap>
          <Text tag="h1" ariaVisible={true}>
            Zoekresultaten voor "${term}"
          </Text>
          <SearchResults title="Gevonden programma's" data={result.programs} />
          <SearchResults title="Gevonden filmpjes" data={result.episodes} />
          <SearchResults title="Gevonden spelletjes en wedstrijden" data={result.games} />
          <SearchResults
            title="Gevonden Junior programma's"
            subTitle="Deze programma's zijn verhuisd naar de VRT Max website"
            data={result.jrPrograms}
          />
          {result.jrPrograms.length !== 0 && <JuniorAppPromo />}
          {totalResults === 0 && <SearchNoResults term={term} />}
        </StyledWrap>
      </Region>
      {result.pagecontent && (
        <StyledAdditionalContent backgroundColor={liveConfig.foregroundColor}>
          {result.pagecontent.map((item, idx) => (
            <PagecontentItem key={idx} item={item} />
          ))}
        </StyledAdditionalContent>
      )}
    </>
  );
};

const StyledWrap = styled.div``;

const StyledAdditionalContent = styled.div<{ backgroundColor: EColor }>`
  ${({ theme, backgroundColor }) => `
    display: flex;
    background-color: ${theme.colors[backgroundColor]};
    margin: ${theme.spacings.extralarge}px 0;
  `};
`;
