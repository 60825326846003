import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as EmojiSadSVG } from './emoji-sad.svg';
import { ReactComponent as EmojiCrazySVG } from './emoji-crazy.svg';
import { ReactComponent as EmojiWowSVG } from './emoji-wow.svg';
import { ReactComponent as EmojiLoveSVG } from './emoji-love.svg';
import { ReactComponent as EmojiShitSVG } from './emoji-shit.svg';
import { TEmoji } from '../../lib/emoji';
import { TStoryReactionName } from '../StoryViewer/types';

type TProps = {
  kind: TEmoji | TStoryReactionName;
  size?: 'small' | 'large';
  className?: string;
};

export const Emoji: React.FC<TProps> = (props) => {
  const { kind = '😍', size = 'small', className } = props;
  const width = useMemo(() => {
    if (size === 'large') return 32;
    if (size === 'small') return 28;
    return 0;
  }, [size]);

  const emoji = useMemo(() => {
    switch (kind) {
      case '😢':
      case 'sad':
        return <EmojiSadSVG width={width} height={width} />;
      case '😜':
      case 'crazy':
        return <EmojiCrazySVG width={width} height={width} />;
      case '💩':
      case 'shit':
        return <EmojiShitSVG width={width} height={width} />;
      case '😍':
      case 'love':
        return <EmojiLoveSVG width={width} height={width} />;
      case '😲':
      case 'shock':
        return <EmojiWowSVG width={width} height={width} />;
    }
  }, [kind, width]);

  return (
    <StyledWrap height={width} className={className}>
      {emoji}
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ height: number }>`
  overflow: hidden;
  height: ${({ height }) => height}px;
`;
