import { TPageTrackingProps } from '../../hooks/usePageTracking';
import { TPageMetadata } from './TAnalyticsService';
import { TProgramPage } from '../../pages/AemPage/types';

export function getProgramPageMetadata(page: TProgramPage): TPageMetadata {
  const { header, id } = page;
  const title = header.title ?? `program ${id}`;
  const program: TPageTrackingProps['program'] = {
    name: title,
    whatsonId: undefined, // TODO: get whatsonId from AEM
  };
  const site: TPageTrackingProps['site'] = {
    section1: 'kijken',
    section2: title,
  };
  return {
    id,
    title,
    site,
    program,
  };
}
