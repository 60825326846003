import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLiveConfig } from '../../lib/useLiveConfig';
import { EColor } from '../../theme/styleguide';
import { TBackgroundTypes } from '../PagecontentItem/types';
import {
  Waves,
  ArrowsUp,
  ArrowsDown,
  ArrowsRight,
  MainHeaderLeft,
  MainHeaderLeftWinter,
  MainHeaderRightWinter,
  Top,
  Bottom,
  FooterLeft,
  FooterRight,
  FooterLeftWinter,
  FooterRightWinter,
  StoriesWinter,
  Stories,
} from '../../assets/backgrounds';

interface TBackgroundProps {
  type: TBackgroundTypes;
  backgroundColor?: EColor;
  foregroundColor?: EColor;
  className?: string;
}

export const Background: React.FC<TBackgroundProps> = (props) => {
  const liveConfig = useLiveConfig();
  const {
    type,
    backgroundColor = liveConfig.backgroundColor,
    foregroundColor = liveConfig.foregroundColor,
    className,
  } = props;

  const activeBackground = useMemo(() => {
    if (liveConfig.activeTemplate === 'winter') {
      switch (type) {
        case 'main-header-left':
          return <MainHeaderLeftWinter />;
        case 'main-header-right':
          return <MainHeaderRightWinter />;
        case 'stories':
          return <StoriesWinter />;
        case 'main-footer-left':
          return <FooterLeftWinter />;
        case 'main-footer-right':
          return <FooterRightWinter />;
      }
    }

    switch (type) {
      case 'waves':
        return <Waves />; // used in playlistTab
      case 'arrows-right':
        return <ArrowsRight />; // used in Program
      case 'stories':
        return <Stories />;
      case 'arrows-up':
        return <ArrowsUp />; // used in ContentService as bg
      case 'arrows-down':
        return <ArrowsDown />; // used in ContentService as bg
      case 'main-header-left':
        return <MainHeaderLeft />;
      case 'top':
        return <Top />; // used in the Post
      case 'bottom':
        return <Bottom />; // used in the Post
      case 'main-footer-left':
        return <FooterLeft />;
      case 'main-footer-right':
        return <FooterRight />;
    }
  }, [type, liveConfig.activeTemplate]);

  return (
    <StyledWrap backgroundColor={backgroundColor} foregroundColor={foregroundColor} className={className}>
      {activeBackground}
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ backgroundColor: EColor; foregroundColor: EColor }>`
  ${({ theme, backgroundColor, foregroundColor }) => `
    & svg {
      display: flex;
      width: 100%;
      height: 100%;
    }
    & svg.scaled {
      transform: scale(0.7);
    }
    & path.background {
      fill: ${theme.colors[backgroundColor]};
    }
    & path.foreground {
      fill: ${theme.colors[foregroundColor]};
    }
    & path.stroke-foreground {
      stroke: ${theme.colors[foregroundColor]};
    }
  `}
`;
