import React, { useState } from 'react';
import styled from 'styled-components';
import { SearchForm } from '../SearchForm/SearchForm';
import { LoginIcon } from '../../assets/icons';
import { mediaQuery } from '../../theme/mediaQuery';
import { BackIcon } from '../../assets/icons';
import { useLocation } from 'react-router-dom';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import useSession from '../../lib/useSession';
import { KetprofielModal } from '../KetprofielModal/KetprofielModal';
import { IconButton } from '../../components/IconButton/IconButton';
import Services from '../../services/Services';
import { Avatar } from '../Avatar/Avatar';
import { Text } from '../Text/Text';
import { useLiveConfig } from '../../lib/useLiveConfig';
import { EColor } from '../../theme/styleguide';
import { Background } from '../Background/Background';

const MAIN_PAGES = ['/', '/kijken', '/spelen'];

export type TMainHeaderProps = {};

export const MainHeader = () => {
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const isMatchMedia = useMatchMedia(mediaQuery.mobileTabletPortraitQuery);
  const session = useSession();
  const handleClosing = () => setIsProfileModalVisible(false);
  const { pathname } = useLocation();
  const isMainPage = MAIN_PAGES.includes(pathname);
  const liveConfig = useLiveConfig();

  return (
    <>
      <StyledWrap data-analyticsid="MainHeader" backgroundColor={liveConfig.foregroundColor}>
        <StyledLinkWrap backgroundColor={liveConfig.accentColor}>
          <StyledLogoLink data-cyid="MainHeaderLogo" href="/" isMainPage={isMainPage}>
            <StyledLogoImage src={isMatchMedia ? liveConfig.logoUrl : liveConfig.logoUrl} alt="Ketnet" />
          </StyledLogoLink>
          {!isMainPage && (
            <StyledBackButtonWrap>
              <IconButton icon={<BackIcon />} onClick={Services.appService.goBack} />
            </StyledBackButtonWrap>
          )}
        </StyledLinkWrap>
        <StyledFormWrap>
          <StyledFormBackgroundLeft type="main-header-left" />
          <SearchForm />
          <StyledFormBackgroundRight type="main-header-right" />
        </StyledFormWrap>
        <StyledProfileLink onClick={() => setIsProfileModalVisible(true)}>
          <StyledLoginText>{!session ? 'Aanmelden' : `Dag ${session.firstName}`}</StyledLoginText>
          {!session ? <LoginIcon /> : <Avatar size="large" imageUrl={session?.picture} initials={session?.firstName} />}
        </StyledProfileLink>
      </StyledWrap>
      {isProfileModalVisible && <KetprofielModal onClose={handleClosing} />}
    </>
  );
};

const StyledWrap = styled.div<{ backgroundColor: EColor }>`
  ${({ theme, backgroundColor }) => `
    background-color: ${theme.colors[backgroundColor]};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    width: 100%;
    ${mediaQuery.tabletPortrait(`
      padding-right: 0px;
    `)}
  `}
`;

const StyledLinkWrap = styled.div<{ backgroundColor: EColor }>`
  position: relative;
  ${({ theme, backgroundColor }) => `
    background: ${theme.colors[backgroundColor]};
    ${mediaQuery.tabletPortrait(`
      padding: 5px 15px;
    `)}
  `}
`;

const StyledLogoLink = styled.a<{ isMainPage: boolean }>`
  ${({ isMainPage }) => `
    ${
      !isMainPage &&
      `
      visibility: hidden;
    `
    }
  `}
`;

const StyledLogoImage = styled.img`
  ${({ theme }) => `
    width: ${theme.components.mainHeader.logo.width}px;
    height: ${theme.components.mainHeader.logo.height}px;
    margin: 16px 24px;
    ${mediaQuery.tabletLandscape(`
      width: ${theme.components.mainHeader.logoTabletLandscape.width}px;
      height: ${theme.components.mainHeader.logoTabletLandscape.height}px;
      margin: 10px;
    `)}
    ${mediaQuery.tabletPortrait(`
      width: ${theme.components.mainHeader.logoTabletPortrait.width}px;
      height: ${theme.components.mainHeader.logoTabletPortrait.height}px;
      margin: 4px 0px;
    `)}
  `}
`;

const StyledBackButtonWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledFormWrap = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFormBackgroundLeft = styled(Background)`
  ${({ theme }) => `
    position: absolute;
    overflow: hidden;
    height: 126px;
    left: calc(${theme.components.mainHeader.logo.width}px + 48px); // Todo: use a styleguide variable.
    z-index: 0;
    bottom: 0;
    ${mediaQuery.tabletLandscape(`
      display: none;
    `)}
  `}
`;

const StyledFormBackgroundRight = styled(Background)`
  ${({ theme }) => `
    position: absolute;
    overflow: hidden;
    right: 0;
    z-index: 0;
    bottom: 0;
    width: 218px;
    height: 66px;
    ${mediaQuery.tabletLandscape(`
      display: none;
    `)}
  `}
`;

const StyledProfileLink = styled.a`
  ${({ theme }) => `
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacings.small}px;
  `}
`;

const StyledLoginText = styled(Text).attrs({ size: 'medium', bold: true })`
  margin-right: 12px;
  text-transform: ${({ theme }) => theme.components.button.textTransform};
  ${mediaQuery.tabletPortrait(`
    margin-right: 0px;
    display: none;
  `)}
`;
