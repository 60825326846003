import { TGame } from '../../pages/GameDetailPage/types';
import { pathToTitle } from './metaHelpers';
import { TPageMeta } from './PageMeta';

export function getGameDetailMeta(path: string, game: TGame, canonical: string | null): TPageMeta {
  const title = game.title ?? pathToTitle(path);
  return {
    title,
    description: /(quiz|wie|welk)/i.test(title) ? 'Quiz mee op ketnet.be' : 'Nu spelen op ketnet.be',
    canonical,
  };
}
