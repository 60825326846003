import { TPageTrackingProps } from '../../hooks/usePageTracking';
import { TVideoPage } from '../../pages/AemPage/types';
import { TPageMetadata } from '../../services/AnalyticsService/TAnalyticsService';

export function getVideoPageMetadata(
  video: Pick<TVideoPage, 'id' | 'trackingData' | 'titleVideodetail'>
): TPageMetadata {
  const { id, trackingData, titleVideodetail: title } = video;
  if (!trackingData) {
    console.warn(`No videoTrackingData for video ${id}`);
    return { id, title };
  }
  const { programName, episodeName, episodeNr, seasonName, episodeBroadcastDate } = trackingData;
  // TODO: whatsonId for program + episode
  const program: TPageTrackingProps['program'] =
    programName === null
      ? undefined
      : {
          name: programName,
          whatsonId: undefined,
        };
  const episode: TPageTrackingProps['episode'] = {
    name: episodeName ?? title,
    broadcastDate: episodeBroadcastDate ?? undefined,
    nr: episodeNr === null ? undefined : episodeNr,
    season: seasonName ?? undefined,
    whatsonId: undefined,
  };
  // TODO: thema videodetail pages site section - quid?
  const site: TPageTrackingProps['site'] =
    programName === null
      ? undefined
      : {
          section1: 'kijken',
          section2: programName,
          section3: episodeName ?? title,
        };
  return {
    id,
    title,
    program,
    episode,
    site,
  };
}
