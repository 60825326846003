import { TFragment } from './fragment';
import { headerFragment, THeader } from './Header';
import { TGameType } from './Game';
import { TStoryReaction, storyReactionFragment } from './StoryReaction';

const name = 'pagecontentItem';

const query = `
fragment ${name} on PagecontentItem {
  ...on Header { ...${headerFragment.name} }
  ...on Highlight { ...highlight }
  ...on Jumbotron { ...jumbotron }
  ...on MultiHighlight { ...multiHighlight }
  ...on Swimlane { ...swimlane }
}

fragment highlight on Highlight {
  type
  title
  description
  link
  linkItem {
    ...on Game { id }
    ...on Program { id }
    ...on Story { id }
    ...on Theme { id }
    ...on Video { id }
  }
  buttonText
  imageUrl
  size
  fullWidthEnabled
  fullWidthImageUrl
  fullWidthBackgroundUrl
  backgroundColor
  textColor
}

fragment jumbotron on Jumbotron {
  linkText
  link
  backgroundImageUrl
  showButtonTime
  onTime
  offTime
  type
}
fragment multiHighlight on MultiHighlight {
    type
    highlights {
      type
      title
      description
      link
      linkItem {
        ...on Game { id }
        ...on Program { id }
        ...on Story { id }
        ...on Theme { id }
        ...on Video { id }
      }
      buttonText
      imageUrl
      size
    }
  }
fragment swimlane on Swimlane {
  id
  type
  title
  style
  items {
    ...on Video { ...swimlaneVideo }
    ...on Theme { ...swimlaneTheme }
    ...on Game { ...swimlaneGame }
    ...on Story { ...swimlaneStory }
    ...on Program { ...swimlaneProgram }
    ...on Peetie { ...swimlanePeetie }
  }
}

fragment swimlaneVideo on Video {
  id
  type
  title
  imageUrl
  titleSwimlane
  subtitleSwimlane
  duration
}

fragment swimlaneTheme on Theme {
  id
  type
  title
  accentColor
  imageUrl
  logoUrl
  animation
}

fragment swimlaneGame on Game {
  id
  type
  title
  imageUrl
  gameType
}

fragment swimlaneStory on Story {
  id
  type
  title
  imageUrl
  topReaction { ...${storyReactionFragment.name} }
}

fragment swimlaneProgram on Program {
  id
  type
  title
  accentColor
  imageUrl
  logoUrl
}

fragment swimlanePeetie on Peetie {
  id
  alt
  type
  imageUrl
  imageUrls
  movies {
    alt
    position
    gifUrl
    webmUrl
    mp3Url
    gifFrames
  }
}
`;

export const pagecontentItemFragment: TFragment = { name, query };

export type TPagecontentItem = THeader | THighlight | TJumbotron | TSwimlane | TMultiHighlight;

export interface THighlight {
  type: 'highlight';
  title: string | null;
  description: string | null;
  link: string | null;
  linkItem: THighlightLinkItem | null;
  buttonText: string | null;
  imageUrl: string | null;
  size: 'large' | 'medium' | 'small';
  fullWidthEnabled: boolean;
  fullWidthImageUrl: string | null;
  fullWidthBackgroundUrl: string | null;
}

// We only need the id for any type of linkItem for now
export interface THighlightLinkItem {
  id: string;
}

export interface TJumbotron {
  type: 'jumbotron';
  title: string | null;
  link: string | null;
  linkText: string | null;
  backgroundImageUrl: string | null;
  showButtonTime: string | null;
  onTime: string | null;
  offTime: string | null;
}

export interface TMultiHighlight {
  type: 'multiHighlight';
  highlights: THighlight[];
}

export interface TSwimlane {
  id: string;
  type: 'swimlane';
  style: TSwimlaneStyle;
  title: string;
  items: TSwimlaneItem[] | null;
}

export type TSwimlaneStyle = 'boxset' | 'heroes' | 'thumbnails' | 'stories';

export type TSwimlaneItem =
  | TSwimlaneTheme
  | TSwimlaneProgram
  | TSwimlaneVideo
  | TSwimlaneStory
  | TSwimlaneGame
  | TSwimlanePeetie;

export type TSwimlaneItemType = 'theme' | 'program' | 'video' | 'peetie';

export type TAnimationType = 'spin';

export interface TSwimlaneItemBase {
  id: string;
  title: string | null;
  imageUrl: string | null;
}

export interface TSwimlaneProgram extends TSwimlaneItemBase {
  type: 'program';
  accentColor: string | null;
  logoUrl: string | null;
}

export interface TSwimlaneTheme extends TSwimlaneItemBase {
  type: 'theme';
  accentColor: string | null;
  logoUrl: string | null;
  animation?: TAnimationType | null;
}

export interface TSwimlaneVideo extends TSwimlaneItemBase {
  type: 'video';
  titleSwimlane: string | null;
  subtitleSwimlane: string | null;
  duration: number | null;
  // TODO: videoType -- live | vod
  // TODO: resume point
}

export interface TSwimlaneStory extends TSwimlaneItemBase {
  type: 'story';
  topReaction: TStoryReaction | null;
}

export interface TSwimlaneStoryTopReaction {
  name: string;
  count: number;
}

export interface TSwimlaneGame extends TSwimlaneItemBase {
  type: 'game';
  gameType: TGameType;
}

export interface TSwimlanePeetie extends TSwimlaneItemBase {
  type: 'peetie';
  id: string;
  alt: string;
  imageUrls: string[];
  imageUrl: string;
  movies: TPeetieMovie[];
}

export type TPeetieMoviePosition =
  | 'center'
  | 'top'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left'
  | 'top-left';

export interface TPeetieMovie {
  id: string;
  alt: string;
  position: TPeetieMoviePosition;
  gifUrl: string;
  webmUrl: string;
  mp3Url: string;
  gifFrames: number;
}
