import anime from 'animejs';

export const scrollTo = (targetY: number) => {
  anime.remove('html, body');
  anime({
    targets: 'html, body',
    scrollTop: targetY,
    duration: 800,
    easing: 'easeInOutExpo',
  });
};
