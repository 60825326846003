import React from 'react';
import { TPagecontentItem } from './types';
import { Swimlane } from '../Swimlane/Swimlane';
import { Header } from '../Header/Header';
import { Highlight } from '../Highlight/Highlight';
import { HighlightSmall } from '../Highlight/HighlightSmall';
import { Jumbotron } from '../Jumbotron/Jumbotron';
import { Region } from '../../components/Region/Region';
import { EColor } from '../../theme/styleguide';
import { useCallback } from 'react';
import { TSwimlaneItem } from '../Swimlane/types';
import { usePeeties } from '../Peetie/PeetieProvider';
import Services from '../../services/Services';
import { liveConfig, useLiveConfig } from '../../lib/useLiveConfig';
import MultiHighlight from '../MultiHighlight/MultiHighlight';
import { useTheme } from 'styled-components';

export interface TPagecontentItemProps {
  item: TPagecontentItem;
}

const getRegionColor = (style: string, config: liveConfig) => {
  if (style === 'stories') {
    return config.foregroundColor;
  }
  if (style === 'heroes') {
    return config.accentColor;
  }
  return EColor.transparent;
};

const getSwimlaneColor = (style: string, config: liveConfig) => {
  if (style === 'stories') {
    return config.foregroundColor;
  }
  if (style === 'heroes') {
    return config.accentColor;
  }
  return config.backgroundColor;
};

export const PagecontentItem: React.FC<TPagecontentItemProps> = (props) => {
  const { item } = props;
  const { launchPeetie } = usePeeties();
  const liveConfig = useLiveConfig();
  const theme = useTheme();
  const onClickItem = useCallback(
    (item: TSwimlaneItem) => {
      if (item.type === 'peetie') {
        launchPeetie(item);
      }
    },
    [launchPeetie]
  );

  switch (item.type) {
    case 'header':
      return (
        <Region color={theme.colors[liveConfig.backgroundColor]}>
          <Header data={item} />
        </Region>
      );
    case 'highlight':
      return item.size === 'small' ? <HighlightSmall data={item} /> : <Highlight data={item} />;
    case 'jumbotron':
      return <Jumbotron data={item} />;
    case 'multiHighlight':
      return <MultiHighlight data={item} />;
    case 'swimlane':
      const regionColor = getRegionColor(item.style, liveConfig);
      const swimlaneColor = getSwimlaneColor(item.style, liveConfig);
      return (
        <Region color={theme.colors[regionColor]} background={item.background}>
          <Swimlane data={item} color={swimlaneColor} onClickItem={onClickItem} />
        </Region>
      );
    default:
      Services.loggerService.errorObject('Unmapped PagecontentItem', item);
      return null;
  }
};
