import { useState, useEffect } from 'react';
import { TSession } from '../services/SessionService/ISessionService';
import Services from '../services/Services';

function useSession() {
  const { sessionService } = Services;
  const initialSession = sessionService.isLoggedIn() ? sessionService.getUserProfile() : null;
  const [session, setSession] = useState<TSession>(initialSession);
  useEffect(() => {
    return sessionService.onSessionChanged(setSession);
  }, [sessionService]);
  return session;
}

export default useSession;
