import { TPageTrackingProps } from '../../hooks/usePageTracking';
import { TGamePage } from '../../pages/AemPage/types';
import { TPageMetadata } from '../../services/AnalyticsService/TAnalyticsService';

export function getGamePageMetadata(page: TGamePage): TPageMetadata {
  const { title, id } = page;

  const site: TPageTrackingProps['site'] = {
    section1: 'spelen',
  };
  return {
    id,
    title: title || `game ${id}`,
    site,
  };
}
