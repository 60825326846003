import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { TPeetie } from './types';
import { Countdown } from './Countdown';
import { usePeeties } from './PeetieProvider';
import { isSafari } from '../../lib/features';

type TProps = {
  data: TPeetie;
  backgroundColor?: string;
};

export const Peetie = ({ data: peetie }: TProps) => {
  const { imageUrl } = peetie;

  // launchPeetie is handled inside of <Swimlane /> via 'onClickItem'
  // due to flickity + static clicks
  const { activePeetie, isLoadingMovie } = usePeeties();
  const isActive = !isLoadingMovie && activePeetie === peetie;
  const isCountingDown = isLoadingMovie && activePeetie === peetie;

  return (
    <StyledWrap>
      <StyledPeetiePulseScale isSafari={isSafari}>
        <StyledPeetiePulseRotate isSafari={isSafari}>
          <StyledPeetie
            style={{ backgroundImage: `url(${imageUrl})` }}
            isActive={isActive}
            isCountingDown={isCountingDown}
          />
        </StyledPeetiePulseRotate>
      </StyledPeetiePulseScale>
      {isCountingDown && <Countdown />}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    ${mediaQuery.netflixQuery(
      theme.components.swimlane.images.heroes.width,
      theme.components.swimlane.images.heroes.height
    )}
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: ${theme.colors.white};
    margin-right: ${theme.components.swimlane.horizontalSpacing}px;
  `}
`;

const StyledPeetiePulseScale = styled.div<{ isSafari: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  ${({ isSafari }) => `
    ${
      !isSafari &&
      `
      @keyframes pulse-scale {
        0% {
          transform: scale(1, 1);
        }
        100% {
          transform: scale(0.9, 0.9);
        }
      }
      animation: pulse-scale 0.5s alternate infinite cubic-bezier(0.735, 0.005, 0.46, 1);
    `
    }
  `}
`;

const StyledPeetiePulseRotate = styled.div<{ isSafari: boolean }>`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  ${({ isSafari }) => `
    ${
      !isSafari &&
      `
      @keyframes pulse-rotate {
        0% {
          transform: rotate(-4deg);
        }
        100% {
          transform: rotate(4deg);
        }
      }
      animation: pulse-rotate 2s alternate infinite cubic-bezier(0.735, 0.005, 0.46, 1);
    `
    }
  `}
`;

const StyledPeetie = styled.div<{ isActive: boolean; isCountingDown: boolean }>`
  ${({ isActive, isCountingDown }) => {
    const animationName = !isActive ? 'appear' : 'hide';
    const animationSpeed = '600ms';
    const easeInOutTimingFunc = 'cubic-bezier(0.785, -0.010, 0.170, 1.000)';
    const easeOutBackTimingFunc = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)';
    const animationTimingFunc = !isActive ? easeOutBackTimingFunc : easeInOutTimingFunc;

    return `
      position: absolute;
      top: 30px;
      left: 20px;
      bottom: 20px;
      right: 20px;
      opacity: ${isCountingDown ? 0.3 : 1};
      transition: opacity 500ms cubic-bezier(0.000, 0.515, 0.295, 1.015);

      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

      @keyframes appear {
        0%   { transform: scale(0, 0); }
        100% { transform: scale(1, 1); }
      }
      @keyframes hide {
        0%   { transform: scale(1, 1); }
        100% { transform: scale(0, 0); }
      }
      animation: ${animationName} ${animationSpeed} ${animationTimingFunc};
      animation-fill-mode: forwards;
    `;
  }}
`;
