import React from 'react';
import styled, { useTheme } from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { ReactComponent as WatchIcon } from './icon-watch.svg';
import { Text } from '../../components/Text/Text';
import { TGameType } from '../../components/GameTypeIcon/types';
import GameTypeIcon from '../../components/GameTypeIcon/GameTypeIcon';

const LABEL_SIZE = 26;

export type TSearchResultLabelProps = {
  icon: 'watch' | 'watchJr' | 'game';
  type?: TGameType;
};

export const SearchResultLabel = (props: TSearchResultLabelProps) => {
  const { icon, type } = props;
  const theme = useTheme();

  switch (icon) {
    case 'watch':
      return (
        <StyledLabel style={{ backgroundColor: theme.colors.yellow }}>
          <WatchIcon />
        </StyledLabel>
      );
    case 'watchJr':
      return (
        <StyledLabel style={{ backgroundColor: theme.colors.yellow }}>
          <WatchIcon />
          <Text size="small" color={EColor.black}>
            JUNIOR
          </Text>
        </StyledLabel>
      );
    case 'game':
      return (
        <StyledLabel style={{ backgroundColor: theme.colors.green }}>
          {type && <GameTypeIcon gameType={type} />}
        </StyledLabel>
      );
    default:
      return null;
  }
};

const StyledLabel = styled.div`
  ${({ theme }) => `
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${LABEL_SIZE}px;
    & svg {
      width: ${LABEL_SIZE}px;
      min-width: ${LABEL_SIZE}px;
    }
    & span {
      padding-right: ${theme.spacings.small}px;
    }
  `}
`;
