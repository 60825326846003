import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { Link } from 'react-router-dom';
import { Text } from '../Text/Text';
import { VRTLogo } from '../../assets/icons';

const footerLinks = [
  { url: 'https://www.vrt.be/nl/contact/', label: 'Contact' },
  { url: 'https://www.vrt.be/heb-je-een-vraag/s/', label: 'Veelgestelde vragen' },
  { url: 'https://jobs.vrt.be/', label: 'Jobs' },
  // Todo: check with Els. { url: 'https://privacy.ketnet.be/spelregels', label: 'Gebruiksvoorwaarden' },
  { url: 'https://www.ketnet.be/privacy', label: 'Privacy' },
  { url: 'https://www.vrt.be/nl/info/responsible-disclosure-policy/', label: 'Ethische veiligheidsmelding' },
  { url: 'https://www.ketnet.be/privacy/ons-cookiebeleid', label: 'Ons cookiebeleid' },
  { url: 'https://ouders.ketnet.be/', label: 'Ketnet voor ouders' },
  { url: 'https://ketnetjunior.be', label: 'Ketnet Junior' },
  { url: 'https://www.cim.be/nl/internet/disclaimer-cim-internet', label: 'Disclaimer CIM' },
].map(({ url, label }) => ({
  url,
  label,
  isExternal: /^https?:\/\//.test(url),
}));

export type TMainFooterProps = {};

export const MainFooter = (props: TMainFooterProps) => {
  return (
    <StyledWrap>
      <StyledFooterMenu>
        <StyledFooterItem>
          <StyledFooterLabel>{`VRT - Ketnet © ${new Date().getUTCFullYear()}`}</StyledFooterLabel>
        </StyledFooterItem>
        {footerLinks.map(({ url, label, isExternal }, idx) => {
          return (
            <StyledFooterItem key={idx}>
              {isExternal ? (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <StyledFooterLabel>{label}</StyledFooterLabel>
                </a>
              ) : (
                <Link to={url}>
                  <StyledFooterLabel>{label}</StyledFooterLabel>
                </Link>
              )}
            </StyledFooterItem>
          );
        })}
      </StyledFooterMenu>
      <StyledFooterWrapper>
        <VRTLogo width={41} height={21} />
      </StyledFooterWrapper>
    </StyledWrap>
  );
};

const StyledWrap = styled.footer`
  ${({ theme }) => `
    margin: 32px auto;
    ${mediaQuery.tabletPortrait(`
      padding: ${theme.spacings.medium}px;
      margin-bottom: 100px;
    `)}
  `}
`;

const StyledFooterMenu = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${mediaQuery.mobile(`
    flex-direction: column;
  `)}
`;

const StyledFooterItem = styled.li`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    &::after {
      padding-left: 5px;
      padding-right: 5px;
      content: '-';
      ${mediaQuery.mobile(`
        display: none;
      `)}
    }
  }
  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledFooterWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block !important;
  }
`;

const StyledFooterLabel = styled(Text).attrs({ size: 'small', tag: 'p' })``;
