import { gql } from '@apollo/client';

export const deletePostCommentMutation = gql`
  mutation DeletePostComment($input: DeletePostCommentInput!) {
    contentfeedDeletePostComment(input: $input)
  }
`;

export interface TDeletePostCommentArgs {
  input: {
    postId: string;
    commentId: string;
  };
}

export interface TDeletePostCommentResult {
  contentfeedDeletePostComment: string;
}

export interface TDeletePostCommentData {
  postId: string;
  commentId: string;
}
