import React from 'react';
import { TProps } from './GameTypeIcon';

const GameTypeIcon = React.lazy(() => import('./GameTypeIcon'));

// Wrap GameTypeIcon in React.lazy + React.suspense to allow bundle splitting
// for asset-containing components.
const LazyGameTypeIcon: React.FC<TProps> = (props) => {
  return (
    <React.Suspense fallback={null}>
      <GameTypeIcon {...props} />
    </React.Suspense>
  );
};

export default LazyGameTypeIcon;
