import UAParser from 'ua-parser-js';

// https://github.com/faisalman/ua-parser-js

export const userAgent = new UAParser();

// possibly some setups on apple devices can work with autoplay, but the setups used by most people will not
export const canAutoplay = userAgent.getDevice().vendor !== 'Apple';

export const supportsMpegDash = userAgent.getDevice().vendor !== 'Apple';

export const isMobile = userAgent.getDevice().type === 'mobile';

export const isTablet = userAgent.getDevice().type === 'tablet';

export const isIos = userAgent.getDevice().vendor === 'Apple' && (isMobile || isTablet);

export const isMobileSafari = userAgent.getBrowser().name === 'Mobile Safari';

export const isEdge = userAgent.getBrowser().name === 'Edge';

export const isSafari = userAgent.getBrowser().name === 'Safari';

export const supportsWebM = !isIos && !isSafari && !isMobileSafari;
