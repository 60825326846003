import React from 'react';
import styled from 'styled-components';
import { Region } from '../Region/Region';
import { THighlight } from './types';
import { Link } from 'react-router-dom';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../Text/Text';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { useTheme } from 'styled-components';

export type THighlightProps = {
  data: THighlight;
};

export const HighlightSmall = (props: THighlightProps) => {
  const { data } = props;
  const theme = useTheme();
  const {
    description,
    imageUrl,
    link,
    backgroundColor,
    fullWidthEnabled,
    fullWidthImageUrl,
    fullWidthBackgroundUrl,
    textColor,
  } = data;
  const fullWidthNeeded = !!(!useMatchMedia(mediaQuery.mobileTabletLandscapeQuery) && fullWidthEnabled);

  let highlightBackgroundColor = backgroundColor ? backgroundColor : theme.colors.red;
  let highlightTextColor = textColor ? textColor : theme.colors.white;

  const highlightContent = () => {
    return (
      <StyledWrap className="highlightContainer" backgroundColor={highlightBackgroundColor}>
        {imageUrl && (
          <StyledImageWrap fullWidthNeeded={fullWidthNeeded}>
            <StyledImage src={fullWidthNeeded && fullWidthImageUrl ? fullWidthImageUrl : imageUrl} />
          </StyledImageWrap>
        )}
        <StyledInfo fullWidthNeeded={fullWidthNeeded} backgroundColor={highlightBackgroundColor}>
          <StyledText textColor={highlightTextColor}>
            {description && (
              <StyledDescription fullWidthNeeded={fullWidthNeeded} textColor={highlightTextColor}>
                {description}
              </StyledDescription>
            )}
          </StyledText>
        </StyledInfo>
      </StyledWrap>
    );
  };

  return (
    <Region
      color={highlightBackgroundColor}
      verticalSpacing={0}
      fullMobileWidth={true}
      backgroundImage={fullWidthBackgroundUrl}
      type="small"
    >
      {link && <StyledLink to={link}>{highlightContent()}</StyledLink>}
      {!link && highlightContent()}
    </Region>
  );
};

const StyledWrap = styled.div<{ backgroundColor: string }>`
  ${({ theme, backgroundColor }) => `
    background-color: ${backgroundColor};
    display: flex;
    position: relative;
    flex-direction: row;
    padding: ${theme.spacings.small}px;
    ${mediaQuery.tabletLandscape(`
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    `)}
  `}
`;

const StyledInfo = styled.div<{ fullWidthNeeded: boolean; backgroundColor: string }>`
  ${({ theme }) => `
    margin-left: ${theme.spacings.medium}px;
  `}
  width: 100%;
  display: inline-flex;
  justify-content: center;
  ${({ theme }) =>
    mediaQuery.tabletLandscape(`
      order: 2;
      padding: ${theme.spacings.small}px 0;
    `)}
  ${({ theme }) =>
    mediaQuery.tabletPortrait(`
      padding: ${theme.spacings.small}px;
    `)}
  ${({ theme, fullWidthNeeded, backgroundColor }) => `
    ${
      fullWidthNeeded &&
      `
      background-color: ${backgroundColor};
      z-index: 2;
      justify-content: unset;
      bottom: 0px;
      left: 0px;
      padding: ${theme.spacings.large}px;
      position: absolute;
      flex-direction: row;
      align-items: center;
    `
    }
    ${
      !fullWidthNeeded &&
      `
      position: unset;
      flex-direction: column;
      align-items: flex-start;
    `
    }
  `}
`;

const StyledText = styled.div<{ textColor: string }>`
  ${({ textColor }) => `
    display: flex;
    flex-direction: column;
    color: ${textColor};
  `}
`;

const StyledDescription = styled(Text)<{ fullWidthNeeded: boolean; textColor: string }>`
  ${({ fullWidthNeeded, textColor }) => `
    ${
      !fullWidthNeeded &&
      `
      color: ${textColor};
      text-transform: uppercase;
      font-weight: bold;
    `
    }
  `}
`;

const StyledLink = styled(Link)``;

const StyledImageWrap = styled.div<{ fullWidthNeeded: boolean }>`
  display: flex;
`;

const StyledImage = styled.img`
  max-height: 75px;
  width: auto;
  object-fit: contain;
`;
