import { IBffService } from './IBffService';
import { TGetUpdatesResult } from './getUpdates.query';
import { TSearchResult, TSearchArgs } from './search.query';
import { TGetPageArgs, TGetPageResult } from './getPage.query';
import { TGetStoryDetailsArgs, TGetStoryDetailsResult } from './getStoryDetails.query';
import { TSaveStoryUserReactionsArgs, TSaveStoryUserReactionsResult } from './saveStoryUserReactions.mutation';
import { TLikeVideoArgs, TLikeVideoResult } from './likeVideo.mutation';
import { TUnlikeVideoArgs, TUnlikeVideoResult } from './unlikeVideo.mutation';
import { SetPostReactionData } from './setPostReaction.mutation';
import { SetPinboardItemReactionData } from './setPinboardItemReaction.mutation';
import { TPostComment } from './PostComment';
import { TAddPostCommentData, TDeletePostCommentData } from './addPostComment.mutation';
import { TGetContentfeedPostsArgs, TGetContentfeedPostsResult } from './getContentfeedPosts.query';
import { TGetPaginatedPinboardRowsArgs, TGetPaginatedPinboardRowsResult } from './getPaginatedPinboardRows.query';
import BaseService from '../BaseService';
import { TStoryDetails } from './StoryDetails';
import { TGetContentfeedPostCommentsArgs, TGetContentfeedPostCommentsResult } from './getContentfeedPostComments.query';

class MockBffService extends BaseService implements IBffService {
  async getPage(args: TGetPageArgs): Promise<TGetPageResult> {
    switch (args.id) {
      case 'content/ketnet/nl.model.json':
        return this.getMock('/mock/fakeHome.json');
      case 'content/ketnet/nl/kijken.model.json':
        return this.getMock('/mock/fakeVideoZone.json');
      case 'content/ketnet/nl/kijken/m/meisjes.model.json':
        return this.getMock('/mock/fakePinboard.json');
      case 'content/ketnet/nl/spelen.model.json':
        return this.getMock('/mock/fakeGameZone.json');
      default:
        break;
    }
    if (args.id.includes('/spelen/f/peetieclub-photobooth.model.json')) {
      return this.getMock('/mock/fakeGameDetail-peetieclub-photobooth.json');
    }
    if (args.id.includes('/spelen/f/vrede-photobooth.model.json')) {
      return this.getMock('/mock/fakeGameDetail-vrede-photobooth.json');
    }
    if (args.id.includes('/spelen/s/generieke-avatar-builder')) {
      return this.getMock('/mock/fakeGameDetail-generieke-avatar-builder.json');
    }
    if (args.id.includes('/spelen/s/stemmingsmodule-sdk')) {
      return this.getMock('/mock/fakeGameDetail-stemmingsmodule-sdk.json');
    }
    if (args.id.includes('/spelen/t/test-sdk')) {
      return this.getMock('/mock/fakeGameDetail-test-sdk.json');
    }
    if (args.id.includes('/spelen/f/fotofabriek')) {
      return this.getMock('/mock/fakeGameDetail-fotofabriek.json');
    }
    if (args.id.includes('/spelen/')) {
      return this.getMock('/mock/fakeGameDetail.json');
    }
    if (/\/themas\/[a-z0-9-]+\.model\.json/i.test(args.id)) {
      return this.getMock('/mock/fakeTheme.json');
    }
    if (/\/kijken\/[a-z0-9]\/[a-z0-9-]+\.model\.json/i.test(args.id)) {
      return this.getMock('/mock/fakeProgram.json');
    }
    if (args.id.includes('/themas/') || args.id.includes('/kijken/')) {
      return this.getMock('/mock/fakeVideoDetail.json');
    }
    return { canonical: null, page: null };
  }

  async search(_args: TSearchArgs): Promise<TSearchResult> {
    return this.getMock('/mock/fakeSearch.json');
  }

  async getUpdates(_args: null): Promise<TGetUpdatesResult> {
    return this.getMock('/mock/fakeUpdates.json');
  }

  async getStoryDetails(args: TGetStoryDetailsArgs): Promise<TGetStoryDetailsResult> {
    if (args.storyId.indexOf('test-story-overlay-calltoaction') >= 0) {
      return this.getMock('/mock/fakeStory-overlay-calltoaction.json');
    }
    if (args.storyId.indexOf('test-story-overlay-poll') >= 0) {
      return this.getMock('/mock/fakeStory-overlay-poll.json');
    }
    return this.getMock('/mock/fakeStory-overlay-reactions.json');
  }

  async saveStoryUserReactions(args: TSaveStoryUserReactionsArgs): Promise<TSaveStoryUserReactionsResult> {
    return {
      storySaveUserReactions: {
        id: args.input.id,
      },
    };
  }

  async likeVideo(args: TLikeVideoArgs): Promise<TLikeVideoResult> {
    return {
      likeVideo: {
        id: args.input.id,
      },
    };
  }

  async unlikeVideo(args: TUnlikeVideoArgs): Promise<TUnlikeVideoResult> {
    return {
      unlikeVideo: {
        id: args.input.id,
      },
    };
  }

  async setPostReaction(_data: SetPostReactionData): Promise<void> {
    return Promise.resolve();
  }

  async setPinboardItemReaction(_data: SetPinboardItemReactionData): Promise<void> {
    return Promise.resolve();
  }

  async addPostComment(data: TAddPostCommentData): Promise<TPostComment> {
    const { postId, message } = data;
    return Promise.resolve({
      commentId: '123',
      postId,
      message,
      author: {
        avatarUrl: '/mock/author1.svg',
        name: 'mock user',
      },
      postedOn: new Date().toISOString(),
      mine: true,
    });
  }

  async deletePostComment(_data: TDeletePostCommentData): Promise<void> {
    return Promise.resolve();
  }

  async getContentfeedPosts(args: TGetContentfeedPostsArgs): Promise<TGetContentfeedPostsResult> {
    return this.getMock('/mock/fakeContentfeedPosts.json');
  }

  async getContentfeedPostComments(args: TGetContentfeedPostCommentsArgs): Promise<TGetContentfeedPostCommentsResult> {
    return this.getMock('/mock/fakeContentfeedPostComments.json');
  }

  async getPaginatedPinboardRows(args: TGetPaginatedPinboardRowsArgs): Promise<TGetPaginatedPinboardRowsResult> {
    return this.getMock('/mock/fakeContentfeedPosts.json'); // Todo: add correct mockdata for pinboard rows.
  }

  getKetprofiel: IBffService['getKetprofiel'] = async () => {
    return this.getMock('/mock/fakeKetprofiel.json');
  };

  castPollVote: IBffService['castPollVote'] = async (args) => {
    const fakePollStory: TStoryDetails = await this.getMock('/mock/fakeStory-overlay-poll.json');
    return {
      storySaveUserVote: fakePollStory,
    };
  };

  private async getMock<TResult>(path: string): Promise<TResult> {
    if (this.services.sessionService.isLoggedIn()) {
      if (path.includes('fakeHome')) {
        path = path.replace('fakeHome', 'fakeHome-authenticated');
      }
    }
    const res = await window.fetch(path);
    const body = await res.json();
    return body.data;
  }
}

export default MockBffService;
