import { TProgram, TTab } from './types';

export function findActiveProgramTab(program: TProgram): TTab | null {
  const { activeTab, tabs } = program;
  if (!tabs || tabs.length === 0) {
    return null;
  }
  if (!activeTab) {
    // default to the first tab
    return tabs[0];
  }
  const active = tabs.find((tab) => tab.name === activeTab);
  return active ?? null;
}
