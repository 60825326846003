import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { TPage } from '../../pages/AemPage/types';
import Services from '../../services/Services';
import { getGameDetailMeta } from './getGameDetailMeta';
import { getPagecontentMeta } from './getPagecontentMeta';
import { getProgramMeta } from './getProgramMeta';
import { getRedirectMeta } from './getRedirectMeta';
import { getThemeMeta } from './getThemeMeta';
import { getVideoDetailMeta } from './getVideoDetailMeta';
import { pathToTitle } from './metaHelpers';

export interface TPageMetaProps {
  page: TPage;
}

export const PageMeta: React.FC<TPageMetaProps> = (props) => {
  const location = useLocation();
  const meta = getPageMeta(location.pathname, props.page);
  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      {props.page.canonical && <link rel="canonical" href={props.page.canonical} />}
    </Helmet>
  );
};

function getPageMeta(path: string, page: TPage): TPageMeta {
  const canonical = page.canonical;
  switch (page.pageType) {
    case 'pagecontent':
      return getPagecontentMeta(path, page, canonical);
    case 'video':
      return getVideoDetailMeta(page, canonical);
    case 'game':
      return getGameDetailMeta(path, page, canonical);
    case 'theme':
      return getThemeMeta(path, page, canonical);
    case 'program':
      return getProgramMeta(path, page, canonical);
    case 'redirect':
      return getRedirectMeta(path, page, canonical);
    default:
      Services.loggerService.errorObject(`getPageMeta: unsupported pageType`, page);
  }
  return {
    title: pathToTitle(path),
    description: 'Welkom op ketnet.be',
    canonical,
  };
}

export interface TPageMeta {
  title: string;
  description: string;
  canonical: string | null;
}
