import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import BaseService from '../BaseService';
import { IMonitoringService } from './IMonitoringService';
import { Scope } from '@sentry/react';

const SENTRY_DSN = 'https://2c906167117e44239944f9c19ccd9cd1@o140591.ingest.sentry.io/5567428';
const SENTRY_SAMPLE_RATE = 0.01;

class MonitoringService extends BaseService implements IMonitoringService {
  private isInitialized: boolean = false;

  public initialize() {
    try {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: this.services.configService.environment,
        integrations: [new Integrations.BrowserTracing()],
        sampleRate: SENTRY_SAMPLE_RATE,
      });
      this.isInitialized = true;
    } catch (e) {
      console.error(`Failed to initialize sentry: ${e instanceof Error ? e.message : 'an error occurred'}`);
      console.error(e);
    }
  }

  public captureException(exception: any, captureContext?: Scope): void {
    if (this.isInitialized) {
      Sentry.captureException(exception, captureContext);
    } else {
      console.error('Sentry not initialized - exception', exception);
    }
  }

  public captureMessage(exception: any, captureContext?: Scope): void {
    if (this.isInitialized) {
      Sentry.captureMessage(exception, captureContext);
    } else {
      console.error('Sentry not initialized - message', exception);
    }
  }
}

export default MonitoringService;
