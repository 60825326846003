import { gql } from '@apollo/client';
import { postCommentFragment, TPostComment } from './PostComment';

export const addPostCommentMutation = gql`
mutation AddPostComment($input: AddPostCommentInput!) {
  contentfeedAddPostComment(input: $input) {
    ...${postCommentFragment.name}
  }
}

${postCommentFragment.query}
`;

export interface TAddPostCommentArgs {
  input: TAddPostCommentInput;
}

export interface TAddPostCommentInput {
  postId: string;
  message: string;
  gdpr: string;
}

export interface TAddPostCommentResult {
  contentfeedAddPostComment: TPostComment;
}

export interface TAddPostCommentData {
  postId: string;
  message: string;
  gdpr: string;
}

export interface TDeletePostCommentData {
  postId: string;
  commentId: string;
}
