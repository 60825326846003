/**
 * RFC5424 standard log levels in descending order of severity:
 * error, warn, info, http, verbose, debug, silly
 */
export enum LogLevel {
  ERROR,
  WARN,
  INFO,
  HTTP,
  VERBOSE,
  DEBUG,
  SILLY,
}
