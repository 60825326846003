import { gql } from '@apollo/client';

export const setPinboardItemReactionMutation = gql`
  mutation SetPinboardItemReaction($input: SetPinboardItemReactionInput!) {
    setPinboardItemReaction(input: $input)
  }
`;

export interface SetPinboardItemReactionArgs {
  input: {
    pinboardItemId: string;
    reactionId: string | null;
    gdpr: string;
  };
}

export interface SetPinboardItemReactionResult {
  setPinboardItemReaction: string;
}

export interface SetPinboardItemReactionData {
  pinboardItemId: string;
  reactionId: string | null;
  gdpr: string;
}
