import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {
    background: ${({ theme }) => theme.colors.darkPurple};
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
  @font-face {
    font-family: 'favorit_regular';
    src: url('/assets/fonts/favorit-20210409/Favorit-Regular.woff2') format('woff2'),
         url('/assets/fonts/favorit-20210409/Favorit-Regular.woff') format('woff'),
         url('/assets/fonts/favorit-20210409/Favorit-Regular.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'favorit_bold';
    src: url('/assets/fonts/favorit-20210409/Favorit-Bold.woff2') format('woff2'),
         url('/assets/fonts/favorit-20210409/Favorit-Bold.woff') format('woff'),
         url('/assets/fonts/favorit-20210409/Favorit-Bold.otf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'noteworthy_light';
    src: url('/assets/fonts/noteworthy/Noteworthy-Light.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  *:focus {
    outline: 0;
  }
  body {
    font-display: swap;
    margin: 0;
    padding: 0;
    font-family: favorit_regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #1042DF; */
  }
  h1, h2, h3, h4, h5, p {
    font-family: favorit_regular;
  }
  a {
    text-decoration: none;
  }

  @-webkit-keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}	
  }

  /* Ketnet bottom borders when hovered in (multi)Highlight banner */
  .borderBtnContainer,
  .filterItem,
  .highlightContainer {
    .filterTitle,
    .btnHighlight,
    .borderBtn {
      border: 0px;
    }
  }
  .borderBtnContainer:hover,
  .filterItem:hover,
  .highlightContainer:hover {
    text-decoration: none;
    pointer: cursor;
    .filterTitle,
    .btnHighlight,
    .borderBtn {
      &::after,
      &::before {
        position: absolute;
        z-index: 3;
        left: 0px;
        right: 0px;
        content: '';
        width: 100%;
        height: 100%;
        border-top: 0px;
        bottom: -5px;
      }
      &::before {
        bottom: -10px;
      }
      & p {
        color:  ${({ theme }) => theme.colors.red};
      }
    }
    .borderBtn,
    .btnHighlight {
      &::after,
      &::before {
        border: 1px solid  ${({ theme }) => theme.colors.yellow};
      }
    }
  }
`;

export default GlobalStyle;
