import React from 'react';
import { TPlaylist, TPlaylistsTab } from './types';
import styled from 'styled-components';
import { PlaylistItem } from '../VideoDetailPage/PlaylistItem';
import { TSwimlaneSeasonsItem, TSwimlane } from '../../components/Swimlane/types';
import { Swimlane } from '../../components/Swimlane/Swimlane';
import { Region } from '../../components/Region/Region';
import { Text } from '../../components/Text/Text';
import { Background } from '../../components/Background/Background';
import { useTheme } from 'styled-components';

export interface TPlaylistsTabProps {
  tab: TPlaylistsTab;
}

export const PlaylistsTab: React.FC<TPlaylistsTabProps> = (props) => {
  const { tab } = props;
  const [activePlaylist, setActivePlaylist] = React.useState<TPlaylist | null>(tab.playlists[0] ?? null);
  const theme = useTheme();

  const items: TSwimlaneSeasonsItem[] = tab.playlists.map((playlist) => {
    const { title: rawTitle, imageUrl } = playlist;
    const title = rawTitle ?? '<geen titel>';
    return {
      imageUrl,
      title,
      alt: title,
      type: 'season',
      isActive: activePlaylist === playlist,
      onClick: () => setActivePlaylist(playlist),
    };
  });

  const data: TSwimlane = {
    id: 'playlist-seasons',
    analyticsId: 'playlist | seasons', // used for analytics tracking
    style: 'seasons',
    items,
  };

  return (
    <Region color={theme.colors.transparent}>
      {data?.items?.length > 1 && <Swimlane data={data} />}
      {activePlaylist ? (
        <StyledEpisodes>
          <StyledWaves type="waves" />

          {activePlaylist.items.map((video, idx) => (
            <StyledEpisode key={idx}>
              <PlaylistItem item={video} />
            </StyledEpisode>
          ))}
        </StyledEpisodes>
      ) : (
        <StyledNoVideosAvailable>Er zijn momenteel nog geen video's beschikbaar</StyledNoVideosAvailable>
      )}
    </Region>
  );
};
const StyledWaves = styled(Background)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -10;
`;

const StyledEpisodes = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.spacings.extralarge}px;
  min-height: 50vh;
`;

const StyledEpisode = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.white};
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.large}px;
    }
  `}
`;

const StyledNoVideosAvailable = styled(Text)``;
