import { TFragment } from './fragment';
import { pagecontentItemFragment, TPagecontentItem } from './PagecontentItem';

const name = 'pagecontent';

const query = `
fragment ${name} on Pagecontent {
  title
  pagecontent { ...${pagecontentItemFragment.name} }
}

${pagecontentItemFragment.query}
`;

export const pagecontentFragment: TFragment = { name, query };

export interface TPagecontent {
  title: string | null;
  pagecontent: TPagecontentItem[];
}
