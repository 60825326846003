import React from 'react';
import styled from 'styled-components';
import useSession from '../../lib/useSession';
import Services from '../../services/Services';
import { OverlayContext } from '../Overlay/OverlayContext';
import { StoryReaction } from './StoryReaction';
import { TStoryReactionsOverlay, TStoryReactionName } from './types';

export interface TStoryReactionsOverlayProps {
  storyId: string;
  overlay: TStoryReactionsOverlay;
}

// TODO: KETNET-3567 this should be cleared when login/logout => store in context rather than inmemory
// now it's persisted even if the overlay is unmounted, when switching session outside of the overlay,
// the values are not cleared and the result is you will see reactions from other user / as logged out user

// keep track of reactions in memory, on user session level
let sessionReactions: { [storyId: string]: TStoryReactionName | null } = {};

export const StoryReactionsOverlay: React.FC<TStoryReactionsOverlayProps> = (props) => {
  const { storyId, overlay } = props;
  const { showLogin } = React.useContext(OverlayContext);
  const [myReaction, setMyReaction] = React.useState<TStoryReactionName | null>(
    sessionReactions.hasOwnProperty(storyId) ? sessionReactions[storyId] : overlay.myReaction
  );
  const session = useSession();
  const handleOnSelect = (name: TStoryReactionName) => {
    // only signed in users can store reactions
    if (!session) {
      showLogin();
      return;
    }
    const nextMyReaction = myReaction === name ? null : name;
    // ensure the UI is always updated, regardless of actually being able to store the reaction
    setMyReaction(nextMyReaction);
    sessionReactions[storyId] = nextMyReaction;
    Services.bffService
      .saveStoryUserReactions({
        input: {
          id: storyId,
          gdpr: nextMyReaction
            ? `Reactie "${nextMyReaction}" geplaatst op Story ${storyId}`
            : `Reactie verwijderd op Story ${storyId}`,
          reactions: nextMyReaction ? [nextMyReaction] : [],
        },
      })
      .catch((e) => {
        Services.loggerService.errorObject(`Failed to save user reaction "${nextMyReaction}" to story ${storyId}`, e);
      });
  };

  React.useEffect(() => {
    return Services.sessionService.onSessionChanged((session) => {
      // clear the stored sessionReactions on logout (can only be triggered when leaving the modal)
      if (!session) {
        sessionReactions = {};
      }
    });
  });
  return (
    <StyledWrap>
      {overlay.reactions.map(({ name, count }) => {
        let increment = 0;
        // increase/decrease when the original reaction has changed
        if (myReaction !== overlay.myReaction) {
          if (myReaction === name) {
            increment = +1;
          } else if (overlay.myReaction === name) {
            increment = -1;
          }
        }
        return (
          <StoryReaction
            name={name}
            count={count + increment}
            key={name}
            isSelected={name === myReaction}
            onSelect={handleOnSelect}
          />
        );
      })}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
