import React from 'react';
import styled from 'styled-components';
import { injectFontSize } from '../../theme/typography';
import { Text } from '../Text/Text';
import { ReactComponent as SearchIcon } from './search-magnifier-big.svg';

export type TNotFoundProps = {};

export const NotFound = (props: TNotFoundProps) => {
  return (
    <StyledWrap>
      <StyledSearchIcon />
      <StyledTitle>Niet gevonden wat je zocht?</StyledTitle>
      <StyledInfo>
        Zoek je Tik Tak, Sarah, Bumba of een ander Ketnet Junior-programma? Download de gratis Ketnet Junior-app of surf
        naar{' '}
        <StyledLink href="https://www.ketnet-junior.be" target="_blank" rel="noopener noreferrer">
          www.ketnet-junior.be
        </StyledLink>
      </StyledInfo>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSearchIcon = styled(SearchIcon)`
  transform: scale(0.5, 0.5);
`;

const StyledTitle = styled(Text).attrs({ tag: 'h2' })`
  margin-bottom: 32px;
`;

const StyledInfo = styled(Text)``;

const StyledLink = styled.a`
  ${({ theme }) => `
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.white};
    color: ${theme.colors.white};
    ${injectFontSize('regular')};
  `};
`;
