import { vrtPlayerFragment, TVrtPlayerConfig } from './VrtPlayer';
import { scaledImageFragment, TScaledImage } from './ScaledImage';
import { TFragment } from './fragment';

const name = 'video';

const query = `
fragment ${name} on Video {
  id
  videoType
  titleVideodetail
  subtitleVideodetail
  metaTitle
  scaledPoster { ...${scaledImageFragment.name} }
  description
  availableUntilDate
  publicationDate
  duration
  episodeNr
  vrtPlayer { ...${vrtPlayerFragment.name} }
  suggestions {
    id
    titleSuggestion
    subtitleSuggestion
    scaledPoster { ...${scaledImageFragment.name} }
    duration
  }
  playlists {
    name
    title
    items {
      id
      titlePlaylist
      subtitlePlaylist
      scaledPoster { ...${scaledImageFragment.name} }
      description
      duration
    }
  }
  activePlaylist
  trackingData {
    programName
    seasonName
    episodeName
    episodeNr
    episodeBroadcastDate
  }
  likedByMe
  likes
}
`;

export const videoFragment: TFragment = { name, query };

export interface TVideo {
  id: string;
  titleVideodetail: string;
  subtitleVideodetail: string | null;
  metaTitle: string | null;
  scaledPoster: TScaledImage | null;
  description: string | null;
  duration: number;
  availableUntilDate: string;
  publicationDate: string | null;
  episodeNr: number | null;
  vrtPlayer: TVrtPlayerConfig;
  suggestions: Array<TVideoSuggestion> | null;
  playlists: Array<TPlaylist> | null;
  activePlaylist: string | null;
  trackingData: TVideoTrackingData | null;
  likedByMe: boolean | null;
  likes: number | null;
}

export interface TVideoSuggestion {
  id: string;
  titleSuggestion: string;
  subtitleSuggestion: string | null;
  scaledPoster: TScaledImage | null;
  duration: number | null;
}

export interface TPlaylist {
  title: string;
  name: string;
  items: Array<TPlaylistItem>;
}

export type TPlaylistItem = TVideoPlaylist;

export interface TVideoPlaylist {
  id: string;
  titlePlaylist: string;
  subtitlePlaylist: string | null;
  description: string | null;
  scaledPoster: TScaledImage | null;
  duration: number | null;
}

export interface TVideoTrackingData {
  videoId: string;
  programName: string | null;
  seasonName: string | null;
  episodeName: string | null;
  episodeNr: number | null;
  episodeBroadcastDate: string | null;
}
