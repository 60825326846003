import React from 'react';
import styled from 'styled-components';

export type TAspectRatioProps = {
  width: number;
  height: number;
  containerWidth?: number;
};

export const AspectRatio: React.FC<TAspectRatioProps> = (props) => {
  const { width, height, children, containerWidth = 100 } = props;
  return (
    <StyledContainer width={width} height={height} containerWidth={containerWidth}>
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ height: number; width: number; containerWidth: number }>`
  width: 100%;
  padding-top: ${(props) => props.containerWidth * (props.height / props.width)}%;
  position: relative;
`;

const StyledContent = styled.div`
  background: ${({ theme }) => theme.colors.transparent};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
