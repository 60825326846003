export { ReactComponent as LoginIcon } from './icons/ketprofiel-yellow.svg';
export { ReactComponent as AccountIcon } from './icons/account.svg';
export { ReactComponent as ArrowNextIcon } from './icons/arrow-next.svg';
export { ReactComponent as ArrowPrevIcon } from './icons/arrow-prev.svg';
export { ReactComponent as ArrowCloseIcon } from './icons/arrow-close.svg';
export { ReactComponent as CloseIcon } from './icons/close.svg';
export { ReactComponent as LoadMoreIcon } from './icons/load-more.svg';
export { ReactComponent as PlayIcon } from './icons/play.svg';
export { ReactComponent as BackIcon } from './icons/back.svg';
export { ReactComponent as NextCircleIcon } from './icons/next-circle.svg';
export { ReactComponent as InfoIcon } from './icons/info.svg';
export { ReactComponent as CallToActionIcon } from './icons/call-to-action.svg';
export { ReactComponent as CallToActionInvertedIcon } from './icons/call-to-action-inverted.svg';
export { ReactComponent as StickyPostIcon } from './icons/sticky-post.svg';
export { ReactComponent as VRTLogo } from './icons/vrt.svg';

export const LikeIcon = '/assets/like.svg';
export const LikeOutlinedIcon = '/assets/like-outlined.svg';
export const Triangles = '/assets/triangles.svg';
