import React from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { MainMenuItem } from './MainMenuItem';

import { ReactComponent as MenuHomeIcon } from './home.svg';
import { ReactComponent as MenuWatchIcon } from './watch.svg';
import { ReactComponent as MenuPlayIcon } from './play.svg';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { mediaQuery } from '../../theme/mediaQuery';
export interface TNavLink {
  path: string;
  label: string;
  icon: JSX.Element;
  activeIcon: JSX.Element;
}

export type TMainMenuProps = {};

export const MainMenu = (props: TMainMenuProps) => {
  const isMobile = useMatchMedia(mediaQuery.mobileTabletPortraitQuery);

  return (
    <nav data-analyticsid="MainMenu" data-cyid="MainMenu" aria-label="hoofdmenu">
      <StyledWrap isMobile={isMobile}>
        <MainMenuItem
          isMobile={isMobile}
          path="/"
          icon={<MenuHomeIcon />}
          text="Home"
          textColor={EColor.darkPurple}
          backgroundColor={EColor.yellow}
        />
        <MainMenuItem
          isMobile={isMobile}
          path="/kijken"
          icon={<MenuWatchIcon />}
          text="Kijken"
          textColor={EColor.white}
          backgroundColor={EColor.red}
        />
        <MainMenuItem
          isMobile={isMobile}
          path="/spelen"
          icon={<MenuPlayIcon />}
          text="Spelen"
          textColor={EColor.blue}
          backgroundColor={EColor.green}
        />
      </StyledWrap>
    </nav>
  );
};

const StyledWrap = styled.ul<{ isMobile: boolean }>`
  position: fixed;
  z-index: 5;
  left: 0;
  top: 200px;
  display: flex;
  flex-direction: column;

  ${({ isMobile }) =>
    isMobile &&
    `
    left: 0px;
    right: 0px;
    top: auto;
    bottom: 0;
    flex-direction: row;
  `}
`;
