import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MainMenu } from '../components/MainMenu/MainMenu';
import { SearchPage } from '../pages/SearchPage/SearchPage';
import { EventPage } from '../pages/EventPage/EventPage';
import { EmbedPage } from '../pages/EmbedPage/EmbedPage';
import { AemPageLoader } from '../pages/AemPage/AemPageLoader';
import { MainHeader } from '../components/MainHeader/MainHeader';
import { MainFooter } from '../components/MainFooter/MainFooter';
import { Snackbar } from '../components/Snackbar/Snackbar';
import { mediaQuery } from '../theme/mediaQuery';
import { ProfileModal } from '../components/ProfileModal/ProfileModal';
import { CookieConsentBox } from '../components/CookieConsentBox/CookieConsentBox';
import { useLiveConfig } from '../lib/useLiveConfig';
import { EColor } from '../theme/styleguide';
import { Background } from '../components/Background/Background';

const MainSite: React.FC = () => {
  const liveConfig = useLiveConfig();

  return (
    <StyledRoot>
      <GlobalMainStyle backgroundColor={liveConfig.backgroundColor} />
      <StyledHeader>
        <MainHeader />
      </StyledHeader>
      <StyledContent>
        <MainMenu />
        <Switch>
          <Route exact path="/zoeken" component={SearchPage} />
          <Route exact path="/livecenter" component={EventPage} />
          <Route path="/embed/" component={EmbedPage} />
          {/* start - legacy routes redirects */}
          <Route path="/programma/buck">
            <Redirect to="/kijken/b/buck" />
          </Route>
          <Route path="/over-deze-website/privacy">
            <Redirect to="/privacy" />
          </Route>
          {/* end   - legacy routes redirects */}
          <Route component={AemPageLoader} />
        </Switch>
      </StyledContent>
      <StyledFooter>
        <StyledBackgroundLeft type="main-footer-left" />
        <MainFooter />
        <StyledBackgroundRight type="main-footer-right" />
      </StyledFooter>
      <Snackbar />
      <ProfileModal />
      <CookieConsentBox />
    </StyledRoot>
  );
};

const GlobalMainStyle = createGlobalStyle<{ backgroundColor: EColor }>`
  ${({ theme, backgroundColor }) => `
    html > body {
      background-color: ${theme.colors[backgroundColor]};
    }
  `}
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
`;

const StyledContent = styled.div`
  flex: 1;
  flex-grow: 1;
  position: relative;
  min-height: 100vh;
`;

const StyledFooter = styled(StyledHeader)`
  & svg {
    display: none;
  }
  ${mediaQuery.desktop(`
    display: flex;
    position: relative;
    align-items: flex-end;
    & svg {
      display: flex;
    }
  `)}
`;

const StyledBackgroundLeft = styled(Background)`
  height: auto;
  width: 216px;
`;

const StyledBackgroundRight = styled(Background)`
  height: auto;
  width: 144px;
`;

export default MainSite;
