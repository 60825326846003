import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { TFeedTheming, TPostComment } from '../../pages/ProgramPage/types';
import { Button } from '../Button/Button';
import { PostComment } from './PostComment';
import { LoadMoreIcon } from '../../assets/icons';
import { EColor } from '../../theme/styleguide';
import { mediaQuery } from '../../theme/mediaQuery';
import { Text } from '../Text/Text';
import { loadNextPostComments } from '../../services/FeedService/FeedService';

type TPostCommentOverviewProps = {
  comments: TPostComment[];
  onDeleteComment: (comment: TPostComment) => void;
  feedTheming: TFeedTheming;
  next: string | null;
  totalComments: number;
  postId: string;
  updateComments: any;
};

export const PostCommentOverview = ({
  comments,
  onDeleteComment,
  feedTheming,
  next,
  totalComments,
  postId,
  updateComments,
}: TPostCommentOverviewProps) => {
  const commentsCount = comments.length;
  const calculateContextText = useCallback(
    (commentsCount: number) => {
      if (totalComments === 0) return 'nog geen reacties';
      if (totalComments === 1) return '1 reactie';
      return commentsCount + ` van ${totalComments} reacties`;
    },
    [totalComments]
  );
  const [postComment, setPostComment] = useState<{
    comments: TPostComment[];
    next: string | null;
    commentsCount: number;
  }>({ comments, next, commentsCount });
  const [isAtEnd, setIsAtEnd] = useState<boolean>(commentsCount >= totalComments);
  const [countText, setCountText] = useState(calculateContextText(commentsCount));

  const loadCommentsHandler = async () => {
    if (postComment.next) {
      const nextComments = await loadNextPostComments(postId, postComment.next);
      const updatedComments = comments.concat(nextComments.postsComments);
      const updatedCommentsCount = updatedComments.length;
      const nextCursor = updatedCommentsCount < totalComments ? nextComments.next : null;
      if (nextCursor) {
        setIsAtEnd(false);
      } else {
        setIsAtEnd(true);
      }
      setPostComment({ comments: updatedComments, next: nextCursor, commentsCount: updatedCommentsCount });
      setCountText(calculateContextText(updatedCommentsCount));
      updateComments(updatedComments);
    }
  };

  const activeComments = Array<TPostComment>();
  const map = new Map();
  for (const comment of comments) {
    if (!map.has(comment.commentId)) {
      map.set(comment.commentId, true);
      activeComments.push(comment);
    }
  }

  useEffect(() => {
    if (activeComments) {
      setCountText(calculateContextText(activeComments.length));
    }
  }, [activeComments, calculateContextText]);

  return (
    <StyledWrap>
      {activeComments.map((comment) => (
        <PostComment key={comment.commentId} data={comment} onDeleted={onDeleteComment} />
      ))}
      <StyledCount>{countText}</StyledCount>
      {!isAtEnd && (
        <StyledShowMoreButton
          text="toon meer reacties"
          onClick={() => loadCommentsHandler()}
          icon={<LoadMoreIcon />}
          textColor={EColor.white}
          primaryColor={EColor[feedTheming.buttonColor]}
        />
      )}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacings.small}px 0;
  `}
`;

const StyledCount = styled(Text).attrs({ size: 'small' })`
  ${({ theme }) => `
    opacity: 0.5;
    text-align: center;
    margin: ${theme.spacings.small}px 0;
    font-weight: 400;
  `}
`;

const StyledShowMoreButton = styled(Button).attrs({})`
  ${({ theme }) => `
    width: calc(100% - ${theme.components.avatar.small}px - ${theme.spacings.small}px);
    margin-left: calc(${theme.components.avatar.small}px + ${theme.spacings.small}px);
    ${mediaQuery.tabletLandscape(`
      width: calc(100% - ${theme.components.avatar.small}px);
      margin-left: ${theme.components.avatar.small}px;
    `)}
  `}
`;
