import React from 'react';
import styled from 'styled-components';
import { VrtPlayer } from '../VrtPlayer/VrtPlayer';
import { Spinner } from '../Spinner/Spinner';
import { TVideo } from './types';
import { AspectRatio } from '../AspectRatio/AspectRatio';
import { Text } from '../Text/Text';
import { mediaQuery } from '../../theme/mediaQuery';

export type TVideoProps = {
  data: TVideo;
};

export const Video = (props: TVideoProps) => {
  const { vrtPlayerConfig, digitalData, poster } = props.data;
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const containerWidth = vrtPlayerConfig.aspectRatio === '9:16' ? 60 : 100;
  const width = vrtPlayerConfig.aspectRatio === '9:16' ? 9 : 16;
  const height = vrtPlayerConfig.aspectRatio === '9:16' ? 16 : 9;
  const hasGlitchyBackgrounds = !!(digitalData?.media?.media_subtype === 'post' && height > width);

  return (
    // aspect ratio is not yet supported in safari
    <AspectRatio width={width} height={height} containerWidth={containerWidth}>
      <StyledWrap width={containerWidth}>
        {hasError ? (
          <StyledPlayerError>
            <Text>De video is momenteel niet beschikbaar.</Text>
          </StyledPlayerError>
        ) : (
          <>
            {!isInitialized && <Spinner size="small" />}
            <StyledPlayerWrapper isHidden={!isInitialized} hasGlitchyBackgrounds={hasGlitchyBackgrounds}>
              <VrtPlayer
                config={vrtPlayerConfig}
                digitalData={digitalData}
                uiConfig={{ posterImageOverrideUrl: poster?.medium }}
                onError={() => setHasError(true)}
                onInitialized={() => setIsInitialized(true)}
              />
            </StyledPlayerWrapper>
          </>
        )}
      </StyledWrap>
    </AspectRatio>
  );
};

const StyledWrap = styled.div<{ width: number }>`
  ${({ theme, width }) => `
    width: ${width}%;
    height: 100%;
    background-color: ${theme.colors.black};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  `}
`;

const StyledPlayerError = styled.div`
  ${({ theme: { colors, spacings } }) => `
    background-color: ${colors.red};
    padding: ${spacings.medium}px;
  `}
`;

const StyledPlayerWrapper = styled.div<{ isHidden: boolean; hasGlitchyBackgrounds: boolean }>`
  ${({ isHidden, hasGlitchyBackgrounds }) => `
    width: 100%;
    height: 100%;
    ${isHidden ? 'display: none;' : ''}
    position: relative;
    ${
      hasGlitchyBackgrounds &&
      `
      &::before,
      &::after {
        display: block;
        content: '';
        width: 82px;
        height: 55px;
        position: absolute;
        background-image: url('/assets/backgrounds/post-top.svg');
        background-repeat: no-repeat;
        top: 10%;
        left: -10%;
        transform: scale(1.8) rotate(-90deg);
        ${mediaQuery.tabletPortrait(`
          display: none;
        `)}
      }
      &::after {
        top: 80%;
        left: 88%;
        transform: scale(1.8) rotate(90deg);
      }
    `
    }
  `}
`;
