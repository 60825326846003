import { TPageMetadata } from './TAnalyticsService';
import { TThemePage } from '../../pages/AemPage/types';

export function getThemePageMetadata(theme: TThemePage): TPageMetadata {
  const { header, id } = theme;
  const title = header.title ?? `thema ${id}`;
  return {
    id,
    title,
    site: {
      section1: 'themas',
      section2: title,
    },
    program: {
      name: title,
      whatsonId: undefined, // TODO: verify if themas will ever have whatson ids
    },
  };
}
