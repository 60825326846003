import { TVideoDetail } from '../../pages/VideoDetailPage/types';
import { TPageMeta } from './PageMeta';

export function getVideoDetailMeta(video: TVideoDetail, canonical: string | null): TPageMeta {
  return {
    title: [video.titleVideodetail, video.subtitleVideodetail].filter((p) => p !== null).join(' - '),
    description: video.description ?? 'Bekijk de video op ketnet.be',
    canonical,
  };
}
