import React, { useEffect, useState } from 'react';
import { Region } from '../Region/Region';
import { EColor } from '../../theme/styleguide';
import styled, { useTheme } from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { TJumbotron } from './types';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import { DateTime } from 'luxon';
import { Text } from '../Text/Text';

export type TJumbotronProps = {
  data: TJumbotron;
};

export const useTime = (refreshCycle = 100) => {
  const [now, setNow] = useState(getTime());
  useEffect(() => {
    const intervalId = setInterval(() => setNow(getTime()), refreshCycle);
    return () => clearInterval(intervalId);
  }, [refreshCycle, setNow]);
  return now;
};

export const getTime = () => {
  return DateTime.local();
};

export const Jumbotron: React.FC<TJumbotronProps> = (props) => {
  const { data } = props;
  const { link, linkText, onTime, offTime, showButtonTime, backgroundImageUrl } = data;
  const [showButton, setShowButton] = useState(false);

  const onTimeSeconds = onTime ? DateTime.fromISO(onTime).toSeconds() : 0;
  const offTimeSeconds = offTime ? DateTime.fromISO(offTime).toSeconds() : 0;
  const now = useTime(1000);
  const countdown = DateTime.fromSeconds(onTimeSeconds).diff(now);
  const buttonDisplayTime = showButtonTime ? DateTime.fromISO(showButtonTime).toSeconds() : 0;
  const formattedCountdown = countdown.toFormat('dd:hh:mm:ss').split(':');
  const countdownLabels = ['Dagen', 'Uren', 'Minuten', 'Seconden'];
  const theme = useTheme();

  useEffect(() => {
    const canShowButton = buttonDisplayTime < now.toSeconds() && now.toSeconds() < offTimeSeconds ? true : false;
    return setShowButton(canShowButton);
  }, [showButton, now, buttonDisplayTime, offTimeSeconds]);

  return (
    <StyledOuterWrap backgroundImageUrl={backgroundImageUrl}>
      <Region color={theme.colors.transparent} verticalSpacing={0} fullMobileWidth={true}>
        <StyledWrap>
          {now.toSeconds() < onTimeSeconds && (
            <>
              <StyledClockWrap>
                <StyledClock>
                  {countdownLabels.map((currLabel, index) => {
                    return (
                      <StyledClockDisplay>
                        <StyledClockDisplayValue>
                          <Text bold={true} size="xlarge">
                            <span>{formattedCountdown[index].charAt(0)}</span>
                            <span>{formattedCountdown[index].charAt(1)}</span>
                          </Text>
                        </StyledClockDisplayValue>
                        <StyledClockDisplayLabel>
                          <Text bold={true} size="medium">
                            {currLabel}
                          </Text>
                        </StyledClockDisplayLabel>
                      </StyledClockDisplay>
                    );
                  })}
                </StyledClock>
              </StyledClockWrap>
              <StyledYellowBox />
            </>
          )}

          {showButton && linkText && link && (
            <StyledLink to={link}>
              <Button text={linkText} primaryColor={EColor.yellow} textColor={EColor.black} />
            </StyledLink>
          )}
        </StyledWrap>
      </Region>
    </StyledOuterWrap>
  );
};

const StyledOuterWrap = styled.div<{ backgroundImageUrl: string | null }>`
  ${({ backgroundImageUrl }) => `
    ${
      backgroundImageUrl &&
      `
      background-image: url('${backgroundImageUrl}');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    `
    }
  `}
`;

const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  min-height: 400px;
  position: relative;
  flex-direction: column;
  width: 1250px;
  max-width: 100%;
  justify-content: center;
`;

const StyledClockWrap = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
`;

const StyledClock = styled.div`
  background-image: url('/assets/backgrounds/jumbotron-red-box.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 768px;
  max-width: 75%;
  height: calc(768px * 0.407);
  min-height: 250px;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding: 25px 7% 0 7%;
  overflow: hidden;
  ${mediaQuery.tabletLandscape(`
    max-width: 90%;
    padding-top: 5px;
  `)}
  & > div:not(:last-child) > div:first-child:after {
    content: ':';
    font-size: 50px;
    color: #fff;
    position: absolute;
    right: -17%;
    top: 0px;
    ${mediaQuery.mobile(`
      font-size: 30px;
    `)}
  }
`;

const StyledClockDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  align-items: center;
  margin: 0 2.5%;
`;

const StyledClockDisplayValue = styled.div`
  background-image: url('/assets/backgrounds/jumbotron-time.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & span {
    display: table-cell;
    font-size: 50px;
    width: 50px;
    text-align: center;
  }
  ${mediaQuery.tabletPortrait(`
    height: 60px;
    & span {
      font-size: 40px;
    }
  `)}
  ${mediaQuery.mobile(`
    height: 40px;
    & span {
      font-size: 30px;
    }
  `)}
`;

const StyledClockDisplayLabel = styled.div`
  margin-top: 10px;
  ${mediaQuery.tabletPortrait(`
    font-size: 13px;
    font-weight: normal;
    margin-top: 0px;
  `)}
`;

const StyledLink = styled(Link)`
  position: absolute;
  bottom: 140px;
  text-decoration: none;
`;

const StyledYellowBox = styled.div`
  background-image: url('/assets/backgrounds/jumbotron-yellow-box.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 156px;
  height: 115px;
  position: absolute;
  bottom: 0px;
`;
