import React from 'react';
import styled from 'styled-components';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { ReactComponent as SnackbarCloseIcon } from './close.svg';
import { IconButton } from '../../components/IconButton/IconButton';
import { useHistory } from 'react-router-dom';
import { mediaQuery } from '../../theme/mediaQuery';
import { Triangles } from '../../assets/icons';
import { useUpdates } from './useUpdates';

export interface TEvent {
  imageUrl: string;
  buttonText: string;
  link: string;
}

export const Snackbar: React.FC = () => {
  const { event, closeEvent } = useUpdates();
  const isMobile = useMatchMedia(mediaQuery.mobileTabletPortraitQuery);
  const history = useHistory();

  const gotoEvent = () => {
    history.push('/livecenter');
    closeEvent();
  };

  const closeSnackbar = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    closeEvent();
  };

  if (!event) return null;
  const { title, imageUrl, buttonText } = event;

  return (
    <StyledWrap isMobile={isMobile} onClick={gotoEvent}>
      <StyledInnerWrap isMobile={isMobile}>
        {!isMobile && <StyledImage style={{ backgroundImage: `url(${imageUrl})` }} />}
        <StyledTitle isMobile={isMobile}>{title}</StyledTitle>
        {!!buttonText && !isMobile && <StyledButtonLabel>{buttonText}</StyledButtonLabel>}
        {!isMobile && <img alt="play icon" src={Triangles} />}
      </StyledInnerWrap>
      <StyledCloseButton icon={<SnackbarCloseIcon width={21} height={21} />} onClick={closeSnackbar} />
    </StyledWrap>
  );
};

const StyledWrap = styled.div<{ isMobile: boolean }>`
  ${({ theme, isMobile }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0px;
    z-index: 2;
    width: 100%;
    cursor: pointer;
    height: 84px;
    background-color: ${theme.colors.red};
    border-top: 2px solid ${theme.colors.black};
    padding-left: 64px;

    @keyframes elastic {
      0% {
          bottom: -100px;
      }
      20% {
          bottom: 20px;
      }
      40% {
          bottom: -10px;
      }
      60% {
          bottom: 0%;
      }
      100% {
          bottom: 0%;
      }
    }
    animation-name: elastic;
    animation-fill-mode: forwards;
    animation-duration: 1.2s;
    
    ${
      isMobile &&
      `
      transform: translate(0px, -76px);
      padding-left: 20px;
      background: linear-gradient(180deg, rgba(239,0,51,1) 75%, rgba(190,0,51,1) 100%);
      `
    }
  `}
`;

const StyledInnerWrap = styled.div<{ isMobile: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledImage = styled.div`
  ${({ theme }) => `
    flex: 0 0 auto;
    width: 150px;
    height: 84px;
    background: ${theme.colors.black};
    background-size: cover;
    background-position: center center;
  `}
`;

const StyledTitle = styled.h4<{ isMobile: boolean }>`
  ${({ isMobile }) => `
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    color: #fff;
    margin-left: 32px;
    margin-right: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${
      isMobile &&
      `
      margin-left: 0px;
      margin-right: 0px;
    `
    }
  `}
`;

const StyledButtonLabel = styled.h5`
  ${({ theme }) => `
    font-size: 16px;
    font-weight: 600;
    background: ${theme.colors.yellow};
    color: ${theme.colors.darkBlue};
    padding: 2px 16px;
    margin-right: 32px;
  `}
`;

const StyledCloseButton = styled(IconButton)``;
