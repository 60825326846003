import React from 'react';
import styled from 'styled-components';
import { TSearchProgram, TSearchJrProgram, TSearchEpisode, TSearchGame } from './types';
import { SearchResult } from './SearchResult';
import { Text } from '../../components/Text/Text';

export type TSearchResultsProps = {
  title: string;
  subTitle?: string;
  data: Array<TSearchProgram | TSearchJrProgram | TSearchEpisode | TSearchGame>;
};

export const SearchResults = (props: TSearchResultsProps) => {
  const { title, subTitle, data } = props;

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <StyledTitle>{title}</StyledTitle>
      {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
      <StyledContainer>
        {data.map((item, idx) => {
          return <SearchResult key={idx} data={item} />;
        })}
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-flow: row wrap;
    margin-left: -${theme.spacings.small}px;
    margin-bottom: ${theme.spacings.extralarge}px;
    & > div {
      margin: ${theme.spacings.small}px;
    }
  `};
`;

const StyledTitle = styled(Text).attrs({ size: 'large', bold: true })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.small}px;
  `};
`;

const StyledSubTitle = styled(Text).attrs({ size: 'medium' })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.small}px;
    opacity: 0.7;
  `};
`;
