class ServiceTypedError<TError> extends Error {
  public readonly serviceName: string;

  public readonly errorType: TError;

  constructor(serviceName: string, errorType: TError) {
    const message = `[${serviceName}] ${errorType}`;
    super(message);
    this.serviceName = serviceName;
    this.errorType = errorType;

    // https://stackoverflow.com/questions/41102060/typescript-extending-error-class
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ServiceTypedError.prototype);
  }
}

export default ServiceTypedError;
