export enum EColor {
  white = 'white',
  black = 'black',
  blue = 'blue',
  darkBlue = 'darkBlue',
  purple = 'purple',
  darkPurple = 'darkPurple',
  yellow = 'yellow',
  darkYellow = 'darkYellow',
  green = 'green',
  darkGreen = 'darkGreen',
  red = 'red',
  transparent = 'transparent',
  lightRed = 'lightRed',
  darkRed = 'darkRed',
  planeetKDarkGreen = 'planeetKDarkGreen',
  planeetKBrightGreen = 'planeetKBrightGreen',
  planeetKGreen = 'planeetKGreen',
}

export enum ESpacing {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extralarge = 'extralarge',
}

export enum ETemplate {
  DEFAULT = 'default',
  SINTERKLAAS = 'sinterklaas',
  WINTER = 'winter',
  GALA = 'gala',
  STIPIT = 'stipit',
}

export const styleguide = {
  colors: {
    [EColor.white]: '#FFF',
    [EColor.black]: '#000',
    [EColor.darkBlue]: '#121A49',
    [EColor.blue]: '#1042DF',
    [EColor.purple]: '#671E75',
    [EColor.darkPurple]: '#1D0259',
    [EColor.yellow]: '#FFD300',
    [EColor.darkYellow]: '#D89000',
    [EColor.green]: '#00DD69',
    [EColor.darkGreen]: '#327d5a',
    [EColor.red]: '#EF0033',
    [EColor.transparent]: 'transparent',
    [EColor.lightRed]: '#DD0130',
    [EColor.darkRed]: '#68031E',
    [EColor.planeetKDarkGreen]: '#00504F',
    [EColor.planeetKGreen]: '#27A09E',
    [EColor.planeetKBrightGreen]: '#27A09E',
  },
  contrast: {
    [EColor.green]: '#0538D8',
    [EColor.yellow]: '#000',
    [EColor.blue]: '#FFF',
  },
  spacings: {
    [ESpacing.small]: 8,
    [ESpacing.medium]: 16,
    [ESpacing.large]: 24,
    [ESpacing.extralarge]: 32,
  },
};
