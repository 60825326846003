import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Text } from '../Text/Text';
import { usePeeties } from './PeetieProvider';

export const Countdown: React.FC = () => {
  const { tickDuration, initialCount } = usePeeties();

  const isMountedRef = useRef(true);
  const timeoutRef = useRef<number>();
  const [count, setCount] = useState(initialCount);

  const countdownTick = useCallback(() => {
    if (timeoutRef.current !== undefined) clearTimeout(timeoutRef.current);
    if (!isMountedRef.current) return;
    setCount((currentCount) => currentCount - 1);
    timeoutRef.current = window.setTimeout(countdownTick, tickDuration);
  }, [tickDuration]);

  useEffect(() => {
    // Did mount
    const timeout = timeoutRef.current;
    timeoutRef.current = window.setTimeout(countdownTick, tickDuration);
    return () => {
      // Unmount
      isMountedRef.current = false;
      if (timeout !== undefined) clearTimeout(timeout);
    };
  }, [countdownTick, tickDuration]);

  const label = useMemo(() => {
    if (count < 1) return 'bijna';
    return count;
  }, [count]);

  return (
    <StyledWrap key={count}>
      <StyledCounter>{label}</StyledCounter>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledCounter = styled(Text)`
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 3px 3px 2px rgba(29, 2, 89, 0.5);
  @keyframes appear {
    0% {
      transform: scale(-0.5, -0.5) rotate(45deg);
    }
    100% {
      transform: scale(1, 1) rotate(0deg);
    }
  }
  animation: appear 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-fill-mode: forwards;
`;
