import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { TPostRoot } from '../../pages/ProgramPage/types';
import { EmojiReactionOverview, TEmojiReaction } from '../EmojiReactionOverview/EmojiReactionOverview';
import Services from '../../services/Services';
import useSession from '../../lib/useSession';
import { KetprofielModal } from '../KetprofielModal/KetprofielModal';
import { EMOJIS, TEmoji } from '../../lib/emoji';
import { ProgramContext } from '../../pages/ProgramPage/ProgramContext';

export type TPostReactionsProps = {
  post: TPostRoot;
};

export const PostReactions = (props: TPostReactionsProps) => {
  const { post } = props;
  const [myReaction, setMyReaction] = React.useState<TEmoji | null>(post.myReaction);

  const session = useSession();
  const [showLogin, setShowLogin] = useState(false);
  const { programTitle, programTabType } = useContext(ProgramContext);

  const handleEmojiClick = async (clickedReactionId: TEmoji) => {
    // Should be logged in
    if (!session) {
      setShowLogin(true);
      return;
    }
    const gdpr = `Reactie op Ketnet programma ${programTitle} - ${programTabType} op ${post.postedOn}`;
    const reactionId = myReaction === clickedReactionId ? null : clickedReactionId;
    // Optimistic update
    setMyReaction(reactionId);
    // Send to server
    try {
      await Services.bffService.setPostReaction({
        postId: post.id,
        reactionId: reactionId,
        gdpr,
      });
    } catch (e) {
      Services.loggerService.errorObject(`Failed to save reaction ${clickedReactionId} to post ${post.id}`, e);
      Services.monitoringService.captureException(e);
    }
  };

  const reactions = useMemo(() => {
    return EMOJIS.map((emoji) => {
      const reaction = post?.reactions?.find((reaction) => reaction.id === emoji);
      const count = (reaction?.count || 0) + (myReaction === emoji ? 1 : 0) + (post?.myReaction === emoji ? -1 : 0);
      const emojiReaction: TEmojiReaction = {
        id: emoji,
        count,
      };
      return emojiReaction;
    });
  }, [post, myReaction]);

  return (
    <>
      <StyledWrap>
        <EmojiReactionOverview reactions={reactions} activeEmojiKind={myReaction} onPressReaction={handleEmojiClick} />
      </StyledWrap>
      {showLogin && <KetprofielModal onClose={() => setShowLogin(false)} />}
    </>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    margin-bottom: ${theme.spacings.small}px;
  `}
`;
