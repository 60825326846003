import { TFragment } from './fragment';

const name = 'game';

const query = `
fragment ${name} on Game {
  id
  title
  imageUrl
  orientation
  gameUrl
  gameType
  requiresSdk
  hideCloseButton
  permissions
}
`;

export const gameFragment: TFragment = { name, query };

export interface TGame {
  id: string;
  title: string | null;
  imageUrl: string | null;
  orientation: TGameOrientation | null;
  gameUrl: string | null;
  requiresSdk: boolean;
  hideCloseButton: boolean;
  gameType: TGameType;
  permissions: TSdkGamePermission[];
}

export const SDK_GAME_PERMISSIONS = ['USERPROFILE', 'DATASTORAGE'] as const;

export type TSdkGamePermission = typeof SDK_GAME_PERMISSIONS[number];

export type TGameOrientation = 'LANDSCAPE' | 'PORTRAIT';

export type TGameType = 'COMPETITION' | 'POLL' | 'QUIZ' | 'GAME';
