import { gql } from '@apollo/client';
import { ETemplate } from '../../theme/styleguide';

const query = `
query getUpdates {
  updates {
    event {
      title
      description
      imageUrl
      buttonText
      link
      type
      isOnline
    }
    theming {
      logoUrl
      activeTemplate
      backgroundColor
      foregroundColor
      accentColor
    }
  }
}
`;

export const updatesQuery = gql`
  ${query}
`;

export interface TGetUpdatesResult {
  updates: TUpdates;
}

export interface TUpdates {
  event: TEvent | null;
  theming: TTheming | null;
}

export interface TEvent {
  title: string;
  description: string;
  imageUrl: string;
  buttonText: string;
  link: string;
  type: string;
  isOnline: boolean;
}

export interface TTheming {
  logo: string;
  theme: ETemplate;
  backgroundColor: string;
  foregroundColor: string;
}
