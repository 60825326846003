import React from 'react';
import { TSearchJrProgram, TSearchProgram, TSearchEpisode, TSearchGame } from './types';
import { SearchResultProgram } from './SearchResultProgram';
import { SearchResultJrProgram } from './SearchResultJrProgram';
import { SearchResultEpisode } from './SearchResultEpisode';
import { SearchResultGame } from './SearchResultGame';

export type TSearchResultProps = {
  data: TSearchProgram | TSearchJrProgram | TSearchEpisode | TSearchGame;
};

export const SearchResult = (props: TSearchResultProps) => {
  const { data } = props;

  switch (data.type) {
    case 'program':
      return <SearchResultProgram data={data} />;
    case 'jrprogram':
      return <SearchResultJrProgram data={data} />;
    case 'episode':
      return <SearchResultEpisode data={data} />;
    case 'game':
      return <SearchResultGame data={data} />;
    default:
      // Todo: Error reporting.
      return null;
  }
};
