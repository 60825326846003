import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { TButtonProps } from '../Button/Button';
import { mediaQuery } from '../../theme/mediaQuery';

export type TIconButtonProps = {
  backgroundColor?: EColor;
  icon?: JSX.Element;
  onClick?: TButtonProps['onClick'];
};

export const IconButton = (props: TIconButtonProps) => {
  const { backgroundColor = 'transparent', icon, onClick } = props;

  const theme = useTheme();

  const wrapBackgroundColor = useMemo(() => {
    return theme.colors[backgroundColor];
  }, [backgroundColor, theme]);

  return (
    <StyledWrap style={{ backgroundColor: wrapBackgroundColor }} onClick={onClick}>
      {icon}
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    cursor: pointer;
    border-radius: 100%;
    overflow: hidden;
    width: ${theme.components.iconButton.width}px;
    height: ${theme.components.iconButton.height}px;
    ${mediaQuery.tabletLandscape(`
      width: ${theme.components.iconButton.widthMobile}px;
      height: ${theme.components.iconButton.heightMobile}px;
    `)}}
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `}
`;
