import React from 'react';
import { TSwimlaneFilterItem } from './types';
import styled from 'styled-components';
import { Text } from '../Text/Text';
import { EColor } from '../../theme/styleguide';

export interface TSwimlaneFilterItemProps {
  data: TSwimlaneFilterItem;
}

export const SwimlaneFilterItem: React.FC<TSwimlaneFilterItemProps> = (props) => {
  const { data } = props;
  const { imageUrl, title, onClick, isActive, isCharacterDisabled, isDefaultFilter } = data;
  const AccentColor = () => {
    if (isCharacterDisabled) {
      return EColor.red;
    }
    if (isActive) {
      return EColor.yellow;
    } else {
      return EColor.green;
    }
  };
  const hasOpacity = () => {
    if (isCharacterDisabled && !isActive) {
      return true;
    } else if (isDefaultFilter) {
      return false;
    } else if (isActive) {
      return false;
    }
    return true;
  };

  return (
    <StyledWrap onClick={() => onClick()} className="filterItem">
      <StyledAvatar imageUrl={imageUrl} accentColor={AccentColor()} hasOpacity={hasOpacity()} isActive={isActive} />
      <StyledTitle tag="h3" color={EColor.darkPurple} size="medium" accentColor={AccentColor()} className="filterTitle">
        {title}
      </StyledTitle>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    cursor: pointer;
    width: ${theme.components.swimlane.images.filters.width}px;
    height: ${theme.components.swimlane.images.filters.height}px;
    &:not(:first-child) {
      margin-left: calc(${theme.spacings.small}px / 2);
    }
    &:not(:last-child) {
      margin-right: calc(${theme.spacings.small}px / 2);
    }
    position: relative;
    display: flex;
    justify-content: center;
    &:first-child > h3 {
      width: 100%;
    }
  `}
`;

const StyledAvatar = styled.div<{ imageUrl: string; accentColor: EColor; hasOpacity: boolean; isActive: boolean }>`
  ${({ theme, imageUrl, accentColor, hasOpacity, isActive }) => `
    width: calc(${theme.components.swimlane.images.filters.width}px - 32px);
    height: calc(${theme.components.swimlane.images.filters.height}px - 26px);
    background-image: url(${imageUrl});
    background-size: contain;
    background-position: center;
    border-radius: 50%;
    border: ${isActive ? `4px` : `2px`} solid ${theme.colors[accentColor]}};
    opacity: ${hasOpacity ? '0.5' : '1'};
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 0px;
  `}
`;

const StyledTitle = styled(Text)<{ accentColor: EColor }>`
  ${({ theme, accentColor }) => `
    display: flex;
    align-items: center;
    text-align: center;
    padding: 4px 8px;
    position: absolute;
    bottom: 10px;
    width: auto;
    justify-content: center;
    background-color: ${theme.colors[accentColor]};
    &.filterTitle::after,
    &.filterTitle::before {
      border: 1px solid ${theme.colors[accentColor]};
    }
  `}
`;
