import React from 'react';
import styled from 'styled-components';
import { AspectRatio } from '../AspectRatio/AspectRatio';
import { PlayIcon } from '../../assets/icons';
import { Link } from 'react-router-dom';
import Services from '../../services/Services';
import { Duration } from '../Duration/Duration';

export type TVideoThumbProps = {
  id: string;
  thumbnailUrl: string;
  duration: number | null;
};

export const VideoThumb = (props: TVideoThumbProps) => {
  const { id, thumbnailUrl, duration } = props;
  const link = '/' + Services.linkService.getSlugForId(id);
  return (
    <Link to={link}>
      <AspectRatio width={16} height={9}>
        <StyledWrap style={{ backgroundImage: `url(${thumbnailUrl})` }}>
          <StyledPlayIcon>
            <PlayIcon width={48} height={undefined} />
          </StyledPlayIcon>
        </StyledWrap>
        {duration && <Duration duration={duration} />}
      </AspectRatio>
    </Link>
  );
};

const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledPlayIcon = styled.div`
  width: 48px;
  height: 48px;
  opacity: 0;
  transition: 0.3s;
  ${StyledWrap}:hover & {
    opacity: 1;
  }
`;
