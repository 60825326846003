import BaseService from '../BaseService';
import { TContentService } from './TContentService';
import * as bff from '../BffService/types';
import {
  TPagecontentItem,
  TPagecontentItemHeader,
  TPagecontentItemHighlight,
  TPagecontentItemSwimlane,
  TPagecontentItemBackground,
  TPagecontentItemMultiHighlight,
  TPagecontentItemJumbotron,
} from '../../components/PagecontentItem/types';
import {
  TSwimlaneThumbnailsGameItem,
  TSwimlaneThumbnailsItem,
  TSwimlaneThumbnailsVideoItem,
  TSwimlaneHeroesItem,
  TSwimlaneBoxsetItem,
  TSwimlaneStoriesItem,
} from '../../components/Swimlane/types';
import {
  TProgram,
  TTab,
  TPagecontentTab,
  TPlaylistsTab,
  TFeedTab,
  TLinkTab,
  TPostEdge,
  TPostRoot,
  TPostComment,
  TctaPostRoot,
  TPaginatedPost,
  TPinboardTab,
  TPinboardItemEdge,
  TPinboardRow,
} from '../../pages/ProgramPage/types';
import { TTheme } from '../../pages/ThemePage/types';
import { THeader } from '../../components/Header/types';
import { TTrackingData, TVideoDetail, TVideoSuggestion } from '../../pages/VideoDetailPage/types';
import {
  TSearchProgram,
  TSearchJrProgram,
  TSearchResult,
  TSearchEpisode,
  TSearchGame,
} from '../../pages/SearchPage/types';
import { TPage } from '../../pages/AemPage/types';
import { TImageStoreProfile } from '../LinkService/TLinkService';
import * as story from '../../components/StoryViewer/types';
import { TDigitalData } from '../AnalyticsService/TAnalyticsService';
import { TPost, TPostMedia, TPostAuthor } from '../BffService/Post';
import { TPostMedia as TPostMediaAlias } from '../../pages/ProgramPage/types';
import ketprofiel from '../../assets/icons/ketprofiel-yellow.svg';
import { TPostCommentAuthor } from '../BffService/PostComment';
import { TPeetie } from '../../components/Peetie/types';
import { TGame } from '../../pages/GameDetailPage/types';
import { GAME_TYPES, TGameType } from '../../components/GameTypeIcon/types';
import { EColor, styleguide } from '../../theme/styleguide';
import { TctaPost } from '../BffService/ctaPost';

const BACKGROUND_POSITIONS_X = ['right'] as const;
const BACKGROUND_POSITIONS_Y = ['top'] as const;

const STORY_REACTIONS: story.TStoryReactionName[] = ['love', 'crazy', 'shock', 'sad', 'shit'];

const DEFAULT_AUTHOR_AVATAR = ketprofiel;

export class ContentService extends BaseService implements TContentService {
  public mapPage(id: string, page: bff.TPage, canonical: string | null): TPage {
    const error = `Unmapped pageType: ${page.pageType}`;
    const analyticsId = this.services.linkService.removeAemSuffix(id);
    const pageTitle = this.mapPageTitle(id, page);
    switch (page.pageType) {
      case 'pagecontent':
        return {
          canonical,
          pageTitle,
          pageType: 'pagecontent',
          analyticsId,
          items: this.mapPagecontent(page.pagecontent),
        };
      case 'program':
        return {
          canonical,
          pageTitle,
          pageType: 'program',
          analyticsId,
          ...this.mapProgram(page),
        };
      case 'theme':
        return {
          canonical,
          pageTitle,
          pageType: 'theme',
          analyticsId,
          ...this.mapTheme(page),
        };
      case 'video':
        return {
          canonical,
          pageTitle,
          pageType: 'video',
          analyticsId,
          ...this.mapVideoDetail(page),
        };
      case 'game':
        return {
          canonical,
          pageTitle,
          pageType: 'game',
          analyticsId,
          ...this.mapGameDetail(page),
        };
      default:
        throw new Error(error);
    }
  }

  public mapPageTitle(id: string, page: bff.TPage): string | null {
    switch (page.pageType) {
      case 'video':
        return page.titleVideodetail;
      default:
        if (page.title) {
          return page.title;
        }
        const idWithoutAemSuffix = this.services.linkService.removeAemSuffix(id);
        const idWithoutAemInfo = this.services.linkService.removeAemPrefix(idWithoutAemSuffix);
        const fallbackTitle = idWithoutAemInfo
          .split('/')
          // trim leading/trailing whitespace
          .map((part) => part.trim())
          // dash to space
          .map((part) => part.replace(/-+/g, ' '))
          // filter out empty parts
          .filter((part) => part.length > 0)
          // reverse the order, as the last part is the most specific, thus relevant in terms of pageTitle
          .reverse()
          // Take first element
          .shift();
        if (fallbackTitle) {
          return fallbackTitle.substr(0, 1).toUpperCase() + fallbackTitle.substr(1);
        }
        return null;
    }
  }

  public mapPagecontent(pagecontent: bff.TPagecontentItem[]): TPagecontentItem[] {
    const all = pagecontent.map(this.mapPagecontentItem).filter(this.nonNullFilter);
    return all.map((item, idx) => {
      let background: TPagecontentItemBackground | null = null;
      if (
        item.type === 'swimlane' &&
        (item.style === 'thumbnails' || item.style === 'boxset' || item.style === 'stories') &&
        idx + 1 < all.length
      ) {
        const prev = idx > 0 ? all[idx - 1] : null;
        if (!prev || prev.type !== 'swimlane' || prev.style === 'heroes' || prev.style === 'stories') {
          if (item.style === 'stories') {
            background = {
              imageType: 'stories',
              positionX: 'left',
              positionY: 'bottom',
            };
          } else if (item.style === 'thumbnails') {
            background = {
              imageType: 'arrows-down',
              positionX: 'right',
              positionY: 'top',
            };
          } else {
            background = {
              imageType: this.randomElement(['arrows-down']),
              positionX: this.randomElement(BACKGROUND_POSITIONS_X),
              positionY: this.randomElement(BACKGROUND_POSITIONS_Y),
            };
          }
        }
      }
      return { ...item, background } as TPagecontentItem;
    });
  }

  public mapGameDetail(game: bff.TGame): TGame {
    return game;
  }

  public mapTheme(theme: bff.TTheme): TTheme {
    const { title, pagecontent, header, ...rest } = theme;
    return {
      ...rest,
      header: this.mapProgramOrThemeHeader(header, title),
      pagecontent: this.mapPagecontent(pagecontent),
    };
  }

  public mapProgram(program: bff.TProgram): TProgram {
    const { title, tabsV2, header, ...rest } = program;
    return {
      ...rest,
      header: this.mapProgramOrThemeHeader(header, title),
      tabs: tabsV2.map(this.mapProgramTab).filter(this.nonNullFilter),
    };
  }

  public mapVideoDetail(video: bff.video.TVideo): TVideoDetail {
    const { trackingData, vrtPlayer, likedByMe, likes, suggestions, ...rest } = video;
    return {
      suggestions: suggestions?.map(this.mapVideoSuggestion) ?? null,
      ...rest,
      vrtPlayerConfig: { ...vrtPlayer, aspectRatio: '16:9' },
      trackingData: trackingData ?? this.mapDefaultTrackingData(video),
      likes: likes ?? 0,
      likedByMe: likedByMe ?? false,
    };
  }

  mapVideoSuggestion = (suggestion: bff.video.TVideoSuggestion): TVideoSuggestion => {
    return {
      ...suggestion,
      type: 'video',
    };
  };

  public mapContentfeedPosts(data: bff.TPostConnection): TPaginatedPost[] {
    const posts = data.edges.map((edge) => this.mapPostType(edge));
    return posts;
  }

  public mapContentfeedPostComments(data: bff.TPostCommentConnection | null): TPostComment[] {
    if (!data || !data.edges) {
      return [];
    }

    const postComments = data.edges.map((edge) => this.mapPostComment(edge.node));
    return postComments;
  }

  public mapPaginatedPinboardRows(data: bff.TPinboardItemConnection): TPinboardRow[] {
    const posts = data.edges.map((edge) => this.mapPinboardRow(edge));
    return posts;
  }

  mapDefaultTrackingData(video: bff.video.TVideo): TTrackingData {
    return {
      seasonName: null,
      programName: null,
      episodeName: video.titleVideodetail,
      episodeNr: null,
      episodeBroadcastDate: null,
    };
  }

  mapProgramOrThemeHeader = (header: bff.THeader | null, title: string | null): THeader => {
    return {
      // Defaults.
      description: null,
      imageUrl: null,
      logoUrl: null,
      height: 'LARGE',
      // Partial header (has no title).
      ...header,
      title: title ?? '<geen titel>',
    };
  };

  mapProgramTab = (tab: bff.TTab): TTab | null => {
    switch (tab.type) {
      case 'playlists':
        return this.mapProgramPlaylistsTab(tab);
      case 'pagecontent':
        return this.mapProgramPagecontentTab(tab);
      case 'feed':
        return this.mapProgramFeedTab(tab);
      case 'pinboard':
        return this.mapProgramPinboardTab(tab);
      case 'link':
        return this.mapProgramLinkTab(tab);
      default:
        // ignore unsupported types
        return null;
    }
  };

  mapProgramPlaylistsTab = (tab: bff.TPlaylistsTab): TPlaylistsTab | null => {
    return tab;
  };

  mapProgramPagecontentTab = (tab: bff.TPagecontentTab): TPagecontentTab | null => {
    const { pagecontent, ...rest } = tab;
    return {
      ...rest,
      pagecontent: this.mapPagecontent(pagecontent),
    };
  };

  mapProgramLinkTab = (tab: bff.TLinkTab): TLinkTab => {
    const { linkItem, ...rest } = tab;
    return {
      ...rest,
      linkItem,
      link: this.mapLinkItem(linkItem.id),
    };
  };

  mapProgramFeedTab = (tab: bff.TFeedTab): TFeedTab | null => {
    const posts = tab.paginatedPosts.edges.map((edge) => this.mapPostType(edge));
    const next = tab.paginatedPosts.next;
    const totalCount = tab.paginatedPosts.totalCount;
    const disableComments = tab.disableComments;
    const theming = tab.theming;
    const { paginatedPosts, ...rest } = tab;
    let mappedTheming = {
      backgroundColor: EColor.darkPurple,
      buttonColor: EColor.green,
      loginButtonColor: EColor.yellow,
    };
    if (theming) {
      for (let [key, value] of Object.entries(theming)) {
        (mappedTheming as any)[key] = this.getEnumKeyByEnumValue(styleguide.colors, value);
      }
    }
    if (tab.paginatedPosts.edges.length < 1) {
      return null;
    }
    return {
      ...rest,
      paginatedPosts: posts,
      theming: mappedTheming,
      disableComments,
      next,
      totalCount,
    };
  };

  mapProgramPinboardTab = (tab: bff.TPinboardTab): TPinboardTab | null => {
    if (tab.paginatedPinboardRows.edges.length < 1) {
      return null;
    }

    const pinboardRows = tab.paginatedPinboardRows.edges.map((edge) => this.mapPinboardRow(edge));
    const next = tab.paginatedPinboardRows.next;
    const totalCount = tab.paginatedPinboardRows.totalCount;
    const { paginatedPinboardRows, ...rest } = tab;

    return {
      ...rest,
      paginatedPinboardRows: pinboardRows,
      next,
      totalCount,
    };
  };

  mapPinboardRow(edge: TPinboardItemEdge): any {
    const pinboardItems = edge.node.items.map((item) => this.mapPinboardItem(item));
    return { items: pinboardItems };
  }

  mapPinboardItem(item: any): any {
    const { media, ...rest } = item;
    return {
      ...rest,
      media: media && this.mapPostMedia(media, null), // Todo !!!!!
    };
  }

  getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : enumValue;
  }

  mapPagecontentItem = (item: bff.TPagecontentItem) => {
    switch (item.type) {
      case 'header':
        return this.mapHeader(item);
      case 'highlight':
        return this.mapHighlight(item);
      case 'jumbotron':
        return this.mapJumbotron(item);
      case 'multiHighlight':
        return this.mapMultiHighlight(item);
      case 'swimlane':
        return this.mapSwimlane(item);
      default:
        this.logger.errorObject(`Unmapped item type`, item);
        return null;
    }
  };

  mapHeader = (header: bff.THeader): TPagecontentItemHeader | null => {
    const { imageUrl, ...rest } = header;
    return {
      imageUrl: imageUrl,
      ...rest,
    };
  };

  mapHighlight = (highlight: bff.THighlight): TPagecontentItemHighlight | null => {
    const { link: _link, linkItem: _linkItem, ...rest } = highlight;
    return {
      link: this.mapLink(highlight),
      ...rest,
    };
  };

  mapMultiHighlight = (multiHighlight: bff.TMultiHighlight): TPagecontentItemMultiHighlight | null => {
    const { highlights, ...rest } = multiHighlight;
    return {
      highlights: (highlights ?? []).map(this.mapHighlight).filter(this.nonNullFilter),
      ...rest,
    };
  };

  mapJumbotron = (jumbotron: bff.TJumbotron): TPagecontentItemJumbotron | null => {
    const { link, linkText, ...rest } = jumbotron;
    return {
      link: this.mapAbsoluteLink(link, linkText),
      linkText,
      ...rest,
    };
  };

  mapAbsoluteLink = (link: string | null, title: string | null): string | null => {
    if (link) {
      // livecenter usecase
      if (link.startsWith('https://live.ketnet.be/ketnet/channels/')) {
        // only allow livecenter via 'event' aem page for highlight links
        return '/livecenter';
      } else if (link.startsWith('https://')) {
        return this.services.linkService.getEmbedLink(title, link);
      } else if (link.startsWith('/content/ketnet/nl/') || link.startsWith('content/ketnet/nl/')) {
        return '/' + this.services.linkService.removeAemPrefix(link);
      }
    }
    return null;
  };

  mapLink = (item: bff.THighlight): string | null => {
    const { link, linkItem, title } = item;
    if (link) {
      return this.mapAbsoluteLink(link, title);
    }
    const id = linkItem?.id;
    if (id) {
      return this.getLinkToAemId(id);
    }
    return null;
  };

  mapSwimlane = (swimlane: bff.TSwimlane): TPagecontentItemSwimlane | null => {
    let res: TPagecontentItemSwimlane | null;
    switch (swimlane.style) {
      case 'boxset':
        res = this.mapBoxsetSwimlane(swimlane);
        break;
      case 'heroes':
        res = this.mapHeroesSwimlane(swimlane);
        break;
      case 'stories':
        res = this.mapStoriesSwimlane(swimlane);
        break;
      case 'thumbnails':
        res = this.mapThumbnailsSwimlane(swimlane);
        break;
      default:
        this.logger.errorObject(`Unmapped swimlane style`, swimlane);
        return null;
    }
    if (res && res.items.length === 0) {
      this.logger.debug(`filtering out empty swimlane ${swimlane.id}`);
      return null;
    }
    return res;
  };

  mapBoxsetSwimlane = (data: bff.TSwimlane): TPagecontentItemSwimlane | null => {
    const { items, title, id } = data;
    return {
      id,
      analyticsId: `boxset | ${title}`,
      title: title,
      type: 'swimlane',
      style: 'boxset',
      items: (items ?? []).map(this.mapBoxsetSwimlaneItem).filter(this.nonNullFilter),
    };
  };

  mapBoxsetSwimlaneItem = (item: bff.TSwimlaneItem): TSwimlaneBoxsetItem | null => {
    switch (item.type) {
      case 'theme':
        return this.mapBoxsetThemeSwimlaneItem(item);
      case 'program':
        return this.mapBoxsetProgramSwimlaneItem(item);
      case 'video':
        return this.mapBoxsetVideoSwimlaneItem(item);
      default:
        this.logger.errorObject(`Boxset swimlane contained item of type ${item.type}`, item);
        return null;
    }
  };

  mapBoxsetThemeSwimlaneItem = (item: bff.TSwimlaneTheme): TSwimlaneBoxsetItem | null => {
    const { id, title, imageUrl } = item;
    const link = this.getLinkToAemId(id);
    if (!link) {
      return null;
    }
    return {
      link,
      alt: title ?? id,
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
      type: 'boxset',
    };
  };

  mapBoxsetProgramSwimlaneItem = (item: bff.TSwimlaneProgram): TSwimlaneBoxsetItem | null => {
    const { id, title, imageUrl } = item;
    const link = this.getLinkToAemId(id);
    if (!link) {
      return null;
    }
    return {
      link,
      alt: title ?? id,
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
      type: 'boxset',
    };
  };

  mapBoxsetVideoSwimlaneItem = (item: bff.TSwimlaneVideo): TSwimlaneBoxsetItem | null => {
    const { id, title, imageUrl } = item;
    const link = this.getLinkToAemId(id);
    if (!link) {
      return null;
    }
    return {
      link,
      alt: title ?? id,
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
      type: 'boxset',
    };
  };

  mapHeroesSwimlane = (data: bff.TSwimlane): TPagecontentItemSwimlane | null => {
    const { items, id } = data;
    return {
      id,
      analyticsId: 'swimlane | heroes',
      type: 'swimlane',
      style: 'heroes',
      items: (items ?? []).map(this.mapHeroesSwimlaneItem).filter(this.nonNullFilter),
    };
  };

  mapHeroesSwimlaneItem = (item: bff.TSwimlaneItem): TSwimlaneHeroesItem | TPeetie | null => {
    switch (item.type) {
      case 'theme':
        return this.mapHeroesThemeSwimlaneItem(item);
      case 'program':
        return this.mapHeroesProgramSwimlaneItem(item);
      case 'peetie':
        return this.mapHeroesPeetieSwimlaneItem(item);
      default:
        this.logger.errorObject(`Heroes swimlane contained item of type ${item.type}`, item);
        return null;
    }
  };

  mapHeroesPeetieSwimlaneItem = (item: bff.TSwimlanePeetie): TPeetie | null => {
    if (!item.imageUrl && !item.imageUrls) {
      return null;
    }
    const { imageUrls, imageUrl, ...rest } = item;
    return {
      ...rest,
      imageUrls,
      imageUrl: imageUrls[(Math.random() * imageUrls.length) | 0],
    };
  };

  mapHeroesThemeSwimlaneItem = (item: bff.TSwimlaneTheme): TSwimlaneHeroesItem | null => {
    const { id, title, imageUrl, logoUrl, accentColor, animation } = item;
    const link = this.getLinkToAemId(id);
    if (!link) {
      return null;
    }
    return {
      link,
      alt: title ?? id,
      accentColor: this.normalizeColor(accentColor),
      logoUrl: this.useImageStoreProfile(logoUrl, 'w480hx'),
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
      animation: animation ?? null,
      type: 'hero',
    };
  };

  mapHeroesProgramSwimlaneItem = (item: bff.TSwimlaneProgram): TSwimlaneHeroesItem | null => {
    const { id, title, imageUrl, logoUrl, accentColor } = item;
    const link = this.getLinkToAemId(id);
    if (!link) {
      return null;
    }
    return {
      link,
      alt: title ?? id,
      accentColor: this.normalizeColor(accentColor),
      logoUrl: this.useImageStoreProfile(logoUrl, 'w480hx'),
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
      type: 'hero',
    };
  };

  mapStoriesSwimlane = (swimlane: bff.TSwimlane): TPagecontentItemSwimlane | null => {
    const { id } = swimlane;
    const items = (swimlane.items ?? []).map(this.mapStorySwimlaneItem).filter(this.nonNullFilter);
    // pass a reference to the swimlane items to each individual item
    for (const item of items) {
      item.items = items;
    }
    return {
      id,
      analyticsId: 'swimlane | stories',
      type: 'swimlane',
      style: 'stories',
      items,
    };
  };

  mapStorySwimlaneItem = (item: bff.TSwimlaneItem): TSwimlaneStoriesItem | null => {
    if (item.type !== 'story') {
      this.logger.errorObject(`Invalid story swimlane item.type`, item);
      return null;
    }
    const { title, imageUrl, id, topReaction } = item;
    return {
      id,
      title: title ?? id,
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
      topReaction: topReaction && this.mapStoryReaction(topReaction),
      items: [], // no reference yet to the complete list of items at this point
      type: 'story',
    };
  };

  mapThumbnailsSwimlane = (swimlane: bff.TSwimlane): TPagecontentItemSwimlane | null => {
    const { id } = swimlane;
    const items = (swimlane.items ?? []).map(this.mapThumbnailSwimlaneItem).filter(this.nonNullFilter);
    if (this.services.configService.environment !== 'development' && (swimlane.items?.length ?? 0 > items.length)) {
      this.logger.warn(`Swimlane ${swimlane.id} lost some items...`);
    }
    return {
      id,
      analyticsId: `swimlane | ${swimlane.title}`,
      type: 'swimlane',
      style: 'thumbnails',
      title: swimlane.title,
      items,
    };
  };

  mapThumbnailSwimlaneItem = (item: bff.TSwimlaneItem): TSwimlaneThumbnailsItem | null => {
    switch (item.type) {
      case 'game':
        return this.mapThumbnailGameSwimlaneItem(item);
      case 'video':
        return this.mapThumbnailVideoSwimlaneItem(item);
      default:
        this.logger.errorObject(`Thumbnail swimlane contained item of type ${item.type}`, item);
        return null;
    }
  };

  mapThumbnailGameSwimlaneItem = (item: bff.TSwimlaneGame): TSwimlaneThumbnailsGameItem | null => {
    const { title, imageUrl, type, id } = item;
    const gameType = this.mapGameType(item.gameType);
    const link = this.getLinkToAemId(id);
    if (!link) {
      return null;
    }

    return {
      link,
      type,
      title: title ?? '<geen titel>',
      gameType: this.mapGameType(gameType),
      genre: this.mapGameGenre(gameType),
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
    };
  };

  private mapGameType(gameType: bff.TGameType): TGameType {
    if (GAME_TYPES.includes(gameType)) {
      return gameType;
    }
    this.logger.warn(`Unknown gameType: ${gameType}`);
    return 'GAME';
  }

  private mapGameGenre(gameType: bff.TGameType): string | null {
    switch (gameType) {
      case 'GAME':
        return 'Spelen';
      case 'COMPETITION':
        return 'Wedstrijd';
      case 'POLL':
        return 'Stemmen';
      case 'QUIZ':
        return 'Quiz';
    }
  }

  mapThumbnailVideoSwimlaneItem = (item: bff.TSwimlaneVideo): TSwimlaneThumbnailsVideoItem | null => {
    const { duration, imageUrl, id, type, titleSwimlane, subtitleSwimlane } = item;
    const link = this.getLinkToAemId(id);
    if (!link) {
      return null;
    }
    return {
      duration,
      imageUrl: this.useImageStoreProfile(imageUrl, 'w480hx_j80'),
      link,
      subtitle: subtitleSwimlane,
      title: titleSwimlane,
      type,
    };
  };

  mapSearchResult = (result: bff.search.TSearchResult): TSearchResult => {
    return {
      title: result.search.title,
      programs: result.search.programs.map(this.mapSearchProgram).filter(this.nonNullFilter),
      jrPrograms: result.search.jrPrograms.map(this.mapSearchJrProgram).filter(this.nonNullFilter),
      episodes: result.search.episodes.map(this.mapSearchEpisodes).filter(this.nonNullFilter),
      games: result.search.games.map(this.mapSearchGames).filter(this.nonNullFilter),
      pagecontent: this.mapPagecontent(result.search.pagecontent),
    };
  };

  mapStoryDetails = (data: bff.story.TStoryDetails): story.TStoryDetails | null => {
    const overlay = data.overlay ? this.mapStoryOverlay(data.overlay) : null;
    if (!overlay) {
      return null;
    }
    // only show gradient on reactions overlays
    const background = data.background ? this.mapStoryBackground(data, data.background, overlay.type) : null;
    if (!background) {
      return null;
    }
    const { id, title } = data;
    return {
      id,
      title: title ?? id,
      overlay,
      background,
    };
  };

  mapPostType(edge: TPostEdge): TctaPostRoot | TPostRoot {
    switch (edge.node.type) {
      case 'ctaPost':
        return this.mapCtaPost(edge.node);
      case 'mediaPost':
        return this.mapPost(edge.cursor, edge.node);
    }
  }

  mapCtaPost(node: TctaPost): TctaPostRoot {
    const link = this.mapLinkItem(node.ctaLinkItem.id);
    return {
      id: node.id,
      title: node.title,
      description: node.description,
      ctaLinkItem: node.ctaLinkItem,
      ctaText: node.ctaText,
      ctaAlt: node.ctaAlt,
      link,
      type: 'CTAPOST',
    };
  }

  mapLinkItem(link: string): string | null {
    const linkWithoutPrefix = this.services.linkService.removeAemSuffix('/' + link);
    return this.mapAbsoluteLink(this.services.linkService.removeAemSuffix(linkWithoutPrefix), null);
  }

  mapPost(cursor: string, node: TPost): TPostRoot {
    const author = node.author ? this.mapPostAuthor(node.author) : null;
    const media = node.media ? this.mapPostMedia(node.media, node) : null;
    const reactions = node.reactions || [];
    const paginatedComments = this.mapContentfeedPostComments(node.paginatedComments);
    const { id, message, postedOn, myReaction, sticky } = node;
    const totalCount = node.paginatedComments ? node.paginatedComments.totalCount : 0;
    const next = node.paginatedComments ? node.paginatedComments.next : null;
    const disableComments = !!node.disableComments;

    return {
      id,
      author,
      message,
      postedOn,
      media,
      reactions,
      comments: paginatedComments,
      myReaction,
      sticky,
      cursor,
      totalCount,
      next,
      type: 'MEDIAPOST',
      disableComments,
    };
  }

  mapPostMedia(input: TPostMedia, root: TPost | null): TPostMediaAlias {
    // Todo!!!
    switch (input.type) {
      case 'video':
        return {
          type: 'VIDEO',
          vrtPlayerConfig: {
            ...input.vrtPlayer,
            aspectRatio: input.vrtPlayer.aspectRatio || '16:9',
          },
          poster: input.scaledPoster,
          digitalData: root ? this.services.analyticsService.getFeedVideoDD(root) : null,
        };

      case 'picture':
        return {
          type: 'PICTURE',
          url: input.scaledImage.medium,
        };
    }
  }

  private mapPostComment(input: TPostComment): TPostComment {
    const { author, ...rest } = input;
    return {
      ...rest,
      author: this.mapPostCommentAuthor(author),
    };
  }

  private mapPostAuthor(input: TPostAuthor): TPostAuthor {
    const { name, avatarUrl } = input;
    return {
      name: name,
      avatarUrl: avatarUrl || DEFAULT_AUTHOR_AVATAR,
    };
  }

  private mapPostCommentAuthor(input: TPostCommentAuthor): TPostCommentAuthor {
    const { name, avatarUrl } = input;
    return {
      name: name,
      avatarUrl: avatarUrl || DEFAULT_AUTHOR_AVATAR,
    };
  }

  private mapStoryBackground(
    story: bff.story.TStoryDetails,
    data: bff.story.TStoryBackground,
    overlayType: story.TStoryOverlayType
  ): story.TStoryBackground | null {
    switch (data.type) {
      case 'image':
        return this.mapStoryImageBackground(data, overlayType);
      case 'video':
        return this.mapStoryVideoBackground(story, data, overlayType);
      default:
        return null;
    }
  }

  private mapStoryImageBackground(
    data: bff.story.TStoryImageBackground,
    overlayType: story.TStoryOverlayType
  ): story.TStoryImageBackground | null {
    return {
      overlayType,
      ...data,
    };
  }

  private mapStoryVideoBackground(
    story: bff.story.TStoryDetails,
    data: bff.story.TStoryVideoBackground,
    overlayType: story.TStoryOverlayType
  ): story.TStoryVideoBackground | null {
    const { vrtPlayer, ...rest } = data;
    if (!data.imageUrl) {
      return null;
    }
    return {
      overlayType,
      ...rest,
      vrtPlayer: {
        ...vrtPlayer,
        aspectRatio: '9:16',
        preloadMode: 'auto',
      },
      digitalData: this.mapStoryVideoDigitalData(story),
    };
  }

  private mapStoryVideoDigitalData(story: bff.story.TStoryDetails): TDigitalData | null {
    const { id } = story;
    const title = story.title ?? id;
    return this.services.analyticsService.getDigitalData({
      id,
      title,
      pagePathname: this.getLinkToAemId(id) ?? '/stories/<missing id>',
      site: {
        section1: 'home',
        section2: 'stories',
      },
      program: {
        name: 'ketnet story',
      },
      episode: {
        name: title,
      },
      media: {
        media_subtype: 'story',
      },
    });
  }

  private mapStoryOverlay(data: bff.story.TStoryOverlay): story.TStoryOverlay | null {
    switch (data.type) {
      case 'calltoaction':
        return this.mapStoryCallToActionOverlay(data);
      case 'reactions':
        return this.mapStoryReactionsOverlay(data);
      case 'poll':
        return this.mapStoryPollOverlay(data);
      default:
        return null;
    }
  }

  private mapStoryPollOverlay(data: bff.story.TStoryPollOverlay): story.TStoryPollOverlay | null {
    return data;
  }

  private mapStoryCallToActionOverlay(
    data: bff.story.TStoryCallToActionOverlay
  ): story.TStoryCallToActionOverlay | null {
    const { link: externalLink, linkItem, buttonText: rawButtonText } = data;
    const buttonText = rawButtonText ?? '<knop tekst>';
    let link: string | null;
    if (linkItem) {
      link = this.getLinkToAemId(linkItem.id);
    } else if (externalLink) {
      link = externalLink;
    } else {
      this.logger.warn(`Invalid overlay: missing linkItem`);
      return null;
    }
    if (!link) {
      return null;
    }
    return {
      type: 'calltoaction',
      buttonText,
      link,
    };
  }

  private mapStoryReactionsOverlay(data: bff.story.TStoryReactionsOverlay): story.TStoryReactionsOverlay | null {
    const myReaction = data.myReaction && this.isStoryReaction(data.myReaction) ? data.myReaction : null;
    const result: story.TStoryReactionsOverlay = {
      type: 'reactions',
      myReaction,
      reactions: STORY_REACTIONS
        // start from our own array to ensure reactions order
        .map((name) => data.reactions.find((r) => r.name === name) ?? { name, count: 0 })
        .map(this.mapStoryReaction)
        .filter(this.nonNullFilter),
    };
    return result;
  }

  private isStoryReaction(name: string): name is story.TStoryReactionName {
    return STORY_REACTIONS.indexOf(name as story.TStoryReactionName) >= 0;
  }

  private mapStoryReaction = (reaction: bff.story.TStoryReaction): story.TStoryReaction | null => {
    const { name, count } = reaction;
    if (!this.isStoryReaction(name)) {
      return null;
    }
    return {
      name,
      count,
    };
  };

  private mapSearchProgram = (program: bff.search.TSearchProgram): TSearchProgram | null => {
    const { id, imageUrl, title } = program;
    const link = '/' + this.services.linkService.getSlugForId(id);
    return {
      imageUrl,
      link,
      title,
      type: 'program',
    };
  };

  private mapSearchJrProgram = (program: bff.search.TSearchJrProgram): TSearchJrProgram | null => {
    const { vrtNuUrl, imageUrl, title } = program;
    return {
      vrtNuUrl,
      imageUrl,
      title,
      type: 'jrprogram',
    };
  };
  private mapSearchEpisodes = (episode: bff.search.TSearchEpisode): TSearchEpisode | null => {
    const { imageUrl, title, subtitle, duration } = episode;
    const id = this.getLinkToAemId(episode.id);
    if (!id) {
      return null;
    }
    return {
      id,
      imageUrl,
      title,
      subtitle,
      duration,
      type: 'episode',
    };
  };
  private mapSearchGames = (game: bff.search.TSearchGame): TSearchGame | null => {
    const { imageUrl, title, gameType } = game;
    const gameTypeDisplay = this.mapGameType(game.gameType);
    const id = this.getLinkToAemId(game.id);
    if (!id) {
      return null;
    }
    return {
      id,
      imageUrl,
      title,
      gameType,
      gameTypeDisplay: this.mapGameGenre(gameTypeDisplay),
      type: 'game',
    };
  };

  private getLinkToAemId(aemId: string): string | null {
    try {
      return '/' + this.services.linkService.getSlugForId(aemId);
    } catch (e) {
      this.logger.errorObject(`Failed to compute AEM link for id "${aemId}"`, e);
      return null;
    }
  }

  private nonNullFilter = <T>(item: T | null): item is T => !!item;

  private normalizeColor(color: string | null): string | null {
    if (!color) {
      return null;
    }
    return color.startsWith('#') ? color : `#${color}`;
  }

  private randomElement = <T>(arr: readonly T[]): T => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  private useImageStoreProfile = (url: string | null, profile: TImageStoreProfile): string | null => {
    if (!url) {
      return null;
    }
    return this.services.linkService.forceImageStoreProfile(url, profile);
  };
}
