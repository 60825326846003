import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Services from '../../services/Services';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { Embed } from './Embed';

const error = <ErrorPage title="Oeps! Hoe ben je hier beland?" />;

export const EmbedPage: React.FC = () => {
  const match = useRouteMatch<{ title: string; url: string }>({
    path: '/embed/:title/:url',
    strict: true,
    sensitive: true,
  });

  if (!match) {
    return error;
  }

  let { title, url } = match.params || {};
  try {
    title = decodeURIComponent(title);
    url = decodeURIComponent(url);
  } catch (e) {
    console.error(e);
    return error;
  }

  if (Services.linkService.getLinkType(url) !== 'embed') {
    return error;
  }

  return <Embed title={title} url={url} />;
};
