import React from 'react';
import { TSwimlaneHero, TAnimationType } from './types';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaQuery } from '../../theme/mediaQuery';
import { isSafari } from '../../lib/features';

export interface TSwimlaneHeroesItemProps {
  data: TSwimlaneHero;
}

export const SwimlaneHeroesItem: React.FC<TSwimlaneHeroesItemProps> = (props) => {
  const { imageUrl, logoUrl, accentColor, alt, link, animation = null } = props.data;
  const theme = useTheme();
  const borderColor = accentColor ?? theme.colors.yellow;
  return (
    <Link to={link}>
      <StyledWrap title={alt}>
        {imageUrl ? (
          <StyledImage imageUrl={imageUrl} borderColor={borderColor} animation={animation} isSafari={isSafari} />
        ) : (
          <StyledBackupWrap borderColor={borderColor}>{alt}</StyledBackupWrap>
        )}
        {logoUrl && <StyledLogo imageUrl={logoUrl} />}
      </StyledWrap>
    </Link>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    ${mediaQuery.netflixQuery(
      theme.components.swimlane.images.heroes.width,
      theme.components.swimlane.images.heroes.height
    )}
    position: relative;
    margin-right: ${theme.components.swimlane.horizontalSpacing}px;
    margin-bottom: 60px;
    pointer-events: none;
  `}
`;

const StyledImage = styled.div<{
  imageUrl: string;
  borderColor: string;
  animation: TAnimationType | null;
  isSafari: boolean;
}>`
  ${({ imageUrl, borderColor, animation }) => `
  width: 100%;
  height: 100%;
  border: 2px ${borderColor} solid;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-image: url(${imageUrl});
  ${animation === 'spin' && !isSafari && `-webkit-animation: spin 5s infinite linear;`}
`}
`;

const StyledBackupWrap = styled.div<{ borderColor: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
`;

const StyledLogo = styled.div<{ imageUrl: string }>`
  ${({ imageUrl }) => `
    position:absolute;
    top: 70%;
    left: 0;
    right: 0;
    height: 110px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-image: url(${imageUrl});
    margin: auto;
    ${mediaQuery.netflixWidthQuery(200)}
  `}
`;
