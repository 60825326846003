import { EColor, styleguide, ETemplate } from '../theme/styleguide';

const LS_LIVE_CONFIG = 'live_config';
export interface liveConfig {
  logoUrl: string;
  activeTemplate: ETemplate;
  backgroundColor: EColor;
  foregroundColor: EColor;
  accentColor: EColor;
}
export function useLiveConfig(): liveConfig {
  // Todo: enhance this with a useEffect to see if localstorage needs to change.
  const liveConfig = localStorage.getItem(LS_LIVE_CONFIG);
  const parsedConfig = liveConfig ? JSON.parse(liveConfig) : null;

  // Default values for theming.
  if (!parsedConfig) {
    return {
      logoUrl: 'https://static.ketnet.be/assets/ketnetbe-assets/logos/logo-ketnet-default.svg',
      activeTemplate: ETemplate.DEFAULT,
      backgroundColor: EColor.darkPurple,
      foregroundColor: EColor.blue,
      accentColor: EColor.blue,
    };
  }

  return {
    logoUrl: parsedConfig.logoUrl,
    activeTemplate: parsedConfig.activeTemplate,
    backgroundColor: getEnumKeyByEnumValue(styleguide.colors, parsedConfig.backgroundColor, EColor.darkPurple),
    foregroundColor: getEnumKeyByEnumValue(styleguide.colors, parsedConfig.foregroundColor, EColor.blue),
    accentColor: getEnumKeyByEnumValue(styleguide.colors, parsedConfig.accentColor, EColor.blue),
  };
}

function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
  defaultValue: EColor
): keyof T | EColor {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : defaultValue;
}
