export type TListenFn<TData> = (data: TData) => void;
export type TDetachListener = () => void;

class Listener<TListenerData> {
  private listeners: { [key: number]: TListenFn<TListenerData> };
  private idx: number;

  constructor() {
    this.listeners = {};
    this.idx = 0;
  }

  listen = (listener: TListenFn<TListenerData>): TDetachListener => {
    const handle = this.idx++;
    this.listeners[handle] = listener;
    return () => delete this.listeners[handle];
  };

  notify = (data: TListenerData) => {
    for (const listener of Object.values(this.listeners)) {
      listener(data);
    }
  };
}

export default Listener;
