import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TPinboardItem, TPinboardItemBackgroundDetail } from '../../pages/ProgramPage/types';

export type TPinboardItemDetailProps = {
  detail: TPinboardItemBackgroundDetail;
  parent: TPinboardItem;
};

const EXTRA_ROTATION = 30;

const loadImage = (setImageDimensions: any, imageUrl: string) => {
  const img = new Image();
  img.src = imageUrl;
  img.onload = () => {
    setImageDimensions({
      height: img.height,
      width: img.width,
    });
  };
};

export const PinboardItemDetail = (props: TPinboardItemDetailProps) => {
  const { detail, parent } = props;
  const { url, amount, position, rotation } = detail;
  const [imageDimensions, setImageDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    loadImage(setImageDimensions, url);
  }, [url]);

  if (!imageDimensions) {
    return null;
  }

  const parentWidth =
    position === 'backgroundTop' && parent?.background?.position.width ? parent.background.position.width : 1;
  const scaleFactor = parentWidth / 1000;
  const detailHeight = imageDimensions.height * scaleFactor;

  let detailLeft = 0;
  let detailTop = 0;
  let availableWidth = 1;

  if (parent?.background?.position) {
    if (position === 'backgroundTop') {
      detailTop = parent?.background?.position.top;
      detailLeft = parent?.background?.position.left;
      availableWidth = parent?.background?.position.width;
    } else if (position === 'bottom') {
      detailTop = parent?.background?.position.top + parent?.background?.position.height - 0.15; // So we are equal with app.
    }
  }

  if (amount === 'single') {
    return (
      <StyledDetailWrap
        width={availableWidth}
        height={detailHeight ? detailHeight : 0.2}
        top={detailTop}
        aside={detailLeft}
        rotation={rotation}
        positionX="center"
      >
        <StyledPinboardItemDetail background={url} />
      </StyledDetailWrap>
    );
  }

  if (amount === 'double') {
    const detailTopRotated = detailTop - detailHeight + 0.1;

    return (
      <>
        <StyledDetailWrap
          width={0.4}
          height={detailHeight}
          top={detailTopRotated}
          aside={0}
          rotation={-EXTRA_ROTATION}
          positionX="left"
        >
          <StyledPinboardItemDetail background={url} />
        </StyledDetailWrap>
        <StyledDetailWrap
          width={0.4}
          height={detailHeight}
          top={detailTopRotated}
          aside={0}
          rotation={EXTRA_ROTATION}
          positionX="right"
        >
          <StyledPinboardItemDetail background={url} />
        </StyledDetailWrap>
      </>
    );
  }

  return null;
};

const StyledDetailWrap = styled.div<{
  aside: number;
  top: number;
  width: number;
  height: number;
  rotation: number;
  positionX: string;
}>`
  ${({ aside, top, width, height, rotation, positionX }) => `
    max-width: 100%;
    width: ${width * 100}%;
    height: ${height * 100}%;
    top: ${(top - 0.03) * 100}%;
    z-index: 9;
    position: absolute;
    transform: rotate(${rotation}deg);
    ${(positionX === 'left' || positionX === 'center') && `right: unset; left: ${aside * 100}%;`}
    ${positionX === 'right' && `left: unset; right: ${aside * 100}%;`}
  `}
`;

const StyledPinboardItemDetail = styled.div<{ background: string }>`
  ${({ background }) => `
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 100%;
  `}
`;
