import React from 'react';

import {
  VRTMediaPlayer,
  IUIConfig,
  IVRTMediaPlayerConfig,
} from '../../services/VRTMediaPlayerService/IVRTMediaPlayerService';
import Services from '../../services/Services';
import styled from 'styled-components';
import { TDigitalData, TVrtPlayerConfig, TVrtPlayerUiConfig } from './types';

export interface TVrtPlayerProps {
  config: TVrtPlayerConfig;
  digitalData: TDigitalData;
  uiConfig: TVrtPlayerUiConfig;
  /**
   * If the underlying VRTPlayer/Theoplayer/Videoplayer methods are needed
   * pass a callback here that will be invoked once the player is ready to
   * be manipulated (when the 'canplay' event is fired from the underlying player)
   */
  onInitialized: (player: VRTMediaPlayer) => any;
  onError: () => any;
}

interface TVrtPlayerState {
  loading: boolean;
  error: boolean;
}

export class VrtPlayer extends React.Component<TVrtPlayerProps, TVrtPlayerState> {
  private isUnmounting: boolean = false;

  private initializationAnimationFrame: number | null = null;

  private container = React.createRef<HTMLDivElement>();

  private mediaPlayer: VRTMediaPlayer | null;

  private detachErrorHandler: (() => void) | null = null;

  constructor(props: TVrtPlayerProps) {
    super(props);
    this.mediaPlayer = null;
    this.state = {
      loading: true,
      error: false,
    };
  }

  async componentDidUpdate(oldProps: TVrtPlayerProps, oldState: TVrtPlayerState) {
    // track changing of mediaReference (e.g. videoDetail(vid A) => videoDetail(vid B))
    // to force rerender of the player
    if (oldProps.config.mediaReference !== this.props.config.mediaReference) {
      Services.loggerService.debug(`VrtPlayer.componentDidUpdate: mediaReference changed`);
      this.setState({ loading: true, error: false });
      // destroy the old player
      this.destroyPlayer();
      // initialize a new player
      this.initializePlayer();
    }
  }

  async componentDidMount() {
    this.initializePlayer();
  }

  componentWillUnmount() {
    this.isUnmounting = true;
    this.destroyPlayer();
  }

  render() {
    return (
      // force a re-render upon mediaReference change
      <StyledWrap key={this.props.config.mediaReference} ref={this.container} />
    );
  }

  private initializePlayer() {
    Services.loggerService.debug(`VrtPlayer.initializePlayer`);
    this.initializationAnimationFrame = window.requestAnimationFrame(async () => {
      const { config, digitalData } = this.props;
      const playerConfig: IVRTMediaPlayerConfig = {
        ...config,
        //cimId: Services.analyticsService.cimPlayerId,
        customErrorHandling: true,
      };
      const uiConfig: IUIConfig = {
        ...this.props.uiConfig,
        detachable: true,
        disableTitle: true,
        skin: 'ketnet',
        uiLanguage: 'nl',
      };
      // don't do anything when we're unmounting
      // e.g. storyplayer mounted and closed before initialization took place
      if (this.isUnmounting) {
        return;
      }
      try {
        const container = this.container.current;
        if (container === null) {
          throw new Error('expected container ref to be populated');
        }
        const api = (this.mediaPlayer = await Services.vrtMediaPlayerService.getPlayer({
          container,
          playerConfig,
          uiConfig,
          digitalData,
          errorHandler: this.handleError,
        }));
        // avoid writing setting state when unmounting is in progress
        if (!this.isUnmounting) {
          this.setState({ loading: false });
          if (this.props.onInitialized) {
            this.props.onInitialized(api);
          }
        }
      } catch (e) {
        // avoid writing setting state when unmounting is in progress
        if (!this.isUnmounting) {
          this.setState({ error: true, loading: false });
        }
        Services.monitoringService.captureException(e);
      }
    });
  }

  private destroyPlayer() {
    if (this.detachErrorHandler) {
      try {
        this.detachErrorHandler();
      } catch (e) {
        Services.loggerService.errorObject(`failed to detachError handler.destroy failed`, e);
        Services.monitoringService.captureException(e);
      }
    }
    if (this.mediaPlayer) {
      try {
        this.mediaPlayer.destroy();
      } catch (e) {
        // potential memleak -- VrtPlayer.destroy does not handle the use-case well where the container is
        // removed during the destroy process and destroy does not indicate when it is done.
        // see https://atlas.vrt.be/jira/browse/VMP-355
        Services.loggerService.errorObject(`mediaPlayer.destroy failed`, e);
        Services.monitoringService.captureException(e);
      }
    }
    if (this.initializationAnimationFrame !== null) {
      window.cancelAnimationFrame(this.initializationAnimationFrame);
    }
  }

  private handleError = (event: CustomEvent) => {
    if (this.isUnmounting) {
      return;
    }
    const msg = `VrtMediaPlayer.error ${event.detail?.message} (code: ${event.detail?.code})`;
    Services.loggerService.error(msg);
    Services.monitoringService.captureMessage(`${msg}\n${JSON.stringify(this.props.config)}`);
    this.props.onError();
  };
}

const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
`;
