import React from 'react';
import { TTab } from './types';
import { PlaylistsTab } from './PlaylistsTab';
import { PagecontentTab } from './PagecontentTab';
import { FeedTab } from './FeedTab';
import { PinboardTab } from './PinboardTab';

export interface TProgramTabProps {
  tab: TTab;
}

export const ProgramTab: React.FC<TProgramTabProps> = (props) => {
  const { tab } = props;

  switch (tab.type) {
    case 'pagecontent':
      return <PagecontentTab key={tab.name} tab={tab} />;
    case 'playlists':
      return <PlaylistsTab key={tab.name} tab={tab} />;
    case 'feed':
      return <FeedTab key={tab.name} tab={tab} />;
    case 'pinboard':
      return <PinboardTab key={tab.name} tab={tab} />;
    default:
      return <pre>{JSON.stringify(tab, null, 2)}</pre>;
  }
};
