import { useCallback, useState, useEffect } from 'react';

export const useMatchMedia = (mediaQueryName: string) => {
  const [isMatchMedia, setMatchMedia] = useState(window.matchMedia(mediaQueryName).matches);

  const checkIfMatchMedia = useCallback(() => {
    setMatchMedia(window.matchMedia(`${mediaQueryName}`).matches);
  }, [mediaQueryName]);

  useEffect(() => {
    window.addEventListener('resize', checkIfMatchMedia);
    return () => {
      window.removeEventListener('resize', checkIfMatchMedia);
    };
  }, [checkIfMatchMedia]);

  return isMatchMedia;
};
