import React from 'react';
import { TStoriesSize } from './StoriesSwimlane';

export interface TStoryContextValue {
  activeStoryIndex: number;
  requestPause: () => void;
  total: number;
  size: TStoriesSize;
  gotoNextStory: () => void;
}

export const StoryContext = React.createContext<TStoryContextValue>({
  activeStoryIndex: 0,
  requestPause: () => {},
  gotoNextStory: () => {},
  total: 0,
  size: {
    height: 0,
    width: 0,
    mode: 'single',
  },
});
