import React from 'react';
import styled from 'styled-components';
import { EColor } from '../../theme/styleguide';
import { ReactComponent as BorderSVG } from './border.svg';
import { mediaQuery } from '../../theme/mediaQuery';

type TBorderSize = 'large' | 'small';

type TPixelBorder = {
  color?: EColor;
  size?: TBorderSize;
};

export const PixelBorder: React.FC<TPixelBorder> = (props) => {
  const { color = EColor.blue, size = 'large' } = props;
  const border = <StyledBorder color={color} size={size} />;

  return (
    <>
      <StyledTopLeftBorder>{border}</StyledTopLeftBorder>
      <StyledTopRightBorder>{border}</StyledTopRightBorder>
      <StyledBottomLeftBorder>{border}</StyledBottomLeftBorder>
      <StyledBottomRightBorder>{border}</StyledBottomRightBorder>
    </>
  );
};

const StyledBorder = styled(BorderSVG)<{ color: EColor; size: TBorderSize }>`
  ${({ theme, size, color }) => `
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${size === 'large' ? 40 : 30}px;
    height: ${size === 'large' ? 40 : 30}px;
    & rect.border {
      stroke: ${theme.colors[color]};
      fill: ${theme.colors[color]};
    }

    ${mediaQuery.tabletLandscape(`
      width: ${size === 'large' ? 40 : 20}px;
      height: ${size === 'large' ? 40 : 20}px;
    `)}
  `}
`;

const StyledTopLeftBorder = styled.div`
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
`;

const StyledTopRightBorder = styled.div`
  position: absolute;
  z-index: 3;
  top: 0px;
  right: 0px;
  transform: rotate(90deg);
`;

const StyledBottomLeftBorder = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0px;
  left: 0px;
  transform: rotate(270deg);
`;

const StyledBottomRightBorder = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0px;
  right: 0px;
  transform: rotate(180deg);
`;
