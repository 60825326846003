import React from 'react';
import { TFeedTab } from './types';
import styled from 'styled-components';
import { Post } from '../../components/Post/Post';
import { CtaPost } from '../../components/Post/CtaPost';
import { Region } from '../../components/Region/Region';
import { EColor } from '../../theme/styleguide';
import { mediaQuery } from '../../theme/mediaQuery';
import { Button } from '../../components/Button/Button';
import { LoadMoreIcon } from '../../assets/icons';
import { loadSpecificPosts } from '../../services/FeedService/FeedService';
import { Text } from '../../components/Text/Text';
import { TSwimlane, TSwimlaneFilterItem } from '../../components/Swimlane/types';
import { Swimlane } from '../../components/Swimlane/Swimlane';
import Services from '../../services/Services';
import { useTheme } from 'styled-components';

const ALL_CHARACTERS_ID = 'all_characters';

export interface TFeedTabProps {
  tab: TFeedTab;
}

export const FeedTab: React.FC<TFeedTabProps> = (props) => {
  const [posts, setPosts] = React.useState(props.tab.paginatedPosts || []);
  const [next, setNext] = React.useState(props.tab.next);
  const feedTheming = props.tab.theming;
  const disableComments = props.tab.disableComments;
  const [total, setTotal] = React.useState(props.tab.totalCount);
  const [isAtEnd, setIsAtEnd] = React.useState(props.tab.paginatedPosts.length >= total);
  const { filterOnCharacters, characters, characterFiltersTitle } = props.tab;
  const [filterId, setFilterId] = React.useState<string>(ALL_CHARACTERS_ID);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const theme = useTheme();

  const loadPosts = async (filter: string | null, reset: boolean) => {
    setIsLoading(true);
    const activeFilter = !filter || (filter === filterId && reset === true) ? ALL_CHARACTERS_ID : filter;
    setFilterId(activeFilter);
    Services.analyticsService.trackEvent('ketnetEvent', 'wrapperFilter', activeFilter);
    const specificPosts = await loadSpecificPosts(props.tab.id, activeFilter === filterId ? next : null, activeFilter);
    setTotal(specificPosts.totalCount);
    const updatedPosts = activeFilter === filterId ? posts.concat(specificPosts.posts) : specificPosts.posts;
    setPosts(updatedPosts);
    const nextCursor = updatedPosts.length < specificPosts.totalCount ? specificPosts.next : null;
    setNext(nextCursor);
    setIsAtEnd(!nextCursor);
    setIsLoading(false);
  };

  const noPosts = () => {
    const activeCharacter = characters.find((character) => character.id === filterId);
    if (!activeCharacter) {
      return 'Nog geen berichten beschikbaar';
    }
    if (!isLoading) {
      return `${activeCharacter.title} heeft nog geen berichten geplaatst, kom later terug!`;
    }
    return '';
  };

  const items: TSwimlaneFilterItem[] =
    characters &&
    characters.map((character) => {
      return {
        id: character.id,
        imageUrl: character.imageUrl,
        title: character.title,
        alt: character.title,
        isCharacterDisabled: character.characterDisabled,
        isActive: !!(character.id === filterId),
        isDefaultFilter: !!(filterId === ALL_CHARACTERS_ID),
        type: 'character',
        onClick: () => loadPosts(character.id, true),
      };
    });

  const data: TSwimlane = {
    id: `feed-${props.tab.id}-filters`,
    analyticsId: `${props.tab.id} | filters`, // used for analytics tracking
    style: 'filters',
    items,
  };

  return (
    <Region color={theme.colors.transparent} fullMobileWidth={true}>
      {filterOnCharacters && (
        <StyledSwimlaneWrap>
          {characterFiltersTitle && <StyledFilterTitle size="large">{characterFiltersTitle}</StyledFilterTitle>}
          <Swimlane data={data} />
        </StyledSwimlaneWrap>
      )}
      <StyledWrap>
        {posts.map((post, idx) => {
          if (post && post.type) {
            switch (post.type) {
              case 'MEDIAPOST':
                return (
                  <Post
                    key={idx}
                    data={post}
                    feedTheming={feedTheming}
                    disableComments={disableComments}
                    totalCount={post.totalCount}
                    next={post.next}
                  />
                );
              case 'CTAPOST':
                return <CtaPost key={idx} data={post} />;
            }
          }
          return null;
        })}
        {!posts.length && <StyledMessage>{noPosts()}</StyledMessage>}
        {!isLoading && (
          <StyledCount>
            {posts.length} van de {total} berichten
          </StyledCount>
        )}
        {!isAtEnd && (
          <StyledButton
            text="meer berichten tonen"
            primaryColor={EColor[feedTheming.buttonColor]}
            textColor={EColor.white}
            onClick={() => loadPosts(filterId, false)}
            icon={<LoadMoreIcon />}
            variant="filledAndBorders"
          />
        )}
      </StyledWrap>
    </Region>
  );
};

const StyledSwimlaneWrap = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacings.extralarge}px 0 64px 0;
    ${mediaQuery.tabletPortrait(`
      margin-left: ${theme.spacings.small}px;
      mergin-right: ${theme.spacings.small}px;
    `)}
  `}
`;

const StyledFilterTitle = styled(Text)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.extralarge}px;
  `}
`;

const StyledWrap = styled.div`
  ${({ theme }) => `
    width: 100%;
    max-width: ${theme.pages.program.feed.maxWidth}px;
  `}
  ${mediaQuery.tabletPortrait(`
    display: flex;
    flex-direction: column;
    align-items: center;
  `)}
`;

const StyledMessage = styled(Text)`
  text-align: center;
`;

const StyledCount = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.white};
    display: flex;
    padding: 0 ${theme.spacings.medium}px;
    margin: ${theme.spacings.medium}px 0;
    justify-content: center;
  `}
`;

const StyledButton = styled(Button)`
  ${({ theme }) => `
    width: 100%;
    max-width: ${theme.pages.program.feed.maxWidth}px;
  `}
`;
