import React from 'react';
import styled from 'styled-components';
import { Region } from '../Region/Region';
import { Text } from '../Text/Text';

export interface TPageLoadingProps {}

export const PageLoading: React.FC<TPageLoadingProps> = (props) => {
  return (
    <StyledWrap>
      <Region>
        <Text>Bezig met laden...</Text>
      </Region>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  height: 100%;
`;
