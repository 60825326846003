import React from 'react';
import useSWR from 'swr';
import { sleep } from '../../lib/sleep';
import Services from '../../services/Services';
import { ETemplate } from '../../theme/styleguide';

// How long to wait for the initial event fetch to avoid CLS penalty and animation
const INITIAL_DELAY_MS = 1000 * 5;
export const LS_LIVE_CONFIG = 'live_config';

export interface TUpdatesResult {
  updates: TUpdates;
}

export interface TUpdates {
  event: TEvent | null;
  theming: TTheming | null;
}

export interface TTheming {
  logo: string;
  theme: ETemplate;
  backgroundColor: string;
  foregroundColor: string;
}

export interface TEvent {
  buttonText?: string;
  imageUrl: string;
  link: string;
  title: string;
  isOnline: boolean;
}

let isInitial = true;

export const updatesFetcher = async (): Promise<TUpdatesResult | null> => {
  if (isInitial) {
    isInitial = false;
    await sleep(INITIAL_DELAY_MS);
  }
  return Services.bffService.getUpdates(null).then((response) => response);
};

export const useUpdates = () => {
  const [closedEvent, setClosedEvent] = React.useState<string | null>(null);
  const { data } = useSWR('updates', updatesFetcher, {
    refreshInterval: 1000 * 60, // poll every 60s
    refreshWhenHidden: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateOnMount: true,
  });

  // Todo: add checkup.
  if (data?.updates?.theming) {
    // Todo mapping set colors as ECOLORS, because.
    localStorage.setItem(LS_LIVE_CONFIG, JSON.stringify(data?.updates?.theming));
  }

  // as long as event query does not contain userspecific data, there's no need to invalidate cache

  return {
    event:
      data?.updates?.event && closedEvent !== data?.updates?.event?.link && data.updates?.event?.isOnline === true
        ? data?.updates?.event
        : null,
    closeEvent: () => setClosedEvent(data?.updates?.event?.link ?? null),
  };
};
