import { useMatchMedia } from '../hooks/useMatchMedia';
import { mediaQuery } from './mediaQuery';

// Based on typescales: https://type-scale.com/

const generateFontSizes = ({ baseSize, typeScale }: { baseSize: number; typeScale: number }) => {
  return {
    small: Math.round(Math.pow(typeScale, -1) * baseSize),
    regular: baseSize,
    medium: Math.round(Math.pow(typeScale, 1) * baseSize),
    large: Math.round(Math.pow(typeScale, 2) * baseSize),
    xlarge: Math.round(Math.pow(typeScale, 3) * baseSize),
  };
};

export const RESPONSIVE_FONT_SIZES = {
  mobile: generateFontSizes({ typeScale: 1.125, baseSize: 15 }),
  tablet: generateFontSizes({ typeScale: 1.125, baseSize: 16 }),
  desktop: generateFontSizes({ typeScale: 1.2, baseSize: 16 }),
};

export type TFontSizes = typeof RESPONSIVE_FONT_SIZES;
type TBreakpoint = keyof TFontSizes;
export type TTextSize = keyof typeof RESPONSIVE_FONT_SIZES[TBreakpoint];

export const useFontSizes = () => {
  const isMobile = useMatchMedia(mediaQuery.mobileQuery);
  const isDesktop = useMatchMedia(mediaQuery.desktopQuery);
  const isTablet = !isMobile && !isDesktop;

  if (isMobile) return RESPONSIVE_FONT_SIZES.mobile;
  if (isTablet) return RESPONSIVE_FONT_SIZES.tablet;
  return RESPONSIVE_FONT_SIZES.desktop;
};

export const useFontSize = ({ size }: { size: TTextSize }) => {
  const fontSizes = useFontSizes();
  return fontSizes[size];
};

export const injectFontSize = (size: TTextSize) => {
  return `
    ${mediaQuery.mobile(`
      font-size: ${RESPONSIVE_FONT_SIZES.mobile[size]}px;
      line-height: ${RESPONSIVE_FONT_SIZES.mobile[size] * 1.3}px;
    `)}
    ${mediaQuery.tablet(`
      font-size: ${RESPONSIVE_FONT_SIZES.tablet[size]}px;
      line-height: ${RESPONSIVE_FONT_SIZES.tablet[size] * 1.3}px;
    `)}
    ${mediaQuery.desktop(`
      font-size: ${RESPONSIVE_FONT_SIZES.desktop[size]}px;      
      line-height: ${RESPONSIVE_FONT_SIZES.desktop[size] * 1.3}px;
    `)}
  `;
};
