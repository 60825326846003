import { useState, useLayoutEffect, useRef } from 'react';

export interface TDimensionObject {
  width: number;
  height: number;
}

export type TUseDimensionsResult<TElement> = [React.RefObject<TElement>, TDimensionObject | null];

function getDimensionObject(node: HTMLElement): TDimensionObject {
  const rect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
  };
}

export function useDimensions<TElement extends HTMLElement>(): TUseDimensionsResult<TElement> {
  const [dimensions, setDimensions] = useState<null | TDimensionObject>(null);
  const ref = useRef<TElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const node = ref.current;
      const measure = () => {
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
      };
      measure();

      node.addEventListener('resize', measure);
      node.addEventListener('scroll', measure);

      return () => {
        node.removeEventListener('resize', measure);
        node.removeEventListener('scroll', measure);
      };
    }
  }, [ref]);

  return [ref, dimensions];
}

export default useDimensions;
