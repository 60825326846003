import React from 'react';
import { Region } from '../../components/Region/Region';
import { TVideoDetail } from './types';
import { EColor } from '../../theme/styleguide';
import styled, { useTheme } from 'styled-components';
import { Suggestion } from './Suggestion';
import { findActivePlaylist } from './findActivePlaylist';
import { Button } from '../../components/Button/Button';
import { PlaylistItem } from './PlaylistItem';
import { mediaQuery } from '../../theme/mediaQuery';
import { VrtPlayer } from '../../components/VrtPlayer/VrtPlayer';
import { AspectRatio } from '../../components/AspectRatio/AspectRatio';
import { Spinner } from '../../components/Spinner/Spinner';
import Services from '../../services/Services';
import { TPageMetadata } from '../../services/AnalyticsService/TAnalyticsService';
import { useMatchMedia } from '../../hooks/useMatchMedia';
import { Swimlane } from '../../components/Swimlane/Swimlane';
import { TSwimlane } from '../../components/Swimlane/types';
import { TVideoSuggestion } from '../../services/BffService/Video';
import { LikeIcon, LikeOutlinedIcon } from '../../assets/icons';
import { toggleLike } from './toggleLike';
import { KetprofielModal } from '../../components/KetprofielModal/KetprofielModal';
import useSession from '../../lib/useSession';
import { Text } from '../../components/Text/Text';

export interface TVideoDetailProps {
  data: TVideoDetail;
  meta: TPageMetadata | null;
}

export const VideoDetail: React.FC<TVideoDetailProps> = (props) => {
  const video = props.data;
  const { description, vrtPlayerConfig, suggestions, playlists, titleVideodetail, subtitleVideodetail, metaTitle } =
    video;
  const [likes, setLikes] = React.useState<number>(props.data.likes);
  const [isLikedByMe, setIsLikedByMe] = React.useState<boolean>(props.data.likedByMe);
  const [showLogin, setShowLogin] = React.useState<boolean>(false);
  const session = useSession();
  const [activePlaylist, setActivePlaylist] = React.useState(findActivePlaylist(video));
  const [hasError, setHasError] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const digitalData = React.useMemo(
    () => props.meta && Services.analyticsService.getDigitalData(props.meta),
    [props.meta]
  );
  const isTabletPortraitDown = useMatchMedia(mediaQuery.mobileTabletPortraitQuery);
  const isDesktop = useMatchMedia(mediaQuery.desktopQuery);
  const data: TSwimlane = {
    id: 'swimlane-suggestions',
    analyticsId: 'swimlane | suggestions',
    style: 'suggestions',
    items: (suggestions ?? []).filter((item: TVideoSuggestion): item is TVideoSuggestion => !!item),
  };
  const likeIcon = isLikedByMe ? LikeIcon : LikeOutlinedIcon;
  const theme = useTheme();
  const likesText = isTabletPortraitDown
    ? likes > 0
      ? likes.toString()
      : ''
    : likes > 0
    ? likes.toString()
    : 'vind ik leuk';

  const handleToggleLike = () => {
    // require login
    if (!session) {
      setShowLogin(true);
      return;
    }
    // toggle the like icon
    setIsLikedByMe(!isLikedByMe);
    // update the count
    setLikes(isLikedByMe ? likes - 1 : likes + 1);
    // launch like process in background
    toggleLike(video, !isLikedByMe);
  };

  return (
    <Region color={theme.colors.transparent}>
      <StyledWrap>
        <StyledMainContent>
          <AspectRatio width={16} height={9}>
            <StyledPlayerContainer>
              {hasError ? (
                <StyledPlayerError>De video is momenteel niet beschikbaar.</StyledPlayerError>
              ) : (
                <>
                  {!isInitialized && <Spinner />}
                  <StyledPlayerWrapper isHidden={!isInitialized}>
                    <VrtPlayer
                      config={vrtPlayerConfig}
                      digitalData={digitalData}
                      uiConfig={{ posterImageOverrideUrl: video.scaledPoster?.medium }}
                      onError={() => setHasError(true)}
                      onInitialized={() => setIsInitialized(true)}
                    />
                  </StyledPlayerWrapper>
                </>
              )}
            </StyledPlayerContainer>
          </AspectRatio>
          <StyledVideoToolbar>
            <StyledTitleContainer>
              <StyledTitle tag="h2">{titleVideodetail}</StyledTitle>
              <StyledLikes onClick={handleToggleLike}>
                <StyledLikesText>{likesText}</StyledLikesText>
                <StyledLikeButton src={likeIcon} alt="vind ik leuk" />
              </StyledLikes>
            </StyledTitleContainer>
            {subtitleVideodetail && <StyledSubtitle>{subtitleVideodetail}</StyledSubtitle>}
            {metaTitle && <StyledMetaTitle>{metaTitle}</StyledMetaTitle>}
          </StyledVideoToolbar>
          {description && <StyledDescription>{description}</StyledDescription>}
          {playlists && (
            <StyledPlaylists>
              <StyledPlaylistsNav>
                {playlists.map((playlist, idx) => (
                  <Button
                    key={idx}
                    variant={playlist.name === activePlaylist?.name ? 'fill' : 'stroke'}
                    primaryColor={playlist.name === activePlaylist?.name ? EColor.red : EColor.transparent}
                    textColor={EColor.white}
                    borderColor={EColor.red}
                    text={playlist.title}
                    onClick={() => setActivePlaylist(playlist)}
                  />
                ))}
              </StyledPlaylistsNav>
              {activePlaylist && (
                <StyledActivePlaylist>
                  {activePlaylist.items.map((item, idx) => (
                    <PlaylistItem key={idx} item={item} />
                  ))}
                </StyledActivePlaylist>
              )}
            </StyledPlaylists>
          )}
        </StyledMainContent>
        {suggestions && (
          <StyledSuggestions>
            <StyledSuggestionsTitle>ook leuk voor jou</StyledSuggestionsTitle>
            {isDesktop ? (
              suggestions.map((suggestion) => <Suggestion key={suggestion.id} suggestion={suggestion} />)
            ) : (
              <Swimlane data={data} />
            )}
          </StyledSuggestions>
        )}
      </StyledWrap>
      {showLogin && <KetprofielModal onClose={() => setShowLogin(false)} isOpen />}
    </Region>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaQuery.tabletLandscape(`
    flex-direction: column;
  `)}
`;

const StyledMainContent = styled.div`
  flex: 0.7;
`;

const StyledSuggestions = styled.div`
  ${({ theme }) => `
    flex: 0.3;
    padding-left: ${theme.spacings.large}px;
    padding-right: ${theme.spacings.small}px;
    overflow: hidden;
    ${mediaQuery.tabletLandscape(`
      padding-left: 0px;
      margin-top: ${theme.spacings.extralarge}px;
    `)}
  `}
`;

const StyledVideoToolbar = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
`;

const StyledTitleContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  `}
`;

const StyledTitle = styled(Text)`
  ${({ theme }) => `
    display; flex;
    flex: 1;
    margin-right: ${theme.spacings.medium}px;
  `}
`;

const StyledLikes = styled.div`
  ${({ theme }) => `
    cursor: pointer;
    display: flex;
    margin-top: ${theme.spacings.small}px;
  `}
`;

const StyledLikesText = styled(Text)`
  ${({ theme }) => `
    text-align: right;
    display: flex;
    height: 32px;
    align-items: center;
    margin-right: ${theme.spacings.small}px;
  `}
`;

const StyledLikeButton = styled.img`
  width: 32px;
  height: 32px;
`;

const StyledPlaylists = styled.div`
  margin-top: 80px;
  & a:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacings.large}px;
  }
`;

const StyledPlaylistsNav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: space-around;
  margin-bottom: ${({ theme }) => theme.spacings.small}px;
  margin-top: -8px; /* only offset nav buttons on 2nd line */
  & > * {
    margin-top: 8px;
    margin-right: 8px;
  }
`;

const StyledActivePlaylist = styled.div`
  margin-top: ${({ theme }) => theme.spacings.large}px;
`;

const StyledPlayerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
`;

const StyledPlayerError = styled(Text)`
  ${({ theme: { colors } }) => `
    background-color: ${colors.red};
    width: 300px;
  `}
`;

const StyledPlayerWrapper = styled.div<{ isHidden: boolean }>`
  ${({ isHidden }) => `
    width: 100%;
    height: 100%;
    ${isHidden ? 'display: none;' : ''}
  `}
`;

const StyledSubtitle = styled(Text).attrs({ tag: 'h3' })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.large}px;
  `}
`;

const StyledMetaTitle = styled(Text)``;

const StyledDescription = styled(Text)``;

const StyledSuggestionsTitle = styled(Text).attrs({ tag: 'h3', size: 'medium' })`
  ${({ theme }) => `
    margin-bottom: ${theme.spacings.extralarge}px;
  `}
`;
