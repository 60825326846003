import React from 'react';
import styled from 'styled-components';

export type TSwimlaneButtonProps = {
  icon?: JSX.Element;
  onClick?: () => any;
};

export const SwimlaneButton = (props: TSwimlaneButtonProps) => {
  const { icon, onClick } = props;
  return <StyledWrap onClick={onClick}>{icon}</StyledWrap>;
};

const StyledWrap = styled.div`
  cursor: pointer;
  border-radius: 100%;
  overflow: hidden;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.green};
`;
