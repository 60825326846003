import React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../assets/icons';
import { Button } from '../../components/Button/Button';
import { Modal } from '../../components/Modal/Modal';
import { Text } from '../../components/Text/Text';
import { TRequestConsentOptions } from '../../services/GamesService/types';
import { EColor } from '../../theme/styleguide';

export interface TProps {
  options: TRequestConsentOptions;
  onClose: (isUpdated: boolean) => any;
}

export const RequestConsentModal: React.FC<TProps> = (props) => {
  const { onClose } = props;
  const { childTitle, childDescription, childButton } = props.options;
  const [showParentDashboard, setShowParentDashboard] = React.useState<boolean>(false);

  return (
    <Modal
      isOpen={true}
      onClose={() => onClose(showParentDashboard)}
      color={EColor.darkPurple}
      closeIcon={<StyledCloseIcon />}
    >
      <StyledWrap>
        <StyledMainContent>
          {showParentDashboard ? (
            <StyledIframe src="/parent/dashboard/access-request" />
          ) : (
            <StyledChildForm>
              <StyledTitle>{childTitle} </StyledTitle>
              <StyledDescription>{childDescription}</StyledDescription>
              <Button
                textColor={EColor.white}
                primaryColor={EColor.blue}
                onClick={() => setShowParentDashboard(true)}
                text={childButton}
              />
            </StyledChildForm>
          )}
        </StyledMainContent>
      </StyledWrap>
    </Modal>
  );
};

const StyledWrap = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 90vh;
  width: 385px;
  color: ${EColor.white};
`;

const StyledMainContent = styled.div`
  flex: 1;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
`;

const StyledChildForm = styled.div`
  ${({ theme }) => `
  padding: 32px;
  display: grid;  
  row-gap: ${theme.spacings.large}px;
`}
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${EColor.white};
`;

const StyledTitle = styled(Text).attrs({ tag: 'h2', color: EColor.yellow })``;

const StyledDescription = styled(Text).attrs({ tag: 'p' })``;
