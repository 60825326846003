import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { PeetieMovie } from './components/Peetie/PeetieMovie';
import { PeetieProvider } from './components/Peetie/PeetieProvider';
import { ProfileProvider } from './components/ProfileModal/ProfileContext';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { preloadImage } from './lib/image';
import { LoadingApp } from './LoadingApp';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import Services from './services/Services';
import MainSite from './subsites/MainSite'; // TODO: check impact of lazy loading MainSite

// use lazyloading for subsites to improve initial bundle size
const PrivacySite = React.lazy(() => import('./subsites/PrivacySite/PrivacySite'));

const App: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    Services.appService.initialize(history).then((success) => {
      if (success) {
        setIsLoaded(true);
      } else {
        setError(true);
      }
    });
  });

  if (error) {
    // TODO: don't use shared error page as this contains logic that might
    // not be available if appService.initialize failed
    return <ErrorPage title="Oeps! De website is momenteel niet bereikbaar. Probeer het later nog eens." />;
  }

  if (!isLoaded) {
    return <LoadingApp />;
  }

  return (
    <SWRConfig
      value={{
        // Global SWR config:
        // https://github.com/vercel/swr#global-configuration
        revalidateOnMount: true,
        revalidateOnFocus: false,
      }}
    >
      <ProfileProvider>
        <PeetieProvider tickDuration={650} preloadImage={preloadImage} logError={Services.loggerService.error}>
          <Suspense fallback={<div>Bezig met laden...</div>}>
            <Switch>
              {/* subsites */}
              <Route path="/privacy" component={PrivacySite} />
              {/* main site */}
              <Route component={MainSite} />
            </Switch>
          </Suspense>
          <PeetieMovie />
          <ScrollToTop />
        </PeetieProvider>
      </ProfileProvider>
    </SWRConfig>
  );
};

export default App;
