import React from 'react';
import styled from 'styled-components';

type TBullets = {
  length: number;
  currentIdx: number;
  idx: number;
  onClickBullets: (idx: number) => void;
};

const Bullets: React.FC<TBullets> = (props) => {
  const { length, currentIdx, onClickBullets, idx } = props;
  return (
    <StyledBullet key={length} type="button" isActive={idx === currentIdx} onClick={() => onClickBullets(currentIdx)} />
  );
};

const StyledBullet = styled.button<{ isActive?: boolean }>`
  ${({ isActive, theme }) => `
    display: flex;
    width: 12px;
    height: 12px;
    margin: 5px;
    cursor: pointer;
    border-style: none !important;
    background: ${theme.colors.darkRed};
    ${isActive && `background: ${theme.colors.yellow};`}
  `}
`;

export default Bullets;
