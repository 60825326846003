import React from 'react';
import styled from 'styled-components';
import { formatVideoDuration } from '../../lib/datetime';
import { Text } from '../Text/Text';

export type TDurationProps = {
  duration: number;
};

export const Duration = (props: TDurationProps) => {
  return (
    <StyledWrap>
      <StyledLabel>{formatVideoDuration(props.duration)}</StyledLabel>
    </StyledWrap>
  );
};

const StyledWrap = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 22px;
    min-width: 45px;
    padding: 0 ${theme.spacings.small}px;
    right: 12px;
    bottom: 12px;
    background: ${theme.colors.red};
  `}
`;

const StyledLabel = styled(Text)``;
