import { gql } from '@apollo/client';
import { includeDependencies } from './fragment';
import { TGameType } from './Game';
import { headerFragment } from './Header';
import { pagecontentItemFragment, TPagecontentItem } from './PagecontentItem';
import { storyReactionFragment } from './StoryReaction';

const dependencies = includeDependencies([pagecontentItemFragment, headerFragment, storyReactionFragment]);

const query = `
query Search($keyword:String!) {
  search(keyword:$keyword) {
    programs{
      id
      imageUrl
      title
      type
    }
    jrPrograms {
      vrtNuUrl
      imageUrl
      title
      type
    }
    games {
      id
      imageUrl
      title
      type
      gameType
    }
    episodes {
      id
      imageUrl
      title
      subtitle
      duration
      type
    }
    title
    pagecontent {
      ...${pagecontentItemFragment.name} 
    }
  }

}

${dependencies}
`;

export const searchQuery = gql`
  ${query}
`;

export interface TSearchArgs {
  keyword: string;
}

export interface TSearchResult {
  search: {
    title: string | null;
    programs: Array<TSearchProgram>;
    jrPrograms: Array<TSearchJrProgram>;
    episodes: Array<TSearchEpisode>;
    games: Array<TSearchGame>;
    pagecontent: TPagecontentItem[];
  };
}

export interface TSearchProgram {
  id: string;
  imageUrl: string;
  title: string;
  type: string;
}

export interface TSearchJrProgram {
  vrtNuUrl: string;
  imageUrl: string;
  title: string;
  type: string;
}
export interface TSearchEpisode {
  id: string;
  imageUrl: string;
  title: string;
  subtitle: string;
  duration: number;
  type: string;
}

export interface TSearchGame {
  id: string;
  imageUrl: string;
  title: string;
  gameType: TGameType;
  gameTypeDisplay: string | null;
  type: string;
}
