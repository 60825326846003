import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TStoryPollAnswer } from './types';

export interface TStoryPollAnswerProps {
  answer: TStoryPollAnswer;
  isSelected: boolean;
  onSelected: (answer: TStoryPollAnswer) => any;
  showPercentage: boolean;
  total: number;
}

function formatPercentage(pct: number) {
  return new Intl.NumberFormat('nl-BE', { maximumSignificantDigits: 4 }).format(pct * 100);
}

export const StoryPollAnswer: React.FC<TStoryPollAnswerProps> = (props) => {
  const { answer, onSelected, isSelected, showPercentage, total } = props;
  const percentage = total === 0 ? 0 : answer.count / total;
  const theme = useTheme();
  const isClickable = !showPercentage;

  const fillColor = isSelected ? theme.colors.darkYellow : theme.colors.darkBlue;
  const bgColor = isSelected ? theme.colors.yellow : theme.colors.blue;
  const colorText = isSelected ? theme.contrast.yellow : theme.contrast.blue;

  const colorLeft = fillColor;
  const colorRight = bgColor;

  const percentageText = showPercentage ? formatPercentage(percentage) : null;
  const gradientPercentage = showPercentage ? percentage * 100 : 0;
  const cornerLeft = showPercentage ? fillColor : bgColor;
  const cornerRight = showPercentage && percentage === 1 ? fillColor : bgColor;

  return (
    <StyledWrap onClick={() => isClickable && onSelected(answer)} isClickable={isClickable}>
      <ButtonCorner color={cornerLeft} />
      <StyledButton {...{ colorLeft, colorRight, gradientPercentage, colorText }}>
        <div>{answer.label}</div>
        {percentageText && <div>{percentageText}%</div>}
      </StyledButton>
      <ButtonCorner color={cornerRight} flipX />
    </StyledWrap>
  );
};

const ButtonCorner = (props: { color: string; flipX?: boolean }) => (
  <StyledCorner style={props.flipX ? { transform: 'scaleX(-1)' } : {}} color={props.color}>
    {/* border fix for HDPR screens: width + 1, margin - 1 */}
    <div style={{ height: 32, width: 5, marginRight: -1 }} />
    <div style={{ background: props.color, height: 40, width: 5, marginRight: -1 }} />
  </StyledCorner>
);

const StyledCorner = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  height: 100%;
  & > * {
    background: ${({ color }) => color};
    width: 4px;
  }
`;

const StyledWrap = styled.div<{ isClickable: boolean }>`
  width: 100%;
  ${({ isClickable }) => (isClickable ? 'cursor: pointer;' : '')}
  height: 48px;
  user-select: none;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.div<{
  colorText: string;
  colorLeft: string;
  colorRight: string;
  gradientPercentage: number;
}>`
  ${({ colorText, colorLeft, colorRight, gradientPercentage, theme }) => `
  flex-grow: 1;
  background: linear-gradient(90deg, ${colorLeft} ${gradientPercentage}%, ${colorRight} ${gradientPercentage}%);
  padding: ${theme.spacings.small}px;
  color: ${colorText};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`}
`;
