import BaseService from '../BaseService';
import { TImageStoreProfile, TLinkService, TLinkType } from './TLinkService';

const WHITELISTED_DOMAINS = ['ketnet.be', 'vrt.be', 'qualtrics.com', 'www.ketnet-junior.be', 'ketnetvoorouders.be'];

const ID_PREFIX = 'content/ketnet/nl/';
const ID_SUFFIX = '.model.json';

export class LinkService extends BaseService implements TLinkService {
  removeAemSuffix(id: string) {
    if (id.endsWith(ID_SUFFIX)) {
      return id.substr(0, id.length - ID_SUFFIX.length);
    }
    return id;
  }

  removeAemPrefix(id: string) {
    const prefix = `/${ID_PREFIX}`;
    if (!id.startsWith(prefix)) {
      return id;
    }
    return id.substring(prefix.length);
  }

  getSlugForId(id: string) {
    // remove preceding '/'
    if (id.startsWith('/')) {
      id = id.substr(1);
    }
    // remove static KETNET AEM prefix
    if (!id.startsWith(ID_PREFIX)) {
      throw new Error(`Invalid formatting for ID ${id} (wrong prefix)`);
    }
    id = id.substr(ID_PREFIX.length);
    // remove optional model.json suffix
    id = this.removeAemSuffix(id);
    return id;
  }

  getIdForSlug(slug: string) {
    if (slug === '') {
      // TODO: cleanup - prefix has trailing /, not useable for home
      return 'content/ketnet/nl.model.json';
    }
    return ID_PREFIX + slug + ID_SUFFIX;
  }

  slugify(text: string) {
    return (
      text
        .toLowerCase()
        // replace all non-alphanumeric by dashes
        .replace(/[^a-z0-9]/g, '-')
        // simplify repeating dashes
        .replace(/--+/, '-')
        // remove dash prefix
        .replace(/^-/, '')
        // remove dash suffix
        .replace(/-$/, '')
    );
  }

  /**
   * Takes a VRT imagestore url and converts the profile part to use the respective JPG Q=80 profile instead.
   * Warning, this renders a JPG so if the input was a PNG the alpha channel will be removed. Don't use for logos.
   * @param url VRT imagestore url, e.g. https://images.vrt.be/w1280hx/2021/01/04/<uuid>.png
   */
  forceImageStoreProfile(url: string, profile: TImageStoreProfile) {
    if (url.split('.').pop() === 'gif') {
      return url;
    }
    const rx = /images\.vrt\.be\/([a-zA-Z0-9_]+)\//;
    const match = rx.exec(url);
    if (!match) {
      return url;
    }
    const oldProfile = match[1];
    if (!oldProfile) {
      return url;
    }
    return url.replace(oldProfile, profile);
  }

  getLinkType(link: string): TLinkType {
    if (link.startsWith('/')) {
      return 'internal';
    }
    try {
      const { hostname } = new URL(link);
      if (WHITELISTED_DOMAINS.some((domain) => hostname === domain || hostname.endsWith(`${domain}`))) {
        return 'embed';
      }
    } catch (e) {
      this.logger.errorObject('Unable to get link type for link: invalid url', link);
    }
    return 'external';
  }

  getEmbedLink(title: string | null, link: string) {
    return `/embed/${encodeURIComponent(title ?? '-')}/${encodeURIComponent(link)}`;
  }

  isValidLivecenterUrl(url: string) {
    try {
      const link = new URL(url);
      if (link.protocol !== 'https:') return false;
      if (link.host !== 'live.ketnet.be') return false;
      return true;
    } catch {}
    return false;
  }
}
